// @flow
import React from 'react';

import type { TContext } from './constants/types';

const initial = {
	dragId: null,
	dropId: null,
	regDropPlace: () => {},
	unregDropPlace: () => {},
	onStart: () => {},
	onStop: () => {},
	onDrag: () => {},
};

const DragAbsoluteContext = React.createContext<TContext>(initial);

export default DragAbsoluteContext;
