// @flow
import React from 'react';
import { Button } from '@graphite/uneon';
import styled from '@emotion/styled';

type TProps = $ReadOnly<{|
	active: boolean,
	title: string,
	value: string,
	onClick: string => void,
|}>;

const ButtonStyled = styled(Button)`
	margin-right: 6px;

	&:last-of-type {
		margin-right: 0;
	}
`;

const ButtonPanel = ({ active, title, value, onClick }: TProps) => {
	const onClickBound = React.useCallback(() => onClick(value), [value, onClick]);

	return (
		<ButtonStyled
			variant="primaryflat.normal.sm"
			onClick={onClickBound}
			color={active ? 'text.accent' : 'text.primary'}
		>
			{title}
		</ButtonStyled>
	);
};

export default React.memo<TProps>(ButtonPanel);
