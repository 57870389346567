// @flow
import Transport from 'winston-transport';
import amplitude from 'amplitude-js';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	user: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	amplitudeKey: string,
	level: string,
|}>;

class AnalyticsTransport extends Transport {
	constructor(opts: TOpts) {
		const { amplitudeKey, user, ...otherOpts } = opts;
		super(otherOpts);

		amplitude.getInstance().init(amplitudeKey, null, {}, () => {
			this.apmlitudeClient = amplitude;
			if (user) {
				this.apmlitudeClient.setUserId(user.id);
				this.apmlitudeClient.setUserProperties({ email: user.email });
			}
		});
	}

	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, meta = {}, level, props } = logEntry;

		if (props) this.apmlitudeClient.setUserProperties(props);
		if (level !== 'info') return;
		this.apmlitudeClient.logEvent(message, meta, callback);
	}
}

export default AnalyticsTransport;
