// @flow
import firebase from '../firebase';

export const perf = (() => {
	if (process.env.NODE_ENV === 'production' && process.env.ENV_MODE !== 'server') {
		require('firebase/performance');
		return firebase.performance();
	}

	return {};
})();

export default perf;
