// @flow
import React from 'react';
import set from 'lodash/fp/set';
import { SelectSimple, Flex, Text } from '@graphite/uneon';
import Logger from '@graphite/logger';
import {
	defaultColorRef,
	defaultGradient,
	defaultShadow,
	defaultBgImage,
	defaultLink,
	libNames,
	libLabels,
	defaultValues,
} from '@graphite/constants';
import type {
	TId,
	TSpecsColor,
	TLibraryValue,
	TLibConfig,
	TLibName,
	TLibNames,
	TSpecsGrid,
	TWidgets,
	TImageLibraryListUploads,
} from '@graphite/types';

import Color from './Color';
import Gradient from './Gradient';
import Shadow from './Shadow';
import BgImage from './BgImage';
import Lynk from './Link';

type TProps = $ReadOnly<{|
	t: string => string,
	children?: ?React$Node,
	value?: TLibraryValue,
	config: TLibConfig,
	colorspec: TSpecsColor,
	onChange?: ?(TLibraryValue) => void,
	onPreview?: ?(TLibraryValue) => void,
	gridspec: TSpecsGrid,
	genId: string => string,
	images?: ?TWidgets,
	insertImage?: FileList => void,
	removeImage?: (imageId: TId) => void,
	resetImage?: () => void,
	uploads?: TImageLibraryListUploads,
|}>;

const logger = Logger.init();

const titleBoxStyle = {
	marginBottom: '18px',
};

// FixMe грязный хак чтобы подставить правильный стиль в селект
// Надо научить селект вместо кнопки подставлять обычный текст
const buttonSx = {
	marginTop: '-6px',
	'> div:first-of-type': {
		fontFamily: 'Epilogue, sans-serif',
		fontWeight: '800',
		fontSize: '20px',
		lineHeight: '27px',
	},
	'> div:last-of-type': {
		transform: 'scale(1.3)',
		marginLeft: '9px',
	},
	svg: {
		fill: 'text.primaryalt',
	},
};

function Library({
	t,
	children,
	value,
	config,
	colorspec,
	onPreview,
	onChange,
	gridspec,
	genId,
	images,
	uploads = {},
	insertImage = () => {},
	removeImage = () => {},
	resetImage = () => {},
}: TProps) {
	const bestLib: ?TLibName = (value && value.kind) || null;

	// For analytic
	React.useEffect(() => {
		if (bestLib !== 'link') logger.info('openColorPicker', { kind: bestLib });
	}, [bestLib]);

	const availableLibs: TLibNames = React.useMemo(
		() => libNames.filter(name => !!config[name]),
		[config],
	);
	const [activeLib, setActiveLib] = React.useState<TLibName>(
		(availableLibs.includes(bestLib) && bestLib) ||
			(config.at && availableLibs.includes(config.at) && config.at) ||
			'color',
	);
	React.useEffect(() => {
		const nextLib = (availableLibs.includes(bestLib) && bestLib) || null;
		if (nextLib && activeLib !== nextLib) {
			setActiveLib(nextLib);
		}
	}, [bestLib, availableLibs, activeLib]);

	const boundChange = React.useCallback(
		// eslint-disable-next-line no-shadow
		value => {
			if (onChange) {
				onChange(set('kind', activeLib, { value }));
			}
			if (bestLib !== 'link') logger.info('changeBgType', { pick: activeLib });
		},
		[bestLib, activeLib, onChange],
	);

	const boundPreview = React.useCallback(
		// eslint-disable-next-line no-shadow
		value => {
			if (onPreview) {
				onPreview(set('kind', activeLib, { value }));
			}
		},
		[activeLib, onPreview],
	);

	const buttonGroup = React.useMemo(
		() =>
			(availableLibs.length > 1 && {
				items: availableLibs.map(name => ({ name, label: libLabels[name] })),
				active: activeLib,
			}) ||
			null,
		[availableLibs, activeLib],
	);

	const changeLib = React.useCallback(
		(e, name) => {
			if (typeof name !== 'string') {
				return;
			}
			const libName = libNames.find(n => n === name);
			if (!libName) {
				return;
			}
			setActiveLib(libName);
			if (!onChange) {
				return;
			}
			onChange(defaultValues[libName]);
		},
		[onChange],
	);

	return (
		<>
			<Flex sx={titleBoxStyle}>
				{(buttonGroup && (
					<SelectSimple
						size="lg"
						list={buttonGroup}
						onClick={changeLib}
						buttonSx={buttonSx}
						colors="primaryaltflat"
					/>
				)) || (
					<Text variant="title4" color="text.primaryalt">
						{libLabels[activeLib]}
					</Text>
				)}
			</Flex>
			{children}
			{(activeLib === 'color' && (
				<Color
					tabs={(config.color && config.color.tabs) || null}
					value={
						(value && value.kind === 'color' && value.value) ||
						defaultColorRef
					}
					colorspec={colorspec}
					onChange={boundChange}
					onPreview={boundPreview}
				/>
			)) ||
				(activeLib === 'gradient' && (
					<Gradient
						value={
							(value && value.kind === 'gradient' && value.value) ||
							defaultGradient
						}
						onChange={boundChange}
						colorspec={colorspec}
						genId={genId}
					/>
				)) ||
				(activeLib === 'shadow' && (
					<Shadow
						value={
							(value && value.kind === 'shadow' && value.value) ||
							defaultShadow
						}
						onChange={boundChange}
						colorspec={colorspec}
					/>
				)) ||
				(activeLib === 'link' && (
					<Lynk
						value={
							(value && value.kind === 'link' && value.value) || defaultLink
						}
						onChange={boundChange}
					/>
				)) ||
				(activeLib === 'bgimage' && (
					<BgImage
						t={t}
						value={
							(value && value.kind === 'bgimage' && value.value) ||
							defaultBgImage
						}
						images={images}
						uploads={uploads}
						insertImage={insertImage}
						removeImage={removeImage}
						resetImage={resetImage}
						onChange={boundChange}
						onPreview={boundPreview}
						gridspec={gridspec}
					/>
				))}
		</>
	);
}

Library.defaultProps = {
	children: null,
	value: null,
	colorspec: null,
	onPreview: null,
	onChange: null,
};

export default React.memo<TProps>(Library);
