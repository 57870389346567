// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import styled from '@emotion/styled';
import { Flex, Box } from '@graphite/uneon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as iconsSolid from '@fortawesome/free-solid-svg-icons';
import * as iconsBrand from '@fortawesome/free-brands-svg-icons';
import getColor from '@graphite/get-color';
import type { TSx, TDesign } from '@graphite/types';
import { minHeight } from '@graphite/constants';
import { getBoxSx, getDesignSx } from '@graphite/selectors';

import useDynamicStyle from 'Widget/libs/use-dynamic-style';

import type { TConnectProps } from './constants/types';

const icons = _.assign(iconsSolid, iconsBrand);

const IconDynamic = styled(({ marginTop, marginLeft, ...rest }) => (
	// eslint-disable-next-line react/jsx-props-no-spreading
	<FontAwesomeIcon {...rest} />
))``;

const Icon = (props: TConnectProps, ref) => {
	const {
		data,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
		dragContainer,
		dragFlip,
		direction,
		position,
		isPassive = false,
	} = props;

	const {
		iconName,
		color,
		link,
		size = null,
		rotate = 0,
		scale = 100,
		marginTop = 0,
		marginLeft = 0,
	} = data;

	const transform = React.useMemo(() => {
		return { rotate };
	}, [rotate]);

	const boxSx: TSx = gridspec
		? getBoxSx({ data, position, gridspec, direction })
		: emptyObject;

	const sx: TSx = React.useMemo(() => {
		const inlineStyles = {};
		inlineStyles.justifyContent = 'center';

		if (!colorspec || !effectspec || !gridspec || !widgetspec) return inlineStyles;

		if (data.color?.value) {
			inlineStyles.color = getColor(colorspec, data.color?.value);
		}

		if (!data.designId) {
			const sz = size || minHeight;
			inlineStyles.fontSize = `${sz}px`;
			inlineStyles.width = inlineStyles.fontSize;
			inlineStyles.height = inlineStyles.fontSize;
			if (sz < minHeight) {
				inlineStyles.padding = `${Math.ceil((minHeight - sz) * 0.5)}px`;
			}
			return inlineStyles;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.icon.find(d => d._id === data.designId);
		if (!design) {
			return inlineStyles;
		}

		if (size) {
			inlineStyles.fontSize = `${size}px`;
			inlineStyles.width = inlineStyles.fontSize;
			inlineStyles.height = inlineStyles.fontSize;
			// FIXME: multidevice - этот код применяет минимальный
			// размер, но не умеет в адаптивность
			// eslint-disable-next-line max-len
			// @luis: я тут как то раз написал злоебучий код, который позволял иконке не сжиматься в мышиный анус, при выставлении неадекватных размеров. но код этот был заточен на то что приходит значение для 1 девайса. т.к. этот код не был затребован по ТЗ изначально, то и фиксинг его я не считаю строго необходимым в контексте адаптивности. но выпиливать тоже жалко, можно после мержа до ума довести. особенно когда станет очевидно что иконка сжимается, и это попадёт в ТЗ
			// if (size < minHeight) {
			// 	const paddingPx =
			// 		breakpoint.paddingUnit === 'px'
			// 			? breakpoint.padding
			// 			: breakpoint.padding * gridspec.unit;
			// 	const requiredPadding = Math.ceil((minHeight - size) * 0.5);
			// 	if (paddingPx < requiredPadding) {
			// 		inlineStyles.padding = `${requiredPadding}px`;
			// 	}
			// }
		}

		return {
			...getDesignSx({
				design,
				colorspec,
				effectspec,
				gridspec,
				widgetspec,
			}),
			...inlineStyles,
		};
	}, [
		data.color,
		data.designId,
		data.designs,
		size,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
	]);

	const boxProps = React.useMemo(() => {
		if (isPassive || !(link && link.href)) {
			return { as: 'div', sx };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : 'self',
			sx,
		};
	}, [isPassive, link, sx]);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);

	if (!colorspec) return null;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Box ref={ref} style={dynamicStyle} {...otherProps} sx={boxSx}>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Flex {...boxProps}>
				<IconDynamic
					icon={icons[iconName]}
					transform={transform}
					scale={scale}
					marginTop={marginTop}
					marginLeft={marginLeft}
					color={getColor(colorspec, color?.value)}
				/>
			</Flex>
		</Box>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Icon));
