// @flow
import React from 'react';
import type { TPropsResize } from '../constants/types';

import DragSide from './DragSide';

const Resize = (props: TPropsResize) => {
	const { onResizeStart, onResize, onResizeStop, padding } = props;

	return (
		<>
			<DragSide
				dir="top"
				height={padding.top}
				resizeUpdate={onResize}
				resizeStart={onResizeStart}
				resizeStop={onResizeStop}
			/>
			<DragSide
				dir="bottom"
				height={padding.bottom}
				resizeUpdate={onResize}
				resizeStart={onResizeStart}
				resizeStop={onResizeStop}
			/>
		</>
	);
};

export default React.memo<TPropsResize>(Resize);
