// @flow

import React from 'react';
import { Box, Flex, Text } from '@graphite/uneon';
import _ from 'lodash/fp';

import useCurrentDevice from 'Editor/libs/use-current-device';
import useDefaultDevice from 'Editor/libs/use-default-device';
import { zIndices, transitions } from '@graphite/constants';

import type {
	TProps,
	TMethodCheckColumnNumber,
	TMethodCheckSiblings,
	TMethodCheckVisible,
	TMethodCheckSmall,
} from './types';
import dndContext from '../dndContext';

const MIN_WIDTH = 150;
const MIN_HEIGHT = 150;

const checkColumnNumber: TMethodCheckColumnNumber = (widgets, rowId) =>
	_.size(
		_.pickBy(w => w.rowId === rowId && w.kind === 'col', {
			...widgets,
		}),
	) > 1;

const checkSiblings: TMethodCheckSiblings = (widgets, { containerId, rowId, widgetId }) =>
	_.some(
		widget =>
			widget.containerId === containerId &&
			widget.rowId === rowId &&
			widget.widgetId !== widgetId,
		widgets,
	);

const checkVisible: TMethodCheckVisible = (state, props) => {
	const { dragId, dragPlace, dropPlace, widgets } = state;

	const isAnotherContainerOver =
		dropPlace && dropPlace.containerId !== props.containerId;

	if (!dragId || isAnotherContainerOver) return false;

	const dragWidget = dragId ? widgets[dragId] : null;
	const dragType = dragWidget?.widgetType;

	const isBlockDrag = dragType === 'block';
	const isWidgetDrag = dragType === 'widget';

	const isEmptyDropPlace = !props.widgetId && !!props.containerId;
	const isHorizontal = props.direction === 'horizontal';
	const overComposeId = dragPlace?.composeId || null;
	const overWidget = overComposeId ? widgets[overComposeId] : dragWidget;
	const isCurrentRow = props.rowId === overWidget?.rowId;

	// show dropplace in empty block for column and widget? (dont sure)
	if (!isBlockDrag && isEmptyDropPlace) return true;

	if (!overWidget || !dragWidget || !isCurrentRow) return false;

	if (isWidgetDrag) {
		const isCurrentRow = props.rowId === overWidget.rowId;

		if (!isCurrentRow) return false;

		const isDraggedRow = props.rowId === dragWidget?.rowId;
		const isWidgetSamePlace =
			dragWidget.containerId === props.widgetId ||
			dragWidget.containerId === props.nextWidgetId;

		// hide place if the widget is the only child
		if (isDraggedRow && isWidgetSamePlace) {
			// if  column in row more than 1, show only horizontal
			if (isHorizontal && checkColumnNumber(widgets, props.rowId)) {
				return true;
			}
			return checkSiblings(widgets, dragWidget);
		}

		return true;
	}

	/* Раскоментить в будущем, когда появятся дроплейсы ровов для колонок
	if (dragType === 'column') {
		const isSameContainer = props.containerId === overWidget.containerId;
		if (!isHorizontal || !isSameContainer) return false; // ну тут наверняка будут другие условия позже

		// hide place if the column is the only child
		const isDraggedRow = props.rowId === dragWidget?.rowId;
		const isFilteredBySiblings = isDraggedRow
			? checkSiblings(widgets, dragWidget)
			: true;

		return isFilteredBySiblings;
	}
	*/

	return false;
};

const checkSmall: TMethodCheckSmall = (node, isHorizontal) => {
	const { width, height } = node.getBoundingClientRect();
	return isHorizontal ? width < MIN_WIDTH : height < MIN_HEIGHT;
};

// Drop Place Container

const placeGlobalSx = {
	position: 'relative',
	flexGrow: '0',
	flexShrink: '0',
	pointerEvents: 'none',
	zIndex: zIndices.dropPlace,
};

const placeSx = {
	horizontal: {
		...placeGlobalSx,
		width: '100%',
		height: '0',
	},
	vertical: {
		...placeGlobalSx,
		width: '0',
		height: 'auto',
	},
	full: {
		...placeGlobalSx,
		width: '100%',
		height: '100%',
	},
};

// Placeholder and Active Place wrapper

const flexSx = {
	position: 'absolute',
	alignItems: 'center',
	justifyContent: 'center',
	opacity: '0',
	willChange: 'opacity',

	transitionDuration: transitions.dropPlace.showDuration,
	transitionTimingFunction: transitions.dropPlace.showTiming,
	transitionDelay: transitions.dropPlace.showDelay,
	transitionProperty: 'opacity',
};

const flexHorizontalSx = {
	...flexSx,
	right: '0',
	left: '0',
	height: '24px',
};

const flexVerticalSx = {
	...flexSx,
	top: '0',
	bottom: '0',
	width: '20px',
	margin: '0 -10px',
	flexDirection: 'column',
};

const lineSx = {
	content: '""',
	position: 'absolute',
	backgroundColor: 'spec.blue10',
	borderRadius: 'sm.all',
	boxShadow: 'smblue',
};

const lineHorizontalSx = {
	...lineSx,
	height: '3px',
};
const lineVerticalSx = {
	...lineSx,
	width: '3px',
};

// Placeholder - shows where droplace located

const placeholderSx = {
	// variant for empty place
	full: {
		...flexHorizontalSx,
		height: '100%',
		width: '100%',
		':after': {
			...lineHorizontalSx,
			width: '60px',
			maxWidth: '100%',
		},
	},
	// variant for diffrent directions
	'horizontal-first': {
		...flexHorizontalSx,
		bottom: '100%',
		':after': {
			...lineHorizontalSx,
			width: '60px',
			maxWidth: '100%',
		},
	},
	'horizontal-middle': {
		...flexHorizontalSx,
		':after': {
			...lineHorizontalSx,
			width: '60px',
			maxWidth: '100%',
		},
	},
	'horizontal-last': {
		...flexHorizontalSx,
		top: '100%',
		':after': {
			...lineHorizontalSx,
			width: '60px',
			maxWidth: '100%',
		},
	},
	'vertical-first': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '60px',
			maxHeight: '100%',
		},
	},
	'vertical-middle': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '60px',
			maxHeight: '100%',
		},
	},
	'vertical-last': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '60px',
			maxHeight: '100%',
		},
	},
};

// Active Place – shown when droplace active
const activeSx = {
	full: {
		...flexHorizontalSx,
		top: '50%',
		marginTop: '-12px',
		marginLeft: 'auto',
		marginRight: 'auto',
		':after': {
			...lineHorizontalSx,
			width: '100%',
		},
	},
	'horizontal-first': {
		...flexHorizontalSx,
		bottom: '100%',

		':after': {
			...lineHorizontalSx,
			width: '100%',
		},
	},
	'horizontal-middle': {
		...flexHorizontalSx,
		':after': {
			...lineHorizontalSx,
			width: '100%',
		},
	},
	'horizontal-last': {
		...flexHorizontalSx,
		top: '100%',
		':after': {
			...lineHorizontalSx,
			width: '100%',
		},
	},
	'vertical-first': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '100%',
		},
	},
	'vertical-middle': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '100%',
		},
	},
	'vertical-last': {
		...flexVerticalSx,
		':after': {
			...lineVerticalSx,
			height: '100%',
		},
	},
};

// Dropplace Area
const dropareaGlobalSx = {
	borderWidth: '0',
	borderStyle: 'solid',
	borderColor: 'transparent',
};

const dropareaHorizontalSx = {
	width: '90%',
	minWidth: '60px',
	maxWidth: '100%',
	height: '36px',
	borderRadius: '50%',
	boxSizing: 'content-box',
};

const dropareaSx = {
	full: {
		...dropareaGlobalSx,
		width: '100%',
		height: '100%',
	},
	'horizontal-first': {
		...dropareaGlobalSx,
		...dropareaHorizontalSx,
	},
	'horizontal-middle': {
		...dropareaGlobalSx,
		...dropareaHorizontalSx,
	},
	'horizontal-last': {
		...dropareaGlobalSx,
		...dropareaHorizontalSx,
	},
	'vertical-first': {
		...dropareaGlobalSx,
		position: 'absolute',
		right: '0%',
		width: '50vw',
		height: '100%',
		marginLeft: '-50vw',
	},
	'vertical-middle': {
		...dropareaGlobalSx,
		width: '36px',
		minHeight: '60px',
		maxHeight: '100%',
		height: '100%',
		boxSizing: 'content-box',
	},
	'vertical-last': {
		...dropareaGlobalSx,
		position: 'absolute',
		left: '0%',
		width: '50vw',
		height: '100%',
		marginRight: '-50vw',
	},
};

// Title
const textSx = {
	position: 'absolute',
	color: '#fff',
	backgroundColor: 'spec.blue10',
	borderRadius: 'md.all',
	whiteSpace: 'nowrap',
	lineHeight: '27px',
	zIndex: 1,
};

const triangleSx = {
	content: '""',
	position: 'absolute',
	height: '0',
	width: '0',
	top: '0',
	left: '0',
	boxSizing: 'content-box',
	overflow: 'hidden',
	border: '13px solid transparent',
};

const triangleHorizontalSx = {
	...triangleSx,
	borderLeftColor: 'spec.blue10',
	borderRightWidth: '0',
	height: '1px',
	left: '100%',
};

const triangleVerticalSx = {
	...triangleSx,
	borderTopColor: 'spec.blue10',
	borderBottomWidth: '0',
	width: '1px',
	top: '100%',
};

const titleSx = {
	full: {
		...textSx,
		top: '-1px',
		left: '0',
		padding: '0 6px 0 9px',
		borderRadius: 'md.left',
		':before': {
			...triangleHorizontalSx,
		},
	},
	'horizontal-small': {
		...textSx,
		top: '8px',
		left: '0',
		width: '15px',
		height: '9px',
		borderRadius: '3px',
		fontSize: '0',
		':before': {
			...triangleHorizontalSx,
			marginLeft: '-1px',
			borderWidth: '4px',
		},
	},
	horizontal: {
		...textSx,
		top: '-1px',
		left: '0',
		padding: '0 6px 0 9px',
		borderRadius: 'md.left',
		':before': {
			...triangleHorizontalSx,
		},
	},

	'vertical-small': {
		...textSx,
		top: '0',
		left: '5px',
		width: '9px',
		height: '12px',
		borderRadius: '3px',
		fontSize: '0',
		':before': {
			...triangleVerticalSx,
			marginTop: '-1px',
			borderWidth: '4px',
		},
	},
	vertical: {
		...textSx,
		top: '0',
		left: '-3px',
		padding: '9px 0 6px',
		borderRadius: 'md.top',
		textOrientation: 'mixed',
		writingMode: 'vertical-rl',
		':before': {
			...triangleVerticalSx,
		},
	},
};

const invisibleSx = {
	placeStyle: null,
	placeholderStyle: null,
	dropareaStyle: null,
	activeStyle: null,
};

const inactiveSx = {
	...invisibleSx,
	placeholderStyle: { opacity: '1', pointerEvents: 'auto' },
	activeStyle: { opacity: '0' },
};

const visibleActiveSx = {
	placeStyle: { zIndex: zIndices.dropPlaceActive },
	placeholderStyle: { pointerEvents: 'auto' },
	activeStyle: { opacity: '1' },
};

const visibleActiveHorizontalSx = {
	...visibleActiveSx,
	dropareaStyle: { borderWidth: '10px 90px' },
};

const visibleActiveVerticalSx = {
	...visibleActiveSx,
	dropareaStyle: { borderWidth: '30px 15px' },
};

const placementFullSx = width => ({
	placeShapeSx: placeSx.full,
	placeholderShapeSx: placeholderSx.full,
	dropareaShapeSx: dropareaSx.full,
	activeShapeSx: {
		...activeSx.full,
		width,
	},
	titleShapeSx: titleSx.full,
});

const DropPlace = (props: TProps) => {
	const {
		widgetId = 'without-id',
		rowId = 'without-row',
		position = 'without-position',
		direction,
		containerId,
		originId,
		placement,
		width = '100%',
	} = props;

	const isDefaultDevice = useCurrentDevice() === useDefaultDevice();

	const placeId = `${widgetId}-${containerId}-${position}-${direction}-${rowId}`;
	const composeId = `${containerId}-${widgetId}-${rowId}`;
	const [state, dispatch] = React.useContext(dndContext);
	const ref = React.useRef<?React$ElementRef<'div'>>(null);

	const isHorizontal = direction === 'horizontal';
	// видимость дроп места для непустого блока через checkVisible
	// для пустого всегда видимы, если драгаем
	const isVisible =
		isDefaultDevice &&
		(checkVisible(state, props) || (state.dropPlace && placement === 'full'));
	const isActive = state.dropPlace?.placeId === placeId;
	const isSmall = ref.current
		? isVisible && checkSmall(ref.current, isHorizontal)
		: true;
	const title = isHorizontal ? 'Row' : 'Column';

	// set styles for visible and active states
	const {
		placeStyle,
		placeholderStyle,
		dropareaStyle,
		activeStyle,
	} = React.useMemo(() => {
		if (!isVisible) {
			return invisibleSx;
		}
		if (!isActive) {
			return inactiveSx;
		}
		return isHorizontal ? visibleActiveHorizontalSx : visibleActiveVerticalSx;
	}, [isActive, isHorizontal, isVisible]);

	// set sx for directions and placement
	const {
		placeShapeSx,
		placeholderShapeSx,
		dropareaShapeSx,
		activeShapeSx,
		titleShapeSx,
	} = React.useMemo(() => {
		if (placement === 'full') {
			return placementFullSx(width);
		}
		return {
			placeShapeSx: placeSx[`${direction}`],
			placeholderShapeSx: placeholderSx[`${direction}-${placement || 'middle'}`],
			dropareaShapeSx: dropareaSx[`${direction}-${placement || 'middle'}`],
			activeShapeSx: activeSx[`${direction}-${placement || 'middle'}`],
			titleShapeSx: titleSx[`${direction}${isSmall ? '-small' : ''}`],
		};
	}, [placement, direction, isSmall, width]);

	React.useEffect(() => {
		dispatch({
			type: 'regDrop',
			payload: {
				containerId,
				originId,
				placeId,
				widgetId,
				composeId,
				position,
				direction,
				ref,
			},
		});
		return () => dispatch({ type: 'unregDrop', payload: { placeId } });
	}, [
		containerId,
		originId,
		placeId,
		composeId,
		ref,
		dispatch,
		widgetId,
		position,
		direction,
	]);

	return (
		<Box ref={ref} sx={placeShapeSx} style={placeStyle} data-kind="drop-place">
			<Flex sx={placeholderShapeSx} style={placeholderStyle}>
				<Box sx={dropareaShapeSx} style={dropareaStyle} />
			</Flex>
			<Flex sx={activeShapeSx} style={activeStyle}>
				<Text variant="captionlg" sx={titleShapeSx}>
					{title}
				</Text>
			</Flex>
		</Box>
	);
};

export default React.memo<TProps>(DropPlace);
