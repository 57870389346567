// @flow
import React, { memo, useCallback, useMemo } from 'react';
import { ListItem, Box, Text } from '@graphite/uneon';
import type { TSpecsGrid, TDesign, TGridBreakpointName } from '@graphite/types';
import { listForFontsLib, fontFamilyNative } from '@graphite/constants';
import { closestDeviceWithKey, getDesignBreakpointSx } from '@graphite/selectors';

type TProps = $ReadOnly<{|
	design: TDesign,
	device: TGridBreakpointName,
	gridspec: TSpecsGrid,
	isActive?: boolean,
	onRemove?: ?(id: string) => void,
	onClick?: ?(id: string) => void,
|}>;

const fontButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					title: 'Remove this font',
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

const listItemSx = {
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
	cursor: 'pointer',
	':hover': {
		backgroundColor: 'bg.primaryplus',
	},
	':last-child': {
		borderBottom: 'none',
	},
};

const fontBoxStyle = {
	padding: '15px 0 14px', // -1px border
};

const elipsisSx = {
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	width: '100%',
	overflow: 'hidden',
};

const infoSx = {
	...elipsisSx,
	marginTop: '6px',
};

function ListItemFonts({
	design,
	device,
	gridspec,
	isActive,
	onRemove = null,
	onClick = null,
}: TProps) {
	const boundClick = useCallback(
		(e, itemName, buttonName) => {
			if (buttonName === 'remove') {
				if (onRemove) {
					onRemove(design._id);
				}
				return;
			}
			if (onClick) {
				onClick(design._id);
			}
		},
		[design._id, onClick, onRemove],
	);

	const fontSx = useMemo(() => {
		const breakpoint = closestDeviceWithKey(design.breakpoints, {
			currentDevice: device,
			key: `breakpoint-${design._id}`,
		});

		const bsx = getDesignBreakpointSx({
			breakpoint,
			device,
			unit: gridspec.unit,
			effectspec: null,
			widgetspec: null,
			colorspec: null,
			isLimited: true,
		});
		const webSafeFont = listForFontsLib.find(conf => conf.family === bsx.fontFamily);
		return {
			...bsx,
			...(webSafeFont ? { fontFamily: webSafeFont.name } : {}),
			...elipsisSx,
			marginBottom: 0,
		};
	}, [design, gridspec, device]);

	const fontFamily = React.useMemo(() => {
		const currentFont: string =
			typeof fontSx.fontFamily === 'string' ? fontSx.fontFamily : fontFamilyNative;
		const webSafeFont = listForFontsLib.find(conf => conf.name === currentFont);
		return webSafeFont ? webSafeFont.family : currentFont;
	}, [fontSx.fontFamily]);

	const fontSize = typeof fontSx.fontSize === 'string' ? fontSx.fontSize : '';
	const lineHeight =
		typeof fontSx.lineHeight === 'string' ? `/ ${fontSx.lineHeight}` : '';

	const fontInfo = `${fontFamily}, ${fontSize} ${lineHeight}`;

	return (
		<>
			<ListItem
				buttonGroup={fontButtons}
				onClick={boundClick}
				size="auto"
				sx={listItemSx}
			>
				<Box sx={fontBoxStyle}>
					<Text
						sx={fontSx}
						color={isActive ? 'text.accent' : 'text.primaryalt'}
					>
						{design.name}
					</Text>
					<Text variant="captionmd" color="text.secondary" sx={infoSx}>
						{fontInfo}
					</Text>
				</Box>
			</ListItem>
		</>
	);
}

export default memo<TProps>(ListItemFonts);
