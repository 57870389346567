// @flow
import React from 'react';

import handleCurrentWidgetCase from './handle-current-widget-case';

import type { TUseOverWatch } from '../types';

const useOverWatch: TUseOverWatch = ({
	frameContext,
	currentWidget,
	refs,
	setCurrentEdit,
	setTargetIds,
	findTargetIds,
}) => {
	const rafID = React.useRef(null);

	React.useEffect(() => {
		const { window, document } = frameContext;
		if (!document || !window) return;
		const { Node } = window;

		const handleOverApp = (event: MouseEvent) => {
			if (!(event.target instanceof Node)) {
				return;
			}
			const eventTarget: Node = event.target;

			if (currentWidget) {
				handleCurrentWidgetCase({
					event,
					refs,
					currentWidget,
					setCurrentEdit,
					overTargets: null,
				});
			}

			if (!refs) {
				setTargetIds(() => null);
				return;
			}

			if (rafID.current) cancelAnimationFrame(rafID.current);
			rafID.current = requestAnimationFrame(() => {
				findTargetIds(eventTarget);
				rafID.current = null;
			});
		};
		document.addEventListener('mouseover', handleOverApp);
		return () => {
			document.removeEventListener('mouseover', handleOverApp);
		};
	}, [refs, findTargetIds, setTargetIds, currentWidget, setCurrentEdit, frameContext]);
};

export default useOverWatch;
