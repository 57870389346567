/* eslint-disable no-console */
// @flow
import _ from 'lodash/fp';
import Transport from 'winston-transport';

import { LEVELS } from '../constants';
import type { TLogEntry, TCallback } from '../types';

const PREFIX = 'Graphite';

type TOpts = $ReadOnly<{|
	format: Object,
	level: string,
|}>;

class BrowserTransport extends Transport {
	constructor(opts: TOpts) {
		super(opts);

		this.logger = _.transform(
			// eslint-disable-next-line no-return-assign
			(result, level) =>
				// eslint-disable-next-line no-param-reassign
				(result[level] = (...args) => console[level](PREFIX, ...args)),
			{},
		)(LEVELS);

		import('logdown').then(({ default: logdown }) => {
			this.logger = logdown(PREFIX);
			this.logger.state.isEnabled = true;
		});
	}

	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, level, meta = {} } = logEntry;
		this.logger[level](message, meta);
		return callback(null, true);
	}
}

export default BrowserTransport;
