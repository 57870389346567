// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PopupMenu } from '@graphite/uneon';

import {
	detachWidget,
	symbolizeWidget,
	removeWidget,
	cloneWidget,
	unhideChildrenWidget,
} from 'Editor/ducks/widgets';

import type { TPropsActions } from './constants/types';

const actionsButton = {
	variant: 'flat',
	colors: 'primaryflat',
	icons: 'dots-horizontal-3',
	size: 'sm',
	title: 'Actions',
};

const Actions = ({
	dispatch,
	data,
	containerId,
	instanceId,
	originId,
	isDefaultDevice,
}: TPropsActions) => {
	const { _id, scope, scopeId } = data;

	const { t } = useTranslation();

	// const isInstance = _.isArray(data.modified);

	const menuItems = React.useMemo(() => {
		const items = [];

		if (!isDefaultDevice) {
			items.push({
				label: t('Show Hidden Widgets'),
				name: 'onUnhide',
			});

			return { colors: 'primaryflat', items };
		}
		// if (isInstance) {
		// 	items.push({
		// 		label: t('Detach Component'),
		// 		name: 'onDetach',
		// 	});
		// } else {
		// 	items.push({
		// 		label: t('Create Component'),
		// 		name: 'onSymbolize',
		// 	});
		// }

		items.push({
			label: t('Duplicate'),
			name: 'onClone',
		});

		items.push({
			label: t('Show Hidden Widgets'),
			name: 'onUnhide',
		});

		items.push({
			label: t('Remove'),
			name: 'onRemove',
		});

		return {
			colors: 'primaryflat',
			items,
		};
	}, [isDefaultDevice, t]);

	const onDetach = React.useCallback(() => {
		if (!originId) return;
		dispatch(detachWidget(_id, instanceId, originId));
	}, [dispatch, _id, instanceId, originId]);

	const onSymbolize = React.useCallback(() => {
		if (!scopeId) return;
		if (!originId) return;

		dispatch(
			symbolizeWidget(_id, originId, {
				scope,
				_id: scopeId,
			}),
		);
	}, [dispatch, _id, originId, scope, scopeId]);

	const onClone = React.useCallback(() => {
		if (!originId) return;
		dispatch(cloneWidget(_id, containerId, instanceId, originId));
	}, [dispatch, _id, containerId, instanceId, originId]);

	const onRemove = React.useCallback(() => {
		if (!originId) return;
		dispatch(removeWidget(_id, containerId, instanceId, originId));
	}, [dispatch, _id, containerId, instanceId, originId]);

	const onUnhide = React.useCallback(() => {
		if (!originId) return;
		dispatch(unhideChildrenWidget(_id, instanceId, originId));
	}, [_id, dispatch, instanceId, originId]);

	const handleSelectAction = React.useCallback(
		(e, action) => {
			if (!action || typeof action !== 'string') return;

			({
				onDetach,
				onSymbolize,
				onClone,
				onRemove,
				onUnhide,
			}[action]());
		},
		[onDetach, onSymbolize, onClone, onRemove, onUnhide],
	);

	return (
		<PopupMenu button={actionsButton} list={menuItems} onClick={handleSelectAction} />
	);
};

export default React.memo<TPropsActions>(Actions);
