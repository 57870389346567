// @flow
import React, { memo, useCallback, useMemo, useRef } from 'react';
import { Box } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import LabelLayer from './LabelLayer';
import type { TTreeDataElement } from './selectors';

type TProps = $ReadOnly<{|
	layer: TTreeDataElement,
	onExpand: (string, boolean) => void,
	onSelect: TTreeDataElement => void,
	onStop: TTreeDataElement => void,
	onStart: TTreeDataElement => void,
	onDrag: (TTreeDataElement, HTMLLIElement, number) => void,
	isDragNode: boolean,
	dropNodePosition: 'top' | 'center' | 'bottom',
	level?: number,
	isDraggble: boolean,
|}>;

const boxBorderTopSx = {
	borderTop: '2px solid',
	borderTopColor: 'spec.blue10',
};

const boxBorderBottomSx = {
	borderBottom: '2px solid',
	borderBottomColor: 'spec.blue10',
};

const boxBorderDropPlaceSx = {
	border: '2px dotted',
	borderColor: 'spec.blue10',
};

const boxSelectedHoverSx = {
	'&:hover > div:last-of-type': {},
};

function LayerItem({
	layer,
	onExpand = emptyFunction,
	onSelect = emptyFunction,
	onStop = emptyFunction,
	onStart = emptyFunction,
	onDrag = emptyFunction,
	isDragNode = false,
	dropNodePosition = '',
	level = 0,
	isDraggble = false,
}: TProps) {
	const dummyNode = useRef(null);

	const getBoxHoverSx = useMemo(
		() => ({
			'&:hover > div:last-of-type': {
				backgroundColor:
					(['block', 'col'].includes(layer.kind) && 'bg.primaryalt') ||
					'spec.blue10',
				opacity: ['block', 'col'].includes(layer.kind)
					? 1
					: (isDragNode && 0.33) || 0.15,
			},
		}),
		[isDragNode, layer],
	);
	const getBoxDraggableSx = useMemo(
		() => ({
			...((layer.selected && boxSelectedHoverSx) || getBoxHoverSx),
			height: layer.kind === 'block' ? '39px' : '33px',
			cursor: 'default',
			paddingTop: `${((layer.kind === 'block' && 11) || 8) -
				((dropNodePosition === 'top' && 2) || 0)}px`,
			paddingBottom: layer.kind === 'block' ? '11px' : '8px',
			position: 'relative',
			...((['bottom', 'top'].includes(dropNodePosition) &&
				(dropNodePosition === 'bottom' ? boxBorderBottomSx : boxBorderTopSx)) ||
				{}),
		}),
		[dropNodePosition, getBoxHoverSx, layer],
	);
	const getBoxHighLight = useMemo(
		() => ({
			top: 0,
			left: 0,
			position: 'absolute',
			width: '318px',
			height: '100%',
			pointerEvents: 'none',
			zIndex: 2,
			...(dropNodePosition === 'center' ? boxBorderDropPlaceSx : {}),
			marginLeft: layer.kind === 'block' ? '-24px' : `-${level * 20}px`,
			opacity: isDragNode ? '0.33' : '1',
			backgroundColor: isDragNode || layer.selected ? 'spec.blue10' : '',
		}),
		[layer, level, isDragNode, dropNodePosition],
	);

	// start dragging and remove the element(dummy) next to cursor
	const onStartHandler = useCallback(
		e => {
			e.dataTransfer.effectAllowed = 'none';
			if (!dummyNode.current) {
				dummyNode.current = e.target.cloneNode(true);
				dummyNode.current.style.height = '0px';
				dummyNode.current.style.opacity = 0;
				window.document.body.appendChild(dummyNode.current);
			}
			e.dataTransfer.setDragImage(dummyNode.current, 0, 0);
			onStart(layer);
		},
		[layer, onStart],
	);

	const clickSelect = useCallback(() => onSelect(layer), [layer, onSelect]);

	// stopping drag and remove dummy for cursor
	const onStopHandler = useCallback(() => {
		if (dummyNode.current) {
			dummyNode.current.remove();
			dummyNode.current = null;
		}
		onStop(layer);
	}, [layer, onStop]);

	// handle each position and highlight if can drop
	const onDragHandler = useCallback(
		e => {
			e.preventDefault();
			onDrag(layer, e.target, e.clientY);
		},
		[layer, onDrag],
	);

	return (
		<Box
			sx={getBoxDraggableSx}
			draggable={isDraggble}
			onDragEnd={onStopHandler}
			onDragEnter={onDragHandler}
			onDragStart={onStartHandler}
			onClick={clickSelect}
		>
			<LabelLayer layer={layer} onExpand={onExpand} isDragNode={isDragNode} />
			<Box sx={getBoxHighLight}> </Box>
		</Box>
	);
}

export default memo<TProps>(LayerItem);
