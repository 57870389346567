// @flow
import _ from 'lodash/fp';

import type { TStopDebounce, TUpdateThrottle } from '../types';

const TROTTLING = 32; // 16 = 60fps, 32 = 30fps

export const updateThrottled: TUpdateThrottle = _.throttle(
	TROTTLING,
	(dispatch, payload) => {
		dispatch({
			type: 'update',
			payload,
		});
	},
);

export const stopDebounce: TStopDebounce = _.debounce(
	TROTTLING + 10,
	(dispatch, payload) => {
		dispatch({
			type: 'stop',
			payload,
		});
	},
);
