// @flow

import type { TDragPosition, TDropRect } from './types';

export const INITIAL_RECT: TDropRect = {
	left: 0,
	top: 0,
	width: 0,
	height: 0,
};

export const INITIAL_POSITION: TDragPosition = {
	x: 0,
	y: 0,
};

export default {};
