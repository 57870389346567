// @flow
import React from 'react';
import flow from 'lodash/fp/flow';
import values from 'lodash/fp/values';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import { Icon, SelectSimple, ListItem, Flex, Text } from '@graphite/uneon';
import type { TId, TSpecs, TSpec, TSite } from '@graphite/types';

type TProps = $ReadOnly<{|
	spec: TSpec,
	specs: TSpecs,
	onInsert?: ?(TSpec) => void,
	genCustomId: string => string,
	t: string => string,
	siteId: string,
	updateSite: (siteId: TId, diff: $Shape<TSite>) => void,
	updateSpecs: TSpecs => void,
|}>;

const flexWideSx = {
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: '18px 0',
};

const textSx = {
	marginRight: '24px',
};

function SelectSpec({
	spec,
	specs,
	siteId,
	onInsert = null,
	genCustomId,
	updateSite,
	updateSpecs,
	t,
}: TProps) {
	const presetList = React.useMemo(
		() =>
			flow(
				values,
				// Fixme: scopeId for symbols
				filter(
					v =>
						v.kind === spec.kind &&
						!v.removedAt &&
						v.scopeId === spec.scopeId,
				),
				// eslint-disable-next-line no-underscore-dangle
				map(v => ({ label: v.name, name: v._id })),
			)(specs),
		[specs, spec.kind, spec.scopeId],
	);

	const preset = React.useMemo(
		// eslint-disable-next-line no-underscore-dangle
		() => presetList.find(p => p.name === spec._id) || presetList[0],
		// eslint-disable-next-line no-underscore-dangle
		[presetList, spec._id],
	);

	const insertPreset = React.useCallback(() => {
		if (onInsert) {
			onInsert(spec);
			return;
		}
		const newId = genCustomId('specs');
		const newSpec = {
			...spec,
			_id: newId,
			name: `${spec.name || 'Unnamed'} - copy`,
		};
		updateSpecs({ [newId]: newSpec });
	}, [genCustomId, onInsert, spec, updateSpecs]);

	const changePreset = React.useCallback(
		(e, name) => {
			if (spec.kind && siteId) {
				updateSite(siteId, { [`${spec.kind}Id`]: name });
			}
		},
		[spec.kind, siteId, updateSite],
	);

	const listProps = React.useMemo(() => ({ items: presetList, active: preset.name }), [
		presetList,
		preset.name,
	]);

	return (
		<ListItem>
			<Text variant="bodysm" color="text.primary">
				{t('Preset')}
			</Text>
			<SelectSimple size="sm" list={listProps} onClick={changePreset}>
				<Flex sx={flexWideSx}>
					<Text variant="title4" color="text.primaryalt" sx={textSx}>
						{t('Preset')}
					</Text>
					<Icon
						name="plus"
						iconSize={18}
						size="md"
						colors="secondary"
						onClick={insertPreset}
					/>
				</Flex>
			</SelectSimple>
		</ListItem>
	);
}

export default React.memo<TProps>(SelectSpec);
