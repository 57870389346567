// @flow
import {
	EditorState,
	CompositeDecorator,
	convertFromRaw,
	type ContentState,
} from 'draft-js';
import type { RawDraftContentState } from 'draft-js/lib/RawDraftContentState';

import getLinkDecorator from './getLinkDecorator';
// import getAlignDecorator from './getAlignDecorator';

const decorators = [getLinkDecorator()];
const compositeDecorator = new CompositeDecorator(decorators);

export default (raw: RawDraftContentState) =>
	EditorState.createWithContent(convertFromRaw(raw), compositeDecorator);

export const createWithContent = (content: ContentState) =>
	EditorState.createWithContent(content, compositeDecorator);
