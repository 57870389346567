// @flow
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { config } from '@graphite/constants';

const {
	apiKey,
	authDomain,
	projectId,
	messagingSenderId,
	measurementId,
	appId,
	databaseURL,
	storageBucket,
} = config;

firebase.initializeApp({
	apiKey,
	authDomain,
	projectId,
	appId,
	messagingSenderId,
	measurementId,
	databaseURL,
	storageBucket,
});

export default firebase;
