// @flow
import React from 'react';
import { Icon, Flex, Box, Text } from '@graphite/uneon';
import { zIndices } from '@graphite/constants';
import styled from '@emotion/styled';
import type { TControlsProps } from './types';

const Wrapper = styled(Box)`
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	border: 1px dashed transparent;

	pointer-events: ${({ isEdit, isResize, isInstance, position }) =>
		isEdit || isResize || isInstance || position ? 'none' : 'auto'};

	z-index: ${({ isEdit, isResize }) =>
		isEdit || isResize ? zIndices.widgetActiveControls : zIndices.widgetControls};

	background: ${({ isEdit, isInstance, isResize }) =>
		isEdit || isInstance || isResize ? 'transparent' : 'rgba(41, 155, 255, 0.15)'};

	border-color: ${({ isEdit, isResize, theme, isInstance }) => {
		// ToDo: Поменять на переменную
		if (isInstance) return 'rgba(228, 51, 255, 0.5)';
		if (isEdit || isResize) return theme.colors.spec.blue10;
		return 'transparent';
	}};

	${({ position }) => position && 'border-style: solid'};
`;

const componentColors = {
	text: 'spec.violet50',
	texthover: 'spec.violet50',
	textfocus: 'spec.violet50',
	textactive: 'spec.violet50',
	bg: 'spec.violet50',
	bghover: 'spec.violet50',
	bgfocus: 'spec.violet50',
	bgactive: 'spec.violet50',
	border: 'spec.violet50',
	borderhover: 'spec.violet50',
	borderfocus: 'spec.violet50',
	borderactive: 'spec.violet50',
};

const iconSx = {
	marginRight: '6px',
};

const nameSx = {
	position: 'absolute',
	top: '-31px', // высота + 1px бордер + 6px отступ
	left: '4px',
	alignItems: 'center',
	backgroundColor: 'spec.blue10',
	padding: '0 9px',
	height: '24px',
	borderRadius: 'rounded.all',
	boxShadow: 'smblue',
};

const textSx = {
	color: '#fff',
	lineHeight: '24px',
};

export function ControlsComponent({
	data,
	title,
	position,
	widgetMode,
}: TControlsProps): React$Node {
	const isInstance = !!data.modified;
	const isStack = data.kind === 'stack';
	const isResize = widgetMode === 'widget-resize';
	const isEdit = widgetMode === 'widget-edit';

	return (
		<Wrapper
			isEdit={isEdit}
			isResize={isResize}
			isInstance={isInstance}
			position={position}
		>
			{isInstance && widgetMode === 'none' && (
				<Flex sx={nameSx}>
					<Icon
						sx={iconSx}
						name="rhombus-stroke"
						colors={componentColors}
						iconSize={18}
					/>
				</Flex>
			)}
			{isStack && !isInstance && widgetMode !== 'widget-resize' && (
				<Flex sx={nameSx}>
					<Text variant="captionlg" sx={textSx}>
						{title}
					</Text>
				</Flex>
			)}
		</Wrapper>
	);
}

export default React.memo<TControlsProps>(ControlsComponent);
