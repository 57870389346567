// @flow
import React from 'react';
import { Text } from '@graphite/uneon';
import type { TDesignProps, TDesignImageLibProps } from '@graphite/types';
import _ from 'lodash/fp';
import { Theme as SectionTheme } from '@graphite/sections';

import Style from './Style';
import Color from './Color';

const titleStyle = {
	marginBottom: '18px',
	cursor: 'default',
};

type TDesignColProps = $ReadOnly<{|
	...$Exact<TDesignProps>,
	...$Exact<TDesignImageLibProps>,
|}>;

function DesignCol({
	design,
	gridspec,
	colorspec,
	effectspec,
	widgetspec,
	onChange,
	device,
	t,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignColProps) {
	const onChangeTheme = React.useCallback(
		id => {
			if (onChange) {
				onChange(_.set('theme', id, design));
			}
		},
		[design, onChange],
	);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleStyle}>
				{t('Design')}
			</Text>
			<SectionTheme
				label={t('Theme')}
				active={design.theme}
				colorspec={colorspec}
				onChange={onChangeTheme}
				t={t}
			/>
			<Style
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
			/>
			<Color
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
				insertImage={insertImage}
				resetImage={resetImage}
				removeImage={removeImage}
				images={images}
				uploads={uploads}
			/>
		</>
	);
}

export default React.memo<TDesignColProps>(DesignCol);
