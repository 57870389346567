// @flow
import React, { memo, useState, useCallback, useEffect } from 'react';
import emptyFunction from 'empty/function';
import { SliderSimple, Box, Text, Flex } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	gridspec: Object,
	updateSpecs?: Object => void,
|}>;

const noteStyle = {
	marginBottom: '48px',
};

const sliderStyle = {
	marginBottom: '13px',
};

const previewFlexStyle = {
	height: '133px',
};

const previewBoxStyle = {
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'spec.violet40',
};

const previewHeightStyle = {
	borderTopWidth: '1px',
	borderTopStyle: 'solid',
	borderTopColor: 'spec.blue40',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'spec.blue40',
	marginLeft: '5px',
	width: '11px',
};
const previewHeightInStyle = {
	borderRightWidth: '1px',
	borderRightStyle: 'solid',
	borderRightColor: 'spec.blue40',
	width: '6px',
	height: '100%',
};

const previewWidthStyle = {
	borderLeftWidth: '1px',
	borderLeftStyle: 'solid',
	borderLeftColor: 'spec.blue40',
	borderRightWidth: '1px',
	borderRightStyle: 'solid',
	borderRightColor: 'spec.blue40',
	marginTop: '5px',
	height: '11px',
};
const previewWidthInStyle = {
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'spec.blue40',
	height: '6px',
	width: '100%',
};

const previewInfoStyle = {
	marginLeft: '25px',
};

const previewInfoTextStyle = {
	marginBottom: '6px',
};

function Spacing({ gridspec, updateSpecs = emptyFunction }: TProps) {
	const [ownUnit, setOwnUnit] = useState(gridspec.unit || 4);

	useEffect(() => setOwnUnit(gridspec.unit), [gridspec.unit]);

	const previewUnit = useCallback(value => setOwnUnit(value), [setOwnUnit]);

	const changeUnit = useCallback(
		value => {
			setOwnUnit(value);
			updateSpecs({ [gridspec._id]: { ...gridspec, unit: value } });
		},
		[setOwnUnit, gridspec, updateSpecs],
	);

	const previewSize = ownUnit * 10;

	return (
		<>
			<Box sx={sliderStyle}>
				<SliderSimple
					min={2}
					max={8}
					title="1 unit"
					value={gridspec.unit}
					unit="px"
					onChange={changeUnit}
					onPreview={previewUnit}
				/>
			</Box>
			<Text variant="bodysm" color="text.secondary" sx={noteStyle}>
				Sizes, paddings and margin are measured in units by default
			</Text>
			<Flex sx={previewFlexStyle}>
				<Box>
					<Flex>
						<Box
							sx={previewBoxStyle}
							width={previewSize}
							height={previewSize}
						/>
						<Box sx={previewHeightStyle} height={previewSize}>
							<Box sx={previewHeightInStyle} />
						</Box>
					</Flex>
					<Box sx={previewWidthStyle} width={previewSize}>
						<Box sx={previewWidthInStyle} />
					</Box>
				</Box>
				<Box sx={previewInfoStyle}>
					<Text
						variant="headlinesm"
						color="text.primary"
						sx={previewInfoTextStyle}
					>
						10 unit
					</Text>
					<Text
						variant="bodysm"
						color="text.secondary"
						sx={previewInfoTextStyle}
					>
						{`${previewSize} px`}
					</Text>
				</Box>
			</Flex>
		</>
	);
}

Spacing.defaultProps = {
	updateSpecs: emptyFunction,
};

export default memo<TProps>(Spacing);
