// @flow
import _ from 'lodash/fp';
import type { TSx } from '@graphite/types';

import reSelect from './libs/re-select';

export type TCombineSxParams = $ReadOnlyArray<TSx>;
export type TCombineSxFn = TCombineSxParams => TSx;

export const combineSx: TCombineSxFn = reSelect<TCombineSxParams, TCombineSxParams, TSx>(
	sxList => sxList,
	sxList => {
		const allKeys = {};
		// Собрать все возможные ключи из массива объектов:
		// [{ x: 1 }, { y: 'a', z: 'b' }, { z: true }] -> { x: 1, y: 'a', z: true }
		_.forEach(breakpointSx => Object.assign(allKeys, breakpointSx), sxList);

		const finalSx = {};
		_.forEach(breakpointSx => {
			_.forEach(k => {
				if (!finalSx[k]) {
					finalSx[k] = [];
				}
				finalSx[k].push(breakpointSx[k] || null);
			}, _.keys(allKeys));
		}, sxList);
		return finalSx;
	},
)(sxList =>
	['widget@combineSx', sxList.length, ...sxList.map(sx => _.size(sx))].join('-'),
);

export default combineSx;
