// @flow
import type {
	TSite,
	TWidget,
	TSpecsGrid,
	TDesignCommonEffect,
	TDesignBreakpoint,
	TDesign,
	TColorTheme,
	TColorReference,
	TGradientLinear,
	TImage,
	TShadow,
	TBgImage,
	TGradientStop,
	TSpecsGridBreakpoint,
	TIconReference,
	TLinkReference,
} from '@graphite/types';
import { listForFontsLib } from './font-family-native';

export const defaultColor = '#EEEEEE';

export const defaultColorRef: TColorReference = {
	entryId: null,
	shadeId: null,
	snapshot: defaultColor,
};
export const defaultWhiteRef: TColorReference = {
	entryId: null,
	shadeId: null,
	snapshot: '#FFFFFF',
};
export const defaultBlackRef: TColorReference = {
	entryId: null,
	shadeId: null,
	snapshot: '#000000',
};

export const defaultGradientStop: TGradientStop = {
	color: defaultColorRef,
	at: 0,
	id: 'default-gradient-stop',
};

export const defaultGradient: TGradientLinear = {
	kind: 'linear',
	angle: 0,
	isRepeating: false,
	stops: {
		white: {
			id: 'white',
			color: {
				entryId: null,
				shadeId: null,
				snapshot: '#00000000',
			},
			at: 0,
		},
		black: {
			id: 'black',
			color: {
				entryId: null,
				shadeId: null,
				snapshot: '#000000',
			},
			at: 100,
		},
	},
};

export const defaultImage: TImage = {
	src:
		'https://images.unsplash.com/photo-1505322715123-90e9aa1e2d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&q=80',
};

export const defaultBgImage: TBgImage = {
	src:
		'https://images.unsplash.com/photo-1504198266287-1659872e6590?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1650&q=80',
	kind: 'fill',
	x: 50,
	xUnit: '%',
	y: 50,
	yUnit: '%',
	repeat: 'repeat',
	width: 100,
	widthUnit: '%',
	height: 100,
	heightUnit: 'auto',
};

export const defaultShadow: TShadow = {
	id: 'default-shadow',
	name: 'Shadow',
	kind: 'drop',
	x: 0,
	y: 5,
	blur: 10,
	spread: 0,
	color: { entryId: null, shadeId: null, snapshot: '#00000044' },
};

export const defaultIcon: TIconReference = {
	src: '',
};

export const defaultLink: TLinkReference = {
	href: '',
	isNewTab: false,
};

export const defaultTheme: TColorTheme = {
	id: 'defaultTheme',
	name: 'Default',
	text: {
		primary: defaultBlackRef,
		primaryalt: defaultBlackRef,
		secondary: defaultBlackRef,
		tertiary: defaultBlackRef,
		accent: defaultBlackRef,
		accentalt: defaultBlackRef,
		success: defaultBlackRef,
		error: defaultBlackRef,
		warning: defaultBlackRef,
		oncolor: defaultBlackRef,
		accentplus: defaultBlackRef,
		accentaltplus: defaultBlackRef,
		successplus: defaultBlackRef,
		errorplus: defaultBlackRef,
		warningplus: defaultBlackRef,
		accentminus: defaultBlackRef,
		accentaltminus: defaultBlackRef,
		successminus: defaultBlackRef,
		errorminus: defaultBlackRef,
		warningminus: defaultBlackRef,
	},
	bg: {
		primary: defaultWhiteRef,
		primaryalt: defaultWhiteRef,
		secondary: defaultWhiteRef,
		tertiary: defaultWhiteRef,
		accent: defaultWhiteRef,
		accentalt: defaultWhiteRef,
		success: defaultWhiteRef,
		error: defaultWhiteRef,
		warning: defaultWhiteRef,
		primaryplus: defaultWhiteRef,
		primaryaltplus: defaultWhiteRef,
		secondaryplus: defaultWhiteRef,
		tertiaryplus: defaultWhiteRef,
		accentplus: defaultWhiteRef,
		accentaltplus: defaultWhiteRef,
		successplus: defaultWhiteRef,
		errorplus: defaultWhiteRef,
		warningplus: defaultWhiteRef,
		primaryminus: defaultWhiteRef,
		primaryaltminus: defaultWhiteRef,
		secondaryminus: defaultWhiteRef,
		tertiaryminus: defaultWhiteRef,
		accentminus: defaultWhiteRef,
		accentaltminus: defaultWhiteRef,
		successminus: defaultWhiteRef,
		errorminus: defaultWhiteRef,
		warningminus: defaultWhiteRef,
	},
};

export const defaultBreakpoint: TSpecsGridBreakpoint = {
	active: false,
	breakpoint: 992,
	columns: 12,
	gutter: 30,
	gutterUnit: 'px',
	container: 990,
	containerUnit: 'px',
	padding: 0,
	paddingUnit: 'px',
};

export const defaultDesign: TDesign = {
	_id: 'defaultDesign',
	name: 'Untitled',
	kind: 'design',
	target: 'stack',
	display: 'normal',
	protoId: null,
	removedAt: null,
	scope: 'site',
	scopeId: null,
	userId: null,
	breakpoints: {},
};

export const defaultDesignBreakpointText: TDesignBreakpoint = {
	family: listForFontsLib[0].family,
	weight: '400',
	fontSize: 16,
	textHeight: 120,
	textHeightUnit: '%',
	spacing: 0,
	textMargin: 0,
	textMarginUnit: 'px',
	lettercase: 'none',
	normal: {
		container: {
			background: null,
			border: null,
			overlay: null,
			shadow: null,
		},
		text: {
			color: 'text.primary',
			shadow: null,
		},
	},
	variants: listForFontsLib[0].variants,
};

export const defaultDesignBreakpointButton: TDesignBreakpoint = {
	height: 42,
	heightUnit: 'px',
	padding: 18,
	paddingUnit: 'px',
	radius: null,
	border: null,
	textDesign: null,
	textMargin: 0,
	textMarginUnit: 'px',
	btnIconSize: 16,
	btnIconSizeUnit: 'px',
	iconMargin: 6,
	iconMarginUnit: 'px',
};

export const defaultDesignBreakpointIcon: TDesignBreakpoint = {
	iconSize: 24,
	iconSizeUnit: 'px',
	padding: 6,
	paddingUnit: 'px',
};

export const defaultCommonEffect: TDesignCommonEffect = {
	container: {
		border: null,
		background: null,
		shadow: null,
		overlay: null,
	},
	text: {
		color: null,
		shadow: null,
	},
};

export const defaultGridspec: TSpecsGrid = {
	_id: 'gridspec-default',
	kind: 'gridspec',
	name: 'Empty',
	display: 'normal',
	protoId: null,
	removedAt: null,
	updatedAt: null,
	scope: 'site',
	scopeId: null,
	userId: null,
	unit: 4,

	breakpoints: {
		desktop: {
			active: true,
			breakpoint: 992,
			columns: 12,
			gutter: 30,
			gutterUnit: 'px',
			container: 990,
			containerUnit: 'px',
			padding: 0,
			paddingUnit: 'px',
		},
	},
};

export const defaultSite: TSite = {
	_id: 'default-site',
	kind: 'site',
	name: '',

	userId: '',
	display: 'normal',
	protoId: null,
	removedAt: null,
	scope: 'site',
	scopeId: '',
	children: {},
	syncedAt: null,
	updatedAt: null,
	colorspecId: '',
	widgetspecId: '',
	effectspecId: '',
	gridspecId: '',
};

export const defaultWidget: TWidget = {
	_id: 'default-widget',
	kind: 'stack',
	name: '',
	userId: '',
	display: 'normal',
	protoId: null,
	removedAt: null,
	scope: 'site',
	scopeId: '',
	children: {},
	syncedAt: null,
	updatedAt: null,
};

export default {};
