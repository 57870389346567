// @flow
import React, { memo, useCallback, useRef } from 'react';
import _ from 'lodash/fp';
import { useTranslation } from 'react-i18next';

import { ToolbarBtn, PopupWhite } from '@graphite/uneon';

import { genId } from 'libs/firebase';
import type { TDesign } from '@graphite/types';
import { defaultDesign } from '@graphite/constants';
import { editWidget } from 'Editor/ducks/widgets';

import DesignBlock from './DesignBlock';
import type { TPropsDesign } from '../constants/types';

const CONTROL_NAME = 'droplet';

const Design = ({
	dispatch,
	gridspec,
	colorspec,
	effectspec,
	widgetspec,
	setOpenedPanel,
	openedPanel,
	currentDevice,
	data,
	instanceId,
	originId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TPropsDesign) => {
	const { t } = useTranslation();
	const btnEl = useRef(null);
	const handleToogleOpen = useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === CONTROL_NAME ? null : CONTROL_NAME,
			);
		},
		[setOpenedPanel],
	);

	const isActive = openedPanel === CONTROL_NAME;

	const design: TDesign =
		(data.designs && data.designId && data.designs[data.designId]) ||
		_.assign(defaultDesign, { _id: genId('specs'), target: 'block' });

	const changeDesign = useCallback(
		(changed: TDesign) => {
			if (!originId) return;
			dispatch(
				editWidget(data._id, instanceId, originId, {
					designId: changed._id,
					designs: { [changed._id]: changed },
				}),
			);
		},
		[data._id, dispatch, instanceId, originId],
	);

	return (
		<>
			<ToolbarBtn
				iconName={CONTROL_NAME}
				variant="sm"
				onMouseDown={handleToogleOpen}
				ref={btnEl}
				isActive={isActive}
			/>

			<PopupWhite
				isOpen={isActive}
				anchorEl={btnEl}
				offsetTop={42}
				onClose={handleToogleOpen}
				mutex="design"
				isFixed
			>
				{isActive && (
					<DesignBlock
						design={design}
						device={currentDevice}
						gridspec={gridspec}
						colorspec={colorspec}
						effectspec={effectspec}
						widgetspec={widgetspec}
						onChange={changeDesign}
						t={t}
						genId={genId}
						insertImage={insertImage}
						removeImage={removeImage}
						resetImage={resetImage}
						images={images}
						uploads={uploads}
					/>
				)}
			</PopupWhite>
		</>
	);
};

export default memo<TPropsDesign>(Design);
