// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box } from '@graphite/uneon';
import type { TSx } from '@graphite/types';
import { getBoxSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';

import type { TConnectProps } from './constants/types';

const Spacer = (props: TConnectProps, ref) => {
	const { dragContainer, dragFlip, data, position, direction, gridspec } = props;

	const boxSx = gridspec
		? getBoxSx({ data, position, direction, gridspec })
		: emptyObject;

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Box ref={ref} style={dynamicStyle} {...otherProps} sx={boxSx} />;
};

export default React.memo<TConnectProps>(React.forwardRef(Spacer));
