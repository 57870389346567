// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn } from '@graphite/uneon';

import { removeInlineStyles } from '../../libs/draft-utils';

type TPropsRemoveStylesBtn = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
|}>;

const RemoveStylesBtn = (props: TPropsRemoveStylesBtn) => {
	const { editorState, setEditorState } = props;

	const changeInlineStyle = React.useMemo(() => {
		const setInlineStyle = removeInlineStyles(editorState);

		return e => {
			e.preventDefault();

			const editorStateNew = setInlineStyle();
			setEditorState(editorStateNew);
		};
	}, [editorState, setEditorState]);

	return <ToolbarBtn onMouseDown={changeInlineStyle} iconName="trash" />;
};

export default React.memo<TPropsRemoveStylesBtn>(RemoveStylesBtn);
