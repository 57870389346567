// @flow
import React, { memo, useMemo, useCallback } from 'react';
import { ListItem, Flex, Box, Text } from '@graphite/uneon';
import { getShadow } from '@graphite/selectors';
import type { TShadowValue, TSpecsColor, TButtonGroupEmbed } from '@graphite/types';

type TProps = $ReadOnly<{|
	name?: ?string,
	shadow: TShadowValue,
	colorspec: TSpecsColor,
	isActive?: boolean,
	buttonGroup?: ?TButtonGroupEmbed,
	onClick?: (MouseEvent, ?string, ?string) => void,
|}>;

const listItemSx = {
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
	':last-child': {
		border: 'none',
	},
};

const textStyle = {
	marginLeft: '15px',
};

const flexSx = {
	alignItems: 'center',
	padding: '3px 0',
	cursor: 'default',
};

const boxOuterSx = {
	width: '18px',
	height: '18px',
	backgroundColor: '#FFFFFF',
	borderColor: 'bg.secondaryminus',
	borderRadius: 'rounded.all',
	borderWidth: '1px',
	borderStyle: 'solid',
	padding: '1px',
	marginTop: '-3px',
	position: 'relative',
};

const boxInnerSx = {
	width: '14px',
	height: '14px',
	position: 'absolute',
	borderRadius: 'rounded.all',
};

function LabelShadow({
	name,
	shadow,
	colorspec,
	buttonGroup,
	isActive,
	onClick,
}: TProps) {
	const boxStyle = useMemo(
		() => ({
			boxShadow: getShadow({ colorspec, shadow: [shadow] }),
		}),
		[shadow, colorspec],
	);

	const clickBound = useCallback(
		(e, itemName, buttonName) => {
			if (onClick && (!buttonName || typeof buttonName === 'string')) {
				onClick(e, name, buttonName);
			}
		},
		[name, onClick],
	);

	return (
		<ListItem buttonGroup={buttonGroup} onClick={clickBound} sx={listItemSx}>
			<Flex sx={flexSx}>
				<Box sx={boxOuterSx}>
					<Box sx={boxInnerSx} style={boxStyle} />
					<Box sx={boxInnerSx} style={boxStyle} />
				</Box>
				<Text
					variant="bodysm"
					sx={textStyle}
					color={isActive ? 'text.accent' : 'text.primary'}
				>
					{shadow.value.name}
				</Text>
			</Flex>
		</ListItem>
	);
}

LabelShadow.defaultProps = {
	name: null,
	buttonGroup: null,
	isActive: false,
	onClick: null,
};

export default memo<TProps>(LabelShadow);
