// @flow
import React from 'react';

import { ButtonColor, Flex, Text } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	color: string,
	title: string,
	isActive?: boolean,
	onClick?: (MouseEvent, ?string) => void,
|}>;

const flexStyle = {
	alignItems: 'center',
	padding: '3px 0',
	cursor: 'default',
};

const textStyle = {
	color: 'text.primary',
	marginLeft: '15px',
};

function LabelColor({ color, title, isActive, onClick }: TProps) {
	return (
		<Flex sx={flexStyle}>
			<ButtonColor color={color} onClick={onClick} isActive={isActive} />
			<Text variant="bodysm" sx={textStyle}>
				{title}
			</Text>
		</Flex>
	);
}

LabelColor.defaultProps = {
	isActive: false,
	onClick: null,
};

export default React.memo<TProps>(LabelColor);
