// @flow

import React from 'react';
import { InputInplace } from '@graphite/uneon';
import type { TEffectBorder, TEffectBorderSide } from '@graphite/types';

type TProps = $ReadOnly<{|
	border: TEffectBorder,
	side: TEffectBorderSide,
	onChange?: ?(TEffectBorderSide, ?number) => void,
|}>;

const onValidate = value => value.length === 0 || !Number.isNaN(+value);

function InputBorder({ border, side, onChange = null }: TProps) {
	const boundChange = React.useMemo(
		() => value => {
			if (!onChange) {
				return;
			}
			onChange(side, value === '' ? null : +value);
		},
		[onChange, side],
	);
	const sideValue = border.sides && border.sides[side];
	const value = sideValue === null || sideValue === undefined ? '' : `${sideValue}`;
	return (
		<InputInplace
			suffix="px"
			placeholder={`${border.size}`}
			onValidate={onValidate}
			value={value}
			onEnter={boundChange}
			onBlur={boundChange}
			isGray={!value}
		/>
	);
}

export default React.memo<TProps>(InputBorder);
