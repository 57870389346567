// @flow

import React from 'react';
import { ThemeProvider } from 'emotion-theming';
import { Button, themes, Flex, Icon } from '@graphite/uneon';

import useDefaultDevice from 'Editor/libs/use-default-device';
import type { TGridBreakpointName } from '@graphite/types';

export type TProps = $ReadOnly<{|
	onAddClick: () => void,
	currentDevice: TGridBreakpointName,
|}>;

const wrapperSx = {
	backgroundColor: 'bg.primaryplus',
	width: '100%',
	height: '300px',
	justifyContent: 'center',
	alignItems: 'center',
};

const contentSx = {
	minWidth: '180px',
	minHeight: '120px',
	flexDirection: 'column',
	justifyContent: 'center',
	alignContent: 'stretch',
	alignItems: 'center',
	flexWrap: 'nowrap',
	cursor: 'pointer',
	opacity: '1',
	transition: 'opacity 0.15s ease-out',
	':hover': {
		opacity: '0.8',
	},
};

const EmptyContent = (props: TProps): React$Node => {
	const { onAddClick, currentDevice } = props;
	const isDefaultDevice = currentDevice === useDefaultDevice();

	return (
		<ThemeProvider theme={themes.dark}>
			<Flex sx={wrapperSx} data-kind="page-empty">
				{isDefaultDevice && (
					<Flex sx={contentSx} onClick={onAddClick}>
						<Button variant="white.iconrounded.md">
							<Icon name="plus" colors="baseflat" />
						</Button>
					</Flex>
				)}
			</Flex>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(EmptyContent);
