// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Text, Box, Button } from '@graphite/uneon';

const BoxConnectError = styled(Box)`
	padding-bottom: 77px;
`;
const BoxError = styled(Box)`
	flex-direction: raw;
	padding: 10px 18px;
	background-color: ${({ theme }) => theme.colors.bg.error};
	border-radius: 6px;
	margin-top: 18px;
	margin-bottom: 18px;
`;
const TextError = styled(Text)`
	margin: 0;
`;
const DomainRetry = styled(Button)`
	position: absolute;
	right: 24px;
	bottom: 30px;
`;

type TProps = $ReadOnly<{|
	t: string => void,
	onBack: () => void,
|}>;

function ConnectedError({ t, onBack }: TProps) {
	return (
		<BoxConnectError>
			<BoxError>
				<TextError variant="bodymd" color="text.oncolor">
					{t('The domain cannot be connected.')}
				</TextError>
			</BoxError>
			<Text variant="bodymd" color="text.primary">
				Probably this domain used on another site. Try to connect another domain.
			</Text>
			<DomainRetry variant="accent.rounded.md" onClick={onBack}>
				{t('Retry')}
			</DomainRetry>
		</BoxConnectError>
	);
}

export default React.memo<TProps>(ConnectedError);
