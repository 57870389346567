// @flow
import React from 'react';
import type {
	TColorPalette,
	TPaletteColorRemoveFn,
	TPaletteColorEditFn,
	TColorReference,
} from '@graphite/types';

import ListItemShadedColors from './ListItemShadedColors';

type TProps = $ReadOnly<{|
	active?: ?TColorReference,
	items: TColorPalette,
	onRemove?: TPaletteColorRemoveFn,
	onClick?: TPaletteColorEditFn,
|}>;

function ListShadedColors({ active, items, onRemove, onClick }: TProps) {
	return (
		<>
			{items.map(({ id, name, color, shades }) => {
				const keyShade = shades.find(shade => id === shade.id);
				return (
					<ListItemShadedColors
						key={id}
						id={id}
						active={active && active.entryId === id ? active : null}
						name={name}
						color={color}
						shades={shades}
						dotAt={keyShade && keyShade.id}
						onRemove={onRemove}
						onClick={onClick}
					/>
				);
			})}
		</>
	);
}

ListShadedColors.defaultProps = {
	active: null,
	onRemove: null,
	onClick: null,
};

export default React.memo<TProps>(ListShadedColors);
