// @flow

export const colorNames = [
	'Red', // 0
	'Red', // 1
	'Red', // 2
	'Red', // 3
	'Red', // 4
	'Red', // 5
	'Red', // 6
	'Red', // 7
	'Scarlet', // 8
	'Scarlet', // 9
	'Scarlet', // 10
	'Scarlet', // 11
	'Scarlet', // 12
	'Scarlet', // 13
	'Scarlet', // 14
	'Vermilion', // 15
	'Vermilion', // 16
	'Vermilion', // 17
	'Vermilion', // 18
	'Vermilion', // 19
	'Vermilion', // 20
	'Vermilion', // 21
	'Vermilion', // 22
	'Tangelo', // 23
	'Tangelo', // 24
	'Tangelo', // 25
	'Tangelo', // 26
	'Tangelo', // 27
	'Tangelo', // 28
	'Tangelo', // 29
	'Orange', // 30
	'Orange', // 31
	'Orange', // 32
	'Orange', // 33
	'Orange', // 34
	'Orange', // 35
	'Orange', // 36
	'Gamboge', // 37
	'Gamboge', // 38
	'Gamboge', // 39
	'Gamboge', // 40
	'Gamboge', // 41
	'Gamboge', // 42
	'Gamboge', // 43
	'Gamboge', // 44
	'Amber', // 45
	'Amber', // 46
	'Amber', // 47
	'Amber', // 48
	'Amber', // 49
	'Amber', // 50
	'Gold', // 51
	'Gold', // 52
	'Gold', // 53
	'Gold', // 54
	'Gold', // 55
	'Gold', // 56
	'Lemon', // 57
	'Lemon', // 58
	'Lemon', // 59
	'Lemon', // 60
	'Lemon', // 61
	'Lemon', // 62
	'Yellow', // 63
	'Yellow', // 64
	'Yellow', // 65
	'Yellow', // 66
	'Yellow', // 67
	'Yellow', // 68
	'Yellow', // 69
	'Yellow', // 70
	'Yellow', // 71
	'Yellow', // 72
	'Yellow', // 73
	'Yellow', // 74
	'Lime', // 75
	'Lime', // 76
	'Lime', // 77
	'Lime', // 78
	'Lime', // 79
	'Lime', // 80
	'Lime', // 81
	'Lime', // 82
	'Lime', // 83
	'Lime', // 84
	'Lime', // 85
	'Lime', // 86
	'Lime', // 87
	'Chartreuse', // 88
	'Chartreuse', // 89
	'Chartreuse', // 90
	'Chartreuse', // 91
	'Chartreuse', // 92
	'Chartreuse', // 93
	'Chartreuse', // 94
	'Chartreuse', // 95
	'Chartreuse', // 96
	'Pistachio', // 97
	'Pistachio', // 98
	'Pistachio', // 99
	'Pistachio', // 100
	'Pistachio', // 101
	'Pistachio', // 102
	'Pistachio', // 103
	'Pistachio', // 104
	'Harlequin', // 105
	'Harlequin', // 106
	'Harlequin', // 107
	'Harlequin', // 108
	'Harlequin', // 109
	'Harlequin', // 110
	'Harlequin', // 111
	'Green', // 112
	'Green', // 113
	'Green', // 114
	'Green', // 115
	'Green', // 116
	'Green', // 117
	'Green', // 118
	'Green', // 119
	'Green', // 120
	'Green', // 121
	'Green', // 122
	'Emerald', // 123
	'Emerald', // 124
	'Emerald', // 125
	'Emerald', // 126
	'Emerald', // 127
	'Emerald', // 128
	'Emerald', // 129
	'Malachite', // 130
	'Malachite', // 131
	'Malachite', // 132
	'Malachite', // 133
	'Malachite', // 134
	'Malachite', // 135
	'Malachite', // 136
	'Malachite', // 137
	'Erin', // 138
	'Erin', // 139
	'Erin', // 140
	'Erin', // 141
	'Erin', // 142
	'Sea-green', // 143
	'Sea-green', // 144
	'Sea-green', // 145
	'Sea-green', // 146
	'Sea-green', // 147
	'Sea-green', // 148
	'Sea-green', // 149
	'Spring-green', // 150
	'Spring-green', // 151
	'Spring-green', // 152
	'Spring-green', // 153
	'Spring-green', // 154
	'Spring-green', // 155
	'Spring-green', // 156
	'Aquamarine', // 157
	'Aquamarine', // 158
	'Aquamarine', // 159
	'Aquamarine', // 160
	'Aquamarine', // 161
	'Aquamarine', // 162
	'Aquamarine', // 163
	'Turquoise', // 164
	'Turquoise', // 165
	'Turquoise', // 166
	'Turquoise', // 167
	'Turquoise', // 168
	'Turquoise', // 169
	'Turquoise', // 170
	'Turquoise', // 171
	'Opal', // 172
	'Opal', // 173
	'Opal', // 174
	'Opal', // 175
	'Opal', // 176
	'Opal', // 177
	'Opal', // 178
	'Opal', // 179
	'Cyan', // 180
	'Cyan', // 181
	'Cyan', // 182
	'Cyan', // 183
	'Cyan', // 184
	'Cyan', // 185
	'Cyan', // 186
	'Arctic', // 187
	'Arctic', // 188
	'Arctic', // 189
	'Arctic', // 190
	'Arctic', // 191
	'Arctic', // 192
	'Arctic', // 193
	'Arctic', // 194
	'Cerulean', // 195
	'Cerulean', // 196
	'Cerulean', // 197
	'Cerulean', // 198
	'Cerulean', // 199
	'Cerulean', // 200
	'Cerulean', // 201
	'Cornflower', // 202
	'Cornflower', // 203
	'Cornflower', // 204
	'Cornflower', // 205
	'Cornflower', // 206
	'Cornflower', // 207
	'Cornflower', // 208
	'Cornflower', // 209
	'Azure', // 210
	'Azure', // 211
	'Azure', // 212
	'Azure', // 213
	'Azure', // 214
	'Azure', // 215
	'Azure', // 216
	'Cobalt', // 217
	'Cobalt', // 218
	'Cobalt', // 219
	'Cobalt', // 220
	'Cobalt', // 221
	'Cobalt', // 222
	'Cobalt', // 223
	'Cobalt', // 224
	'Sapphire', // 225
	'Sapphire', // 226
	'Sapphire', // 227
	'Sapphire', // 228
	'Sapphire', // 229
	'Sapphire', // 230
	'Sapphire', // 231
	'Phthalo', // 232
	'Phthalo', // 233
	'Phthalo', // 234
	'Phthalo', // 235
	'Phthalo', // 236
	'Phthalo', // 237
	'Phthalo', // 238
	'Blue', // 239
	'Blue', // 240
	'Blue', // 241
	'Blue', // 242
	'Blue', // 243
	'Blue', // 244
	'Blue', // 245
	'Blue', // 246
	'Persian', // 247
	'Persian', // 248
	'Persian', // 249
	'Persian', // 250
	'Persian', // 251
	'Persian', // 252
	'Indigo', // 253
	'Indigo', // 254
	'Indigo', // 255
	'Indigo', // 256
	'Indigo', // 257
	'Indigo', // 258
	'Ultramarine', // 259
	'Ultramarine', // 260
	'Ultramarine', // 261
	'Ultramarine', // 262
	'Ultramarine', // 263
	'Ultramarine', // 264
	'Ultramarine', // 265
	'Ultramarine', // 266
	'Ultramarine', // 267
	'Ultramarine', // 268
	'Violet', // 269
	'Violet', // 270
	'Violet', // 271
	'Violet', // 272
	'Violet', // 273
	'Violet', // 274
	'Violet', // 275
	'Purple', // 276
	'Purple', // 277
	'Purple', // 278
	'Purple', // 279
	'Purple', // 280
	'Purple', // 281
	'Purple', // 282
	'Purple', // 283
	'Purple', // 284
	'Mulberry', // 285
	'Mulberry', // 286
	'Mulberry', // 287
	'Mulberry', // 288
	'Mulberry', // 289
	'Mulberry', // 290
	'Mulberry', // 291
	'Heliotrope', // 292
	'Heliotrope', // 293
	'Heliotrope', // 294
	'Heliotrope', // 295
	'Heliotrope', // 296
	'Heliotrope', // 297
	'Heliotrope', // 298
	'Heliotrope', // 299
	'Magenta', // 300
	'Magenta', // 301
	'Magenta', // 302
	'Magenta', // 303
	'Magenta', // 304
	'Magenta', // 305
	'Magenta', // 306
	'Orchid', // 307
	'Orchid', // 308
	'Orchid', // 309
	'Orchid', // 310
	'Orchid', // 311
	'Orchid', // 312
	'Orchid', // 313
	'Orchid', // 314
	'Fuchsia', // 315
	'Fuchsia', // 316
	'Fuchsia', // 317
	'Fuchsia', // 318
	'Fuchsia', // 319-
	'Fuchsia', // 320
	'Cerise', // 321
	'Cerise', // 322
	'Cerise', // 323
	'Cerise', // 324
	'Cerise', // 325
	'Cerise', // 326
	'Cerise', // 327
	'Cerise', // 328
	'Rose', // 329
	'Rose', // 330-
	'Rose', // 331
	'Rose', // 332
	'Rose', // 333
	'Rose', // 334
	'Rose', // 335
	'Rose', // 336
	'Raspberry', // 337
	'Raspberry', // 338
	'Raspberry', // 339
	'Raspberry', // 340
	'Raspberry', // 341
	'Raspberry', // 342
	'Raspberry', // 343
	'Raspberry', // 344
	'Crimson', // 345
	'Crimson', // 346
	'Crimson', // 347
	'Crimson', // 348
	'Crimson', // 349
	'Crimson', // 350
	'Crimson', // 351
	'Amaranth', // 352
	'Amaranth', // 353
	'Amaranth', // 354
	'Amaranth', // 355
	'Amaranth', // 356
	'Amaranth', // 357
	'Amaranth', // 358
	'Amaranth', // 359
];

export default {};
