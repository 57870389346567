// @flow

import _ from 'lodash/fp';
import { findPlace } from './findPlace';
import type {
	TDNDMethodFindOver,
	TDNDContext,
	TDNDWidget,
	TDNDDrop,
} from '../constants/types';

export const findOver: TDNDMethodFindOver = (state, payload) => {
	const resetState: TDNDContext = _.merge(state, {
		overNode: null,
		overId: null,
		lastOverId: null,
	});
	const overNode = payload ? payload.node : null;
	const { widgets, dragId, dropPlaces } = state;
	const dragWidget = dragId ? widgets[dragId] : null;

	const isAlreadyFound: boolean = overNode === state.overNode && !!state.overId;
	const isInsideNode: boolean = !!(
		dragWidget && dragWidget.ref.current.contains(overNode)
	);

	if (isAlreadyFound) return state;
	if (!dragWidget || !overNode) return resetState;

	if (isInsideNode) {
		if (state.overId === dragId) return state;
		return _.merge(state, { overNode, overId: dragId });
	}

	let node = overNode;
	let widget: ?TDNDWidget = null;
	let drop: ?TDNDDrop = null;
	const checkWidgetType = widget => widget.widgetType === dragWidget.widgetType;

	// проходимся по всем вложенным виджетам снизу вверх
	while (node && !drop) {
		// определяем виджет-цель
		const widgetResult =
			findPlace<TDNDWidget>({
				node,
				places: widgets,
				callback: checkWidgetType,
			}) || widget;

		// don't flip with parent widget
		// проверяем то что цель не родительский контенер
		if (widgetResult?.widgetId !== dragWidget.containerId) {
			widget = widgetResult;
			// останавливаемся, если пытаемся сортировать во вложенном виджете
			if (
				widgetResult &&
				widgetResult.ref.current.parentNode.contains(dragWidget.ref.current)
			)
				break;
		}

		drop = findPlace<TDNDDrop>({
			node,
			places: dropPlaces,
		});

		node = node.parentElement;
	}

	const overId = (drop ? drop.placeId : widget?.composeId) || null;
	const lastOverId = overId || state.lastOverId;

	// если over node не поменялась или не можем найти, то берем с прошлой итерации
	if (!overId || (state.overId === overId && state.overNode === overNode)) return state;
	return _.merge(state, { overNode, overId, lastOverId });
};

export default {};
