// @flow

import React from 'react';
import { Text, Flex } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	borderName: string,
|}>;

const blockSx = {
	position: 'absolute',
	top: '0',
	left: '0',
	right: '0',
	bottom: '0',
	pointerEvents: 'none',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'spec.blue10',
	justifyContent: 'start',
	transition: 'opacity 0.1s ease-in',
};

const textSx = {
	color: 'text.oncolor',
	lineHeight: '24px',
};

const handlerSx = {
	position: 'absolute',
	top: '-32px', // высота + 2px бордер + 6px отступ
	backgroundColor: 'spec.blue10',
	padding: '0 9px',
	height: '24px',
	borderRadius: 'rounded.all',
	boxShadow: 'smblue',
};

const Border = ({ borderName }: TProps) => {
	return (
		<Flex sx={blockSx}>
			<Flex sx={handlerSx}>
				<Text variant="captionlg" sx={textSx}>
					{borderName}
				</Text>
			</Flex>
		</Flex>
	);
};

export default React.memo<TProps>(Border);
