// @flow

import type { TColorPalette, TColorThemes } from '@graphite/types';

type TPreset = $ReadOnly<{|
	palette: TColorPalette,
	themes: TColorThemes,
|}>;

export const colorspecUneon: TPreset = {
	palette: [
		{
			id: 'lightblue50',
			name: 'Lightblue',
			color: '#C5CED6',
			shades: [
				{ color: '#FAFCFD', id: 'lightblue10' },
				{ color: '#ECF2F5', id: 'lightblue20' },
				{ color: '#DFE7EC', id: 'lightblue30' },
				{ color: '#D2DCE2', id: 'lightblue40' },
				{ color: '#C5CED6', id: 'lightblue50' },
				{ color: '#B5C0CC', id: 'lightblue60' },
				{ color: '#A5B1C0', id: 'lightblue70' },
				{ color: '#939FB1', id: 'lightblue80' },
				{ color: '#7F8A9E', id: 'lightblue90' },
			],
		},
		{
			id: 'darkblue50',
			name: 'Darkblue',
			color: '#363847',
			shades: [
				{ color: '#4A5060', id: 'darkblue10' },
				{ color: '#464B5B', id: 'darkblue20' },
				{ color: '#414555', id: 'darkblue30' },
				{ color: '#3C3F4F', id: 'darkblue40' },
				{ color: '#363847', id: 'darkblue50' },
				{ color: '#2E3040', id: 'darkblue60' },
				{ color: '#252634', id: 'darkblue70' },
				{ color: '#191925', id: 'darkblue80' },
				{ color: '#020203', id: 'darkblue90' },
			],
		},
		{
			id: 'blue50',
			name: 'Blue',
			color: '#3D5EFF',
			shades: [
				{ color: '#5D98FF', id: 'blue10' },
				{ color: '#578CFF', id: 'blue20' },
				{ color: '#507FFF', id: 'blue30' },
				{ color: '#4870FF', id: 'blue40' },
				{ color: '#3D5EFF', id: 'blue50' },
				{ color: '#334AF4', id: 'blue60' },
				{ color: '#2834DD', id: 'blue70' },
				{ color: '#1A1DAE', id: 'blue80' },
				{ color: '#17145B', id: 'blue90' },
			],
		},
		{
			id: 'violet50',
			name: 'Violet',
			color: '#E433FF',
			shades: [
				{ color: '#F78DFF', id: 'violet10' },
				{ color: '#F47DFF', id: 'violet20' },
				{ color: '#F06AFF', id: 'violet30' },
				{ color: '#EB53FF', id: 'violet40' },
				{ color: '#E433FF', id: 'violet50' },
				{ color: '#CF2CEC', id: 'violet60' },
				{ color: '#B724D4', id: 'violet70' },
				{ color: '#9A1CB6', id: 'violet80' },
				{ color: '#74148C', id: 'violet90' },
			],
		},
		{
			id: 'green50',
			name: 'Green',
			color: '#14CC86',
			shades: [
				{ color: '#AFEFCA', id: 'green10' },
				{ color: '#8FE9BB', id: 'green20' },
				{ color: '#6AE1AC', id: 'green30' },
				{ color: '#43D89A', id: 'green40' },
				{ color: '#14CC86', id: 'green50' },
				{ color: '#09BC7E', id: 'green60' },
				{ color: '#00AA74', id: 'green70' },
				{ color: '#009569', id: 'green80' },
				{ color: '#007C5A', id: 'green90' },
			],
		},
		{
			id: 'gold50',
			name: 'Gold',
			color: '#F5BA31',
			shades: [
				{ color: '#FDF6C2', id: 'gold10' },
				{ color: '#FBEB91', id: 'gold20' },
				{ color: '#F9DD6D', id: 'gold30' },
				{ color: '#F7CD4E', id: 'gold40' },
				{ color: '#F5BA31', id: 'gold50' },
				{ color: '#EEA620', id: 'gold60' },
				{ color: '#E59010', id: 'gold70' },
				{ color: '#D87701', id: 'gold80' },
				{ color: '#C15B00', id: 'gold90' },
			],
		},
		{
			id: 'red50',
			name: 'Red',
			color: '#FA4B62',
			shades: [
				{ color: '#FC97B2', id: 'red10' },
				{ color: '#FC89A4', id: 'red20' },
				{ color: '#FB7993', id: 'red30' },
				{ color: '#FB657E', id: 'red40' },
				{ color: '#FA4B62', id: 'red50' },
				{ color: '#E54253', id: 'red60' },
				{ color: '#CC3943', id: 'red70' },
				{ color: '#AE2F33', id: 'red80' },
				{ color: '#842222', id: 'red90' },
			],
		},
	],

	themes: [
		{
			id: 'light',
			name: 'Light',
			text: {
				primary: {
					entryId: 'darkblue50',
					shadeId: 'darkblue40',
					snapshot: '#3C3F4F',
				},
				primaryalt: {
					entryId: 'darkblue50',
					shadeId: 'darkblue80',
					snapshot: '#191925',
				},
				secondary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue90',
					snapshot: '#7F8A9E',
				},
				tertiary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue60',
					snapshot: '#B5C0CC',
				},
				accent: {
					entryId: 'blue50',
					shadeId: 'blue50',
					snapshot: '#3D5EFF',
				},
				accentalt: {
					entryId: 'blue50',
					shadeId: 'blue50',
					snapshot: '#3D5EFF',
				},
				success: {
					entryId: 'green50',
					shadeId: 'green60',
					snapshot: '#09BC7E',
				},
				error: {
					entryId: 'red50',
					shadeId: 'red50',
					snapshot: '#FA4B62',
				},
				warning: {
					entryId: 'gold50',
					shadeId: 'gold50',
					snapshot: '#FA4B62',
				},
				oncolor: {
					entryId: 'lightblue50',
					shadeId: 'lightblue10',
					snapshot: '#FAFCFD',
				},
				accentplus: {
					entryId: 'blue50',
					shadeId: 'blue20',
					snapshot: '#578CFF',
				},
				accentaltplus: {
					entryId: 'blue50',
					shadeId: 'blue20',
					snapshot: '#578CFF',
				},
				successplus: {
					entryId: 'green50',
					shadeId: 'green30',
					snapshot: '#6AE1AC',
				},
				errorplus: {
					entryId: 'red50',
					shadeId: 'red30',
					snapshot: '#FB7993',
				},
				warningplus: {
					entryId: 'gold50',
					shadeId: 'gold30',
					snapshot: '#FB7993',
				},
				accentminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				accentaltminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				successminus: {
					entryId: 'green50',
					shadeId: 'green90',
					snapshot: '#007C5A',
				},
				errorminus: {
					entryId: 'red50',
					shadeId: 'red90',
					snapshot: '#842222',
				},
				warningminus: {
					entryId: 'gold50',
					shadeId: 'gold90',
					snapshot: '#842222',
				},
			},
			bg: {
				primary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue10',
					snapshot: '#FAFCFD',
				},
				primaryalt: {
					entryId: 'lightblue50',
					shadeId: 'lightblue20',
					snapshot: '#ECF2F5',
				},
				secondary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue30',
					snapshot: '#DFE7EC',
				},
				tertiary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue30',
					snapshot: '#DFE7EC',
				},
				accent: {
					entryId: 'blue50',
					shadeId: 'blue40',
					snapshot: '#4870FF',
				},
				accentalt: {
					entryId: 'blue50',
					shadeId: 'blue40',
					snapshot: '#4870FF',
				},
				success: {
					entryId: 'green50',
					shadeId: 'green50',
					snapshot: '#14CC86',
				},
				error: {
					entryId: 'red50',
					shadeId: 'red50',
					snapshot: '#FA4B62',
				},
				warning: {
					entryId: 'gold50',
					shadeId: 'gold50',
					snapshot: '#FA4B62',
				},
				primaryplus: {
					entryId: null,
					shadeId: null,
					snapshot: '#FFFFFF',
				},
				primaryaltplus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue30',
					snapshot: '#DFE7EC',
				},
				secondaryplus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue40',
					snapshot: '#D2DCE2',
				},
				tertiaryplus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue40',
					snapshot: '#D2DCE2',
				},
				accentplus: {
					entryId: 'blue50',
					shadeId: 'blue10',
					snapshot: '#5D98FF',
				},
				accentaltplus: {
					entryId: 'blue50',
					shadeId: 'blue10',
					snapshot: '#5D98FF',
				},
				successplus: {
					entryId: 'green50',
					shadeId: 'green30',
					snapshot: '#6AE1AC',
				},
				errorplus: {
					entryId: 'red50',
					shadeId: 'red40',
					snapshot: '#FB657E',
				},
				warningplus: {
					entryId: 'gold50',
					shadeId: 'gold40',
					snapshot: '#FB657E',
				},
				primaryminus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue20',
					snapshot: '#ECF2F5',
				},
				primaryaltminus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue40',
					snapshot: '#D2DCE2',
				},
				secondaryminus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue50',
					snapshot: '#C5CED6',
				},
				tertiaryminus: {
					entryId: 'lightblue50',
					shadeId: 'lightblue50',
					snapshot: '#C5CED6',
				},
				accentminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				accentaltminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				successminus: {
					entryId: 'green50',
					shadeId: 'green70',
					snapshot: '#00AA74',
				},
				errorminus: {
					entryId: 'red50',
					shadeId: 'red70',
					snapshot: '#CC3943',
				},
				warningminus: {
					entryId: 'gold50',
					shadeId: 'gold70',
					snapshot: '#CC3943',
				},
			},
		},
		{
			id: 'dark',
			name: 'Dark',
			text: {
				primary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue60',
					snapshot: '#B5C0CC',
				},
				primaryalt: {
					entryId: 'lightblue50',
					shadeId: 'lightblue20',
					snapshot: '#ECF2F5',
				},
				secondary: {
					entryId: 'lightblue50',
					shadeId: 'lightblue90',
					snapshot: '#7F8A9E',
				},
				tertiary: {
					entryId: 'darkblue50',
					shadeId: 'darkblue10',
					snapshot: '#4A5060',
				},
				accent: {
					entryId: 'blue50',
					shadeId: 'blue30',
					snapshot: '#507FFF',
				},
				accentalt: {
					entryId: 'blue50',
					shadeId: 'blue30',
					snapshot: '#507FFF',
				},
				success: {
					entryId: 'green50',
					shadeId: 'green50',
					snapshot: '#14CC86',
				},
				error: {
					entryId: 'red50',
					shadeId: 'red50',
					snapshot: '#FA4B62',
				},
				warning: {
					entryId: 'gold50',
					shadeId: 'gold50',
					snapshot: '#FA4B62',
				},
				oncolor: {
					entryId: 'lightblue50',
					shadeId: 'lightblue10',
					snapshot: '#FAFCFD',
				},
				accentplus: {
					entryId: 'blue50',
					shadeId: 'blue10',
					snapshot: '#5D98FF',
				},
				accentaltplus: {
					entryId: 'blue50',
					shadeId: 'blue10',
					snapshot: '#5D98FF',
				},
				successplus: {
					entryId: 'blue50',
					shadeId: 'blue30',
					snapshot: '#507FFF',
				},
				errorplus: {
					entryId: 'blue50',
					shadeId: 'blue40',
					snapshot: '#4870FF',
				},
				warningplus: {
					entryId: 'gold50',
					shadeId: 'golde40',
					snapshot: '#4870FF',
				},
				accentminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				accentaltminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				successminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				errorminus: {
					entryId: 'blue50',
					shadeId: 'blue70',
					snapshot: '#2834DD',
				},
				warningminus: {
					entryId: 'gold50',
					shadeId: 'golde70',
					snapshot: '#2834DD',
				},
			},
			bg: {
				primary: {
					entryId: 'darkblue50',
					shadeId: 'darkblue80',
					snapshot: '#191925',
				},
				primaryalt: {
					entryId: 'darkblue50',
					shadeId: 'darkblue70',
					snapshot: '#252634',
				},
				secondary: {
					entryId: 'darkblue50',
					shadeId: 'darkblue90',
					snapshot: '#020203',
				},
				tertiary: {
					entryId: 'darkblue50',
					shadeId: 'darkblue90',
					snapshot: '#020203',
				},
				accent: {
					entryId: 'blue50',
					shadeId: 'blue60',
					snapshot: '#334AF4',
				},
				accentalt: {
					entryId: 'blue50',
					shadeId: 'blue60',
					snapshot: '#334AF4',
				},
				success: {
					entryId: 'green50',
					shadeId: 'green60',
					snapshot: '#09BC7E',
				},
				error: {
					entryId: 'red50',
					shadeId: 'red50',
					snapshot: '#FA4B62',
				},
				warning: {
					entryId: 'gold50',
					shadeId: 'gold50',
					snapshot: '#FA4B62',
				},
				primaryplus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue70',
					snapshot: '#252634',
				},
				primaryaltplus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue60',
					snapshot: '#2E3040',
				},
				secondaryplus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue80',
					snapshot: '#191925',
				},
				tertiaryplus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue80',
					snapshot: '#191925',
				},
				accentplus: {
					entryId: 'blue50',
					shadeId: 'blue40',
					snapshot: '#4870FF',
				},
				accentaltplus: {
					entryId: 'blue50',
					shadeId: 'blue40',
					snapshot: '#4870FF',
				},
				successplus: {
					entryId: 'green50',
					shadeId: 'green40',
					snapshot: '#43D89A',
				},
				errorplus: {
					entryId: 'red50',
					shadeId: 'red40',
					snapshot: '#FB657E',
				},
				warningplus: {
					entryId: 'gold50',
					shadeId: 'gold40',
					snapshot: '#FB657E',
				},
				primaryminus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue90',
					snapshot: '#020203',
				},
				primaryaltminus: {
					entryId: 'darkblue50',
					shadeId: 'darkblue80',
					snapshot: '#191925',
				},
				secondaryminus: {
					entryId: null,
					shadeId: null,
					snapshot: '#000000',
				},
				tertiaryminus: {
					entryId: null,
					shadeId: null,
					snapshot: '#000000',
				},
				accentminus: {
					entryId: 'blue50',
					shadeId: 'blue80',
					snapshot: '#1A1DAE',
				},
				accentaltminus: {
					entryId: 'blue50',
					shadeId: 'blue80',
					snapshot: '#1A1DAE',
				},
				successminus: {
					entryId: 'green50',
					shadeId: 'green70',
					snapshot: '#00AA74',
				},
				errorminus: {
					entryId: 'red50',
					shadeId: 'red70',
					snapshot: '#CC3943',
				},
				warningminus: {
					entryId: 'gold50',
					shadeId: 'gold70',
					snapshot: '#CC3943',
				},
			},
		},
	],
};

export default colorspecUneon;
