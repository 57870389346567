// @flow
/**
 * Pack items from heap one by one into baskets of fixed size.
 *
 * heap {Array} - array of items to pack
 * basketSize {Number} - basket max size (capacity)
 * sizeSelector {Function} - item size selector
 */
export function packToBaskets(
	heap: $ReadOnlyArray<*>,
	basketSize: number,
	sizeSelector: (s: number) => number = s => s,
) {
	const sum = a => a.reduce((a, c) => a + sizeSelector(c), 0);

	return heap.reduce(
		(acc, curr) => {
			const last = acc[acc.length - 1];

			if (sum([...last, curr]) <= basketSize) {
				last.push(curr);
				return acc;
			}

			acc.push([curr]);
			return acc;
		},
		[[]],
	);
}

/**
 * Create a hash of sibling items
 * from baskets list created by `packToBaskets` function
 *
 * heapOfBaskets {Array} - list of baskets
 * keySelector {Function}
 */
export function createSiblingsHash(
	heapOfBaskets: $ReadOnlyArray<*>,
	keySelector: (k: mixed) => mixed = k => k,
) {
	return heapOfBaskets.reduce((hash, basket) => {
		const siblings = basket.map(basketItem => keySelector(basketItem));

		return siblings.reduce((hash, _id) => {
			hash[_id] = siblings.filter(s => s !== _id);
			return hash;
		}, hash);
	}, {});
}

/**
 * Insert special delimiter between items of array
 *
 * heap {Array} - array of items to delimit
 * delimiterCreator {Function} - function that creates delimiter to insert
 */
type TDelimiterCreator = (before: string, after: string) => string;
export function insertBetween(
	heap: $ReadOnlyArray<*>,
	delimiterCreator: TDelimiterCreator = (before = '', after = '') =>
		`${before}|${after}`,
): $ReadOnlyArray<*> {
	if (heap.length === 0 || heap.length === 1) {
		return heap;
	}

	const result = heap.reduce(
		(acc, curr, idx) => [
			...acc,
			curr,
			delimiterCreator(curr, heap.length - 1 === idx ? '' : heap[idx + 1]),
		],
		[],
	);

	return result.slice(0, -1);
}

/**
 * Flatten immutable lists but don't flatten immutable maps
 *
 * list {Array} - list to flatten
 */
export function flattenLists(list: $ReadOnlyArray<*>) {
	if (Array.isArray(list) === false) {
		return [list];
	}

	return list.reduce((a, c) => [...a, ...flattenLists(c)], []);
}

/**
 * Find first truthy value according to predicate,
 * returns null if none found
 *
 * array {Array} - items to check by predicate
 * predicate {Function} function to check items in array
 */
export function firstTrue<A, B>(
	array: $ReadOnlyArray<A>,
	predicate: (i: A, idx: number, array: $ReadOnlyArray<A>) => ?B,
): ?B {
	for (let i = 0; i < array.length; i++) {
		const result = predicate(array[i], i, array);

		if (result) {
			return result;
		}
	}

	return null;
}

export function getNeighbors<T>(
	items: $ReadOnlyArray<T>,
	item: T,
): { prev: T | null, next: T | null } | null {
	const idx = items.findIndex(i => i === item);

	if (idx === -1) {
		return null;
	}

	const r = {};

	r.prev = idx === 0 ? null : items[idx - 1];
	r.next = idx === items.length - 1 ? null : items[idx + 1];

	return r;
}
