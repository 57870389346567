// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash/fp';
import { ButtonGroup } from '@graphite/uneon';
import { deviceTabsMd, gridBreakpointsNames } from '@graphite/constants';

import logger from 'libs/logger';
import { getCurrentSiteGridSpec } from '@graphite/selectors';
import type { TSpecsGrid } from '@graphite/types';
import { updateEditor } from 'Editor/ducks/editor';
import { useDispatch } from '@graphite/use-redux';
import { getCurrentDevice } from 'Editor/selectors/editor';

type TProps = $ReadOnly<{||}>;

const groupStyle = {
	flexShrink: 0,
};

const ButtonsDevices = () => {
	const currentDevice = useSelector(getCurrentDevice);
	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const dispatch = useDispatch();

	const availableDevices = React.useMemo(
		() =>
			deviceTabsMd.filter(
				({ name }) => !!_.get(`breakpoints.${name}.active`, gridspec),
			),
		[gridspec],
	);

	const device = React.useMemo(
		() =>
			availableDevices.find(d => d.name === currentDevice) ||
			availableDevices[0] ||
			deviceTabsMd[0],
		[currentDevice, availableDevices],
	);

	const changeDevice = React.useCallback(
		(e, device) => {
			const currentDevice =
				gridBreakpointsNames.find(name => name === device) ||
				gridBreakpointsNames[0];
			logger.info('pickDevice', { device: currentDevice });
			dispatch(updateEditor({ currentDevice }));
		},
		[dispatch],
	);

	return (
		<ButtonGroup
			behavior="radio"
			active={device.name}
			buttons={availableDevices}
			variant="flat"
			size="md"
			colors="secondary"
			activeColors="primary"
			onClick={changeDevice}
			sx={groupStyle}
			margin={6}
		/>
	);
};

export default React.memo<TProps>(ButtonsDevices);
