/* eslint-disable one-var */
/* eslint-disable no-var */
/* eslint-disable prefer-spread */
/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
/* eslint-disable no-multi-assign */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-expressions */
/* eslint-disable flowtype/require-valid-file-annotation */
export default () => {
	if (process.env.NODE_ENV === 'production') {
		!(function(f, b, e, v, n, t, s) {
			if (f.fbq) return;
			n = f.fbq = function() {
				n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
			};
			if (!f._fbq) f._fbq = n;
			n.push = n;
			n.loaded = !0;
			n.version = '2.0';
			n.queue = [];
			t = b.createElement(e);
			t.async = !0;
			t.src = v;
			s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s);
		})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
		fbq('init', '1646275085582737');
		fbq('track', 'PageView');

		const noscript = document.createElement('noscript');
		const img = document.createElement('img');
		img.height = 1;
		img.width = 1;
		img.style.display = 'none';
		img.src = 'https://www.facebook.com/tr?id=1646275085582737&ev=PageView&noscript=1';

		noscript.appendChild(img);
		document.head.appendChild(noscript);

		const rootChat = document.createElement('div');
		rootChat.id = 'fb-root';
		const chat = document.createElement('div');
		chat.className = 'fb-customerchat';
		chat.setAttribute('attribution', 'setup_tool');
		chat.setAttribute('page_id', '106400247809751');
		chat.setAttribute('minimized', 'true');

		document.body.appendChild(rootChat);
		document.body.appendChild(chat);

		window.fbAsyncInit = function() {
			FB.init({
				xfbml: true,
				version: 'v9.0',
			});
		};

		(function(d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	}
};
