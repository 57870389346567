// @flow
import React, { useMemo, forwardRef, type ElementRef } from 'react';
import type { TDragMixedProps, TDragFlippedProps } from './types';

type TProps = $ReadOnly<{|
	...$Exact<TDragFlippedProps>,
	Component: React$ComponentType<TDragMixedProps>,
|}>;

const FlippedWrapper = (props: TProps, ref: ElementRef<*>) => {
	const {
		Component,
		'data-flip-config': flipConfig,
		'data-flip-id': flipId,
		'data-portal-key': flipPortal,
		...restProps
	} = props;

	const dragFlip = useMemo(
		() => ({
			'data-flip-config': flipConfig,
			'data-flip-id': flipId,
			'data-portal-key': flipPortal,
		}),
		[flipConfig, flipId, flipPortal],
	);

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Component dragFlip={dragFlip} {...restProps} ref={ref} />;
};

export default React.memo<TProps>(forwardRef(FlippedWrapper));
