// @flow
import React from 'react';
import { InputInplace } from '@graphite/uneon';
import type { TEffectRadius, TEffectRadiusSide } from '@graphite/types';

type TProps = $ReadOnly<{|
	radius: TEffectRadius,
	side: TEffectRadiusSide,
	onChange?: ?(TEffectRadiusSide, ?number) => void,
|}>;

const onValidate = value => value.length === 0 || !Number.isNaN(+value);

function InputRadius({ radius, side, onChange = null }: TProps) {
	const boundChange = React.useCallback(
		value => {
			if (!onChange) {
				return;
			}
			onChange(side, value === '' ? null : +value);
		},
		[onChange, side],
	);
	const sideValue = radius.sides && radius.sides[side];
	const value = sideValue === null || sideValue === undefined ? '' : `${sideValue}`;
	return (
		<InputInplace
			suffix="px"
			placeholder={`${radius.size}`}
			onValidate={onValidate}
			value={value}
			onEnter={boundChange}
			onBlur={boundChange}
			isGray={!value}
		/>
	);
}

export default React.memo<TProps>(InputRadius);
