// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { Toolbar, ToolbarBtn, Popper } from '@graphite/uneon';

import { getAlign, toggleAlign } from '../libs/draft-utils';

const ICONS = {
	left: 'text-align-left',
	center: 'text-align-center',
	right: 'text-align-right',
	justify: 'text-align-justify',
};

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
|}>;

const TextAlign = ({
	editorState,
	setEditorState,
	setOpenedPanel,
	openedPanel,
}: TProps) => {
	const textAlignBtnEl = React.useRef(null);
	const handleTextAlign = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === 'textAlign' ? null : 'textAlign',
			);
		},
		[setOpenedPanel],
	);

	const textAlignStyle = React.useMemo(() => getAlign(editorState), [editorState]);

	const changeTextAlignStyle = React.useMemo(() => toggleAlign(editorState), [
		editorState,
	]);

	const handleSetLeft = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('left'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetCenter = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('center'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetRight = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('right'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	const handleSetJustify = React.useCallback(
		e => {
			e.preventDefault();

			setEditorState(changeTextAlignStyle('justify'));
		},
		[setEditorState, changeTextAlignStyle],
	);

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleTextAlign}
				iconName={ICONS[textAlignStyle || 'left']}
				ref={textAlignBtnEl}
				isActive={openedPanel === 'textAlign'}
			/>

			<Popper
				isOpen={openedPanel === 'textAlign'}
				anchorEl={textAlignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<ToolbarBtn
						onMouseDown={handleSetLeft}
						iconName={ICONS.left}
						isActive={textAlignStyle === 'left'}
					/>
					<ToolbarBtn
						onMouseDown={handleSetCenter}
						iconName={ICONS.center}
						isActive={textAlignStyle === 'center'}
					/>
					<ToolbarBtn
						onMouseDown={handleSetRight}
						iconName={ICONS.right}
						isActive={textAlignStyle === 'right'}
					/>
					<ToolbarBtn
						onMouseDown={handleSetJustify}
						iconName={ICONS.justify}
						isActive={textAlignStyle === 'justify'}
					/>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(TextAlign);
