// @flow
import { takeEvery, put, fork, select, cancelled, take } from 'redux-saga/effects';
import { handleActions } from 'redux-actions';
import { type Saga, eventChannel, type EventChannel } from 'redux-saga';
import _ from 'lodash/fp';
import type {
	TAction,
	TId,
	TWidget,
	TQueryConstraints2,
	TQueryConstraint,
	TPublicationType,
	TPublications,
	TPublicationStatus,
} from '@graphite/types';

import logger from 'libs/logger';
import { collection, storage } from 'libs/firebase';
import {
	getCurrentProjectId,
	getCurrentSiteId,
	getCurrentPageId,
	getWidgets,
	getWidget,
	getSpecs,
	getTrueWidgets,
} from '@graphite/selectors';
import { getCurrentDevice } from 'Editor/selectors/editor';
import { getPublishStatus } from '../selectors/editor';

type TState = $ReadOnly<{|
	status: TPublicationStatus,
|}>;

type TStatusProcessing = 'publishing' | 'exporting';

const PUBLISH = 'PUBLISH/PUBLISH';
const REQUEST = 'PUBLISH/REQUEST';
const RECEIVE = 'PUBLISH/RECEIVE';
const REJECT = 'PUBLISH/REJECT';
const RESET = 'PUBLISH/RESET';
const FETCH_IF_NEEDED = 'PUBLISH/FETCH_IF_NEEDED';

export const fetchIfNeeded = (userId: ?TId, siteId: ?TId): TAction => ({
	type: FETCH_IF_NEEDED,
	payload: { userId, siteId },
});

export const publish = (type: TPublicationType = 'netlify'): TAction => ({
	type: PUBLISH,
	payload: { type },
});

export const request = (type: TStatusProcessing): TAction => ({
	type: REQUEST,
	payload: { type },
});

export const receive = (): TAction => ({
	type: RECEIVE,
	payload: {},
});

export const reject = (): TAction => ({
	type: REJECT,
	payload: {},
});
export const reset = (): TAction => ({
	type: RESET,
	payload: {},
});

const getPublicationEventsChannel = (
	userId: string,
	siteId: string,
): EventChannel<{ publications: TPublications }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ publications: TPublications }>(
		(emit: ({ publications: TPublications }) => void): (() => void) => {
			const unsubscriptors: $ReadOnlyArray<() => void> = [
				{
					siteId: ['==', siteId],
					userId: ['==', userId],
					doneAt: ['>', minDate],
				},
			].map((constraints: TQueryConstraints2): (() => void) => {
				let publications = collection('publications').where(
					'siteId',
					'==',
					siteId,
				);
				_.forEach(([k, v]: [string, TQueryConstraint]) => {
					publications = publications.where(k, v[0], v[1]);
				}, _.entries(constraints));
				return publications.onSnapshot((snapshot: any) => {
					const publications = {};
					snapshot.docChanges().forEach((change: any) => {
						const publication = change.doc.data();
						publications[publication.siteId] = publication;
					});
					if (_.size(publications)) {
						emit({ publications });
					}
				});
			});
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* fetchIfNeededSaga(): Saga<void> {
	let publicEventsChannel = null;
	yield takeEvery(FETCH_IF_NEEDED, function*({
		payload: { userId, siteId },
	}: {
		payload: { userId: ?TId, siteId: ?TId },
	}): any {
		if (publicEventsChannel) {
			publicEventsChannel.close();
			publicEventsChannel = null;
		}
		if (!userId || !siteId) return;

		publicEventsChannel = getPublicationEventsChannel(userId, siteId);

		try {
			while (!0) {
				const { publications } = yield take(publicEventsChannel);
				const publishStatus: TPublicationStatus = yield select(getPublishStatus);

				if (
					publications[siteId].status === 'done' &&
					['publishing', 'exporting'].includes(publishStatus)
				) {
					logger.info(`${publications[siteId].type}Finished`);

					if (publications[siteId].type === 'export') {
						// get url for download public.zip
						const zipUrl = yield storage
							.ref(`user/${userId}/${siteId}/public.zip`)
							.getDownloadURL();

						if (zipUrl) window.location.href = zipUrl;
						yield put(reset());
					} else {
						yield put(receive());
					}
					// need to clean up the status for right working
					yield collection('publications')
						.doc(siteId)
						.set({ status: null }, { merge: true });
				}
			}
		} catch (e) {
			logger.error(e);
		} finally {
			if (yield cancelled() && publicEventsChannel) publicEventsChannel.close();
		}
	});
}

export function* publishSaga(): Saga<void> {
	yield takeEvery(PUBLISH, function*({
		payload: { type },
	}: {
		payload: { type: TPublicationType },
	}): Saga<void> {
		try {
			yield put(request((type === 'export' && 'exporting') || 'publishing'));

			const currentDevice = yield select(getCurrentDevice);
			const projectId: ?TId = yield select(getCurrentProjectId);
			const pageId: ?TId = yield select(getCurrentPageId);
			const siteId: ?TId = yield select(getCurrentSiteId);
			if (!(projectId && pageId && siteId)) {
				throw new Error('Project, Site, and Page should all be specified.');
			}

			const site: TWidget = yield select(getWidget, { id: siteId });
			const widgets = yield select(getWidgets);
			const specs = yield select(getSpecs);
			const pageWidgets = yield select(getTrueWidgets, {
				id: site._id,
				currentDevice,
			});

			const sites = {
				[siteId]: site,
			};

			const pages = _.map(
				(page: TWidget): { [string]: string } => ({
					url: `/project/${projectId}/site/${siteId}/page/${page._id}`,
					name:
						page.url ||
						page.name?.replace(/\s+/g, '-').toLowerCase() ||
						page._id,
				}),
				pageWidgets,
			);

			// TODO: if updateAt didnt change in site and other data that dont do
			yield collection('publications')
				.doc(siteId)
				.set({
					status: 'active',
					updateAt: new Date().toISOString(),
					doneAt: null,
					sites,
					widgets,
					specs,
					pages,
					userId: site.userId,
					siteId,
					type,
				});
			logger.info('publishSite', { type });
		} catch (e) {
			yield put(reject());
			logger.error(e);
		}
	});
}

export function* saga(): Saga<void> {
	yield fork(publishSaga);
	yield fork(fetchIfNeededSaga);
}

const initialState: TState = {
	status: 'unpublished',
};

export default handleActions<TState, TAction>(
	{
		[RESET](state: TState): TState {
			return _.set('status', 'unpublished', state);
		},
		[REQUEST](
			state: TState,
			{ payload: { type } }: { +payload: { +type: TStatusProcessing } },
		): TState {
			return _.set('status', type, state);
		},
		[RECEIVE](state: TState): TState {
			return _.set('status', 'published', state);
		},
		[REJECT](state: TState): TState {
			return _.set('status', 'error', state);
		},
	},
	initialState,
);
