// @flow
import React from 'react';
import reduce from 'lodash/fp/reduce';
import { Box, Checkers } from '@graphite/uneon';
import { defaultGradientStop } from '@graphite/constants';
import { getGradient } from '@graphite/selectors';
import type {
	TSx,
	TId,
	TSpecsColor,
	TGradientStop,
	TGradientStops,
} from '@graphite/types';

import GradientStop from './GradientStop';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	stops: $ReadOnlyArray<TGradientStop>,
	active: TId,
	onClick?: ?(string) => void,
	onChange?: ?(TGradientStops) => void,
	genId: string => string,
|}>;

const wrapperSx = {
	margin: '24px 0 36px',
};

const containerSx = {
	height: '36px',
	position: 'relative',
	cursor: 'copy',
};

const previewWrapperSx = {
	position: 'relative',
};

const checkersSx = {
	height: '18px',
	borderRadius: 'rounded.all',
	margin: '0 12px',
};

const previewSx: TSx = {
	...checkersSx,
	position: 'absolute',
	top: '0',
	left: '0',
	right: '0',
	boxShadow: 'inset 0 0 0 1px rgba(0, 0, 0, 0.15)',
};

function GradientStopSlider({
	colorspec,
	stops,
	active,
	onClick = null,
	onChange = null,
	genId,
}: TProps) {
	const containerRef = React.useRef();

	const [width, setWidth] = React.useState(0);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const previewStyle: TSx = React.useMemo(() => {
		const linear = {
			kind: 'linear',
			stops: reduce(
				(accum, stop) => ({
					...accum,
					[stop.id]: stop,
				}),
				{},
				stops,
			),
			angle: 90,
			isRepeating: false,
		};
		return {
			background: getGradient({ colorspec, gradient: linear }),
		};
	}, [colorspec, stops]);

	const changeStop = React.useCallback(
		(stop: TGradientStop) => {
			if (!onChange) {
				return;
			}
			onChange({
				...reduce(
					// eslint-disable-next-line no-shadow
					(accum, stop) => ({
						...accum,
						[stop.id]: stop,
					}),
					{},
					stops,
				),
				[stop.id]: stop,
			});
		},
		[onChange, stops],
	);

	const insertStop = React.useCallback(
		(e: SyntheticMouseEvent<EventTarget>) => {
			if (!onChange || e.target !== containerRef.current || !e.nativeEvent) {
				return;
			}
			const percent = Math.round(
				Math.min(100, Math.max(0, (100 * e.nativeEvent.offsetX) / width)),
			);
			const newId = genId('specs');
			onChange({
				...reduce(
					(accum, stop) => ({
						...accum,
						[stop.id]: stop,
					}),
					{},
					stops,
				),
				[newId]: {
					...defaultGradientStop,
					id: newId,
					at: percent,
				},
			});
		},
		[genId, onChange, stops, width],
	);

	const removeStop = React.useCallback(
		(id: TId) => {
			if (!onChange || stops.length < 3) {
				return;
			}
			if (active === id) {
				const nextStop = stops.find(s => s.id !== id);
				if (!nextStop || !onClick) {
					return;
				}
				onClick(nextStop.id);
			}
			onChange(
				reduce(
					(accum, stop) => ({
						...accum,
						[stop.id]: stop,
					}),
					{},
					stops.filter(s => s.id !== id),
				),
			);
		},
		[active, onChange, onClick, stops],
	);

	return (
		<Box sx={wrapperSx}>
			<Box sx={containerSx} ref={containerRef} onClick={insertStop}>
				{stops.map(stop => (
					<GradientStop
						key={stop.id}
						stop={stop}
						width={width - 24}
						isActive={active === stop.id}
						colorspec={colorspec}
						onClick={onClick}
						onRemove={stops.length < 3 ? null : removeStop}
						onChange={changeStop}
					/>
				))}
			</Box>
			<Box sx={previewWrapperSx}>
				<Checkers color="#80808044" size={4.5} sx={checkersSx} />
				<Box sx={previewSx} style={previewStyle} />
			</Box>
		</Box>
	);
}

export default React.memo<TProps>(GradientStopSlider);
