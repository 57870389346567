// @flow
import React from 'react';
import type { TSx, TWidgetStyle } from '@graphite/types';

// ToDo Зачем это?)
const useDynamicStyle = (style: ?TWidgetStyle): ?TSx => {
	const dynamicStyle: ?TSx = React.useMemo(() => {
		if (!style) {
			return null;
		}
		return {
			marginLeft: style.marginLeft,
			marginRight: style.marginRight,
			marginTop: style.marginTop,
			marginBottom: style.marginBottom,
			width: style.width,
			height: style.height,
			flexGrow: style.flexGrow,
		};
	}, [style]);

	return dynamicStyle;
};

export default useDynamicStyle;
