// @flow
import React from 'react';
import emptyArray from 'empty/array';
import { Flipped } from 'react-flip-toolkit';
import _ from 'lodash/fp';
import getDisplayName from '@graphite/get-display-name';

import type { TWidgetKind } from '@graphite/types';
import type {
	TDragPropsFlip,
	TDNDWidgetType,
	TDNDDirection,
} from 'Widget/libs/dnd/constants/types';

import type {
	TDragGridProps,
	TDragGridWidgetType,
	TDragGridExtendedProps,
	TMethodShouldFlip,
} from './types';
import FlippedWrapper from './FlippedWrapper';
import dndContext from '../dndContext';

const SPRING_CONFIG = {
	stiffness: 2000,
	damping: 100,
};

const DND_DIRECTION = {
	block: 'vertical',
	column: 'horizontal',
	widget: 'vertical',
};

const DND_TYPES: TDragGridWidgetType = {
	block: 'block',
	col: 'column',
	default: 'widget',
};

const shouldFlipCallback: TMethodShouldFlip = (containerId, widgetId) => (
	prevData,
	currData,
) => {
	// stop drag
	if (!!prevData.srcWidgetId && !currData.srcWidgetId) {
		return false;
	}

	// is drag
	if (currData.srcWidgetId) {
		const isContainerChanged = prevData.dstContainerId !== currData.dstContainerId;

		// flipp all widgets in prev and current containers
		// if container has been changed
		if (
			isContainerChanged &&
			(prevData.dstContainerId === containerId ||
				currData.dstContainerId === containerId)
		) {
			// source state
			if (!currData.dstWidgetId) return false;
			return true;
		}

		// flipp src and dst widgets
		return currData.dstWidgetId === widgetId || currData.srcWidgetId === widgetId;
	}

	return false;
};

const DragGrid = <T: TDragGridProps>(
	Component: React$ComponentType<
		$ReadOnly<{|
			...$Exact<T>,
			...$Exact<TDragGridExtendedProps>,
		|}>,
	>,
): React$ComponentType<T> => {
	const DragGridHOC = (props: T, forwardedRef: React$ElementRef<*>) => {
		const {
			id: widgetId,
			containerId: initialContainerId,
			originId,
			instanceId,
			rowId,
		} = props;

		const containerId = initialContainerId || 'none';

		const ref: React$ElementRef<*> = React.useRef(null);
		React.useImperativeHandle(forwardedRef, () => ref.current);

		const kind: ?TWidgetKind = props?.type || props?.data?.kind || null;
		const widgetType: TDNDWidgetType = (kind && DND_TYPES[kind]) || DND_TYPES.default;
		const direction: TDNDDirection = props.direction || DND_DIRECTION[widgetType];
		const isStack = kind === 'stack';

		const [state, dispatch] = React.useContext(dndContext);
		const { dragInitStyles, widgets } = state;
		const composeId = `${containerId}-${widgetId}-${rowId || 'without-row'}`;
		const dragWidget = state.dragId ? state.widgets[state.dragId] : null;
		const placeId = state.dragPlace?.composeId || null;
		const placeWidget = placeId ? state.widgets[placeId] : null;
		const isDragged = dragWidget?.widgetId === widgetId;
		const isAnotherRow =
			!!placeWidget &&
			(placeWidget?.containerId !== containerId || placeWidget?.rowId !== rowId);
		const isSource = state.dragId === composeId && isAnotherRow;
		const isNotAloneChild = React.useMemo(
			() =>
				isSource &&
				_.some(
					widget =>
						widget.containerId === containerId &&
						widget.rowId === rowId &&
						widget.widgetId !== widgetId,
					widgets,
				),
			[widgets, isSource, containerId, rowId, widgetId],
		);
		const isHidden: boolean = (isSource && isNotAloneChild) || false;
		const isDropOver = !!(isDragged && state.dropPlace?.placeId);
		const isFromPanel = containerId === 'panel';
		const isStackPlace = state.stackPlace?.composeId === composeId;
		const stackType = isStack ? 'add-to-stack' : 'create-stack';
		const stackTitle = isStackPlace ? stackType : false;
		const isInstance = !!props?.data?.modified;

		React.useEffect(() => {
			dispatch({
				type: 'regWidget',
				payload: {
					ref,
					kind,
					composeId,
					widgetId,
					instanceId,
					containerId,
					rowId,
					originId,
					widgetType,
					direction,
					isInstance,
				},
			});

			return () =>
				dispatch({
					type: 'unregWidget',
					payload: { composeId },
				});
		}, [
			ref,
			widgetType,
			composeId,
			rowId,
			widgetId,
			dispatch,
			containerId,
			originId,
			instanceId,
			direction,
			kind,
			isInstance,
		]);

		const container = React.useMemo(
			() => ({
				'data-draggable': isDragged,
				'data-drop-over': isDropOver,
				'data-drag-source': isSource,
				'data-drag-hidden': isHidden,
				'data-drag-panel': isFromPanel,
				'data-drag-stack': stackTitle,
				...(isSource
					? {
							style: {
								width: dragInitStyles?.width,
								height: dragInitStyles?.height,
								margin: dragInitStyles?.margin,
							},
					  }
					: null),
			}),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[isDragged, isDropOver, isSource, isFromPanel, stackTitle],
		);

		const handler = React.useMemo(
			() => ({ 'data-drag-id': composeId, draggable: true }),
			[composeId],
		);

		const onStart = React.useCallback(() => dispatch({ type: 'startFlip' }), [
			dispatch,
		]);
		const onComplete = React.useCallback(() => dispatch({ type: 'stopFlip' }), [
			dispatch,
		]);
		const shouldFlip = React.useMemo(
			() => shouldFlipCallback(containerId, widgetId),
			[containerId, widgetId],
		);

		const overridedProps = React.useMemo(() => {
			const { children, babies, colSizeMap, ...restProps } = props;

			return isSource
				? _.assign(
						{
							children: undefined,
							babies: emptyArray,
							colSizeMap: emptyArray,
						},
						restProps,
				  )
				: props;
		}, [props, isSource]);

		return (
			<Flipped
				shouldFlip={shouldFlip}
				key={overridedProps.id}
				flipId={composeId}
				onStart={onStart}
				onComplete={onComplete}
				spring={SPRING_CONFIG}
				scale={false}
				translate
				opacity
			>
				{(flippedProps: TDragPropsFlip) => (
					<FlippedWrapper
						ref={ref}
						Component={Component}
						dragHandler={handler}
						dragContainer={container}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...overridedProps}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...flippedProps}
					/>
				)}
			</Flipped>
		);
	};

	DragGridHOC.displayName = `Drag(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(DragGridHOC));
};

export default DragGrid;
