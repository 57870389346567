// @flow

import type { TId, Dispatch, TAction, TWidgetOwnProps } from '@graphite/types';

import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

import getMapDispatchToPropsEdit from 'Widget/libs/primitive/getMapDispatchToPropsEdit';

const mapDispatchToPropsEdit = (
	dispatch: Dispatch<TAction>,
	ownProps: TWidgetOwnProps,
) => {
	return {
		...getMapDispatchToPropsEdit(dispatch, ownProps),
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	};
};

export default mapDispatchToPropsEdit;
