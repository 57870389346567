// @flow

import emptyObject from 'empty/object';

export default {
	initialData: {
		_id: '',
		display: 'normal',
		kind: 'block',
		protoId: '',
		scope: 'site',
		scopeId: '',
		userId: '',
		sizes: emptyObject,
		modified: [],
	},
	data: {
		_id: '',
		display: 'normal',
		kind: 'block',
		protoId: '',
		scope: 'site',
		scopeId: '',
		userId: '',
		sizes: emptyObject,
		modified: [],
	},
	resizeInfo: emptyObject,
	currentDevice: 'desktop',
	instanceId: null,
	originId: '',
	dir: null,
	x: 0,
	y: 0,
	maxRange: null,
	isResize: false,
	propName: null,
	dispatch: () => ({ payload: '', type: '' }),
};
