// @flow
import React from 'react';
import chroma from 'chroma-js';
import { ThemeProvider } from 'emotion-theming';
import { themes, Flex, Box, Portal, Overlay } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import type { TColorReference, TSpecsColor } from '@graphite/types';

import SliderColorSv from './SliderColorSv';
import SliderColorHue from './SliderColorHue';
import SliderColorAlpha from './SliderColorAlpha';
import InputColor from './InputColor';

type TProps = $ReadOnly<{|
	value: TColorReference,
	colorspec: TSpecsColor,
	onChange?: ?(TColorReference) => void,
	onPreview?: ?(TColorReference) => void,
|}>;

const containerStyle = {
	height: '378px',
};

const absWrapperStyle = {
	position: 'absolute',
	left: '0',
	right: '0',
	bottom: '-1px', // чтобы перекрыть закругления углов белого фона под ним
};

const blackFlexStyle = {
	backgroundColor: 'spec.black',
	padding: '24px 24px 29px 24px',
	height: '150px',
	flexDirection: 'column',
	justifyContent: 'space-between',
	borderRadius: 'lg.bottom',
};

const sliderBoxStyle = {
	marginBottom: '12px',
};

const inputBoxStyle = {
	paddingTop: '10px',
};

function Custom({ value, colorspec, onChange, onPreview }: TProps) {
	const [hue, setHue] = React.useState<number>(0);
	const [sat, setSaturation] = React.useState<number>(0);
	const [val, setVal] = React.useState<number>(0);
	const [alpha, setAlpha] = React.useState<number>(0);
	const [isActive, setActive] = React.useState(false);

	const effectiveColor = React.useMemo(() => getColor(colorspec, value), [
		colorspec,
		value,
	]);

	React.useEffect(
		() => {
			// Если хексы равны, то не надо обновлять HSV
			if (
				chroma
					.hsv(hue, sat, val)
					.alpha(alpha)
					.hex() === effectiveColor
			) {
				return;
			}
			const chromaColor = chroma(effectiveColor);
			const [h, s, v] = chromaColor.hsv();
			const a = chromaColor.alpha();
			setHue(h || 0);
			setSaturation(s);
			setVal(v);
			setAlpha(a);
		},
		// Этот хук реагирует ТОЛЬКО на изменение цвета извне
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[effectiveColor],
	);

	const previewSvBound = React.useCallback(
		(s, v) => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(hue, s, v, 'hsv')
					.alpha(alpha)
					.hex(),
			};
			setSaturation(s);
			setVal(v);
			if (onPreview) {
				onPreview(newValue);
			}
		},
		[onPreview, hue, alpha],
	);

	const changeSvBound = React.useCallback(
		(s, v) => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(hue, s, v, 'hsv')
					.alpha(alpha)
					.hex(),
			};
			setSaturation(s);
			setVal(v);
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange, hue, alpha],
	);

	const previewHueBound = React.useCallback(
		h => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(h, sat, val, 'hsv')
					.alpha(alpha)
					.hex(),
			};
			setHue(h || 0);
			if (onPreview) {
				onPreview(newValue);
			}
		},
		[onPreview, sat, val, alpha],
	);

	const changeHueBound = React.useCallback(
		h => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(h, sat, val, 'hsv')
					.alpha(alpha)
					.hex(),
			};
			setHue(h || 0);
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange, sat, val, alpha],
	);

	const previewAlphaBound = React.useCallback(
		a => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(hue, sat, val, 'hsv')
					.alpha(a)
					.hex(),
			};
			setAlpha(a);
			if (onPreview) {
				onPreview(newValue);
			}
		},
		[onPreview, hue, sat, val],
	);

	const changeAlphaBound = React.useCallback(
		a => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(hue, sat, val, 'hsv')
					.alpha(a)
					.hex(),
			};
			setAlpha(a);
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange, hue, sat, val],
	);

	const changeAllBound = React.useCallback(
		(h, s, v, a) => {
			const newValue = {
				entryId: null,
				shadeId: null,
				snapshot: chroma(h, s, v, 'hsv')
					.alpha(a)
					.hex(),
			};
			setHue(h || 0);
			setSaturation(s);
			setVal(v);
			setAlpha(a);
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange],
	);

	const color = React.useMemo(() => chroma(hue, sat, val, 'hsv').hex(), [
		hue,
		sat,
		val,
	]);

	return (
		<>
			<Box sx={containerStyle}>
				<Box sx={absWrapperStyle}>
					<SliderColorSv
						hue={hue || 0}
						saturation={sat}
						value={val}
						onPreview={previewSvBound}
						onChange={changeSvBound}
						setActive={setActive}
					/>
					<ThemeProvider theme={themes.dark}>
						<Flex sx={blackFlexStyle}>
							<Box sx={sliderBoxStyle}>
								<SliderColorHue
									hue={hue || 0}
									onPreview={previewHueBound}
									onChange={changeHueBound}
									setActive={setActive}
								/>
							</Box>
							<Box sx={sliderBoxStyle}>
								<SliderColorAlpha
									alpha={alpha}
									color={color}
									onPreview={previewAlphaBound}
									onChange={changeAlphaBound}
									setActive={setActive}
								/>
							</Box>
							<Box sx={inputBoxStyle}>
								<InputColor
									hue={hue}
									saturation={sat}
									value={val}
									alpha={alpha}
									onChange={changeAllBound}
								/>
							</Box>
						</Flex>
					</ThemeProvider>
				</Box>
			</Box>
			<Portal>
				<Overlay isActive={isActive} />
			</Portal>
		</>
	);
}

Custom.defaultProps = {
	onChange: null,
	onPreview: null,
};

export default React.memo<TProps>(Custom);
