// @flow
import type { TEditor } from '@graphite/types';

import { defaultDevice } from './widgets';

export const editorEmptyState: TEditor = {
	language: 'en',
	currentDevice: defaultDevice,
	defaultDevice,
	currentGridHighlight: false,
	currentWidget: null,
	widgetsBuffer: null,
	currentInstance: null,
	currentDebug: null,
	currentSymbol: null,
};

export default editorEmptyState;
