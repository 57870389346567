// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex, Box } from '@graphite/uneon';
import type { TId } from '@graphite/types';
import Widget from 'Widget';

import About from './About';
import Header from './Header';
import Footer from './Footer';
import EmptyProject from './EmptyProject';

type TProps = $ReadOnly<{|
	id: TId,
	babies: $ReadOnlyArray<TId>,
	onInsert: () => void,
	logOut: () => void,
	saveInfo: (
		$ReadOnly<{
			profession: string,
			employment: string,
			employees: string,
		}>,
	) => void,
	isShowFormAboutUser: boolean,
|}>;

// push footer to bottom

const bodySx = {
	minHeight: '100vh',
	flexDirection: 'column',
	backgroundColor: 'bg.primary',
};

const bottomSx = {
	marginTop: 'auto',
};

// main layout

const contentSx = {
	marginTop: '30px',
	marginBottom: '60px',
};

const User = ({
	id,
	babies,
	onInsert,
	logOut,
	saveInfo,
	isShowFormAboutUser,
}: TProps) => {
	return (
		<Flex sx={bodySx}>
			{isShowFormAboutUser && <About saveInfo={saveInfo} />}
			<Header logOut={logOut} insertProject={onInsert} />
			<Box sx={contentSx} variant="container.fluid">
				{_.isEmpty(babies) ? (
					<EmptyProject insertProject={onInsert} />
				) : (
					babies.map(babyId => (
						<Widget
							key={babyId}
							containerId={id}
							instanceId={null}
							id={babyId}
						/>
					))
				)}
			</Box>

			<Box sx={bottomSx}>
				<Footer />
			</Box>
		</Flex>
	);
};

export default React.memo<TProps>(User);
