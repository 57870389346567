// @flow

export const fontFamilyNative = [
	'-apple-system',
	'BlinkMacSystemFont',
	'Segoe UI',
	'Roboto',
	'Oxygen',
	'Ubuntu',
	'Cantarell',
	'Fira Sans',
	'Droid Sans',
	'Helvetica Neue',
	'sans-serif',
].join(',');

export const listMatchWeight = [
	{ name: '100', value: '100', label: 'Thin' },
	{ name: '200', value: '200', label: 'Extra Light' },
	{ name: '300', value: '300', label: 'Light' },
	{ name: '400', value: 'regular', label: 'Regular' },
	{ name: '500', value: '500', label: 'Medium' },
	{ name: '600', value: '600', label: 'Semi Bold' },
	{ name: '700', value: '700', label: 'Bold' },
	{ name: '800', value: '800', label: 'Extra Bold' },
	{ name: '900', value: '900', label: 'Black' },
];

export const listForFontsLib = [
	{
		name: fontFamilyNative,
		family: 'System UI',
		variants: ['100', '200', '300', '500', '600', '700', '800', '900', 'regular'],
	},
	{
		name: 'Arial, Helvetica, sans-serif',
		family: 'Arial',
		variants: ['700', 'regular'],
	},
	{
		name: '"Arial Black", Gadget, sans-serif',
		family: 'Arial Black',
		variants: ['regular'],
	},
	{
		name: 'Impact, Charcoal, sans-serif',
		family: 'Impact',
		variants: ['regular'],
	},
	{
		name: 'Tahoma, Geneva, sans-serif',
		family: 'Tahoma',
		variants: ['700', 'regular'],
	},
	{
		name: 'Verdana, Arial, sans-serif',
		family: 'Verdana',
		variants: ['700', 'regular'],
	},
	{
		name: 'Georgia, serif',
		family: 'Georgia',
		variants: ['700', 'regular'],
	},
	{
		name: '"Times New Roman", Times, serif',
		family: 'Times New Roman',
		variants: ['700', 'regular'],
	},
	{
		name: '"Trebuchet MS", Helvetica, sans-serif',
		family: 'Trebuchet MS',
		variants: ['700', 'regular'],
	},
	{
		name: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
		family: 'Lucida Sans',
		variants: ['700', 'regular'],
	},

	{
		name: '"Courier New", Courier, monospace',
		family: 'Courier New',
		variants: ['700', 'regular'],
	},
];
export default {};
