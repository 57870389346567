// @flow
import _, { size, max, merge } from 'lodash/fp';
import {
	groupColumnsToRow,
	calcColumns,
	flatDelimiters,
	getDelimiters,
} from '@graphite/calc-columns';
import type {
	TSizesBreakpoint,
	TSize,
	TOrder,
	TEntityChildren,
	TId,
} from '@graphite/types';

import reSelect from 'libs/re-select';

import type { TColumn } from 'libs/types/calc-columns';
import type {
	TMethodFindRow,
	TMethodFindParentRow,
	TMethodRemoveColumnFromRow,
	TMethodFindDropRow,
	TMethodRefineDropPosition,
	TMethodDropToRow,
	TMethodReorder,
	TMethodConvertToSizes,
	TMethodConvertToColumn,
	TMethodResize,
	TSubMethodResize,
	TCanMethodResize,
	TCalculateMethodResize,
	TMethodCalculateDelimiter,
	TMethodGetClosest,
	TMethodGetDeltaRange,
	TMethodRefineColumnSize,
} from 'libs/types/calc-rows';
import { getTrueWidgetIds, closestDeviceWithKey, getOrder } from '@graphite/selectors';

const mapValues = _.mapValues.convert({ cap: false });

export const defaultSize = {
	width: 1,
	margin: { left: 0, right: 0 },
};

export const convertToColumn: TMethodConvertToColumn = ({
	width,
	margin: { left: marginLeft, right: marginRight },
}) => ({
	width,
	marginLeft,
	marginRight,
});

export const convertToSizes: TMethodConvertToSizes = ({
	width,
	marginLeft,
	marginRight,
}) => ({
	width,
	margin: {
		left: marginLeft,
		right: marginRight,
	},
});

export const refineColumnSize: TMethodRefineColumnSize = ({ srcSizes, prevRowSize }) => {
	if (prevRowSize <= 0 && srcSizes && srcSizes?.width) {
		return srcSizes;
	}

	const countSizes = { ...defaultSize, width: 1 / max([prevRowSize, 1]) };
	return countSizes;

	/**
	 * USEFUL CODE
	 *
	 * it needs for save source column size
	 * see also: /src/Widget/controls/dnd/lib/reorder.js
	 */

	// if (srcSizes.margin.left > 0 || srcSizes.margin.right > 0) {
	// 	return srcSizes;
	// }

	// // use 1/n column size if the defference is less then 50%
	// if (Math.abs(1 - srcSizes.width / countSizes.width) <= 0.5) {
	// 	return countSizes;
	// }

	// return srcSizes;
};

export const findRow: TMethodFindRow = ({ widget, currentDevice, callback }) => {
	const { sizes, _id } = widget;
	const key = `sizes-${_id}`;
	const orderList = getTrueWidgetIds({ ...widget, currentDevice });

	const deviceColumns = mapValues(
		column =>
			convertToColumn(
				column[currentDevice] ||
					closestDeviceWithKey<TSizesBreakpoint>(column, {
						currentDevice,
						key,
					}),
			),
		sizes,
	);
	const rows = groupColumnsToRow({ columns: deviceColumns, orderList });

	return (
		rows.find(columnsInRow => {
			const { orderList, nextId, beforeId, ...columns } = columnsInRow;
			return callback({ columns, orderList });
		}) || null
	);
};

export const findParentRow: TMethodFindParentRow = ({ widget, srcId, currentDevice }) =>
	findRow({
		widget,
		currentDevice,
		callback: ({ columns }) => !!columns[srcId],
	});

export const removeColumnFromRow: TMethodRemoveColumnFromRow = ({
	widget,
	srcId,
	colAmount,
	currentDevice,
}) => {
	const parentRow = findParentRow({ widget, srcId, currentDevice }) || {};
	const { orderList = [], nextId, beforeId, ...columns } = parentRow;

	const newRowOrder = orderList.filter(({ refId }) => srcId !== refId);
	const delimiters = flatDelimiters(getDelimiters(colAmount, newRowOrder.length || 1));

	const newRowColumns = { ...columns };
	delete newRowColumns[srcId];

	const scaledColumns = calcColumns({
		orderList: newRowOrder,
		columns: newRowColumns,
		delimiters,
	});

	const newChildren = { ...widget.children };
	delete newChildren[srcId];

	const deviceOrder = { ...widget.order?.[currentDevice] };
	let mutatedOrder: TOrder = {
		...widget.order,
		[`${currentDevice}`]: deviceOrder,
	};

	const { [srcId]: __, ...mutatedOrderWithoutSrcId } = mutatedOrder[currentDevice];
	mutatedOrder = {
		...mutatedOrder,
		[`${currentDevice}`]: mutatedOrderWithoutSrcId,
	};

	if (!size(mutatedOrder[currentDevice])) {
		const { [currentDevice]: __, ...newMutatedOrder } = mutatedOrder;
		mutatedOrder = (newMutatedOrder: TOrder);
	}

	const originalSizes = { ...widget.sizes };
	delete originalSizes[srcId];

	return {
		children: newChildren,
		sizes: {
			...mapValues(
				(devices: TSize, id) => ({
					[`${currentDevice}`]:
						devices[currentDevice] ||
						closestDeviceWithKey(devices, {
							currentDevice,
							key: `sizes-${id}`,
						}),
				}),
				originalSizes,
			),
			...mapValues(
				(columnSize: TColumn) => ({
					[`${currentDevice}`]: convertToSizes(columnSize),
				}),
				scaledColumns,
			),
		},
		order: mutatedOrder,
	};
};

export const findDropRow: TMethodFindDropRow = ({ widget, position, currentDevice }) => {
	const { prevId = null, nextId = null, row = null } =
		position.kind === 'grid' ? position : {};

	if (row === 'new') {
		return null;
	}

	const callback = ({ columns, orderList }) => {
		const prevColumn = prevId && columns[prevId];
		const nextColumn = nextId && columns[nextId];

		if (!prevColumn && !nextColumn) {
			return false;
		}

		const firstId = orderList[0].refId;
		const lastId = orderList[orderList.length - 1].refId;

		const isInPrevRow = firstId === nextId && row === 'before';
		const isInNextRow = lastId === prevId && row === 'after';

		const isContainsPrevId = prevId && prevColumn && !isInNextRow;
		const isContainsNextId = nextId && nextColumn && !isInPrevRow;
		const isDesiredRow = isContainsPrevId || isContainsNextId;

		return !!isDesiredRow;
	};

	return findRow({ widget, currentDevice, callback });
};

export const refineDropPosition: TMethodRefineDropPosition = ({
	widget,
	position,
	currentDevice,
}) => {
	if (position.kind !== 'grid' || position.row !== 'new') {
		return position;
	}

	const { prevId, nextId } = position;
	let lastIdPrevRow = prevId;
	let firstIdNextRow = nextId;

	if (prevId) {
		// find the row where the prevId is
		const prevCallback = ({ columns }) => !!columns[prevId];
		const prevRow = findRow({
			widget,
			currentDevice,
			callback: prevCallback,
		});
		const prevRowOrderList = prevRow?.orderList || [];
		lastIdPrevRow = prevRowOrderList[prevRowOrderList.length - 1]?.refId || null;
	}

	if (nextId) {
		// find the row where the nextId is
		const nextCallback = ({ columns }) => !!columns[nextId];
		const nextRow = findRow({
			widget,
			currentDevice,
			callback: nextCallback,
		});
		firstIdNextRow = nextRow?.orderList[0]?.refId || null;
	}

	return {
		...position,
		prevId: lastIdPrevRow,
		nextId: firstIdNextRow,
	};
};

export const getInlineChildren: TId => TEntityChildren = reSelect<
	TId,
	TId,
	TEntityChildren,
>(
	id => id,
	id => ({ [id]: id }),
)(id => `calc-rows@getInlineChildren-${id}`);

export const dropToRow: TMethodDropToRow = ({
	widget,
	position,
	colAmount,
	srcId,
	currentDevice,
	srcSizes,
}) => {
	const mutatedWidget = merge(widget, { children: getInlineChildren(srcId) });
	const newOrderList = getOrder({
		widget: mutatedWidget,
		position,
		newId: srcId,
		currentDevice,
	});

	const dropRow = findDropRow({ widget, position, currentDevice }) || {};
	const { orderList, nextId, beforeId, ...columns } = dropRow;
	const prevRowSize = size(columns);
	const columnSizes = refineColumnSize({ srcSizes, prevRowSize });
	const srcColumn = convertToColumn(columnSizes);

	const hasPrevId = !!(position.kind === 'grid' && position?.prevId);
	const hasNextId = !!(position.kind === 'grid' && position?.nextId);
	const isDropToNewPlace = !hasPrevId && !hasNextId;

	if (isDropToNewPlace) {
		const scaledColumns = calcColumns({
			orderList: [{ refId: srcId, trueId: srcId }],
			columns: { [srcId]: srcColumn },
			delimiters: flatDelimiters(getDelimiters(colAmount)),
		});

		return {
			children: mutatedWidget.children,
			sizes: {
				...mapValues(
					(devices: TSize) => ({
						[`${currentDevice}`]: devices[currentDevice],
					}),
					mutatedWidget.sizes,
				),
				...mapValues(
					(columnSize: TColumn) => ({
						[`${currentDevice}`]: convertToSizes(columnSize),
					}),
					scaledColumns,
				),
			},
			order: { ...newOrderList.order },
		};
	}

	const withoutMarginsColumn = convertToColumn(
		refineColumnSize({ srcSizes: columnSizes, prevRowSize }),
	);
	const mutatedColumns = {
		...columns,
		[srcId]: prevRowSize ? withoutMarginsColumn : srcColumn,
	};

	const mutatedOrderList = getTrueWidgetIds({
		...merge(mutatedWidget, newOrderList),
		currentDevice,
	}).filter(({ refId }) => !!mutatedColumns[refId]);

	const delimiters = flatDelimiters(getDelimiters(colAmount, mutatedOrderList.length));
	const scaledColumns = calcColumns({
		orderList: mutatedOrderList,
		columns: mutatedColumns,
		delimiters,
	});

	return {
		children: mutatedWidget.children,
		sizes: {
			...mapValues(
				(devices: TSize) => ({
					[`${currentDevice}`]: devices[currentDevice],
				}),
				widget.sizes,
			),
			...mapValues(
				(columnSize: TColumn) => ({
					[`${currentDevice}`]: convertToSizes(columnSize),
				}),
				scaledColumns,
			),
		},
		order: { ...newOrderList.order },
	};
};

export const reorder: TMethodReorder = ({
	widget,
	position,
	srcId,
	colAmount,
	currentDevice,
}) => {
	const dropRow = findDropRow({ widget, position, currentDevice }) || {};
	const isSameRow = !!dropRow[srcId];

	if (isSameRow) {
		const newOrderList = getOrder({
			widget,
			position,
			newId: srcId,
			currentDevice,
		});
		const { orderList, nextId, beforeId, ...columns } = dropRow;

		const mutatedOrderList = getTrueWidgetIds({
			...merge(widget, newOrderList),
			currentDevice,
		}).filter(({ trueId }) => !!columns[trueId]);
		const delimiters = flatDelimiters(
			getDelimiters(colAmount, mutatedOrderList.length),
		);

		const scaledColumns = calcColumns({
			orderList: mutatedOrderList,
			columns,
			delimiters,
		});

		return {
			sizes: {
				...mapValues(
					(devices: TSize) => ({
						[`${currentDevice}`]: devices[currentDevice],
					}),
					widget.sizes,
				),
				...mapValues(
					(columnSize: TColumn) => ({
						[`${currentDevice}`]: convertToSizes(columnSize),
					}),
					scaledColumns,
				),
			},
			order: { ...newOrderList.order },
		};
	}

	const srcSizes: ?TSizesBreakpoint = widget.sizes?.[srcId]
		? closestDeviceWithKey(widget.sizes?.[srcId], {
				currentDevice,
				key: `sizes-${srcId}`,
		  })
		: defaultSize;

	const removeUpdated = removeColumnFromRow({
		widget,
		srcId,
		colAmount,
		currentDevice,
	});

	return dropToRow({
		widget: _.assign(widget, removeUpdated),
		position,
		colAmount,
		srcId,
		srcSizes,
		currentDevice,
	});
};

const reverseSide = {
	left: 'right',
	right: 'left',
};

const getReverseMarginName = (side: string) => `margin${_.capitalize(reverseSide[side])}`;
const getMarginName = (side: string) => `margin${_.capitalize(side)}`;

export const getClosest: TMethodGetClosest = (counts, goal) =>
	_.reduce(
		(prev, curr) => (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev),
		-1,
		counts,
	);

const calculateDelimiter: TMethodCalculateDelimiter = data => ({
	...data,
	// считаем сдвиг
	delimiter: (() => {
		const { delimiters, columns, srcId, idNeighbour, side, start, end, value } = data;
		const fullDelimitrs = [0, ...delimiters, 1];
		let delimiter = -1;

		const marginSrc = columns[srcId][getMarginName(side)];
		const widthSrc = columns[srcId].width;
		const marginNeighbour = columns[idNeighbour]?.[getReverseMarginName(side)] ?? 0;
		const widthNeighbour = columns[idNeighbour]?.width ?? 0;

		if (side === 'right') {
			delimiter = getClosest(delimiters, end - marginSrc + value);
			if (value > 0) {
				const maxDistance = +(end + marginNeighbour + widthNeighbour).toFixed(5);
				if (delimiter >= maxDistance) {
					delimiter = fullDelimitrs[fullDelimitrs.indexOf(maxDistance) - 1];
				}
				delimiter = delimiter - end + marginSrc;
			} else {
				const maxDistance = +(end - marginSrc - widthSrc).toFixed(5);
				if (delimiter <= maxDistance) {
					delimiter = fullDelimitrs[fullDelimitrs.indexOf(maxDistance) + 1];
				}
				delimiter = end - marginSrc - delimiter;
			}
		}

		if (side === 'left') {
			delimiter = getClosest(delimiters, start + marginSrc + value);

			if (value < 0) {
				const maxDistance = +(start - marginNeighbour - widthNeighbour).toFixed(
					5,
				);
				if (delimiter <= maxDistance) {
					delimiter = fullDelimitrs[fullDelimitrs.indexOf(maxDistance) + 1];
				}
				delimiter = start - delimiter + marginSrc;
			} else {
				const maxDistance = +(start + marginSrc + widthSrc).toFixed(5);
				if (delimiter >= maxDistance) {
					delimiter = fullDelimitrs[fullDelimitrs.indexOf(maxDistance) - 1];
				}
				delimiter = delimiter - start - marginSrc;
			}
		}

		return delimiter;
	})(),
});

const canResizeSrcWidget: TCanMethodResize = ({ side, columns, srcId, value }) =>
	(side === 'right' && value > 0 && columns[srcId][getMarginName(side)] > 0) ||
	(side === 'left' && value < 0 && columns[srcId][getMarginName(side)] > 0);

const resizeSrcWidget: TSubMethodResize = _.cond([
	[
		// если отступ текущего виджета меньше сдвига
		({ columns, srcId, side, value, delimiters, start, end }) => {
			const marginDelta = columns[srcId][getMarginName(side)] - Math.abs(value);
			const isStart = side === 'right' && end === 1;
			const isEnd = side === 'left' && start === 0;
			const isExtreme = isStart || isEnd;

			if (isExtreme) {
				return marginDelta < delimiters[0] / 2;
			}

			return marginDelta < 0;
		},
		({ widget, srcId, currentDevice, side, value, colAmount, isBack, columns }) =>
			// eslint-disable-next-line no-use-before-define
			resize({
				widget: _.flow(
					_.set(`sizes.${srcId}.${currentDevice}.margin.${side}`, 0),
					_.update(
						`sizes.${srcId}.${currentDevice}.width`,
						width => width + columns[srcId][`margin${_.capitalize(side)}`],
					),
				)(widget),
				srcId,
				side,
				value:
					(Math.abs(value) - columns[srcId][getMarginName(side)]) *
					(value > 0 ? 1 : -1),
				colAmount,
				currentDevice,
				isBack,
			}),
	],
	[
		// если отступ текущего виджета больше сдвига,
		// то просто уменьшаем отступ и отдаём виджет
		_.stubTrue,
		_.flow(
			calculateDelimiter,
			_.cond([
				[_.matches({ delimiter: -1 }), _.property('widget')],
				[
					_.stubTrue,
					({ widget, srcId, currentDevice, side, delimiter }) =>
						_.flow(
							_.update(
								`sizes.${srcId}.${currentDevice}.margin.${side}`,
								margin => margin - delimiter,
							),
							_.update(
								`sizes.${srcId}.${currentDevice}.width`,
								width => width + delimiter,
							),
						)(widget),
				],
			]),
		),
	],
]);

const canResizeMarginNeighbourWidgetWithContinue: TCanMethodResize = ({
	columns,
	idNeighbour,
	value,
	side,
}) =>
	!!idNeighbour &&
	((value > 0 && side === 'right') || (value < 0 && side === 'left')) &&
	// если меняем отступ
	columns[idNeighbour][getReverseMarginName(side)] > 0 &&
	// и если значение маргина меньше ресайза,
	// то нужно ещё ресайзить ширину и
	// поэтому убираем маржин и вызываем функцию ещё раз
	Math.abs(value) > columns[idNeighbour][getReverseMarginName(side)];

const resizeMarginNeighbourWidgetWithContinue: TSubMethodResize = ({
	widget,
	columns,
	srcId,
	currentDevice,
	side,
	idNeighbour,
	value,
	colAmount,
	isBack,
}) =>
	// eslint-disable-next-line no-use-before-define
	resize({
		widget: _.flow(
			_.update(
				`sizes.${srcId}.${currentDevice}.width`,
				_.add(columns[idNeighbour][getReverseMarginName(side)]),
			),
			_.set(`sizes.${idNeighbour}.${currentDevice}.margin.${reverseSide[side]}`, 0),
		)(widget),
		srcId,
		side,
		value:
			(Math.abs(value) - columns[idNeighbour][getReverseMarginName(side)]) *
			(value > 0 ? 1 : -1),
		colAmount,
		currentDevice,
		isBack,
	});

const canResizeMarginNeighbourWidget: TCanMethodResize = ({
	columns,
	idNeighbour,
	side,
	value,
}) =>
	!!idNeighbour &&
	((value > 0 && side === 'right') || (value < 0 && side === 'left')) &&
	// если меняем отступ
	columns[idNeighbour][`margin${_.capitalize(reverseSide[side])}`] > 0 &&
	// и если значение маргина больше ресайза,
	value <= columns[idNeighbour][getReverseMarginName(side)];

const resizeMarginNeighbourWidget: TSubMethodResize = _.flow(
	calculateDelimiter,
	({ delimiter, widget, srcId, idNeighbour, currentDevice, side }) =>
		_.flow(
			_.update(`sizes.${srcId}.${currentDevice}.width`, _.add(delimiter)),
			_.update(
				`sizes.${idNeighbour}.${currentDevice}.margin.${reverseSide[side]}`,
				width => width - delimiter,
			),
		)(widget),
);

const canResizeNeighbourWidget: TCanMethodResize = ({
	columns,
	idNeighbour,
	delimiters,
	value,
	side,
}) =>
	!!idNeighbour &&
	((value > 0 && side === 'right') || (value < 0 && side === 'left')) &&
	columns[idNeighbour].width > delimiters[0];

const resizeNeighbourWidget: TSubMethodResize = _.flow(
	calculateDelimiter,
	_.cond([
		[_.matches({ delimiter: -1 }), _.property('widget')],
		[
			_.stubTrue,
			({ widget, srcId, currentDevice, idNeighbour, delimiter }) =>
				_.flow(
					// меняем ширину текущего виджета
					_.update(`sizes.${srcId}.${currentDevice}.width`, _.add(delimiter)),
					// меняем ширину соседа
					_.update(
						`sizes.${idNeighbour}.${currentDevice}.width`,
						width => width - delimiter,
					),
				)(widget),
		],
	]),
);

const canAddMarginSrcWidget: TCanMethodResize = ({ side, value }) =>
	(side === 'right' && value < 0) || (side === 'left' && value > 0);

const addMarginSrcWidget: TSubMethodResize = _.flow(
	calculateDelimiter,
	_.cond([
		[_.matches({ delimiter: -1 }), _.property('widget')],
		[
			_.stubTrue,
			({ widget, srcId, currentDevice, side, delimiter }) =>
				_.flow(
					_.update(
						`sizes.${srcId}.${currentDevice}.width`,
						width => width - delimiter,
					),
					_.update(
						`sizes.${srcId}.${currentDevice}.margin.${side}`,
						margin => margin + delimiter,
					),
				)(widget),
		],
	]),
);

const ifDontNeedToResize: TCanMethodResize = ({ value, delimiters }) =>
	Math.abs(value) < delimiters[0] / 2;

const calculateDependenciesForResize: TCalculateMethodResize = data => {
	const { widget, srcId, currentDevice, colAmount, side } = data;
	const { orderList = [], nextId, beforeId, ...columns } =
		findParentRow({ widget, srcId, currentDevice }) || {};
	// доступные брейкпоинты
	const delimiters = flatDelimiters(getDelimiters(colAmount, orderList.length)).slice(
		1,
		-1,
	);

	// порядковый номер текущего элемента
	const index = _.findIndex(({ refId }) => refId === srcId, orderList);
	// порядковый номер соседа
	const indexNeighbour = index + (side === 'left' ? -1 : 1);
	// ИД соседа
	const idNeighbour = orderList[indexNeighbour]?.refId;

	// start
	const { value: end }: { value: number } = _.transform(
		(res, key) => {
			const column = columns[key];
			res.value +=
				column[getMarginName('left')] +
				column.width +
				column[getMarginName('right')];
			return key !== srcId;
		},
		{ value: 0 },
		orderList.map(({ refId }) => refId),
	);

	// end
	const columnSrc = columns[srcId];
	const start =
		end -
		columnSrc[getMarginName('left')] -
		columnSrc.width -
		columnSrc[getMarginName('right')];

	return _.assign(data, {
		orderList,
		columns,
		delimiters,
		index,
		indexNeighbour,
		idNeighbour,
		start,
		end,
	});
};

/**
 * @param  {} {widget
 * @param  {} srcId
 * @param  {} side
 * @param  {} value
 * @param  {} colAmount
 * @param  {} currentDevice}
 */
export const resize: TMethodResize = _.flow(
	calculateDependenciesForResize,
	_.cond([
		// если слабо потянули, то ничего не делаем
		[ifDontNeedToResize, ({ widget }) => widget],
		/**
			двигаемся наружу,
			т.е. правую границу виджета вправо или левую влево
		 */
		// сначала пытаемся уменьшить отступ текущего виджета
		[canResizeSrcWidget, resizeSrcWidget],
		// затем уменьшаем маржин соседнего виджета
		[
			canResizeMarginNeighbourWidgetWithContinue,
			resizeMarginNeighbourWidgetWithContinue,
		],
		[canResizeMarginNeighbourWidget, resizeMarginNeighbourWidget],
		// затем уменьшаем ширину соседа
		[canResizeNeighbourWidget, resizeNeighbourWidget],
		/**
			двигаемся внутрь,
			т.е. правую границу виджета влево или левую вправо
		 */
		// увеличиваем отступ текущего виджета
		[canAddMarginSrcWidget, addMarginSrcWidget],
		/**
			двигаемся в никуда
		 */
		// и если ничего не совпало, то ничего не делаем
		[_.stubTrue, ({ widget }) => widget],
	]),
);

export const getDeltaRange: TMethodGetDeltaRange = _.flow(
	calculateDependenciesForResize,
	data => {
		const { side, srcId, delimiters, columns, idNeighbour, start, end } = data;
		const fullDelimiters = [0, ...delimiters, 1];

		const columnSrc = columns[srcId];
		const marginSrc = columnSrc[getMarginName(side)];
		const reverseMarginSrc = columnSrc[getReverseMarginName(side)];
		const marginNeighbour = columns[idNeighbour]?.[getReverseMarginName(side)] ?? 0;
		const widthNeighbour = columns[idNeighbour]?.width ?? 0;

		// left
		const left =
			side === 'right'
				? end -
				  fullDelimiters[
						fullDelimiters.indexOf(
							getClosest(fullDelimiters, end - columns[srcId].width),
						) + 1
				  ]
				: fullDelimiters[
						fullDelimiters.indexOf(
							getClosest(fullDelimiters, end - reverseMarginSrc),
						) - 1
				  ] -
				  start -
				  marginSrc;

		// right
		const right = !idNeighbour
			? marginSrc
			: (() => {
					if (side === 'right') {
						return (
							fullDelimiters[
								fullDelimiters.indexOf(
									getClosest(
										fullDelimiters,
										end + marginNeighbour + widthNeighbour,
									),
								) - 1
							] -
							end +
							columnSrc[getMarginName(side)]
						);
					}

					return (
						start +
						marginSrc -
						fullDelimiters[
							fullDelimiters.indexOf(
								getClosest(
									fullDelimiters,
									start - marginNeighbour - widthNeighbour,
								),
							) + 1
						]
					);
			  })();

		return {
			left,
			right,
			side,
		};
	},
	_.cond([
		[
			_.matches({ side: 'right' }),
			({ left, right }) => [-Math.max(left, 0), Math.max(right, 0)],
		],
		[
			_.matches({ side: 'left' }),
			({ left, right }) => [-Math.max(right, 0), Math.max(left, 0)],
		],
		[_.stubTrue, _.constant([0, 0])],
	]),
);

export default {};
