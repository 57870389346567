// @flow

import React, { memo, useMemo } from 'react';
import { Flex, Box, Text } from '@graphite/uneon';
import getBorder from '@graphite/get-border';
import type { TEffectBorder } from '@graphite/types';

type TProps = $ReadOnly<{|
	border: TEffectBorder,
	isActive?: boolean,
|}>;

const flexSx = {
	alignItems: 'center',
	padding: '3px 0',
};

const boxSx = {
	borderColor: 'bg.accent',
	width: '18px',
	height: '18px',
	marginTop: '-2px',
	marginRight: '12px',
};

function LabelBorder({ border, isActive = false }: TProps) {
	const boxStyle = useMemo(
		() => ({
			borderRadius: border.sides ? 0 : '4px',
			borderStyle: border.kind,
			borderWidth: getBorder(border, 1),
		}),
		[border],
	);
	return (
		<Flex sx={flexSx}>
			<Box sx={boxSx} style={boxStyle} />
			<Text variant="bodysm" color={isActive ? 'text.accent' : 'text.primary'}>
				{border.name}
			</Text>
		</Flex>
	);
}

export default memo<TProps>(LabelBorder);
