// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import type {
	TSx,
	TId,
	TSpecsGrid,
	TWidget,
	TWidgetBox,
	TWidgetBoxBreakpoint,
} from '@graphite/types';

import logger from 'libs/logger';
import reSelect from 'libs/re-select';
import {
	closestDeviceWithKey,
	getActiveBreakpointNames,
	maxCombineSx,
} from '@graphite/selectors';

export type TGetButtonAlignBreakpointSxParams = $ReadOnly<{|
	breakpoint: TWidgetBoxBreakpoint,
|}>;

export type TGetButtonAlignBreakpointSxFn = TGetButtonAlignBreakpointSxParams => TSx;

export type TGetButtonAlignSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
export type TGetButtonAlignSxFn = TGetButtonAlignSxParams => TSx;

// Для стилизации текста по спеке
export const getButtonAlignBreakpointSx: TGetButtonAlignBreakpointSxFn = reSelect<
	TGetButtonAlignBreakpointSxParams,
	TWidgetBoxBreakpoint,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	breakpoint => {
		try {
			const { align } = breakpoint;
			const customSx = {
				width: 'auto',
			};

			if (align === 'justify') {
				customSx.width = '100%';
			}

			return customSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint }) => ['widget@getContainerBreakpointSx', _.size(breakpoint)].join('-'));

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getButtonAlignSx: TGetButtonAlignSxFn = reSelect<
	TGetButtonAlignSxParams,
	?TWidgetBox,
	TId,
	TSpecsGrid,
	TSx,
>(
	({ data }) => data.box,
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(box, _id, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(box, {
						currentDevice,
						key: `box-${_id}`,
					});
					const breakpointSx = getButtonAlignBreakpointSx({
						breakpoint,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec }) => ['widget@getButtonAlignSx', data._id, gridspec._id].join('-'));
