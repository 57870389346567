// @flow

import React, { memo, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import emptyFunction from 'empty/function';
import size from 'lodash/fp/size';
import delay from 'lodash/fp/delay';
import { Box, Text } from '@graphite/uneon';
import { type TTreeDataElement, findNodeById } from './selectors';
import LayerItem from './LayerItem';

type TProps = $ReadOnly<{|
	source: $ReadOnlyArray<TTreeDataElement>,
	onExpand: (string, boolean) => void,
	onSelect: TTreeDataElement => void,
	onDropEnd: (TTreeDataElement, string, string) => void,
	isDraggble: boolean,
|}>;

const rowTextSx = {
	fontSize: '9px',
	textTransform: 'uppercase',
	letterSpacing: '1.5px',
	lineHeight: '33px',
};

const getChildrenBoxSx = expanded => ({
	marginLeft: '18px',
	display: expanded ? 'block' : 'none',
});

const TIME_WAIT_FOR_OPEN_NODE = 1000;
const isWidgetLayer = (kind): boolean => !['col', 'block'].includes(kind);

const isAbsRightPosition = (data, containerId, id, position) => {
	const node = findNodeById({
		data,
		id: containerId || '',
	});
	const dropIndex = node?.children
		?.filter(i => i.kind === 'col')
		?.findIndex(i => i._id === id);
	return (
		(position === 'top' && dropIndex === 0) ||
		(position === 'bottom' &&
			(node
				? node.children?.filter(i => i.kind === 'col').length - 1 === dropIndex
				: 0))
	);
};

function LayerList({
	source,
	onExpand = emptyFunction,
	onSelect = emptyFunction,
	onDropEnd = emptyFunction,
	isDraggble = false,
}: TProps) {
	const [dropNode, setDropNode] = React.useState({});
	const [dragNode, setDragNode] = React.useState<?string>(null);
	const { t } = useTranslation();

	// for timer onened node when drag over
	const selectTimer = useRef(null);

	// all ruls for drag element
	const canDrop = useCallback(
		(
			{ kind: dropNodeKind, key: dropKey, containerId, _id: dropNodeId },
			position,
			countChilds,
		): boolean => {
			const topBottom = ['bottom', 'top'].includes(position);
			const [dragNodeId, dragNodeKind, containerIdDrag, isdragNodeAbs] =
				dragNode?.split('_') || [];
			// if we try to move node into itself
			if (dragNodeId && dropKey.includes(dragNodeId)) {
				return false;
			}

			// if dragging block only sorting with other blocks
			if (
				dragNodeKind === 'block' &&
				(dropNodeKind !== 'block' || position === 'center')
			) {
				return false;
			}

			// if dragging col only for block
			if (
				dragNodeKind === 'col' &&
				((dropNodeKind === 'col' && position === 'center') ||
					(dropNodeKind === 'block' && topBottom) ||
					isWidgetLayer(dropNodeKind))
			) {
				return false;
			}

			// if widget (not abs) -  can move into stack and col or empty block
			if (
				isWidgetLayer(dragNodeKind) &&
				!isdragNodeAbs &&
				((!['col', 'stack'].includes(dropNodeKind) &&
					position === 'center' &&
					countChilds) ||
					(topBottom && ['block', 'col'].includes(dropNodeKind)) ||
					(position === 'center' &&
						!['block', 'col', 'stack'].includes(dropNodeKind)))
			) {
				return false;
			}

			// for abs widget - only can't choise positions next to blocks and
			// pos. among colls
			if (
				isWidgetLayer(dragNodeKind) &&
				isdragNodeAbs &&
				((position === 'center' &&
					!['block', 'col', 'stack'].includes(dropNodeKind)) ||
					(topBottom && dropNodeKind === 'block') ||
					(position !== 'center' &&
						(containerIdDrag !== containerId ||
							(dropNodeKind === 'col' &&
								!isAbsRightPosition(
									source,
									containerId,
									dropNodeId,
									position,
								)))))
			) {
				return false;
			}

			return true;
		},
		[dragNode, source],
	);

	const onStart = useCallback(
		layer => {
			if (isWidgetLayer(layer.kind) && !layer.selected) {
				onSelect(layer);
			}
			setDragNode(
				`${layer._id}_${layer.kind}_${layer.containerId || ''}_${layer.position ||
					''}`,
			);
		},
		[setDragNode, onSelect],
	);

	// when drag over node
	const onDrag = useCallback(
		({ _id, key, kind, children, containerId }, el: HTMLLIElement, y: number) => {
			if (selectTimer.current) clearTimeout(selectTimer.current);
			let position = 'center';

			// if around of node
			if (el?.draggable) {
				position =
					y <= (el.children?.[0].getBoundingClientRect().top || 0)
						? 'top'
						: 'bottom';
			}
			if (canDrop({ kind, key, containerId, _id }, position, children?.length)) {
				setDropNode({ [_id]: position });
			}
			if (position === 'center') {
				selectTimer.current = delay(TIME_WAIT_FOR_OPEN_NODE, () =>
					onExpand(key, true),
				);
			}
		},
		[setDropNode, onExpand, canDrop],
	);

	const onStop = useCallback(
		layer => {
			const dropKey: ?string = Object.keys(dropNode)?.[0] || null;
			if (dropKey) {
				onDropEnd(layer, dropKey, dropNode[dropKey]);
			}
			setDropNode({});
			setDragNode(null);
		},
		[dropNode, onDropEnd, setDropNode],
	);

	// recursive walk over list of element
	const treeLayer = (data, rowGroup = null, level = 0) => {
		// for understand how deep we are
		level++;
		return data.map(layer => {
			const { key, expanded, children } = layer;
			const isDragNode = !!dragNode?.split('_')?.[0].includes(layer._id);
			// if colSizeMap exsist then devid block for row and sorting col
			let rowPair = null;
			if (layer.colSizeMap?.length && layer.kind === 'block') {
				rowPair = layer.colSizeMap.map(item =>
					item.orderList.map(order => order.trueId),
				);
				rowPair = size(rowPair) > 1 ? rowPair : null; // if several rows
			}

			return (
				<Box key={key}>
					{/* each new row group show label */}
					{rowGroup && rowGroup.find(group => group.indexOf(layer._id) === 0) && (
						<Box>
							<Text variant="bodysm" color="spec.blue10" sx={rowTextSx}>
								{t('Row')}
							</Text>
						</Box>
					)}
					<LayerItem
						layer={layer}
						onExpand={onExpand}
						isDragNode={isDragNode}
						isDraggble={isDraggble}
						onStop={onStop}
						onStart={onStart}
						onDrag={onDrag}
						onSelect={onSelect}
						level={level}
						dropNodePosition={dropNode[layer._id]}
					/>
					<Box sx={getChildrenBoxSx(expanded)}>
						{children && treeLayer(children, rowPair, level)}
					</Box>
				</Box>
			);
		});
	};

	return treeLayer(source);
}

export default memo<TProps>(LayerList);
