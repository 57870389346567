// @flow
import emptyObject from 'empty/object';
import reduce from 'lodash/fp/reduce';
import keys from 'lodash/fp/keys';
import sortBy from 'lodash/fp/sortBy';
import mapValues from 'lodash/fp/mapValues';
import type {
	TWidgetMethodReorderWidgets,
	TWidgetMethodAddWidget,
	TWidgetMethodRemoveWidget,
	TOrderDevice,
} from '@graphite/types';

import compose from 'libs/compose';
import { getOrder } from '@graphite/selectors';
import repopulate from 'libs/repopulate';

export const addWidgetHook: TWidgetMethodAddWidget = (
	widgets,
	srcContainerId,
	destContainerId,
	position,
	srcId,
	currentDevice,
) => {
	const composed = compose(widgets, widgets[destContainerId]);

	const edited = repopulate(composed, {}, currentDevice);
	const go = getOrder({
		widget: { ...edited, children: composed.children },
		position,
		newId: srcId,
		currentDevice,
	});

	return {
		...edited,
		...go,
	};
};

export const removeWidgetHook: TWidgetMethodRemoveWidget = (
	composed,
	position,
	srcId,
) => {
	const { [srcId]: removedChildId, ...newChildren } = composed.children || emptyObject;
	const go = repopulate({ ...composed, children: newChildren }, {});

	// Ордеры нормализуются до "0,1,2,3..."
	const reordered = mapValues(
		(deviceOrder: TOrderDevice): TOrderDevice =>
			reduce.convert({ cap: false })(
				(accum, id, key) => ({
					...accum,
					[id]: key,
				}),
				emptyObject,
				sortBy(c => deviceOrder[c])(keys(newChildren)),
			),
		go.order,
	);

	return {
		...go,
		order: reordered,
	};
};

export const reorderWidgetsHook: TWidgetMethodReorderWidgets = (
	widget,
	position,
	newId,
	currentDevice,
) =>
	getOrder({
		widget,
		position,
		newId,
		currentDevice,
	});
