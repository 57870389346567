// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Flex, Icon } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import type { TSpecsColor } from '@graphite/types';

const Placeholder = styled(Flex)`
	padding: 18px 0;
	justify-content: center;
`;

type TMinimlProps = $ReadOnly<{
	data: $ReadOnly<{
		code: string,
	}>,
	colorspec: TSpecsColor,
}>;

const componentColors = {
	text: 'spec.violet50',
	texthover: 'spec.violet50',
	textfocus: 'spec.violet50',
	textactive: 'spec.violet50',
	bg: 'spec.violet50',
	bghover: 'spec.violet50',
	bgfocus: 'spec.violet50',
	bgactive: 'spec.violet50',
	border: 'spec.violet50',
	borderhover: 'spec.violet50',
	borderfocus: 'spec.violet50',
	borderactive: 'spec.violet50',
};

const withCodeEditor = <T: TMinimlProps>(
	Component: React$AbstractComponent<T, mixed>,
) => {
	const WidgetWrapper = (props: T) => {
		const {
			data: { code },
			colorspec,
		} = props;

		const [isShowPlaceholder, setShowPlaceholder] = React.useState(false);

		const ref = React.useRef<mixed>(null);

		React.useEffect(() => {
			setShowPlaceholder(
				!(
					ref.current &&
					typeof ref.current === 'object' &&
					ref.current.offsetHeight !== 0
				),
			);
		}, [code]);

		const colors = React.useMemo(() => {
			return {
				...componentColors,
				text: getColor(colorspec, 'text.primary'),
			};
		}, [colorspec]);

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} ref={ref} />
				{isShowPlaceholder && (
					<Placeholder backgroundColor={getColor(colorspec, 'bg.primaryalt')}>
						<Icon name="code" size="xsm" iconSize={24} colors={colors} />
					</Placeholder>
				)}
			</>
		);
	};

	return React.memo<T>(React.forwardRef(WidgetWrapper));
};

export default withCodeEditor;
