// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Flex } from '@graphite/uneon';

import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as BracketLeft } from './bracket-left.svg';
import { ReactComponent as BracketRight } from './bracket-right.svg';

type TProps = $ReadOnly<{||}>;

const Wrap = styled(Flex)`
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.spec.darkblue90};

	svg {
		display: inline-block;
		opacity: 1;
		margin: 10px;

		animation-name: pulse;
		animation-duration: 0.6s;
		animation-timing-function: ease-in-out;
		animation-direction: alternate;
		animation-iteration-count: infinite;

		@keyframes pulse {
			to {
				transform: scale(0.9);
				opacity: 0.7;
			}
		}
	}
`;

const LogoStyled = styled(Logo)`
	width: 54px;
	animation-delay: 0.4s;

	@media (min-width: 767px) {
		width: 374px;
	}
`;

const Loader = () => {
	return (
		<Wrap>
			<BracketLeft />
			<LogoStyled />
			<BracketRight />
		</Wrap>
	);
};

export default React.memo<TProps>(Loader);
