// @flow

import React from 'react';
import _ from 'lodash/fp';
import { Flex, Box } from '@graphite/uneon';

import { zIndices, transitions } from '@graphite/constants';
import { getBlockBreakpointInfo, closestDeviceWithKey } from '@graphite/selectors';
import type { TSpecsGrid, TGridBreakpointName, TWidget } from '@graphite/types';

export type TGridHiglightProps = $ReadOnly<{|
	currentDevice: TGridBreakpointName,
	gridspec: TSpecsGrid,
	colAmount: number,
	data: TWidget,
|}>;

const wrapSx = {
	position: 'absolute',
	top: '0',
	left: '0',
	width: '100%',
	height: '100%',
	pointerEvents: 'none',
	justifyContent: 'center',
	zIndex: zIndices.gridHightlight,

	transitionDuration: transitions.gridHightlight.showDuration,
	transitionTimingFunction: transitions.gridHightlight.showTiming,
	transitionDelay: transitions.gridHightlight.showDelay,
	transitionProperty: 'opacity',
};

const dashedSx = {
	borderWidth: '1px',
	borderBottomWidth: '0',
	borderTopWidth: '0',
	borderStyle: 'dashed',
	borderColor: 'spec.cyan30',
};

const colSx = {
	borderLeftWidth: '1px',
	borderRightWidth: '1px',
	borderLeftStyle: 'solid',
	borderRightStyle: 'solid',
	borderLeftColor: 'spec.cyan30',
	borderRightColor: 'spec.cyan30',
	backgroundColor: 'spec.cyan20',
	position: 'relative',
	textAlign: 'center',
	opacity: '0.33',
};

const toCss = (value: number): string =>
	(value || -1) < 0 ? `${-value * 100}%` : `${value}px`;

const GridHighlight = (props: TGridHiglightProps) => {
	const { currentDevice, gridspec, colAmount, data } = props;

	const breakpoint = gridspec.breakpoints[currentDevice];
	const box = closestDeviceWithKey(data.box, { currentDevice, key: `box-${data._id}` });
	const info = getBlockBreakpointInfo({ breakpoint, box, unit: gridspec.unit });

	const containerStyle = React.useMemo(
		() => ({
			paddingLeft: toCss(info.padding),
			paddingRight: toCss(info.padding),
			width: `calc(${toCss(info.width)} + ${info.padding * 2}px)`,
		}),
		[info.padding, info.width],
	);

	const rowStyle = React.useMemo(
		() => ({
			marginLeft: `-${toCss(info.gutter / 2)}`,
			marginRight: `-${toCss(info.gutter / 2)}`,
			width: `calc(100% + ${info.gutter}px)`,
		}),
		[info.gutter],
	);

	const colStyle = React.useMemo(
		() => ({
			marginLeft: toCss(info.gutter / 2),
			marginRight: toCss(info.gutter / 2),
			width: `${100 / colAmount}%`,
		}),
		[colAmount, info.gutter],
	);

	return (
		<Flex sx={wrapSx} data-kind="grid-highlight">
			<Flex
				sx={dashedSx}
				style={containerStyle}
				data-kind="grid-highlight-container"
			>
				<Flex style={rowStyle} data-kind="grid-highlight-row">
					{_.times(
						i => (
							<Box
								key={`col_${i}`}
								sx={colSx}
								style={colStyle}
								data-kind="grid-highlight-col"
							/>
						),
						colAmount,
					)}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default React.memo<TGridHiglightProps>(GridHighlight);
