// @flow

import React, { memo, useContext } from 'react';
import { createPortal } from 'react-dom';
import { Box, Flex } from '@graphite/uneon';

import reSelect from 'libs/re-select';
import { CursorContext } from 'CursorProvider';

type Props = {
	children: React$Node,
};

const boxSx = {
	willChange: 'transform',
	pointerEvents: 'none',
	position: 'absolute',
	zIndex: '600',
};

const flexSx = {
	backgroundColor: 'spec.blue30',
	borderRadius: 'rounded.all',
	left: '12px',
	padding: '5px 9px',
	position: 'relative',
	top: '-30px',
	whiteSpace: 'pre',
	boxShadow: 'smblue',
};

const emptyDiv = document.createElement('div');
const body: HTMLElement = document.body || emptyDiv;

const getWrapperStyle = reSelect(
	({ x }) => x,
	({ y }) => y,
	(x, y) => ({ top: `${y}px`, left: `${x}px` }),
)(() => 'Poper@getWrapperStyle');

function Poper({ children }) {
	const getPosition = useContext(CursorContext);

	return createPortal(
		<Box sx={boxSx} style={getWrapperStyle(getPosition())}>
			<Flex sx={flexSx}>{children}</Flex>
		</Box>,
		body,
	);
}

export default memo<Props>(Poper);
