// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

type Tprops = $ReadOnly<{||}>;

const Error = () => {
	const { t } = useTranslation();

	return <div>{t('Error')}</div>;
};

export default React.memo<Tprops>(Error);
