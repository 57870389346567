// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import getDisplayName from '@graphite/get-display-name';
import { closestDeviceWithKey } from '@graphite/selectors';
import ResizeCols from 'Widget/libs/resize-cols';
import dndContext from 'Widget/libs/dnd/dndContext';
import type { TSx, TId, TSizesBreakpoint } from '@graphite/types';

import type { TConnectPropsEdit, TConnectPropsEditWithSizes } from '../constants/types';

const withResizeCol = <
	TProps: TConnectPropsEdit,
	WrapedComponent: React$ComponentType<TConnectPropsEditWithSizes>,
>(
	Component: WrapedComponent,
): React$ComponentType<TProps> => {
	const WithResizeCol = (props: TProps, ref) => {
		const [{ data, isResize }] = React.useContext(ResizeCols);
		const [{ dragId }] = React.useContext(dndContext);

		const id: TId = props.data._id;
		const myId: TId = React.useMemo(
			() => (isResize ? _.findKey(v => v === id, { ...data.children }) || id : id),
			[data.children, id, isResize],
		);

		const colSizes: TSx = React.useMemo(() => {
			if (isResize || dragId) {
				const childSizes: TSizesBreakpoint = closestDeviceWithKey(
					data.sizes?.[myId],
					{ currentDevice: props.currentDevice, key: `sizes-${myId}` },
				);
				const width: number = childSizes.width || 1;
				const margin = childSizes.margin || emptyObject;
				const { left = 0, right = 0 } = margin;

				return ({
					width: `${width * 100}%`,
					marginLeft: `${left * 100}%`,
					marginRight: `${right * 100}%`,
				}: TSx);
			}
			return {};
		}, [isResize, data.sizes, myId, props.currentDevice, dragId]);

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} style={colSizes} ref={ref} />;
	};

	WithResizeCol.displayName = `withResizeCol(${getDisplayName(Component)})`;

	return React.memo<TProps>(React.forwardRef(WithResizeCol));
};

export default withResizeCol;
