// @flow
import React from 'react';
import { ButtonGroup } from '@graphite/uneon';
import type { TSpecsColor, TColorReference, TColorTabs } from '@graphite/types';

import Palette from './Palette';
import Themes from './Themes';
import Custom from './Custom';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	value?: TColorReference,
	tabs?: ?TColorTabs,
	onChange?: ?(TColorReference) => void,
	onPreview?: ?(TColorReference) => void,
|}>;

const tabsSx = {
	margin: '0 -24px',
	padding: '0 0 12px',
};

const tabList = [
	{ label: 'Palette', name: 'palette' },
	{ label: 'Theme', name: 'theme' },
	{ label: 'Custom', name: 'custom' },
];

const emptyColor = {
	entryId: null,
	shadeId: null,
	snapshot: '#000000',
};

const customOnly = ['custom'];

const getBestTab = (colorspec, value: TColorReference): string => {
	if (typeof value === 'string') {
		return 'theme';
	}
	const colorEntry = colorspec.palette.find(entry => entry.id === value.entryId);
	if (!colorEntry) {
		return 'custom';
	}
	if (!value.shadeId) {
		return 'palette';
	}
	const colorShade = colorEntry.shades.find(shade => shade.id === value.shadeId);
	if (!colorShade) {
		return 'custom';
	}
	return 'palette';
};

function LibColors({
	colorspec,
	value = emptyColor,
	tabs = customOnly,
	onPreview = null,
	onChange = null,
}: TProps) {
	const bestTab = React.useMemo(() => getBestTab(colorspec, value), [colorspec, value]);

	const [activeTab, setActiveTab] = React.useState(
		(tabs || customOnly).includes(bestTab) ? bestTab : 'custom',
	);
	React.useEffect(
		() => setActiveTab((tabs || customOnly).includes(bestTab) ? bestTab : 'custom'),
		[bestTab, value, tabs],
	);

	const availableTabs = React.useMemo(() => {
		const filtered = tabList.filter(({ name }) =>
			(tabs || customOnly).includes(name),
		);
		if (filtered.length > 0) {
			return filtered;
		}
		return tabList.filter(({ name }) => customOnly.includes(name));
	}, [tabs]);

	const clickBound = React.useCallback((e, name) => {
		if (typeof name === 'string') {
			setActiveTab(name);
		}
	}, []);

	return (
		<>
			{availableTabs.length > 1 && (
				<ButtonGroup
					behavior="radio"
					active={activeTab}
					buttons={availableTabs}
					variant="flat"
					size="sm"
					colors="secondaryflat"
					activeColors="accentflat"
					onClick={clickBound}
					sx={tabsSx}
				/>
			)}
			{(activeTab === 'palette' && (
				<Palette value={value} colorspec={colorspec} onChange={onChange} />
			)) ||
				(activeTab === 'theme' && (
					<Themes value={value} colorspec={colorspec} onChange={onChange} />
				)) ||
				(activeTab === 'custom' && (
					<Custom
						colorspec={colorspec}
						value={value}
						onPreview={onPreview}
						onChange={onChange}
					/>
				))}
		</>
	);
}

export default React.memo<TProps>(LibColors);
