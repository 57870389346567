// @flow
import React from 'react';
import { ToolbarSelect } from '@graphite/uneon';
import { useTranslation } from 'react-i18next';

import type { TWidgetImage } from '../constants/types';

type TProps = $ReadOnly<{|
	// setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	// openedPanel: ?string,
	data: TWidgetImage,
	save: (string, string | Object) => void,
|}>;

const selectSx = {
	width: 'auto',
	minWidth: '72px',
};

const Settings = ({ save, data }: TProps) => {
	const { t } = useTranslation();

	const [activeFormat, setActiveFormat] = React.useState(data.cropMode || 'fit');

	const buttonGroup = React.useMemo(
		() => ({
			items: [
				{ label: t('Fill'), name: 'fill' },
				{ label: t('Fit'), name: 'fit' },
				// { label: t('Crop'), name: 'crop' },
			],
			active: activeFormat,
		}),
		[t, activeFormat],
	);

	const modeClickBound = React.useCallback(
		(e, value) => {
			if (typeof value === 'string') {
				setActiveFormat(value);
				save('cropMode', value);
			}
		},
		[save],
	);

	return (
		<>
			<ToolbarSelect list={buttonGroup} onClick={modeClickBound} sx={selectSx} />
		</>
	);
};

export default React.memo<TProps>(Settings);
