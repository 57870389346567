// @flow
import _ from 'lodash/fp';
import { calcColumns, flatDelimiters, getDelimiters } from '@graphite/calc-columns';
import emptyObject from 'empty/object';

import type { TColumnsOrder } from 'libs/types/calc-columns';
import type {
	TDNDMethodReorderBabies,
	TDNDMethodReorderRow,
	TDNDMethodCheckBabieSamePosition,
	TDNDMethodCheckRowSamePosition,
	TDNDMethodSimpleReorder,
	TDNDMethodColumnReorder,
} from '../constants/types';

export const reorderBabies: TDNDMethodReorderBabies = ({
	babies,
	srcId,
	dstId,
	position,
}) =>
	babies.reduce((ids, currentId) => {
		if (currentId === dstId) {
			if (position === 'before') return [...ids, srcId, currentId];
			if (position === 'after') return [...ids, currentId, srcId];
		}

		if (currentId === srcId) return ids;
		return [...ids, currentId];
	}, []);

export const reorderRow: TDNDMethodReorderRow = ({ row, srcIds, dstId, position }) =>
	row.reduce((ids, currentIds: TColumnsOrder) => {
		if (currentIds.trueId === dstId) {
			if (position === 'before') return [...ids, srcIds, currentIds];
			if (position === 'after') return [...ids, currentIds, srcIds];
		}

		if (currentIds.trueId === srcIds.trueId) return ids;
		return [...ids, currentIds];
	}, []);

export const checkBabieSamePosition: TDNDMethodCheckBabieSamePosition = ({
	srcId,
	dstId,
	position,
	babies,
}) => {
	const indexSrc = babies.indexOf(srcId);
	const indexDst = babies.indexOf(dstId);

	if (indexSrc === -1 || indexDst === -1) return false;

	return indexDst === indexSrc + (position === 'before' ? 1 : -1);
};

export const checkRowSamePosition: TDNDMethodCheckRowSamePosition = ({
	srcId,
	dstId,
	position,
	row,
}) => {
	const indexSrc = row.findIndex(ids => ids.trueId === srcId);
	const indexDst = row.findIndex(ids => ids.trueId === dstId);

	if (indexSrc === -1 || indexDst === -1) return false;

	return indexDst === indexSrc + (position === 'before' ? 1 : -1);
};

export const simpleReorder: TDNDMethodSimpleReorder = (state, babies) => {
	const { dragId, dragPlace, widgets } = state;
	const placeId = dragPlace ? dragPlace.composeId : null;
	const position = dragPlace ? dragPlace.position : null;

	const srcId = dragId ? widgets[dragId].widgetId : null;
	const dstId = placeId ? widgets[placeId].widgetId : null;

	if (!srcId || !position || !dstId || !babies || !dragPlace) return babies;

	const isSrcContainer = babies.includes(srcId);
	const isDstContainer = babies.includes(dstId);
	const isSamePosition = checkBabieSamePosition({
		srcId,
		dstId,
		position,
		babies,
	});

	if (isSamePosition || (isSrcContainer && !isDstContainer)) {
		return babies;
	}

	if (isDstContainer) {
		return reorderBabies({
			srcId,
			babies,
			dstId,
			position,
		});
	}

	return babies;
};

export const columnReorder: TDNDMethodColumnReorder = (
	state,
	{ colSizeMap, colAmount },
) => {
	const { dragId, dragPlace, widgets } = state;
	const placeId = dragPlace ? dragPlace.composeId : null;
	const position = dragPlace ? dragPlace.position : null;

	const srcId = dragId ? widgets[dragId].widgetId : null;
	const dstId = placeId ? widgets[placeId].widgetId : null;

	if (!srcId || !dstId || !position || !colSizeMap) return colSizeMap;

	const isSrcContainer = colSizeMap.some(row => row[srcId]);
	const isDstContainer = colSizeMap.some(row => row[dstId]);

	let newColSizeMap = colSizeMap;
	let isSameRow = false;
	let isSamePosition = false;

	if (isSrcContainer) {
		newColSizeMap = newColSizeMap.map(row => {
			if (row[srcId]) {
				const { orderList = [], beforeId, nextId, ...columns } = row || {};

				if (columns[dstId]) {
					isSameRow = true;
					isSamePosition = checkRowSamePosition({
						srcId,
						dstId,
						row: orderList,
						position,
					});

					if (isSamePosition) return row;

					return {
						...row,
						orderList: reorderRow({
							row: orderList,
							srcIds: { refId: srcId, trueId: srcId },
							dstId,
							position,
						}),
					};
				}

				delete columns[srcId];

				const newOrderList = orderList.filter(({ trueId }) => srcId !== trueId);
				const delimiters = flatDelimiters(
					getDelimiters(newOrderList.length, colAmount || 1),
				);

				return _.assign(
					{
						orderList,
						beforeId,
						nextId,
						[srcId]: {
							width: 0,
							marginLeft: 0,
							marginRight: 0,
						},
					},
					calcColumns({
						orderList: newOrderList,
						columns,
						delimiters,
					}),
				);
			}

			return row;
		});
	}

	if (isSamePosition) return colSizeMap;

	if (isDstContainer && !isSameRow) {
		newColSizeMap = newColSizeMap.map(row => {
			if (row[dstId]) {
				const { orderList = [], beforeId, nextId, ...columns } =
					row || emptyObject;

				const newOrderList = reorderRow({
					row: orderList,
					srcIds: { refId: srcId, trueId: srcId },
					dstId,
					position,
				});

				const width: number = 1 / (orderList.length || 1);

				columns[srcId] = {
					width,
					marginLeft: 0,
					marginRight: 0,
				};

				const delimiters = flatDelimiters(
					getDelimiters(newOrderList.length, colAmount || 1),
				);

				return _.assign(
					{ orderList: newOrderList, beforeId, nextId },
					calcColumns({
						orderList: newOrderList,
						columns,
						delimiters,
					}),
				);
			}

			return row;
		});
	}

	return newColSizeMap;
};
