// @flow
import React from 'react';
import { ToolbarSelect, ListItemText, PopupWhite } from '@graphite/uneon';
import find from 'lodash/fp/find';
import filter from 'lodash/fp/filter';
import some from 'lodash/fp/some';
import map from 'lodash/fp/map';
import assign from 'lodash/fp/assign';
import findIndex from 'lodash/fp/findIndex';
import set from 'lodash/fp/set';
import slice from 'lodash/fp/slice';
import type {
	TId,
	TWidgetKind,
	TDesign,
	TSpecsColor,
	TSpecsWidget,
	TSpecsEffect,
	TSpecsGrid,
	TGridBreakpointName,
	TDesignSelectProps,
	TDesignStyleProps,
	TSpecs,
	TDesignImageLibProps,
	TDesignColorProps,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	designId: ?TId,
	device: TGridBreakpointName,
	gridspec: TSpecsGrid,
	colorspec: TSpecsColor,
	effectspec: TSpecsEffect,
	widgetspec: TSpecsWidget,
	target: TWidgetKind,
	defaultDesign: TDesign,
	customDesign?: ?TDesign,
	DesignPanel: React$ComponentType<TDesignSelectProps>,
	DesignColor: React$ComponentType<TDesignColorProps>,
	DesignStyle: React$ComponentType<TDesignStyleProps>,
	onChange?: ?(?TId, ?TDesign) => void,
	updateSpecs: TSpecs => void,
	genCustomId: string => TId,
	t: string => string,
	...$Exact<TDesignImageLibProps>,
|}>;

const titleSx = {
	marginBottom: '12px',
};

const buttonGroupInsert = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
					size: 'md',
					colors: 'secondary',
				},
			],
		},
	],
};

const noremControls = {
	buttons: [
		{
			name: 'edit',
			icons: [
				{
					name: 'pen',
					title: 'Edit',
					iconSize: 18,
					size: 'sm',
					colors: 'primary',
				},
			],
		},
		{
			name: 'clone',
			icons: [
				{
					name: 'copy',
					title: 'Clone',
					iconSize: 18,
					size: 'sm',
					colors: 'primary',
				},
			],
		},
	],
};

const itemControls = {
	buttons: [
		...noremControls.buttons,
		{
			name: 'remove',
			icons: [
				{
					name: 'trash',
					title: 'Remove',
					iconSize: 18,
					size: 'sm',
					colors: 'primary',
				},
			],
		},
	],
};

const staticColors = {
	text: 'text.primaryalt',
	texthover: 'text.primaryalt',
	textfocus: 'text.primaryalt',
	textactive: 'text.primaryalt',
	bg: 'transparent',
	bghover: 'transparent',
	bgfocus: 'transparent',
	bgactive: 'transparent',
	border: 'transparent',
	borderhover: 'transparent',
	borderfocus: 'transparent',
	borderactive: 'transparent',
};

const SelectDesign = ({
	target,
	device,
	designId,
	customDesign = null,
	defaultDesign,
	DesignPanel,
	DesignColor,
	DesignStyle,
	colorspec,
	gridspec,
	effectspec,
	widgetspec,
	onChange = null,
	updateSpecs,
	genCustomId,
	t,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TProps) => {
	const [editedDesign, setEditedDesign] = React.useState<?TId>(null);
	// При смене designId переключить редактирование на другой дизайн,

	const design = React.useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		if (customDesign && designId === customDesign._id) {
			return customDesign;
		}
		return designId && find(({ _id }) => _id === designId, widgetspec[target]);
	}, [customDesign, designId, widgetspec, target]);

	const listDesigns = React.useMemo(() => {
		const available = filter(({ removedAt }) => !removedAt, widgetspec[target]);
		// eslint-disable-next-line no-underscore-dangle
		const customId = (customDesign && customDesign._id) || '__custom';
		const hasActive = some(({ _id }) => _id === designId, available);
		return {
			items: [
				...map(
					({ _id, name }) => ({
						name: _id,
						label: name,
						buttonGroup: itemControls,
					}),
					available,
				),
				{
					name: '__ignore',
					style: { height: '12px' },
				},
				{
					name: customId,
					label: 'Custom',
					colors: 'secondaryflat',
				},
			],
			active: hasActive ? designId : customId,
		};
	}, [customDesign, designId, target, widgetspec]);

	const insertDesign = React.useCallback(() => {
		const newDesign: TDesign = (assign(defaultDesign, {
			_id: genCustomId('specs'),
			target,
		}): TDesign);

		updateSpecs({
			// eslint-disable-next-line no-underscore-dangle
			[widgetspec._id]: {
				...widgetspec,
				[`${target}`]: [newDesign, ...widgetspec[target]],
			},
		});
	}, [defaultDesign, genCustomId, target, updateSpecs, widgetspec]);

	const editDesign = React.useCallback(
		id => {
			// eslint-disable-next-line no-underscore-dangle
			const edited: ?TDesign = find(p => p._id === id, widgetspec[target]);
			if (!edited) {
				return;
			}
			setEditedDesign(id);
		},
		[target, widgetspec],
	);

	const uneditDesign = React.useCallback(() => setEditedDesign(null), [
		setEditedDesign,
	]);

	const changeDesign = React.useCallback(
		(changed: TDesign) => {
			if (!editedDesign) {
				return;
			}
			// eslint-disable-next-line no-underscore-dangle
			if (customDesign && customDesign._id === changed._id) {
				if (onChange) {
					onChange(null, changed);
					return;
				}

				return;
			}

			// eslint-disable-next-line no-underscore-dangle
			const editAt = findIndex(p => p._id === changed._id, widgetspec[target]);

			if (editAt < 0) {
				return;
			}

			updateSpecs({
				// eslint-disable-next-line no-underscore-dangle
				[widgetspec._id]: set(
					target,
					[
						...slice(0, editAt, widgetspec[target]),
						changed,
						...slice(
							editAt + 1,
							widgetspec[target].length,
							widgetspec[target],
						),
					],
					widgetspec,
				),
			});
		},
		[editedDesign, customDesign, widgetspec, target, updateSpecs, onChange],
	);

	const anchorRef = React.useRef();

	const boundClickCustom = React.useCallback(() => {
		// Если кастом был пустой то надо его создать
		const custom: TDesign =
			customDesign ||
			assign(defaultDesign, {
				_id: genCustomId('specs'),
				name: 'Custom',
			});

		// eslint-disable-next-line no-underscore-dangle
		if (custom._id !== designId && onChange) {
			// eslint-disable-next-line no-underscore-dangle
			onChange(custom._id, (!customDesign && custom) || null);
		}

		// eslint-disable-next-line no-underscore-dangle
		if (!editedDesign || custom._id !== editedDesign) {
			// eslint-disable-next-line no-underscore-dangle
			setEditedDesign(custom._id);
		}
	}, [customDesign, defaultDesign, designId, editedDesign, onChange, genCustomId]);

	const boundClick = React.useCallback(
		(e, listItem, buttonAction) => {
			if (typeof listItem !== 'string' || listItem === '__ignore') {
				return;
			}

			// eslint-disable-next-line no-underscore-dangle
			if (listItem === ((customDesign && customDesign._id) || '__custom')) {
				boundClickCustom();
				return;
			}

			if (buttonAction === 'remove') {
				e.stopPropagation();
				const itemAt: number = findIndex(
					// eslint-disable-next-line no-underscore-dangle
					p => p._id === listItem,
					widgetspec[target],
				);
				if (itemAt < 0) {
					return;
				}

				updateSpecs({
					// eslint-disable-next-line no-underscore-dangle
					[widgetspec._id]: set(
						`${target}.${itemAt}.removedAt`,
						new Date().toISOString(),
						widgetspec,
					),
				});

				return;
			}

			if (buttonAction === 'edit') {
				if (!editedDesign) {
					editDesign(listItem);
				}
				if (listItem !== designId && onChange) {
					onChange(listItem);
				}
				return;
			}

			if (buttonAction === 'clone') {
				e.stopPropagation();
				// eslint-disable-next-line no-underscore-dangle
				const item: ?TDesign = find(p => p._id === listItem, widgetspec[target]);
				if (!item) {
					return;
				}
				const newDesign: TDesign = assign(item, {
					_id: genCustomId('specs'),
					target,
					name: `${item.name || t('Unnamed')} ${t('copy')}`,
				});

				updateSpecs({
					// eslint-disable-next-line no-underscore-dangle
					[widgetspec._id]: {
						...widgetspec,
						[`${target}`]: [...widgetspec[target], newDesign],
					},
				});

				return;
			}

			if (listItem !== designId) {
				if (onChange) {
					onChange(listItem);
				}
			}
		},
		[
			target,
			boundClickCustom,
			customDesign,
			designId,
			editDesign,
			editedDesign,
			onChange,
			widgetspec,
			t,
			genCustomId,
			updateSpecs,
		],
	);

	const selectColors = editedDesign
		? 'accentflat'
		: (customDesign && 'secondary') || 'primary';

	return (
		<>
			<ToolbarSelect
				label="None"
				colors={selectColors}
				list={listDesigns}
				onClick={boundClick}
				ref={anchorRef}
			>
				<ListItemText
					label="Design"
					variant="title4"
					colors={staticColors}
					isExplicit
					buttonGroup={buttonGroupInsert}
					onClick={insertDesign}
					sx={titleSx}
				/>
			</ToolbarSelect>
			{editedDesign && design && (
				<PopupWhite
					// eslint-disable-next-line no-underscore-dangle
					key={editedDesign}
					isOpen={!!editedDesign}
					anchorEl={anchorRef}
					offsetLeft={150}
					offsetTop={-100}
					onClose={uneditDesign}
					mutex="design"
					isFixed
				>
					<DesignPanel
						design={design}
						device={device}
						gridspec={gridspec}
						colorspec={colorspec}
						widgetspec={widgetspec}
						effectspec={effectspec}
						onChange={changeDesign}
						Style={DesignStyle}
						Color={DesignColor}
						t={t}
						genId={genCustomId}
						insertImage={insertImage}
						removeImage={removeImage}
						resetImage={resetImage}
						images={images}
						uploads={uploads}
					/>
				</PopupWhite>
			)}
		</>
	);
};

export default React.memo<TProps>(SelectDesign);
