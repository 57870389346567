// @flow
import _ from 'lodash/fp';

import type {
	TDNDDispatch,
	TDNDMethodInitDrag,
	TDNDLastPosition,
} from './constants/types';

let dndStoreDispatch: ?TDNDDispatch = null;

const ESC = 17;
const DEBOUNCE_TIME = 20;

const debouncedDispatch = _.debounce(DEBOUNCE_TIME)((...args) => {
	if (!dndStoreDispatch) return;
	return dndStoreDispatch(...args);
});

const lastPosition: TDNDLastPosition = {
	x: null,
	y: null,
};

const handleDragOver = (event: DragEvent) => {
	event.preventDefault();
	if (event.dataTransfer) event.dataTransfer.dropEffect = 'move';

	if (lastPosition.x !== event.clientX || lastPosition.y !== event.clientY) {
		lastPosition.x = event.clientX;
		lastPosition.y = event.clientY;

		debouncedDispatch({
			type: 'setPosition',
			payload: { x: event.clientX, y: event.clientY },
		});
	}
};

const handleDragEnter = (event: DragEvent) => {
	if (event.dataTransfer) event.dataTransfer.dropEffect = 'move';
	const HTMLElementFrame = document.getElementsByTagName('iframe')[0].contentWindow
		.HTMLElement;
	if (
		!dndStoreDispatch ||
		(!(event.target instanceof HTMLElement) &&
			!(event.target instanceof HTMLElementFrame))
	)
		return;
	dndStoreDispatch({ type: 'setOver', payload: { node: event.target } });
};

const handleDragEnd = () => {
	removeListeners(); // eslint-disable-line no-use-before-define

	if (!dndStoreDispatch) return;
	dndStoreDispatch({ type: 'endDrag' });
};

const handleDrop = (event: DragEvent) => {
	event.preventDefault();
	removeListeners(); // eslint-disable-line no-use-before-define

	if (!dndStoreDispatch) return;
	dndStoreDispatch({ type: 'drop' });
};

const handleKeyDown = (event: KeyboardEvent) => {
	if (event.keyCode === ESC && dndStoreDispatch) {
		dndStoreDispatch({ type: 'endDrag' });
	}
};

const removeListeners = () => {
	const documentFrame = document.getElementsByTagName('iframe')[0].contentDocument;
	[document, documentFrame].forEach(doc => {
		doc.removeEventListener('drop', handleDrop);
		doc.removeEventListener('dragend', handleDragEnd);
		doc.removeEventListener('dragover', handleDragOver);
		doc.removeEventListener('dragenter', handleDragEnter);
		doc.removeEventListener('keydown', handleKeyDown);
	});
};

const addListeners = () => {
	const documentFrame = document.getElementsByTagName('iframe')[0].contentDocument;
	[document, documentFrame].forEach(doc => {
		doc.addEventListener('drop', handleDrop);
		doc.addEventListener('dragend', handleDragEnd);
		doc.addEventListener('dragover', handleDragOver);
		doc.addEventListener('dragenter', handleDragEnter);
		doc.addEventListener('keydown', handleKeyDown);
	});
};

const handleDragStart = (event: DragEvent) => {
	if (!dndStoreDispatch) return;
	const { target } = event;
	if (target instanceof HTMLElement && !('dragId' in target.dataset)) return;

	dndStoreDispatch({
		type: 'startDrag',
		payload: { event },
	});

	removeListeners();
	addListeners();
};

const handleNoDrag = (event: DragEvent) => {
	event.preventDefault();
};

export const initDrag: TDNDMethodInitDrag = (dispatch, isDisabled: boolean) => {
	document.removeEventListener('dragstart', handleDragStart);
	document.removeEventListener('dragstart', handleNoDrag);

	if (isDisabled) {
		document.addEventListener('dragstart', handleNoDrag);
		return () => {
			document.removeEventListener('dragstart', handleNoDrag);
		};
	}
	document.addEventListener('dragstart', handleDragStart);

	// FixMe грязная функция
	dndStoreDispatch = dispatch;

	return () => {
		document.removeEventListener('dragstart', handleDragStart);
	};
};

export default {};
