// @flow

import _ from 'lodash/fp';
import reSelect from 'libs/re-select';
import { listMatchWeight } from '@graphite/constants';
import type { TSx, TId, TFonts } from '@graphite/types';

const getFont = (desing: TSx) => {
	const font = {};

	if (typeof desing.fontFamily === 'string') {
		const { fontFamily } = desing;
		font.family = fontFamily;
		font.id = fontFamily.replace(/\s+/g, '-').toLowerCase();

		if (typeof desing.fontWeight === 'string') {
			const currentWeight = listMatchWeight.find(
				({ name }) => name === desing.fontWeight,
			);

			// В будущем у кнопки может появится italic нужно его брать из дизайна текста
			if (currentWeight) {
				font.variants = [currentWeight.value];
			}
		}
	}

	return font;
};

const getFonts = reSelect<
	$ReadOnly<{
		id: TId,
		designs: TSx,
	}>,
	TSx,
	TFonts,
>(
	({ designs }) => designs,
	designs => {
		let mediasFont = [];
		const mainFont = getFont(designs);
		_.forEach(media => {
			if (typeof media === 'object' && !Array.isArray(media) && media?.fontFamily) {
				const font = getFont(media);
				if (font.fontFamily) mediasFont = [...mediasFont, font];
			}
		}, designs);

		return [mainFont, ...mediasFont];
	},
)(({ id }) => {
	return `widget@getFonts-${id}`;
});

export default getFonts;
