// @flow
import _ from 'lodash/fp';

import { INITIAL_RECT } from '../constants';
import type { TFindDropId, TWindow } from '../constants/types';

const getPlaces = (places, type4filter) =>
	_.filter(({ type }) => type === type4filter, places);

// onlyId - на девайсе можно перетаскивать только в тот контейнер, в который можно
const findDropId: TFindDropId = (dropPlaces, dropRects, cursorPosition) => {
	// eslint-disable-next-line no-restricted-syntax
	for (const dropAbsoluteType of ['stack', 'col', 'container', 'block']) {
		const dropPlace = _.find(place => {
			const rect = (() => {
				// eslint-disable-next-line no-shadow
				const rect = place.ref.current
					? place.ref.current.getBoundingClientRect()
					: INITIAL_RECT;

				// eslint-disable-next-line no-param-reassign
				dropRects[place.dropId] = rect;
				return rect;
			})();

			const { pageXOffset, pageYOffset } = (window: TWindow);

			return (
				_.inRange(
					pageXOffset + rect.left,
					pageXOffset + rect.left + rect.width,
					cursorPosition.x,
				) &&
				_.inRange(
					pageYOffset + rect.top,
					pageYOffset + rect.top + rect.height,
					cursorPosition.y,
				)
			);
		}, getPlaces(dropPlaces, dropAbsoluteType));

		if (dropPlace) {
			return dropPlace.dropId;
		}
	}

	return null;
};

export default findDropId;
