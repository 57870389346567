// @flow
import React from 'react';

import type { TUseOutWatch } from '../types';

const useOutWatch: TUseOutWatch = ({ setTargetIds, symbioteRootRef }) => {
	React.useEffect(() => {
		const symbioteRoot = symbioteRootRef.current;
		const modalRoot = document.getElementById('modal-root');
		if (!symbioteRoot || !modalRoot) {
			return;
		}
		const symbioteParent = symbioteRoot.parentElement;
		if (!symbioteParent) {
			return;
		}
		const handleLeaveDoc = (event: MouseEvent) => {
			if (!(event.relatedTarget && event.relatedTarget instanceof Element)) {
				return;
			}
			const target: Element = event.relatedTarget;
			// Reset target if cursor has left the document
			if (!symbioteParent.contains(target) && !modalRoot.contains(target)) {
				setTargetIds(() => null);
			}
		};
		modalRoot.addEventListener('mouseout', handleLeaveDoc);
		symbioteParent.addEventListener('mouseout', handleLeaveDoc);
		return () => {
			modalRoot.removeEventListener('mouseout', handleLeaveDoc);
			symbioteParent.removeEventListener('mouseout', handleLeaveDoc);
		};
	}, [setTargetIds, symbioteRootRef]);
};

export default useOutWatch;
