// @flow

import React from 'react';
import { Box } from '@graphite/uneon';
import type { TSx } from '@graphite/types';
import { getBoxSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';

import type { TWrapProps } from './constants/types';

const Wrap = (props: TWrapProps, ref) => {
	const {
		children,
		data,
		widgetspec,
		effectspec,
		colorspec,
		gridspec,
		direction,
		position,
		...propsWrap
	} = props;

	const boxSx: TSx = React.useMemo(
		() => ({
			...getBoxSx({ data, position, direction, gridspec }),
			wordBreak: 'break-word',
			flexDirection: 'column',
		}),
		[data, gridspec, position, direction],
	);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	return (
		<Box
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...propsWrap}
			sx={boxSx}
			style={dynamicStyle}
			ref={ref}
			data-kind="widget-text-wrap"
		>
			{children}
		</Box>
	);
};

export default React.memo<TWrapProps>(React.forwardRef(Wrap));
