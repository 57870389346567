// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';

import useOverrider from 'Editor/libs/use-overrider';

import type {
	TConnectOverriderProps,
	TOverriderComponentOut,
	TOverriderComponentIn,
} from '../constants/types';

/**
	Позволяет переопределить любой пропс этого виджета, не меняя весь стейт
	и не перерисовывая весь холст.

	Это полезно, например, при ресайзе колонок и паддингов блока.
	Чтобы при ресайзе на каждый пиксель не менять стейт, поможно поменять
	данные только одного виджета.
 */
const withOverrider = (Component: TOverriderComponentOut): TOverriderComponentIn => {
	const Overrider = (props: TConnectOverriderProps, ref) => {
		const [data, setData] = useOverrider<
			$PropertyType<TConnectOverriderProps, 'data'>,
		>(props.data);
		const [gridHighlight, setGridHighlight] = useOverrider<
			$PropertyType<TConnectOverriderProps, 'gridHighlight'>,
		>(props.gridHighlight);

		return (
			<Component
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				data={data}
				setData={setData}
				gridHighlight={gridHighlight}
				setGridHighlight={setGridHighlight}
				ref={ref}
			/>
		);
	};

	Overrider.displayName = `withOverrider(${getDisplayName(Component)})`;

	return React.memo<TConnectOverriderProps>(React.forwardRef(Overrider));
};

export default withOverrider;
