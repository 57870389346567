// @flow
import React from 'react';
import set from 'lodash/fp/set';
import { Box, Section, PopupWhite } from '@graphite/uneon';
import type {
	TSpecsColor,
	TShadowValue,
	TShadowValues,
	TLibConfig,
	TLibraryValue,
	TSpecsGrid,
} from '@graphite/types';
import { Shadow as LabelShadow } from '@graphite/labels';
import Library from '@graphite/library';

type TProps = $ReadOnly<{|
	t: string => string,
	label: string,
	isSeparated?: boolean,
	source: TShadowValues,
	defaultItem: TShadowValue,
	config?: ?TLibConfig,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	onChange?: ?(TShadowValues) => void,
	genId: string => string,
|}>;

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px 0 -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

const shadowInsertButtons = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

const shadowRemoveButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

const libShadowConf: TLibConfig = {
	shadow: {},
};

function Shadows({
	t,
	label,
	isSeparated = false,
	source,
	config = null,
	defaultItem,
	colorspec,
	gridspec,
	onChange = null,
	genId,
}: TProps) {
	const [editedShadow, setEditedShadow] = React.useState<?TShadowValue>(null);

	const uneditShadow = React.useCallback(() => {
		if (editedShadow) {
			setEditedShadow(null);
		}
	}, [setEditedShadow, editedShadow]);

	const clickSection = React.useCallback(
		(e, buttonName) => {
			if (buttonName === 'insert') {
				if (onChange && Array.isArray(source)) {
					onChange([set('value.id', genId('specs'), defaultItem), ...source]);
				}
			}
		},
		[defaultItem, genId, onChange, source],
	);

	const clickItem = React.useCallback(
		(e, itemName, buttonName) => {
			const itemAt = source.findIndex(s => s.value.id === itemName);
			if (itemAt < 0) {
				return;
			}
			if (buttonName === 'remove') {
				if (editedShadow && editedShadow.value.id === itemName) {
					setEditedShadow(null);
				}
				if (onChange) {
					onChange([...source.slice(0, itemAt), ...source.slice(itemAt + 1)]);
				}
				return;
			}
			setEditedShadow(source[itemAt]);
		},
		[source, editedShadow, onChange],
	);

	const changeShadow = React.useCallback(
		(value: TLibraryValue) => {
			if (!editedShadow || value.kind !== 'shadow') {
				return;
			}
			const itemAt = source.findIndex(p => p.value.id === value.value.id);
			if (itemAt < 0) {
				return;
			}
			setEditedShadow(value);
			if (onChange) {
				onChange([
					...source.slice(0, itemAt),
					value,
					...source.slice(itemAt + 1),
				]);
			}
		},
		[editedShadow, onChange, source],
	);

	const anchorRef = React.useRef();

	return (
		<>
			<Section
				size="xsm"
				isSeparated={isSeparated}
				color={source.length ? 'text.primary' : 'text.secondary'}
				label={label}
				buttonGroup={shadowInsertButtons}
				onClick={clickSection}
			>
				<Box sx={anchorBoxOuterStyle}>
					<Box ref={anchorRef} sx={anchorBoxInnerStyle} />
				</Box>
				{source.map(shadow => {
					const { id } = shadow.value;
					const isActive = !!editedShadow && editedShadow.value.id === id;
					return (
						<LabelShadow
							key={id}
							name={id}
							shadow={shadow}
							colorspec={colorspec}
							isActive={isActive}
							onClick={clickItem}
							buttonGroup={shadowRemoveButtons}
						/>
					);
				})}
			</Section>
			{editedShadow && (
				<PopupWhite
					isOpen={!!editedShadow}
					anchorEl={anchorRef}
					offsetLeft={12}
					offsetTop={-20}
					onClose={uneditShadow}
					mutex="color"
					isFixed
				>
					<Library
						t={t}
						config={config || libShadowConf}
						value={editedShadow}
						onChange={changeShadow}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(Shadows);
