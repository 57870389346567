// @flow
import {
	EditorState,
	RichUtils,
	Modifier,
	SelectionState,
	type EditorState as TEditorState,
} from 'draft-js';
import emptyObject from 'empty/object';
import _ from 'lodash/fp';
import type { TLibraryValue } from '@graphite/types';

import { tags } from './blockRenderMap';

import { styles } from './style-map';

/**
	Если нет выделения текста, то эта функция выделяет весь абзац
 */
const normalizeSelectionEditorState = editorState => {
	const selection = editorState.getSelection();

	// если уже что-то выделено, то ничего не делаем
	if (!selection.isCollapsed()) return editorState;

	const currentContent = editorState.getCurrentContent();
	const firstBlock = currentContent.getBlockMap().first();
	const lastBlock = currentContent.getBlockMap().last();
	const firstBlockKey = firstBlock.getKey();
	const lastBlockKey = lastBlock.getKey();
	const lengthOfLastBlock = lastBlock.getLength();

	const newSelection = new SelectionState({
		anchorKey: firstBlockKey,
		anchorOffset: 0,
		focusKey: lastBlockKey,
		focusOffset: lengthOfLastBlock,
	});

	const newEditorState = EditorState.acceptSelection(editorState, newSelection);

	return newEditorState;
};

/**
	Color
 */

export const getColor = (styles => (editorState: TEditorState) => {
	const selection = editorState.getSelection();
	const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());

	let value = null;
	try {
		value = JSON.parse(styles.color.current(editorState));
	} catch (e) {
		// logger
	}
	if (value) return value;

	const blockColor = block.getData().get('color');
	if (blockColor)
		return {
			kind: 'color',
			value: { entryId: null, shadeId: null, snapshot: blockColor },
		};

	return null;
})(styles);

export const toggleColor = (styles => (editorState: TEditorState) => (
	color: TLibraryValue,
) => {
	const newEditorState = styles.color.add(editorState, JSON.stringify(color));

	return newEditorState;
})(styles);

/**
	Inline Styles
 */
export type TInlineStyle =
	| 'fontWeight'
	| 'fontStyle'
	| 'textDecoration'
	| 'verticalAlign';

export const getInlineStyles = (styles => (editorState: TEditorState) => (
	type: TInlineStyle,
) => styles[type].current(editorState) || null)(styles);

export const toggleInlineStyles = (styles => (editorState: TEditorState) => (
	type: TInlineStyle,
) => (value: string) => {
	const selection = editorState.getSelection();

	const newEditorState = styles[type][selection.isCollapsed() ? 'add' : 'toggle'](
		editorState,
		value,
	);

	return newEditorState;
})(styles);

export const removeInlineStyles = (styles => (editorState: TEditorState) => () => {
	const newEditorState = _.reduce(
		(editorState, { remove }) => remove(editorState),
		editorState,
		styles,
	);

	return newEditorState;
})(styles);

/**
	Block Styles
 */
export type TBlockStyle = 'ordered-list-item' | 'unordered-list-item';

export const getBlockStyles = (editorState: TEditorState) => (type: TBlockStyle) => {
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return blockType === type;
};

export const applyBlockStyle = (editorState: TEditorState) => (blockType: string) => {
	const newEditorState = RichUtils.toggleBlockType(editorState, blockType);

	return newEditorState;
};

/**
	Link
 */

export type TToggleLink = $ReadOnly<{| value: string, isNewTab: boolean |}>;

export const getLink = (editorState: TEditorState) => () => {
	const contentState = editorState.getCurrentContent();
	const startKey = editorState.getSelection().getStartKey();
	const startOffset = editorState.getSelection().getStartOffset();
	const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
	const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

	if (!linkKey) return emptyObject;

	const linkInstance = contentState.getEntity(linkKey);
	const data = linkInstance.getData();

	return {
		...data,
		isNewTab: data.target === '_blank',
	};
};

export const toggleLink = (editorState: TEditorState) => ({
	value,
	isNewTab,
}: TToggleLink) => {
	const url = value;
	const target = isNewTab ? '_blank' : '_self';
	let selection = editorState.getSelection();

	let contentState = editorState.getCurrentContent();
	const entityKey = contentState
		.createEntity('LINK', 'MUTABLE', {
			url,
			target,
		})
		.getLastCreatedEntityKey();

	/**
		Если пользователь выделил текст, то просто ставим ему значение
	 */
	if (!selection.isCollapsed()) {
		const newEditorState = RichUtils.toggleLink(editorState, selection, entityKey);

		return newEditorState;
	}

	/**
		Иначе вставляем кусок текста и оборачиваем его в ссылку
	 */
	contentState = Modifier.replaceText(
		editorState.getCurrentContent(),
		selection,
		`${value}`,
		editorState.getCurrentInlineStyle(),
		entityKey,
	);

	editorState = EditorState.push(editorState, contentState, 'insert-characters');

	// insert a blank space after link
	selection = editorState.getSelection().merge({
		anchorOffset: selection.get('anchorOffset') + value.length,
		focusOffset: selection.get('anchorOffset') + value.length,
	});
	editorState = EditorState.acceptSelection(editorState, selection);
	contentState = Modifier.insertText(
		editorState.getCurrentContent(),
		selection,
		' ',
		editorState.getCurrentInlineStyle(),
		undefined,
	);

	return EditorState.push(editorState, contentState, 'insert-characters');
};

/**
	Align
 */

type TAlignValue = 'left' | 'right' | 'center' | 'justify';

export const getAlign = (editorState: TEditorState) => {
	const currentKey = editorState.getSelection().getAnchorKey();
	const currentBlock = editorState.getCurrentContent().getBlockForKey(currentKey);

	return currentBlock.getData() && currentBlock.getData().get('text-align');
};

export const toggleAlign = (editorState: TEditorState) => (value: TAlignValue) => {
	const nextEditorState = normalizeSelectionEditorState(editorState);

	const selection = editorState.getSelection();

	const align = getAlign(nextEditorState);

	const nextContentState = Modifier.mergeBlockData(
		nextEditorState.getCurrentContent(),
		selection,
		{ 'text-align': align === value ? null : value },
	);

	const newEditorState = EditorState.push(
		nextEditorState,
		nextContentState,
		'change-block-data',
	);

	return newEditorState;
};

/**
	Block Types
 */

export const getSelectedBlocksKey = (editorState: TEditorState): string => {
	const selection = editorState.getSelection();
	const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
	return block.getKey();
};

const getSelectedBlocksTypeTag = (editorState: TEditorState) => {
	const selection = editorState.getSelection();
	const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());

	const type = block.getType();

	return type;
};

export const getSelectedBlocksType = (editorState: TEditorState) => {
	const type = getSelectedBlocksTypeTag(editorState);
	const typeSpliting = type.split('-');

	const [tag] = typeSpliting.slice(-1);

	if (!tags.includes(tag)) {
		return type;
	}

	return typeSpliting.slice(0, -1).join('-');
};

export const getSelectedBlocksTag = (editorState: TEditorState) => {
	const type = getSelectedBlocksTypeTag(editorState);
	const typeSpliting = type.split('-');

	const [tag] = typeSpliting.slice(-1);

	if (tags.includes(tag)) {
		return tag;
	}

	return 'div';
};

export const toggleBlocksType = (editorState: TEditorState) => (type: string) => {
	// получаем текущий тег
	let tag = getSelectedBlocksTypeTag(editorState)
		.split('-')
		.pop();

	// если текущий тек говно, то ставим дефолтный
	if (!tags.includes(tag)) {
		[tag] = tags.slice(-1);
	}

	const newEditorState = RichUtils.toggleBlockType(editorState, `${type}-${tag}`);

	return newEditorState;
};

export const toggleBlocksTag = (editorState: TEditorState) => (tag: string) => {
	// получаем текущий тип блока без тэга
	const type = getSelectedBlocksType(editorState);

	const newEditorState = RichUtils.toggleBlockType(editorState, `${type}-${tag}`);

	return newEditorState;
};

/**
	Other
 */

export const selectAll = (editorState: TEditorState) => (isSoft: ?boolean = false) => {
	const currentContent = editorState.getCurrentContent();
	const firstBlock = currentContent.getBlockMap().first();
	const lastBlock = currentContent.getBlockMap().last();
	const firstBlockKey = firstBlock.getKey();
	const lastBlockKey = lastBlock.getKey();
	const lengthOfLastBlock = lastBlock.getLength();

	const selection = new SelectionState({
		anchorKey: firstBlockKey,
		anchorOffset: 0,
		focusKey: lastBlockKey,
		focusOffset: lengthOfLastBlock,
	});

	if (isSoft) {
		return EditorState.acceptSelection(editorState, selection);
	}

	return EditorState.forceSelection(editorState, selection);
};
