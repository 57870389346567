/* eslint-disable global-require */
// @flow

import winston from 'winston';
import _ from 'lodash/fp';

import { config } from '@graphite/constants';
import { LEVELS } from './constants';

type TOpts = () => Promise<{|
	logEvent?: (string, Object) => void,
	user?: ?$ReadOnly<{|
		id: string,
		username: string,
		email: string,
	|}>,
	sentryDNS?: string,
	sentryEnabled?: Boolean,
|}>;

const Logger = {
	logger: null,

	init(giveData: TOpts = () => new Promise(() => {}), force: boolean = false) {
		if (this.logger && !force) return this.logger;
		const logger = winston.createLogger({
			format: winston.format.json(),
			defaultMeta: { service: 'front' },
			transports: [],
		});

		if (process.env.ENV_MODE !== 'server' && process.env.NODE_ENV !== 'production') {
			const BrowserTransport = require('./libs/browser-transport').default;
			logger.add(
				new BrowserTransport({
					format: winston.format.json(),
					level: 'debug',
				}),
			);
		}

		if (process.env.NODE_ENV === 'production' && process.env.ENV_MODE !== 'server') {
			const SentryTransport = require('./libs/sentry-transport').default;
			const { sentryEnabled: sentryEnabledConf, sentryDNS: sentryDNSConf } = config;
			(async () => {
				try {
					const { sentryEnabled, sentryDNS, user, logEvent } = await giveData();

					if (sentryEnabledConf && sentryEnabled)
						logger.add(
							new SentryTransport({
								format: winston.format.json(),
								level: 'error',
								sentryDNS: sentryDNS || sentryDNSConf || '',
								user,
								host: window.location.hostname,
							}),
						);

					const AnalyticsTransport = require('./libs/analytics-transport')
						.default;

					if (logEvent)
						logger.add(
							new AnalyticsTransport({
								level: 'info',
								logEvent,
							}),
						);

					const FbTransport = require('./libs/fb-transport').default;
					logger.add(
						new FbTransport({
							level: 'info',
						}),
					);

					const AmplitudeTransport = require('./libs/amplitude-transport')
						.default;
					logger.add(
						new AmplitudeTransport({
							amplitudeKey: config.apiKeyAmplitude,
							level: 'info',
							user,
						}),
					);
				} catch (e) {
					// eslint-disable-next-line no-console
					console.error(e);
				}
			})();
		}
		this.logger = _.transform(
			// eslint-disable-next-line no-return-assign
			(result, level) =>
				// eslint-disable-next-line no-param-reassign
				(result[level] = (message, meta, props) =>
					logger.log({ level, message, meta, props })),
			{},
		)(LEVELS);

		return this.logger;
	},
};

export default Logger;
