// @flow
import React, { type Node, useCallback, memo } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import type { TProps } from './types';

const cssAll = css`
	position: absolute;
	user-select: none;
	transition: opacity 0.2s;
	pointer-events: auto;

	display: flex;
	justify-content: center;
	align-items: center;
`;

const cssTopBottom = css`
	${cssAll}
	cursor: ns-resize;
	height: 10px;
	left: 0;
	right: 0;
	user-select: none;
`;

const cssTop = css`
	${cssTopBottom}
	top: -5px;
`;

const cssBottom = css`
	${cssTopBottom}
	bottom: -5px;
`;

const cssLeftRight = css`
	${cssAll}
	bottom: 0;
	cursor: ew-resize;
	top: 0;
	width: 10px;
`;

const cssLeft = css`
	${cssLeftRight}
	left: -5px;
`;

const cssRight = css`
	${cssLeftRight}
	right: -5px;
`;

const cssCorners = css`
	${cssAll}
	height: 20px;
	width: 20px;
`;

const cssTopLeft = css`
	${cssCorners}
	top: -10px;
	left: -10px;
	cursor: nwse-resize;
`;

const cssTopRight = css`
	${cssCorners}
	top: -10px;
	right: -10px;
	cursor: nesw-resize;
`;

const cssBottomLeft = css`
	${cssCorners}
	bottom: -10px;
	left: -10px;
	cursor: nesw-resize;
`;

const cssBottomRight = css`
	${cssCorners}
	bottom: -10px;
	right: -10px;
	cursor: nwse-resize;
`;

const Wrapper = styled.div`
	${({ isDrag }) =>
		isDrag &&
		css`
			&:before {
				opacity: 1 !important;
			}
		`}

	${({ dir }) =>
		({
			top: cssTop,
			bottom: cssBottom,
			left: cssLeft,
			right: cssRight,
			'top-left': cssTopLeft,
			'top-right': cssTopRight,
			'bottom-left': cssBottomLeft,
			'bottom-right': cssBottomRight,
		}[dir])}
`;

const getAxisData = {
	top: {
		disable: 'x',
	},
	bottom: {
		disable: 'x',
	},
	left: {
		disable: 'y',
	},
	right: {
		disable: 'y',
	},
	'bottom-left': {
		disable: false,
	},
	'bottom-right': {
		disable: false,
	},
	'top-left': {
		disable: false,
	},
	'top-right': {
		disable: false,
	},
};

const getDataCoords = ({ dir, element, swipeX, swipeY }) => {
	const { pageX: x, pageY: y } = element;
	const data = {
		x: swipeX.current - x,
		y: swipeY.current - y,
	};

	if (dir && getAxisData[dir].disable === 'y') {
		data.y = 0;
	}

	if (dir && getAxisData[dir].disable === 'x') {
		data.x = 0;
	}

	return data;
};

function DragSide(props: TProps): Node {
	const {
		dir,
		resizeUpdate,
		resizeStart,
		resizeStop,
		className,
		style,
		control,
	} = props;

	const [isDrag, setDrag] = React.useState(false);

	const swipeX = React.useRef(0);
	const swipeY = React.useRef(0);

	const onResize = useCallback(
		dragData => {
			if (!dir) return;

			resizeUpdate(dragData);
		},
		[dir, resizeUpdate],
	);

	const onStop = useCallback(
		dragData => {
			if (!dir) return;

			resizeStop(dragData);
		},
		[dir, resizeStop],
	);

	const onMouseUpdate = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			const data = getDataCoords({
				element: e,
				swipeX,
				swipeY,
				dir,
			});
			onResize(data);
		},
		[onResize, dir],
	);

	const onTouchUpdate = useCallback(
		(e: TouchEvent) => {
			e.preventDefault();
			const touch = e.touches ? e.touches[0] : {};
			const data = getDataCoords({
				element: touch,
				swipeX,
				swipeY,
				dir,
			});
			onResize(data);
		},
		[onResize, dir],
	);

	const onMouseStop = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();

			const data = getDataCoords({
				element: e,
				swipeX,
				swipeY,
				dir,
			});

			onStop(data);
			setDrag(false);
		},
		[onStop, dir],
	);

	const onTouchStop = useCallback(
		(e: TouchEvent) => {
			e.stopPropagation();
			const touch = e.touches ? e.touches[0] : {};
			const data = getDataCoords({
				element: touch,
				swipeX,
				swipeY,
				dir,
			});

			onStop(data);
			setDrag(false);
		},
		[onStop, dir],
	);

	const onMouseStart = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();

			swipeX.current = e.pageX;
			swipeY.current = e.pageY;

			if (dir) resizeStart({ dir, propName: control });
			setDrag(true);
		},
		[dir, resizeStart, control],
	);

	const onTouchStart = useCallback(
		(e: TouchEvent) => {
			e.preventDefault();
			e.stopPropagation();

			const touch = e.touches ? e.touches[0] : {};
			swipeX.current = touch.pageX;
			swipeY.current = touch.pageY;

			if (dir) resizeStart({ dir, propName: control });
			setDrag(true);
		},
		[dir, resizeStart, control],
	);

	React.useEffect(() => {
		if (!isDrag) return;

		document.addEventListener('mousemove', onMouseUpdate);
		document.addEventListener('mouseup', onMouseStop);

		document.addEventListener('touchmove', onTouchUpdate, {
			passive: false,
		});
		document.addEventListener('touchend', onTouchStop);

		return () => {
			if (!isDrag) return;
			document.removeEventListener('mousemove', onMouseUpdate);
			document.removeEventListener('mouseup', onMouseStop);

			document.removeEventListener('touchmove', onTouchUpdate);
			document.removeEventListener('touchend', onTouchStop);
		};
	}, [onMouseUpdate, onMouseStop, onTouchUpdate, onTouchStop, isDrag]);

	return (
		<Wrapper
			dir={dir}
			isDrag={isDrag}
			className={className}
			style={style}
			onMouseDown={onMouseStart}
			onTouchStart={onTouchStart}
		/>
	);
}

export default memo<TProps>(DragSide);
