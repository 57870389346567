// @flow
import { css } from '@emotion/core';
import emotionReset from 'emotion-reset';

const GlobalStyle = css`
	${emotionReset}

	html {
		box-sizing: border-box;
	}

	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

export default GlobalStyle;
