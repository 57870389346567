// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';

import type {
	TMethodRegSymbioteArg,
	TMethodRegSymbiote,
	TMethodUnregSymbioteArg,
	TMethodUnregSymbiote,
	TRegUnreg,
	TRegPool,
	TRegData,
} from '../types';

// Сколько симбиот запросов максимум накапливать перед коммитом
const POOL_THRESHOLD = 50;
// Множитель интервала дебаунса для запросов регистрации симбиотов
const POOL_DEBOUNCE = 5;

let regPool: TRegPool = [];
let regData: ?TRegData = null;
let commitTimer: ?TimeoutID = null;

const pushAction = (entry: TRegUnreg) => {
	regPool = [...regPool, entry];
};

const performReg: TMethodRegSymbiote = ({
	composeId,
	kind,
	containerId,
	regRefs: { symbioteRef, currentRef },
	widgetId,
	instanceId,
}) => {
	if (regData && regData[composeId]) {
		const existCurrent = regData[composeId].currentRefs || [];
		const existSymbiote = regData[composeId].symbioteRefs || [];

		// merge existing symbiote with registered
		// one symbiote can contain multiple refs
		regData = (_.merge(regData, {
			[composeId]: {
				...regData[composeId],
				symbioteRefs: [...existSymbiote, symbioteRef],
				currentRefs: [...existCurrent, currentRef],
			},
		}): TRegData);
		return;
	}

	regData = (_.set(
		composeId,
		{
			kind,
			containerId,
			currentRefs: [currentRef],
			symbioteRefs: [symbioteRef],
			widgetId,
			instanceId,
		},
		regData || emptyObject,
	): TRegData);
};

const performUnreg: TMethodUnregSymbiote = ({ composeId, symbioteRef, currentRef }) => {
	if (!regData || !regData[composeId]) {
		return;
	}
	/* у примитивов (пока) только один симбиот, отписка сразу */
	const item = regData[composeId];
	if (item.currentRefs.length === 1 && item.symbioteRefs.length === 1) {
		regData = (_.unset(composeId, regData): TRegData);
		return;
	}

	// remove refs from symbiote
	// one symbiote can contains more than one refs
	const symbioteRefs = item.symbioteRefs.filter(ref => ref !== symbioteRef);
	const currentRefs = item.currentRefs.filter(ref => ref !== currentRef);

	if (!currentRefs.length || !symbioteRefs.length) {
		regData = (_.unset(composeId, regData): TRegData);
		return;
	}

	regData = (_.merge(regData, {
		...regData,
		[composeId]: {
			...item,
			symbioteRefs,
			currentRefs,
		},
	}): TRegData);
};

const useRegSymbiote = () => {
	const [refs, setRefs] = React.useState<?TRegData>(null);

	const isMount = React.useRef(true);
	React.useEffect(() => {
		isMount.current = true;
		if (commitTimer) {
			clearTimeout(commitTimer);
			commitTimer = null;
		}
		regPool = [];
		return () => {
			isMount.current = false;
			regPool = [];
			if (commitTimer) {
				clearTimeout(commitTimer);
				commitTimer = null;
			}
		};
	}, []);

	const commitSymbiote = React.useCallback(() => {
		const currentPool = regPool;
		regPool = [];
		setRefs(refsList => {
			regData = refsList;
			currentPool.forEach(item => {
				if (item.kind === 'reg') {
					performReg(item.args);
				} else if (item.kind === 'unreg') {
					performUnreg(item.args);
				}
			});
			const nextRefs = regData;
			regData = null;
			commitTimer = null;
			return nextRefs;
		});
	}, [setRefs]);

	const regSymbiote = React.useCallback(
		(args: TMethodRegSymbioteArg) => {
			if (!isMount.current) return;

			pushAction({ kind: 'reg', args });
			if (commitTimer) {
				clearTimeout(commitTimer);
			}
			if (regPool.length > POOL_THRESHOLD) {
				commitSymbiote();
				return;
			}
			commitTimer = setTimeout(commitSymbiote, POOL_DEBOUNCE * regPool.length);
		},
		[commitSymbiote],
	);

	const unregSymbiote = React.useCallback(
		(args: TMethodUnregSymbioteArg) => {
			if (!isMount.current) return;

			pushAction({ kind: 'unreg', args });
			if (commitTimer) {
				clearTimeout(commitTimer);
			}
			commitTimer = setTimeout(commitSymbiote, POOL_DEBOUNCE * regPool.length);
		},
		[commitSymbiote],
	);

	return { refs, regSymbiote, unregSymbiote };
};

export default useRegSymbiote;
