// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsoluteDropPlace } from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';

import withSymbiote from 'Editor/libs/with-symbiote';

import withDrag from 'Widget/libs/dnd/drag';
import withDragReorder from 'Widget/libs/dnd/dragReorder';
import withUnedit from 'Widget/libs/with-unedit';
import withRef from 'Widget/libs/with-ref';

import Block from './Block';
import withOverrider from './libs/with-overrider';
import withControls from './libs/with-controls';
import withResize from './libs/with-resize';
import withGridHighlight from './libs/with-grid-highlight';
import withResizeCol from './libs/with-resize-col';
import withFilter from './libs/with-filter';
import withThemeProvider from './libs/with-theme-provider';
import withWrapper from './libs/with-wrapper';
import withContainer from './libs/with-container';
import mapStateToProps from './libs/mapStateToPropsEdit';
import mapDispatchToProps from './libs/mapDispatchToProps';
import type {
	TConnectEditProps,
	TWrapperProviderPropsEditor,
	TThemeProviderPropsEditor,
} from './constants/types';

import withDropPlace from './libs/with-drop-place';

const BlockEdit = compose(
	// берём данные из стейта
	connect<TConnectEditProps, TWidgetOwnProps, _, _, _, _>(
		mapStateToProps,
		mapDispatchToProps,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectEditProps>(Component),
	// позволяет выходить из виджета по клику в пустое место блока
	Component => withUnedit<TConnectEditProps>(Component),
	// Дроп плейс для пустого блока
	withSymbiote(withDropPlace, {
		level: 'grid-drag-place',
	}),
	// меняет порядок колонок в блоке при драге
	withDragReorder,
	// добавляет драг и флип блоку
	withDrag,
	// добавляет в пропсы функциии setData и setGridHighlight
	// ToDo использовать глобальную функцию
	withOverrider,
	// добаляет првайдер темы, в основном для правильных брейкпоинтов
	Component => withThemeProvider<TThemeProviderPropsEditor>(Component),
	// ...
	withSymbiote(
		// декораторы симбиота
		compose(
			// ресайзы нижненго и верхнего паддинега
			withResize,
			// подстветка сетки
			withGridHighlight,
		),
		{
			level: 'block-padding-resize',
		},
	),
	// ...
	withSymbiote(
		// правый, левый и нижний контрол
		withControls,
	),
	// добавляет возможность ресайзить колонки внутри блока
	withResizeCol,
	// ...
	withSymbiote(
		// добавляет подсвеику дропа абсолтного виджета
		Component =>
			withAbsoluteDropPlace(
				{ name: 'Block', type: 'block', kind: 'absolute' },
				Component,
			),
		{
			level: 'abs-drag-place',
		},
	),
	// добаляет обёртку вокруг блока
	// на эту обёртку много чего завязано, и в том числе реф
	Component => withWrapper<TWrapperProviderPropsEditor>(Component),
	// позволяет выходить из виджета по клику в пустое место блока
	withUnedit,
	// Удаляем лишние пропсы
	withFilter,
	// добаляет контейнер в блок
	withContainer,
)(Block);

export default BlockEdit;
