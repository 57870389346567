// @flow

import type { TImageLibraryListUploads, TStateEditor } from '@graphite/types';
import reSelect from './libs/re-select';

export const getImageLibraryListUploads = reSelect<
	TStateEditor,
	TImageLibraryListUploads,
	TImageLibraryListUploads,
>(
	state => state.imageLibrary || {},
	imageLibrary => imageLibrary,
)(() => 'editor@getImageLibraryListUploads');

export default getImageLibraryListUploads;
