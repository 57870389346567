// @flow
import type {
	TStateEditor,
	TWidgetOwnProps,
	TWidgetBoxBreakpoint,
} from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';
import { composeCached } from 'Widget/selectors';
import { presetWidgets } from '@graphite/constants';

import type { TMinimalConnectProps } from './types';

const dirDefaults = {
	stack: 'horizontal',
};

const getMapStateToProps = (
	// FixMe: тут должен быть TPublishedState
	{ widgets }: TStateEditor,
	{
		id,
		containerId: containerInitialId,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
	}: TWidgetOwnProps,
) => {
	const containerId = containerInitialId || 'none';
	const parentWidget = widgets[containerId];
	const parent: $PropertyType<TMinimalConnectProps, 'data'> | null = parentWidget
		? composeCached(widgets, widgets[containerId])
		: null;

	const parentBox: ?TWidgetBoxBreakpoint = parent
		? closestDeviceWithKey(parent.box, {
				currentDevice: 'desktop',
				key: `box-${parent._id || 'null'}`,
		  })
		: null;
	const direction =
		parentBox?.flexDirection === 'row'
			? 'horizontal'
			: (parent && dirDefaults[parent.kind]) || 'vertical';
	const data: $PropertyType<TMinimalConnectProps, 'data'> = composeCached(
		widgets,
		widgets[id] || presetWidgets[id],
	);
	const positions = parent ? parent.positions : { [id]: 'absolute' };

	return {
		data,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
		position: positions[id],
		direction,
	};
};

export default getMapStateToProps;
