// @flow

import React from 'react';
import emptyObject from 'empty/object';
import { merge, set, unset } from 'lodash/fp';
import { Global } from '@emotion/core';

import logger from 'libs/logger';
import FrameContext from 'Editor/Frame/Context';
import useCurrentDevice from 'Editor/libs/use-current-device';
import useDefaultDevice from 'Editor/libs/use-default-device';
import { ContextSetActiveLevel } from 'Editor/libs/with-symbiote/Provider/Context';

import { initDrag } from './dndHandlers';
import { getRects, findOver, setPosition, dropWidget } from './lib';
import setDragImage from './dragImage';
import globalStyle from './lib/globalStyle';
import type {
	TDNDContext,
	TDNDMethodStartDrag,
	TDNDMethodEndDrag,
	TDNDMethodDrop,
	TDNDReducer,
	TDNDProviderProps,
	TDNDProvider,
	TDNDStore,
} from './constants/types';

let startTimer: ?TimeoutID = null;

const initialState: TDNDContext = {
	widgets: {},
	dropPlaces: {},
	dropPlace: null,
	dragPlace: null,
	stackPlace: null,
	dragId: null,
	overNode: null,
	overId: null,
	lastOverId: null,
	isFliping: false,
	srcContainerId: null,
	dragInitStyles: null,
};

const reset = {
	dropPlace: null,
	dragPlace: null,
	stackPlace: null,
	dragId: null,
	overNode: null,
	overId: null,
	lastOverId: null,
	isFliping: false,
	srcContainerId: null,
	dragInitStyles: null,
};

const DNDContext = React.createContext<TDNDStore>([initialState, () => {}]);

const startDrag: TDNDMethodStartDrag = (state, payload, elBox) => {
	const { event = null } = payload || emptyObject;

	// Hide symbiote in the next tick
	clearTimeout(startTimer);
	startTimer = setTimeout(() => {
		if (document.body) {
			document.body.classList.add('isGridDragging');
		}
	}, 0);

	if (!event || !(event.target instanceof HTMLElement)) return state;

	const widgetId = event.target.getAttribute('data-drag-id');
	const widget = widgetId ? state.widgets[widgetId] : null;

	if (!widget || !widgetId) return state;

	if (event.dataTransfer) {
		event.dataTransfer.effectAllowed = 'copyMove';
		event.dataTransfer.setData('text/plain', widgetId);
	}

	setDragImage(event, widget.ref.current, elBox.current);

	const rects = getRects(widget);
	const margin =
		rects && rects.margin
			? `
		${rects.margin.top}px
		${rects.margin.right}px
		${rects.margin.bottom}px
		${rects.margin.left}px
	`
			: null;

	return merge(state, {
		...reset,
		dragId: widgetId,
		dragInitStyles: {
			width: rects?.rect?.width || null,
			height: rects?.rect?.height || null,
			margin,
		},
		srcContainerId: widget.containerId,
	});
};

const endDrag: TDNDMethodEndDrag = state => {
	clearTimeout(startTimer);
	if (document.body) {
		document.body.classList.remove('isGridDragging');
	}

	return merge(state, reset);
};

const drop: TDNDMethodDrop = (state, rootDispatch, currentDevice) => {
	const wasSagaCalled = !!dropWidget(state, rootDispatch, currentDevice);
	if (!wasSagaCalled) return endDrag(state);
	return state;
};

const reducerConstructor: TDNDReducer = (
	rootDispatch,
	currentDevice,
	defaultDevice,
	elBox,
	setActiveLevel,
) => (state, action) => {
	const { type, payload } = action;
	const { placeId = null, composeId = null } = payload || emptyObject;

	switch (type) {
		case 'regWidget':
			return composeId ? set(`widgets.${composeId}`, payload, state) : state;
		case 'unregWidget':
			return composeId ? unset(`widgets.${composeId}`, state) : state;
		case 'regDrop':
			return placeId ? set(`dropPlaces.${placeId}`, payload, state) : state;
		case 'unregDrop':
			return placeId ? unset(`dropPlaces.${placeId}`, state) : state;
		case 'startDrag':
			return startDrag(state, payload, elBox);
		case 'endDrag':
			setActiveLevel(null);
			return endDrag(state);
		case 'drop':
			setActiveLevel(null);
			return drop(state, rootDispatch, currentDevice);
		case 'setPosition':
			return setPosition(state, payload, currentDevice === defaultDevice);
		case 'setOver':
			setActiveLevel('grid-drag-place');
			return findOver(state, payload);
		case 'startFlip':
			return set('isFliping', true, state);
		case 'stopFlip':
			return set('isFliping', false, state);
		default:
			logger.error('dnd action is not valid', action);
			return state;
	}
};

export const DNDProvider: TDNDProvider = ({
	children,
	dispatch,
	widgets,
}: TDNDProviderProps) => {
	const setActiveLevel = React.useContext(ContextSetActiveLevel);
	const { elBox } = React.useContext(FrameContext);
	const currentDevice = useCurrentDevice();
	const defaultDevice = useDefaultDevice();
	const isDefaultDevice = currentDevice === defaultDevice;
	const reducer = React.useMemo(
		() =>
			reducerConstructor(
				dispatch,
				currentDevice,
				defaultDevice,
				elBox,
				setActiveLevel,
			),
		[dispatch, currentDevice, defaultDevice, elBox, setActiveLevel],
	);

	const store: TDNDStore = React.useReducer(reducer, initialState);
	const { dragPlace, dropPlace, stackPlace, dragId, overId } = store[0] || {};
	const [, storeDispatch] = store;

	React.useEffect(() => initDrag(storeDispatch, !isDefaultDevice), [
		storeDispatch,
		isDefaultDevice,
	]);

	React.useEffect(
		() => {
			if (!dragId) return;
			storeDispatch({ type: 'endDrag' });
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[widgets],
	);

	const value = React.useMemo(
		() => store,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dragPlace, dropPlace, stackPlace, dragId, overId],
	);

	return (
		<DNDContext.Provider value={value}>
			<Global styles={globalStyle} />
			{children}
		</DNDContext.Provider>
	);
};

export default DNDContext;
