// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import type {
	TSx,
	TDesign,
	TSpecsColor,
	TSpecsWidget,
	TSpecsEffect,
	TSpecsGrid,
} from '@graphite/types';
import { getDesignSx } from '@graphite/selectors';

import type { TWidgetImage } from './constants/types';

const getObjectFit = ({ cropMode }) => {
	if (cropMode === 'fill') return 'cover';
	if (cropMode === 'fit') return 'contain';
	return 'inherit';
};

const getSize = ({ cropMode }) => {
	if (cropMode === 'fill') return '100%';
	if (cropMode === 'fit') return '100%';
	return 'auto';
};

const getTransform = ({ cropMode, crop: { x, y } = {}, zoom, rotation }) => {
	if (cropMode === 'fill' || cropMode === 'fit') return `rotate(${rotation || 0}deg)`;

	return `translate(${x || 0}px, ${y || 0}px) rotate(${rotation ||
		0}deg) scale(${zoom || 1})`;
};

const getObjectPosition = ({ cropMode, objectPosition }) => {
	if (cropMode === 'fill' || cropMode === 'fit') {
		return objectPosition || 'inherit';
	}
	return 'inherit';
};

const getFilter = ({ brightness, contrast, saturation, grayscale, hue, blur }) =>
	[
		`brightness(${brightness || 100}%)`,
		`contrast(${contrast || 100}%)`,
		`saturate(${saturation || 100}%)`,
		`grayscale(${grayscale || 0}%)`,
		`hue-rotate(${hue || 0}deg)`,
		`blur(${blur || 0}px)`,
	].join(' ');

type TProps = $ReadOnly<{|
	data: TWidgetImage,
	widgetspec: TSpecsWidget,
	gridspec: TSpecsGrid,
	colorspec: TSpecsColor,
	effectspec: TSpecsEffect,
|}>;

const Element = (props: TProps) => {
	const { colorspec, effectspec, widgetspec, gridspec, data } = props;
	const { src = '', alt = '', title = '' } = data;

	const baseSx: TSx = React.useMemo(
		() => ({
			maxWidth: '100%',
			maxHeight: '100%',
			objectFit: getObjectFit(data),
			height: getSize(data),
			width: getSize(data),
			margin: 'auto',
			position: 'absolute',
			top: '0',
			bottom: '0',
			left: '0',
			right: '0',
			willChange: 'transform',
			filter: getFilter(data),
			transform: getTransform(data),
			objectPosition: getObjectPosition(data),
		}),
		[data],
	);

	const sx: TSx = React.useMemo(() => {
		if (!data.designId) {
			return baseSx;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			(custom && custom.target === 'image' ? custom : null) ||
			widgetspec.image.find(d => d._id === data.designId);

		if (!design) {
			return baseSx;
		}

		const { boxShadow, ...restSx } = getDesignSx({
			design,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
		});

		return _.assign(baseSx, restSx);
	}, [
		baseSx,
		data.designId,
		data.designs,
		widgetspec,
		colorspec,
		effectspec,
		gridspec,
	]);

	return <Box as="img" sx={sx} src={src} alt={alt} title={title} />;
};

export default React.memo<TProps>(Element);
