// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import logger from 'libs/logger';
import reSelect from 'libs/re-select';
import type {
	TSx,
	TId,
	TWidget,
	TSpecsGrid,
	TSpecsGridBreakpoint,
} from '@graphite/types';

import {
	closestDeviceWithKey,
	getActiveBreakpointNames,
	maxCombineSx,
} from '@graphite/selectors';

export type TGetRowBreakpointSxParams = $ReadOnly<{|
	breakpoint: TSpecsGridBreakpoint,
	unit: number,
|}>;

export type TGetRowBreakpointSxFn = TGetRowBreakpointSxParams => TSx;

// Для стилизации текста по спеке
export const getRowBreakpointSx: TGetRowBreakpointSxFn = reSelect<
	TGetRowBreakpointSxParams,
	TSpecsGridBreakpoint,
	number,
	TSx,
>(
	({ breakpoint }) => breakpoint,
	({ unit }) => unit,
	(breakpoint, unit) => {
		try {
			const { gutter, gutterUnit } = breakpoint;
			const customSx = {
				width: 'auto',
				flexGrow: 1,
				margin: '0',
			};

			if (gutter && gutterUnit === 'px') {
				customSx.margin = `0 ${-(gutter / 2)}px`;
			}
			if (gutter && gutterUnit === 'unit') {
				customSx.margin = `0 ${-((gutter * unit) / 2)}px`;
			}

			return customSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ breakpoint, unit }) =>
	['widget@getRowBreakpointSx', _.size(breakpoint), unit].join('-'),
);

type TetRowSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
|}>;

type TetRowSx = TetRowSxParams => TSx;

// Стилизация всех виджетов по спекам, с учётом адаптивности для активных девайсов
export const getRowSx: TetRowSx = reSelect<TetRowSxParams, TId, TSpecsGrid, TSx>(
	({ data }) => data._id,
	({ gridspec }) => gridspec,
	(_id, gridspec) => {
		try {
			const breakpointNames = getActiveBreakpointNames({ gridspec });

			const finalSx = maxCombineSx({
				sxList: _.map(currentDevice => {
					const breakpoint = closestDeviceWithKey(gridspec.breakpoints, {
						currentDevice,
						key: `breakpoint-${gridspec._id}`,
					});

					const breakpointSx = getRowBreakpointSx({
						breakpoint,
						unit: gridspec.unit,
					});
					return breakpointSx;
				}, breakpointNames),
				gridspec,
			});

			return finalSx;
		} catch (e) {
			logger.error(e);
			return (emptyObject: TSx);
		}
	},
)(({ data, gridspec }) => ['widget@getRowSx', data._id, gridspec._id].join('-'));
