// @flow
import React from 'react';

import type { TConnectProps } from './constants/types';

const Code = (props: TConnectProps, ref) => {
	const { data } = props;

	return (
		<div
			ref={ref}
			dangerouslySetInnerHTML={{
				__html: data.code,
			}}
		/>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Code));
