// @flow
import type { TDNDMethodGetRects } from '../constants/types';

export const getPropValue = (
	style: CSSStyleDeclaration,
	prop: 'margin' | 'padding' | 'border',
) => {
	const suffix = prop === 'border' ? '-width' : '';
	const top = style.getPropertyValue(`${prop}-top${suffix}`);
	const right = style.getPropertyValue(`${prop}-right${suffix}`);
	const bottom = style.getPropertyValue(`${prop}-bottom${suffix}`);
	const left = style.getPropertyValue(`${prop}-left${suffix}`);

	return {
		top: parseInt(top, 10) || 0,
		right: parseInt(right, 10) || 0,
		bottom: parseInt(bottom, 10) || 0,
		left: parseInt(left, 10) || 0,
	};
};

export const getRects: TDNDMethodGetRects = widget => {
	const node = widget?.ref?.current ?? null;
	if (!node) return null;

	const rect: DOMRectReadOnly = node.getBoundingClientRect();
	const styles = getComputedStyle(node);
	const margin = getPropValue(styles, 'margin');

	const { width, height, top, left } = rect;
	const outer = new DOMRectReadOnly(
		left - margin.left,
		top - margin.top,
		width + margin.left + margin.right,
		height + margin.top + margin.bottom,
	);

	return {
		rect,
		outer,
		margin,
	};
};

export default {};
