// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import { useGoogleFonts } from '@graphite/fonts-lib';
import { getDesignSx } from '@graphite/selectors';
import type {
	TFonts,
	TSpecsWidget,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
	TDesign,
} from '@graphite/types';

import type { TWidgetButton } from '../constants/types';
import getFonts from './getFonts';

type TMinimalProps = $ReadOnly<{
	fonts: TFonts,
	data: TWidgetButton,
	widgetspec: TSpecsWidget,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
}>;

const withFontsEdit = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const Fonts = (props: T, ref) => {
		const { data, widgetspec, colorspec, effectspec, gridspec } = props;

		const custom: ?TDesign =
			(data.designs && data.designId && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.button.find(d => d._id === data.designId);

		const designs = design
			? getDesignSx({
					design,
					widgetspec,
					colorspec,
					effectspec,
					gridspec,
			  })
			: {};

		const fonts = getFonts({ designs, id: data._id });

		useGoogleFonts(fonts, { isFrame: true });

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={ref} />;
	};

	Fonts.displayName = `withFontsEdit(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(Fonts));
};

export default withFontsEdit;
