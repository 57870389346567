// @flow
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import fp from 'lodash/fp';
import type { TWidgetOwnProps } from '@graphite/types';

import { placeWidget } from 'Editor/ducks/widgets';
import withRef from 'Widget/libs/with-ref';
import dndFlipper from 'Widget/libs/dnd/dndFlipper';
import withDragReorder from 'Widget/libs/dnd/dragReorder';

import type { TConnectPropsEdit } from './constants/types';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';
import Page from './Page';
import EmptyContent from './EmptyContent';

type TProps = $ReadOnly<{|
	...$Exact<TConnectPropsEdit>,
|}>;

const PageEdit = (props: TProps, ref: React$Ref<*>) => {
	const {
		data,
		id,
		instanceId,
		containerId,
		babies,
		dispatch,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
		currentDevice,
	} = props;
	const onAddBlock = React.useCallback(() => {
		dispatch(
			placeWidget('block', data._id, null, data._id, {
				kind: 'grid',
				prevId: null,
				nextId: null,
				destRect: null,
				dragRect: null,
				breakpoints: null,
			}),
		);
	}, [dispatch, data]);

	const babyCond = [
		[fp.size, fp.constant<React$Node>(null)],
		[
			fp.stubTrue,
			fp.constant<React$Node>(
				<EmptyContent onAddClick={onAddBlock} currentDevice={currentDevice} />,
			),
		],
	];

	return (
		<Page
			ref={ref}
			id={id}
			data={data}
			instanceId={instanceId}
			containerId={containerId}
			babies={babies}
			dispatch={dispatch}
			widgetChain={widgetChain}
			widgetspec={widgetspec}
			colorspec={colorspec}
			gridspec={gridspec}
			effectspec={effectspec}
		>
			{(fp.cond(babyCond)(babies): React$Node)}
		</Page>
	);
};

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(mapStateToPropsEdit),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	dndFlipper,
	withDragReorder,
)(React.memo<TProps>(React.forwardRef(PageEdit)));

export default WidgetComponentEdit;
