// @flow

import mapValues from 'lodash/fp/mapValues';
import pickBy from 'lodash/fp/pickBy';
import isString from 'lodash/fp/isString';
import type { TId, TPositioned, TPositionValue, TEntityChildren } from '@graphite/types';

export default (
	id: TId,
	positions: ?TPositioned,
	children: $ReadOnly<TEntityChildren> = {},
): TPositioned => {
	const nulls: TPositioned = mapValues(() => null, children);

	return (pickBy(
		(v: TPositionValue, k: TId): boolean => !!children[k],
		pickBy(isString, {
			...nulls,
			...positions,
		}),
	): TPositioned);
};
