// @flow
import _ from 'lodash/fp';
import emptyArray from 'empty/array';
import type { TId, TStateEditor, TWidgetOwnProps, TWidget } from '@graphite/types';

import { composeCached } from 'Widget/selectors';
import { getOrderIndex, getCurrentPageId } from '@graphite/selectors';

import getMapStateToProps from './getMapStateToProps';

const resizableWidgets = ['text', 'code', 'image'];

const getHoverAvailablity = (currentWidget, widgets, widgetChain, myId) => {
	const isParentEdited: boolean = !!widgetChain && widgetChain[0] === currentWidget?.id;
	const parentKind = widgetChain[0] && widgets[widgetChain[0]].kind;
	const excludesParentTypes = ['col', 'block'];
	const isSiblingsEditing =
		currentWidget &&
		currentWidget.widgetChain.includes(widgetChain[0]) &&
		!currentWidget.widgetChain.includes(myId);
	/* Мой ховер активен в случаях:
        1. я нахожусь непосредственно в блоке/коле или редактируется мой прямой родитель
        2. если редактируется кто-то из других детей нашего общего родителя
	*/
	return !!(
		excludesParentTypes.includes(parentKind) ||
		isParentEdited ||
		isSiblingsEditing
	);
};

const getDragAvailable = (id, currentWidget, widgets, widgetChain, widgetMode) => {
	const isParentEdited: boolean = !!widgetChain && widgetChain[0] === currentWidget?.id;
	const isResized: boolean = widgetMode === 'widget-resize';
	const parentKind = widgetChain[0] && widgets[widgetChain[0]].kind;
	const excludesParentTypes = ['col', 'block'];
	/*
		Драгать можно если:
		- виджет лежит в колонке или блоке
		- виджет лежит в стеке, который сейчас редактируется
		- виджет находится в режиме ресайза
		- виджет находится не в режиме редактирования
		- другой виджет не находится в режиме редактирования (как в фигме)
	*/

	if (
		currentWidget?.id &&
		currentWidget?.controls &&
		id !== currentWidget?.id &&
		currentWidget?.controls === 'widget-edit'
	)
		return false;

	return (
		excludesParentTypes.includes(parentKind) ||
		(parentKind === 'stack' && isParentEdited) ||
		isResized
	);
};

const getWidgetMode = _.cond([
	[
		(currentWidget, propsPublised) =>
			!currentWidget || currentWidget.widgetChain.includes(propsPublised.data._id),
		_.constant<'none'>('none'),
	],
	[
		({ id, controls }, propsPublised) => id !== propsPublised.data._id || !controls,
		_.constant<'none'>('none'),
	],
	[
		({ controls }, propsPublised) =>
			!resizableWidgets.includes(propsPublised.data.kind) &&
			controls === 'widget-edit',
		_.constant<'widget-resize'>('widget-resize'),
	],
	[_.stubTrue, ({ controls }) => controls],
]);

const getMapStateToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const {
		editor: { currentWidget, currentDevice },
		widgets,
	} = state;
	const currentPage = getCurrentPageId(state);

	// Если нет текущей страницы, то лучше не едлать вид, что мы сможем это отрендерить.
	// Пусть уже падает с ошибкой, ибо произошла какая-то дичь
	// FixMe: Подумать как обработать эту ошибку
	if (!currentPage) throw new Error('originId not found');

	const { id, containerId } = ownProps;
	const { widgetChain = emptyArray, ...ownPropsPublished } = ownProps;

	const parent: TWidget = composeCached(widgets, widgets[containerId || 'none']);
	const propsPublised = getMapStateToProps(state, ownPropsPublished);

	const widgetMode = getWidgetMode(currentWidget, propsPublised);

	const justifyContent = _.get('justifyContent', parent) || 'space-evenly';
	const alignItems = _.get('alignItems', parent) || 'stretch';

	const hoverAvailable = getHoverAvailablity(currentWidget, widgets, widgetChain, id);

	let editId = id;
	let editChain = widgetChain;
	let tmpChain = editChain;
	for (const wId of editChain) {
		if (id === currentWidget?.id) {
			break;
		}

		if (wId === currentWidget?.id || !wId) {
			break;
		}

		tmpChain = (tmpChain.slice(1): $ReadOnlyArray<TId>);
		if (widgets[wId].kind === 'stack') {
			editId = wId;
			editChain = tmpChain;
		}
	}

	const widgetOrderIndex = getOrderIndex({
		id,
		currentPage,
		widgets,
		currentDevice,
	});

	return {
		...propsPublised,
		widgetMode,
		originId: currentPage,
		currentDevice,
		justifyContent,
		alignItems,
		hoverAvailable,
		editId,
		editChain,
		clickAvailable: true,
		dragAvailable: getDragAvailable(
			id,
			currentWidget,
			widgets,
			widgetChain,
			widgetMode,
		),
		widgetOrderIndex,
	};
};

export default getMapStateToPropsEdit;
