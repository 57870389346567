// @flow
import React from 'react';
import { Section } from '@graphite/uneon';
import { Params as ListParams } from '@graphite/lists';

import type { TWidget } from '@graphite/types';

type TProps = $ReadOnly<{|
	data: TWidget,
	save: (string, string | Object) => void,
|}>;

const paramsShowOnly = [
	{
		title: 'Show',
		key: 'isShown',
		kind: 'switch',
		info: {},
	},
];

const paramLabel = {
	title: 'Label',
	key: 'label',
	kind: 'string',
	info: { maxLength: 140 },
};

const paramsIcon = [
	{
		title: 'Name',
		key: 'name',
		kind: 'string',
		info: { maxLength: 140 },
	},
	{
		title: 'Position',
		key: 'position',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'left', label: 'Left' },
					{ name: 'right', label: 'Right' },
				],
			},
		},
	},
];

const Settings = ({ save, data }: TProps) => {
	/**
		Text
	 */
	const paramListText = React.useMemo(() => {
		if (!data.text || !data.text.isShown) {
			return paramsShowOnly;
		}
		return [...paramsShowOnly, paramLabel];
	}, [data.text]);

	const paramSourceText = React.useMemo(
		() => ({
			isShown: data.text && data.text.isShown ? 'true' : '',
			label: (data.text && data.text.label) || '',
		}),
		[data.text],
	);

	const changeParamText = React.useCallback(
		(key, value) => {
			save('text', {
				...data.text,
				[key]: value,
			});
		},
		[save, data.text],
	);

	/**
		Icon
	 */
	const paramListIcon = React.useMemo(() => {
		if (!data.icon || !data.icon.isShown) {
			return paramsShowOnly;
		}
		return [...paramsShowOnly, ...paramsIcon];
	}, [data.icon]);

	const paramSourceIcon = React.useMemo(
		() => ({
			isShown: data.icon && data.icon.isShown ? 'true' : '',
			name: (data.icon && data.icon.name) || '',
			position: (data.icon && data.icon.position) || 'left',
		}),
		[data.icon],
	);

	const changeParamIcon = React.useCallback(
		(key, value) => save('icon', { ...data.icon, [key]: value }),
		[save, data.icon],
	);

	return (
		<>
			<Section label="Text">
				<ListParams
					listName="text"
					paramSource={paramSourceText}
					paramList={paramListText}
					unit={1}
					onChange={changeParamText}
				/>
			</Section>
			<Section label="Icon">
				<ListParams
					listName="icon"
					paramSource={paramSourceIcon}
					paramList={paramListIcon}
					unit={1}
					onChange={changeParamIcon}
				/>
			</Section>
		</>
	);
};

export default React.memo<TProps>(Settings);
