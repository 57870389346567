// @flow

import React from 'react';
import type { Dispatch } from 'redux';
import getDisplayName from '@graphite/get-display-name';
import { resetEdit } from 'Editor/ducks/editor';
import type { TAction } from '@graphite/types';

type TRequiredProps = $ReadOnly<{
	dispatch: Dispatch<TAction>,
}>;

/**
 * Добавляет выход из редактирования по клику
 */
const withUnedit = <TProps: TRequiredProps>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const Hoc = (props: TProps, ref) => {
		const { dispatch } = props;
		const currentRef = React.useRef(ref);
		React.useImperativeHandle(ref, () => currentRef.current);
		const clickHandler = React.useCallback(
			(e: MouseEvent, isForced: ?boolean = false) => {
				if (isForced || e.target === currentRef.current) {
					e.stopPropagation();
					dispatch(resetEdit());
				}
			},
			[dispatch],
		);

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={currentRef} onClick={clickHandler} />;
	};
	Hoc.displayName = `withUnedit(${getDisplayName(Component)})`;
	return React.memo<TProps>(React.forwardRef(Hoc));
};

export default withUnedit;
