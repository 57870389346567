// @flow

import emptyObject from 'empty/object';
import _ from 'lodash/fp';
import type { TSx } from '@graphite/types';
import type { TStyleParams } from './types';

export default ({ propName, box, dir }: TStyleParams): TSx => {
	if (!dir)
		return {
			style: emptyObject,
		};

	if (propName === 'margin' && box.margin) {
		const margin = _.assign(
			{
				left: 0,
				right: 0,
				top: 0,
				bottom: 0,
			},
			box.margin,
		);

		return {
			style: {
				marginTop: `${margin.top}px`,
				marginRight: `${margin.right}px`,
				marginBottom: `${margin.bottom}px`,
				marginLeft: `${margin.left}px`,
			},
		};
	}

	if (propName === 'sizes') {
		const { height, width, heightUnit = 'px', widthUnit = 'px' } = box;
		const style = {};

		if (height && (dir.includes('bottom') || dir.includes('top'))) {
			style.height = `${height}${heightUnit}`;
		}

		if (width && (dir.includes('left') || dir.includes('right'))) {
			style.width = `${width}${widthUnit}`;
			style.flexGrow = 0; // Если у нас есть размер, то это свойство с значением 1 может его перебивать
		}

		return { style };
	}

	if (propName === 'offset') {
		const style = {};
		const { offset } = box;

		if (offset?.height !== undefined) {
			style.absHeight = `${offset?.height || 0}px`;
		}
		if (offset?.width !== undefined) {
			style.absWidth = `${offset?.width || 0}px`;
		}

		if (offset?.top !== undefined) {
			style.absTop = `${offset?.top}px`;
		}
		if (offset?.right !== undefined) {
			style.absRight = `${offset?.right}px`;
		}
		if (offset?.bottom !== undefined) {
			style.absBottom = `${offset?.bottom}px`;
		}
		if (offset?.left !== undefined) {
			style.absLeft = `${offset?.left}px`;
		}

		return { style };
	}

	return {
		style: emptyObject,
	};
};
