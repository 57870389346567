// @flow
import _ from 'lodash/fp';
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';

import { getActiveColAmount } from 'Editor/selectors/site';

import {
	getActiveGutter,
	getWidgetsByKind,
	getImageLibraryListUploads,
	getCurrentSiteId,
} from '@graphite/selectors';

import mapStateToPropsPublished from './mapStateToProps';

import type { TPropControls, TConnectProps } from '../constants/types';

type TGetControlsArgs = {
	editor: $PropertyType<TStateEditor, 'editor'>,
	data: $PropertyType<TConnectProps, 'data'>,
};
type TGetControls = TGetControlsArgs => TPropControls;

const getControls: TGetControls = _.cond([
	[
		// Если какой-то виджет редактируется, не обязательно внутри этого блока
		({ editor }: TGetControlsArgs) => {
			return editor && editor.currentWidget;
		},

		// то никаких контроллов
		_.constant<'none'>('none'),
	],
	[
		// если блок — инстанс
		({ data }: TGetControlsArgs) =>
			data && typeof data.modified === 'object' && Array.isArray(data.modified),
		_.constant<'instance'>('instance'),
	],
	[
		// иначе показываем обычные контролы
		_.stubTrue,
		_.constant<'widget'>('widget'),
	],
]);

const mapStateToProps = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const { editor } = state;

	const mapStateToPropsFromPublished = mapStateToPropsPublished(state, ownProps);
	const { data } = mapStateToPropsFromPublished;
	const siteId = getCurrentSiteId(state);

	const { gridspec } = mapStateToPropsFromPublished;

	return {
		...mapStateToPropsFromPublished,
		currentDevice: editor.currentDevice,
		colAmount: gridspec
			? getActiveColAmount({
					currentDevice: editor.currentDevice,
					gridspec,
			  })
			: 0,
		gutter: gridspec
			? getActiveGutter({
					gridspec,
			  })
			: [],
		gridHighlight: editor.currentGridHighlight,
		controls: getControls({ editor, data }),
		images: getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
		uploads: getImageLibraryListUploads(state),
	};
};

export default mapStateToProps;
