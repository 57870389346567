// @flow

import React from 'react';
import { Flipper } from 'react-flip-toolkit';
import getDisplayName from '@graphite/get-display-name';

import dndContext from './dndContext';
import type { TDNDFlipData } from './constants/types';

const STAGGER_CONFIG = { speed: 0.1 };

export default (Component: React$ComponentType<*>) => {
	let flipKey = 0;

	const FlipperHOC = (props: *, ref) => {
		const [state] = React.useContext(dndContext);
		const { dragId, dragPlace, widgets, srcContainerId } = state;
		const { composeId, position } = dragPlace || {};

		const srcWidget = dragId ? widgets[dragId] : null;
		const dstWidget = composeId ? widgets[composeId] : null;

		const srcWidgetId = srcWidget?.widgetId || null;
		const dstWidgetId = dstWidget?.widgetId || null;

		const dstContainerId = dstWidget?.containerId || null;

		if (srcWidget) flipKey++;

		return React.useMemo(() => {
			const decisionData: TDNDFlipData = {
				srcContainerId,
				dstContainerId,
				srcWidgetId,
				dstWidgetId,
				position,
			};

			return (
				<Flipper
					flipKey={flipKey}
					decisionData={decisionData}
					staggerConfig={STAGGER_CONFIG}
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...props} ref={ref} />
				</Flipper>
			);
		}, [
			srcContainerId,
			dstContainerId,
			srcWidgetId,
			dstWidgetId,
			position,
			props,
			ref,
		]);
	};

	FlipperHOC.displayName = `Flipper(${getDisplayName(Component)})`;
	return React.memo<*>(React.forwardRef(FlipperHOC));
};
