// @flow
import _ from 'lodash/fp';
import type {
	TId,
	TPublishedState,
	TSpecs,
	TSpec,
	TSpecsWidget,
	TSpecsGrid,
	TSpecsGridBreakpoints,
	TSite,
	TGridBreakpointNames,
	TStateEditor,
	TSpecsColor,
	TSpecsEffect,
	TSpecsGridBreakpointList,
	TWidgetTheme,
} from '@graphite/types';
import { gridBreakpointsNames } from '@graphite/constants';

import reSelect from './libs/re-select';
import { getCurrentSite } from './router';

const HALF_PERCENT_FACTOR = 0.005;

export const getSiteBreakpoints = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGrid,
	TSpecsGridBreakpoints,
>(
	({ gridspec }) => gridspec,
	gridspec => gridspec.breakpoints,
)(() => 'getBreakpoints');

export const getActiveBreakpointNames = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpoints,
	TGridBreakpointNames,
>(
	getSiteBreakpoints,
	breakpoints =>
		(_.filter(
			name => breakpoints[name] && breakpoints[name].active,
			gridBreakpointsNames,
		): TGridBreakpointNames),
)(({ gridspec }) => `getActiveBreakpointNames-${gridspec._id}`);

type TGetSpecs = TPublishedState => TSpecs;
export const getSpecs: TGetSpecs = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(specs: TSpecs): TSpecs => specs,
)(() => 'specs@getSpecs');

export const getSpec = reSelect<
	TPublishedState,
	$ReadOnly<{| id: TId |}>,
	TSpecs,
	TId,
	?TSpec,
>(
	(state: TPublishedState): TSpecs => state.specs,
	(state, { id }): TId => id,
	(specs, id) => specs[id],
)((state, { id }) => `specs@getSpec-${id}`);

type TGetSpecsById = (TPublishedState, { ids: $ReadOnlyArray<TId> }) => ?TSpecs;
export const getSpecByIds: TGetSpecsById = reSelect(
	(state: TPublishedState): TSpecs => state.specs,
	(state, { ids }: { ids: $ReadOnlyArray<TId> }) => ids,
	(specs, ids) => _.pickBy(spec => ids.includes(spec._id), specs),
)((state, { ids }) => `specs@getSpec-${ids.join('-')}`);

export const getCurrentSiteWidgetSpec = reSelect<
	TStateEditor,
	TSpecs,
	?TSite,
	?TSpecsWidget,
>(
	(state: TPublishedState): TSpecs => state.specs,
	getCurrentSite,
	(specs, currentSite) => {
		if (!currentSite) {
			return null;
		}

		const { widgetspecId } = currentSite;

		if (
			!widgetspecId ||
			!specs[widgetspecId] ||
			specs[widgetspecId].kind !== 'widgetspec'
		) {
			return null;
		}

		return specs[widgetspecId];
	},
)(() => 'specs@getCurrentSiteWidgetSpec');

export const getCurrentSiteGridSpec = reSelect<TStateEditor, TSpecs, ?TSite, ?TSpecsGrid>(
	(state: TPublishedState): TSpecs => state.specs,
	getCurrentSite,
	(specs, currentSite) => {
		if (!currentSite) {
			return null;
		}

		const { gridspecId } = currentSite;

		if (!gridspecId || !specs[gridspecId] || specs[gridspecId].kind !== 'gridspec') {
			return null;
		}

		return specs[gridspecId];
	},
)(() => 'specs@getCurrentSiteGridSpec');

export const getCurrentSiteBreakpoints = reSelect<
	TStateEditor,
	?TSpecsGrid,
	?TSpecsGridBreakpoints,
>(
	getCurrentSiteGridSpec,
	gridSpec => gridSpec?.breakpoints ?? null,
)(() => 'specs@getBreakpoints');

export const getCurrentSiteColorSpec = reSelect<
	TStateEditor,
	TSpecs,
	?TSite,
	?TSpecsColor,
>(
	(state: TPublishedState): TSpecs => state.specs,
	getCurrentSite,
	(specs, currentSite) => {
		if (!currentSite) {
			return null;
		}

		const { colorspecId } = currentSite;

		if (
			!colorspecId ||
			!specs[colorspecId] ||
			specs[colorspecId].kind !== 'colorspec'
		) {
			return null;
		}

		return specs[colorspecId];
	},
)(() => 'specs@getCurrentSiteColorSpec');

export const getCurrentSiteEffectSpec = reSelect<
	TStateEditor,
	TSpecs,
	?TSite,
	?TSpecsEffect,
>(
	(state: TPublishedState): TSpecs => state.specs,
	getCurrentSite,
	(specs, currentSite) => {
		if (!currentSite) {
			return null;
		}

		const { effectspecId } = currentSite;

		if (
			!effectspecId ||
			!specs[effectspecId] ||
			specs[effectspecId].kind !== 'effectspec'
		) {
			return null;
		}

		return specs[effectspecId];
	},
)(() => 'specs@getCurrentSiteEffectSpec');

export const getUnit = reSelect<TSpecsGrid, TSpecsGrid, number>(
	gridspec => gridspec,
	gridspec => gridspec.unit,
)(gridspec => `site@getUnit-${gridspec._id}`);

export const getActiveBreakpointList = reSelect<
	{| gridspec: TSpecsGrid |},
	TSpecsGridBreakpoints,
	TGridBreakpointNames,
	TSpecsGridBreakpointList,
>(getSiteBreakpoints, getActiveBreakpointNames, (breakpoints, names) =>
	_.map(name => breakpoints[name], names),
)(({ gridspec }) => `getActiveBreakpointList-${gridspec._id}`);

export const getActiveGutter = reSelect<
	{| gridspec: TSpecsGrid |},
	number,
	TSpecsGridBreakpointList,
	$ReadOnlyArray<number>,
>(
	({ gridspec }) => getUnit(gridspec),
	({ gridspec }) => getActiveBreakpointList({ gridspec }),
	(unit, activeBreakpoints) =>
		(_.map(({ gutter, gutterUnit }) => {
			if (gutterUnit === 'px') {
				return gutter / 2;
			}
			if (gutterUnit === 'unit') {
				return (gutter * unit) / 2;
			}
			if (gutterUnit === '%') {
				return gutter * HALF_PERCENT_FACTOR;
			}
			return 0;
		}, activeBreakpoints): $ReadOnlyArray<number>),
)(({ gridspec }) => `getGutters-${gridspec._id}`);

export const getRebassBreakpoints = reSelect<
	TSpecsGrid,
	TSpecsGridBreakpoints,
	TWidgetTheme,
>(
	gridspec => getSiteBreakpoints({ gridspec }),
	breakpoints =>
		({
			breakpoints: gridBreakpointsNames
				.filter(name => breakpoints[name] && breakpoints[name].active)
				.map(name =>
					breakpoints[name] ? `${breakpoints[name].breakpoint}px` : '0px',
				)
				.slice(1),
		}: TWidgetTheme),
)(gridspec => `site@getRebassBreakpoints-${gridspec._id}`);

export default {};
