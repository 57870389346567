// @flow
import React from 'react';
import { Box, Section } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import type { TDesignStyleProps } from '@graphite/types';
import _ from 'lodash/fp';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';

const paramListContainer = [
	{
		title: 'Padding',
		key: 'padding',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'paddingUnit',
		},
	},
	{
		title: 'Radius',
		key: 'radius',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
	{
		title: 'Border',
		key: 'border',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

const paramListIcon = [
	{
		title: 'Size',
		key: 'size',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'sizeUnit',
		},
	},
];

function Style({
	design,
	gridspec,
	effectspec,
	device,
	onChange = null,
}: TDesignStyleProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);

	const paramListContainerFinal = React.useMemo(
		() =>
			_.set(
				[2, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				_.set(
					[1, 'info', 'list', 'items'],
					[
						emptySelection,
						...effectspec.radii.map(radius => ({
							name: radius.id,
							label: radius.name,
						})),
					],
					paramListContainer,
				),
			),
		[effectspec.borders, effectspec.radii],
	);

	return (
		<Box>
			<Section label="Icon">
				<ListParams
					listName={`${device}-i`}
					paramSource={paramSource}
					paramList={paramListIcon}
					unit={gridspec.unit}
					onChange={changeParam}
				/>
			</Section>
			<Section label="Container">
				<ListParams
					listName={`${device}-c`}
					paramSource={paramSource}
					paramList={paramListContainerFinal}
					unit={gridspec.unit}
					onChange={changeParam}
				/>
			</Section>
		</Box>
	);
}

export default React.memo<TDesignStyleProps>(Style);
