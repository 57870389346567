// @flow
import _ from 'lodash/fp';
import type { TId, TWidget, TWidgets, TWidgetComposed } from '@graphite/types';

// Сбор данных виджета по его цепочке прототипов
import { fallbackWidget } from '@graphite/constants';

export default (widgets: TWidgets, widget: TWidget): TWidgetComposed => {
	const { _id }: TWidget = widget;

	let result: TWidget = { ...widget };
	let next: ?TId = result.protoId;

	while (next) {
		const proto: TWidget = widgets[next];

		result = { ...proto, ...result, _id };

		if (proto.children || result.children) {
			result = {
				...result,
				children: _.assign(proto.children, result.children),
			};
		}

		if (proto.designs || result.designs) {
			result = {
				...result,
				designs: _.assign(proto.designs, result.designs),
			};
		}

		next = proto.protoId;
	}

	return _.assign(result, { kind: result.kind || fallbackWidget });
};
