// @flow
import React from 'react';
import { Box, Switch } from '@graphite/uneon';
import type { TSwitchParam } from '@graphite/types';

type TProps = $ReadOnly<{|
	value: string,
	param: TSwitchParam,
	onChange?: ?(boolean) => void,
|}>;

const paramRightStyle = {
	flexGrow: 1,
	width: '50%',
	position: 'relative',
};

function ListItemStringParams({ value, param, onChange }: TProps) {
	const changeParam = React.useCallback(
		() => (onChange && onChange(!value)) || undefined,
		[onChange, value],
	);

	return (
		<Box sx={paramRightStyle}>
			<Switch label={param.info.label} checked={!!value} onClick={changeParam} />
		</Box>
	);
}

ListItemStringParams.defaultProps = {
	onChange: null,
};

export default React.memo<TProps>(ListItemStringParams);
