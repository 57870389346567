// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import type { TWidgetBoxBreakpoint, TMarginDevice } from '@graphite/types';
import { minWidth } from '@graphite/constants';
import { closestDeviceWithKey } from '@graphite/selectors';

import ResizeContext from './resizeReducer';
import getNewBox from './getNewBox';
import getStyle from './getStyle';
import { updateThrottled } from './utils';

import type {
	TUseActionResize,
	TResizeActions,
	TStopDebounceData,
	TComputedSizes,
} from '../types';

const useAction = ({
	refWrap,
	currentDevice,
	requiredControls,
	direction,
}: TUseActionResize): TResizeActions => {
	const [resizeData, dispatch] = React.useContext(ResizeContext);
	const { initialData, dir, maxRange, propName } = resizeData;

	const initialBox: TWidgetBoxBreakpoint = closestDeviceWithKey(initialData.box, {
		currentDevice,
		key: `initialBox-${initialData._id}`,
	});

	const isHeightEnabled = requiredControls?.height;
	const isWidthEnabled = requiredControls?.width;

	const heightUnit: '%' | 'px' = initialBox.heightUnit === '%' ? '%' : 'px';
	const widthUnit: '%' | 'px' = initialBox.widthUnit === '%' ? '%' : 'px';

	const initialHeight = initialBox.height;
	const initialWidth = initialBox.width;

	const computedSizes = React.useRef<TComputedSizes>({});

	const onStart = React.useCallback(
		({ dir, propName }) => {
			const isRectNeeded: boolean =
				propName !== 'margin' &&
				(heightUnit === '%' ||
					widthUnit === '%' ||
					!initialHeight ||
					!initialWidth);

			const isMaxRangeNeeded: boolean =
				propName === 'margin' &&
				refWrap.current &&
				(dir === 'right' || dir === 'left');

			computedSizes.current = {
				refRect: isRectNeeded ? refWrap.current.getBoundingClientRect() : null,
				heightUnit,
				widthUnit,
			};

			let maxRange = null;
			if (isMaxRangeNeeded) {
				const margin: TMarginDevice = initialBox.margin || emptyObject;
				const { width } = refWrap.current.parentNode.getBoundingClientRect();

				if (dir === 'right') {
					maxRange = width - minWidth;

					if (direction === 'horizontal')
						maxRange = width - minWidth - margin.right;
				}
				if (dir === 'left') {
					maxRange = width - minWidth;

					if (direction === 'horizontal')
						maxRange = width - minWidth - margin.left;
				}
			}

			dispatch({
				type: 'start',
				payload: { dir, propName, maxRange, refWrap, resizeInfo: initialBox },
			});
		},
		[
			dispatch,
			refWrap,
			initialBox,
			heightUnit,
			widthUnit,
			initialHeight,
			initialWidth,
			direction,
		],
	);

	const onUpdate = React.useCallback(
		({ x = 0, y = 0 }) => {
			if (!propName || !dir) return;

			const data = getNewBox({
				propName,
				data: {
					dir,
					computed: computedSizes.current,
					initialBox,
					maxRange,
					refWrap,
					x,
					y,
					isHeightEnabled,
					isWidthEnabled,
				},
			});

			const styleData = getStyle({
				propName,
				box: data,
				dir,
			});

			updateThrottled(dispatch, {
				newData: styleData,
				resizeInfo: data,
			});
		},
		[
			dir,
			dispatch,
			initialBox,
			maxRange,
			refWrap,
			propName,
			isHeightEnabled,
			isWidthEnabled,
		],
	);

	const onStop = React.useCallback(
		({ x = 0, y = 0 }) => {
			if (!propName || !dir) return;

			const data = getNewBox({
				propName,
				data: {
					dir,
					computed: computedSizes.current,
					initialBox,
					maxRange,
					refWrap,
					x,
					y,
					isHeightEnabled,
					isWidthEnabled,
				},
			});

			const newData: TStopDebounceData = (_.set(
				`box.${currentDevice}`,
				data,
				initialData,
			): TStopDebounceData);

			dispatch({
				type: 'stop',
				payload: { newData },
			});
		},
		[
			currentDevice,
			initialData,
			dir,
			dispatch,
			maxRange,
			refWrap,
			initialBox,
			propName,
			isHeightEnabled,
			isWidthEnabled,
		],
	);

	return React.useMemo(() => ({ onStart, onUpdate, onStop }), [
		onStart,
		onUpdate,
		onStop,
	]);
};

export default useAction;
