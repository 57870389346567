// @flow
import React, { memo } from 'react';
import type { TDesign, TSpecsGrid, TGridBreakpointName } from '@graphite/types';

import ListItemFonts from './ListItemFonts';

type TProps = $ReadOnly<{|
	active?: ?string,
	device: TGridBreakpointName,
	items: $ReadOnlyArray<TDesign>,
	gridspec: TSpecsGrid,
	onRemove?: ?(id: string) => void,
	onClick?: ?(id: string) => void,
|}>;

function ListFonts({
	active = null,
	items,
	device,
	gridspec,
	onRemove = null,
	onClick = null,
}: TProps) {
	return (
		<>
			{items.map(design => (
				<ListItemFonts
					key={design._id}
					design={design}
					device={device}
					gridspec={gridspec}
					isActive={active === design._id}
					onRemove={onRemove}
					onClick={onClick}
				/>
			))}
		</>
	);
}

export default memo<TProps>(ListFonts);
