// @flow

import type { TWidgets } from '@graphite/types';
import { listForFontsLib } from './font-family-native';

export const presetWidgets: TWidgets = {
	project__REPLACE_WITH_NEW_ID: {
		_id: 'project__REPLACE_WITH_NEW_ID',
		kind: 'project',
		name: 'Project',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		children: {},
		syncedAt: null,
		updatedAt: null,
	},
	site__REPLACE_WITH_NEW_ID: {
		_id: 'site__REPLACE_WITH_NEW_ID',
		kind: 'site',
		name: 'Site',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		children: { page_main__REPLACE_WITH_NEW_ID: 'page_main__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
	},
	page__REPLACE_WITH_NEW_ID: {
		_id: 'page__REPLACE_WITH_NEW_ID',
		kind: 'page',
		name: 'Page',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		children: { block__REPLACE_WITH_NEW_ID: 'block__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
	},
	page_main__REPLACE_WITH_NEW_ID: {
		_id: 'page_main__REPLACE_WITH_NEW_ID',
		kind: 'page',
		name: 'Page',
		userId: null,
		display: 'preset',
		protoId: null,
		removedAt: null,
		scope: 'system',
		scopeId: null,
		url: 'index',
		children: { block__REPLACE_WITH_NEW_ID: 'block__REPLACE_WITH_NEW_ID' },
		syncedAt: null,
		updatedAt: null,
	},
	block__REPLACE_WITH_NEW_ID: {
		_id: 'block__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'block',
		name: 'Block',
		display: 'preset',
		scope: 'system',
		scopeId: null,
		removedAt: null,
		protoId: null,
		box: {
			desktop: {
				padding: {
					bottom: 180,
					top: 180,
				},
			},
		},
		designId: 'cus123',
		designs: {
			cus123: {
				_id: 'cus123',
				kind: 'design',
				name: 'Custom',
				target: 'block',
				userId: '',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'page',
				scopeId: '',
				theme: null,
				breakpoints: {
					desktop: {
						border: null,
						radius: null,
						normal: {
							container: {
								border: null,
								background: [
									{
										kind: 'color',
										value: 'bg.primary',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
		},
	},
	col__REPLACE_WITH_NEW_ID: {
		_id: 'col__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'col',
		name: 'Column',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		box: {
			desktop: {
				alignContent: 'flex-start',
				alignItems: 'stretch',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},
		},
	},
	text__REPLACE_WITH_NEW_ID: {
		_id: 'text__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'text',
		name: 'Text',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		designs: {
			cus123: {
				_id: 'cus123',
				kind: 'design',
				name: 'Custom',
				target: 'text',
				section: 'body',
				userId: '',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'page',
				scopeId: '',
				breakpoints: {
					desktop: {
						family: listForFontsLib[0].family,
						variants: listForFontsLib[0].variants,
						weight: '400',
						fontSize: 16,
						textHeight: 120,
						textHeightUnit: '%',
						spacing: 0,
						textMargin: 0,
						textMarginUnit: 'px',
						lettercase: 'none',
						normal: {
							container: {
								background: null,
								border: null,
								overlay: null,
								shadow: null,
							},
							text: {
								color: 'text.primary',
								shadow: null,
							},
						},
					},
				},
			},
		},
		box: {
			desktop: {
				margin: {
					bottom: 30,
				},
				offset: {
					width: 300,
				},
			},
		},
		raw: {
			blocks: [
				{
					data: {
						align: 'left',
					},
					depth: 0,
					entityRanges: [],
					inlineStyleRanges: [],
					key: 'e314r',
					text: 'Lorem ipsum dolor sit amet, ea elit eruditi sit.',
					type: 'cus123-div',
				},
			],
			entityMap: {},
		},
	},
	image__REPLACE_WITH_NEW_ID: {
		_id: 'image__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'image',
		name: 'Image',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		alt: '',
		cropMode: 'fill',
		designId: 'cus123',
		designs: {
			cus123: {
				_id: 'cus123',
				kind: 'design',
				name: 'Custom',
				target: 'image',
				userId: '',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'page',
				scopeId: '',
				breakpoints: {},
			},
		},
		box: {
			desktop: {
				height: 400,
				margin: {
					bottom: 30,
				},
				offset: {
					height: 200,
					width: 200,
				},
			},
		},
		objectPosition: '50% 50%',
		src:
			'https://images.unsplash.com/photo-1505322715123-90e9aa1e2d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&q=80',
	},
	icon__REPLACE_WITH_NEW_ID: {
		_id: 'icon__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'icon',
		name: 'Icon',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'cus123',
		designs: {
			cus123: {
				_id: 'cus123',
				kind: 'design',
				name: 'Custom',
				target: 'icon',
				userId: '',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'page',
				scopeId: '',
				breakpoints: {
					desktop: {
						border: null,
						padding: 0,
						paddingUnit: 'px',
						radius: 'null',
						iconSize: 24,
						iconSizeUnit: 'px',
						normal: {
							container: {
								background: null,
								border: null,
								overlay: null,
								shadow: null,
							},
							text: {
								color: 'text.primary',
								shadow: null,
							},
						},
					},
				},
			},
		},
		iconName: 'faStar',
		box: {
			desktop: {
				margin: {
					bottom: 30,
				},
				offset: {
					width: 100,
				},
			},
		},
		src:
			'https://images.unsplash.com/photo-1505322715123-90e9aa1e2d86?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=640&q=80',
	},
	button__REPLACE_WITH_NEW_ID: {
		_id: 'button__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'button',
		name: 'Button',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		designId: 'cus123',
		designs: {
			cus123: {
				_id: 'cus123',
				kind: 'design',
				name: 'Custom',
				target: 'button',
				userId: '',
				display: 'normal',
				protoId: null,
				removedAt: null,
				updatedAt: null,
				scope: 'page',
				scopeId: '',
				breakpoints: {
					desktop: {
						height: 42,
						heightUnit: 'px',
						padding: 18,
						paddingUnit: 'px',
						radius: 'small',
						textDesign: 'body-button',
						textMargin: 0,
						textMarginUnit: 'px',
						btnIconSize: 16,
						btnIconSizeUnit: 'px',
						iconMargin: 6,
						iconMarginUnit: 'px',
						normal: {
							container: {
								border: 'bg.accentplus',
								background: [
									{
										value: 'bg.accent',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: 'text.oncolor',
								shadow: null,
							},
						},
						border: 'thin',
						hover: {
							container: {
								border: 'bg.accentminus',
								background: [
									{
										value: 'bg.accentplus',
										kind: 'color',
									},
								],
								shadow: null,
								overlay: null,
							},
							text: {
								color: null,
								shadow: null,
							},
						},
					},
				},
			},
		},
		icon: {
			isShown: false,
			name: 'faStar',
			position: 'left',
		},
		box: {
			desktop: {
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
		text: { isShown: true, label: 'Button' },
	},
	stack__REPLACE_WITH_NEW_ID: {
		_id: 'stack__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'stack',
		name: 'Stack',
		display: 'preset',
		scope: 'system',
		protoId: null,
		scopeId: null,
		removedAt: null,
		box: {
			desktop: {
				margin: {
					bottom: 30,
				},
				offset: {
					width: 300,
				},
				alignContent: 'flex-start',
				alignItems: 'flex-start',
				flexDirection: 'row',
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
			},
		},
	},
	spacer__REPLACE_WITH_NEW_ID: {
		_id: 'spacer__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'spacer',
		name: 'Spacer',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		box: {
			desktop: {
				height: 50,
				offset: {
					width: 150,
				},
			},
		},
	},
	code__REPLACE_WITH_NEW_ID: {
		_id: 'code__REPLACE_WITH_NEW_ID',
		userId: null,
		kind: 'code',
		name: 'Code',
		display: 'preset',
		protoId: null,
		scope: 'system',
		scopeId: null,
		removedAt: null,
		updatedAt: null,
		code: `<style>
  .box {
      background: #e9ecef;
      padding: 20px;
      font: 14px/20px monospace;
    }
</style>

<div class="box">Hello World!</div>`,
		box: {
			desktop: {
				margin: {
					bottom: 30,
				},
				offset: {
					width: 150,
				},
			},
		},
	},
};

export default {};
