// @flow
import mapValues from 'lodash/fp/mapValues';
import max from 'lodash/fp/max';
import sumBy from 'lodash/fp/sumBy';
import sortBy from 'lodash/fp/sortBy';
import sortedUniq from 'lodash/fp/sortedUniq';
import reduce from 'lodash/fp/reduce';

import type {
	TDelimiter,
	TDelimiters,
	TDelimitersIds,
	TColumn,
	TColumns,
	TColumnsVariant,
	TColumnsOrder,
	TColumnsGroupRow,
	TMethodGetDelimiters,
	TMethodFlatDelimiters,
	TMethodCalcColumns,
	TMethodCalcWidth,
	TMethodCalcColumnsWidth,
	TMethodScaleColumns,
	TMethodGroupColumnsToRow,
	TMethodCalcMargins,
	TMethodCalcColumnsFromDelimiters,
	TMethodCalcDiff,
	TMethodCalcMarginsDiff,
	TMethodGetNextVariant,
	TMethodFindBestVariant,
	TMethodGetScaledSize,
} from './types';

export const getDelimiters: TMethodGetDelimiters = (...columnsCounts) =>
	columnsCounts.map((amount: number) => {
		const delimiters: Array<TDelimiter> = [];
		// eslint-disable-next-line no-plusplus
		for (let size = 0; size <= amount; size++) {
			delimiters.push(+(size / amount).toFixed(5));
		}
		return delimiters;
	});

export const flatDelimiters: TMethodFlatDelimiters = delimiters =>
	sortedUniq(sortBy(delimiter => delimiter, [].concat(...delimiters)));

export const calcWidth: TMethodCalcWidth = column =>
	sumBy(size => max([0, size]), [column.width, column.marginLeft, column.marginRight]);

export const calcColumnsWidth: TMethodCalcColumnsWidth = columns =>
	reduce((totalWidth, column) => totalWidth + calcWidth(column), 0, columns);

export const scaleColumns: TMethodScaleColumns = ({ columns, totalWidth = 1 }) => {
	const sourceWidth = calcColumnsWidth(columns);
	const scaleRatio = totalWidth / sourceWidth;

	return mapValues(
		(column: TColumn) => ({
			...column,
			width: scaleRatio * column.width,
			marginLeft: scaleRatio * column.marginLeft,
			marginRight: scaleRatio * column.marginRight,
		}),
		columns,
	);
};

export const groupColumnsToRow: TMethodGroupColumnsToRow = ({
	columns,
	orderList,
	totalWidth = 1,
}) => {
	const emptyRow: TColumnsGroupRow = {
		orderList: [],
		beforeId: null,
		nextId: null,
	};
	let fillWidth = 0;

	return orderList.reduce(
		(rows, columnIds: TColumnsOrder, orderListId: number) => {
			if (!columns[columnIds.refId]) {
				return rows;
			}

			const column = { ...columns[columnIds.refId] };
			const columnTrueId = columnIds.trueId;
			const columnWidth = calcWidth(column);

			if (fillWidth + columnWidth <= totalWidth) {
				const currentRow = rows[rows.length - 1];
				const nextId = orderList[orderListId + 1]?.trueId || null;

				currentRow[columnTrueId] = column;
				currentRow.nextId = nextId;
				currentRow.orderList = [...currentRow.orderList, columnIds];

				fillWidth += columnWidth;
			} else {
				rows.push({
					[columnTrueId]: column,
					orderList: [columnIds],
					beforeId: orderList[orderListId - 1]?.trueId || null,
					nextId: orderList[orderListId + 1]?.trueId || null,
				});
				fillWidth = columnWidth;
			}

			return rows;
		},
		[emptyRow],
	);
};

export const calcMarginsDiff: TMethodCalcMarginsDiff = ({ firstColumn, secondColumn }) =>
	['marginLeft', 'width', 'marginRight'].reduce(
		(totalDiff: number, property: string) => {
			if (!secondColumn[property]) {
				return totalDiff + (firstColumn[property] ? 1 : 0);
			}
			return (
				totalDiff + Math.abs(1 - firstColumn[property] / secondColumn[property])
			);
		},
		0,
	);

export const calcDiff: TMethodCalcDiff = ({ firstList, secondList, orderList }) =>
	orderList.reduce((totalDiff: number, columnIds: TColumnsOrder) => {
		const firstWidth = calcWidth(firstList[columnIds.trueId]);
		const secondWidth = calcWidth(secondList[columnIds.trueId]);
		if (!secondWidth) return firstWidth ? 1 : 0;

		return totalDiff + Math.abs(1 - firstWidth / secondWidth);
	}, 0);

export const calcMargins: TMethodCalcMargins = ({
	column,
	delimiters,
	leftDelimiterId,
	rightDelimiterId,
	sumWidth,
	totalWidth = 1,
}) => {
	const scaleRatio = sumWidth / calcWidth(column);
	const scaledMarginLeft = scaleRatio * column.marginLeft;
	const scaledMarginRight = scaleRatio * column.marginRight;
	const scaledWidth = scaleRatio * column.width;

	const scaledSource = {
		width: scaledWidth,
		marginLeft: scaledMarginLeft,
		marginRight: scaledMarginRight,
	};

	let minDiff = Infinity;
	let bestVariant = scaledSource;

	// eslint-disable-next-line no-plusplus
	for (let leftId = leftDelimiterId; leftId < rightDelimiterId; leftId++) {
		// eslint-disable-next-line no-plusplus
		for (let rightId = rightDelimiterId; rightId > leftId; rightId--) {
			const marginLeft =
				(delimiters[leftId] - delimiters[leftDelimiterId]) * totalWidth;
			const marginRight =
				(delimiters[rightDelimiterId] - delimiters[rightId]) * totalWidth;
			const width = (delimiters[rightId] - delimiters[leftId]) * totalWidth;
			const variant = { marginLeft, marginRight, width };

			const diff = calcMarginsDiff({
				firstColumn: variant,
				secondColumn: scaledSource,
			});

			if (diff < minDiff) {
				minDiff = diff;
				bestVariant = variant;
			}
		}
	}

	return bestVariant;
};

export const calcColumnsFromDelimiters: TMethodCalcColumnsFromDelimiters = ({
	columns,
	orderList,
	delimiters,
	delimitersIds,
	totalWidth = 1,
}) => {
	const maxIndexId = delimitersIds.length - 1;
	const outputColumns = {};

	// eslint-disable-next-line no-plusplus
	for (let indexId = 1; indexId <= maxIndexId; indexId++) {
		const leftDelimiterId = delimitersIds[indexId - 1];
		const rightDelimiterId = delimitersIds[indexId];
		const columnId = orderList[indexId - 1].trueId;
		const column = columns[columnId];
		const sumWidth =
			(delimiters[rightDelimiterId] - delimiters[leftDelimiterId]) * totalWidth;

		outputColumns[columnId] = calcMargins({
			column,
			delimiters,
			leftDelimiterId,
			rightDelimiterId,
			sumWidth,
			totalWidth,
		});
	}

	return outputColumns;
};

export const getNextVariant: TMethodGetNextVariant = ({
	columns,
	orderList,
	delimiters,
	delimitersIds,
	minDiff,
	totalWidth = 1,
}) => {
	// eslint-disable-next-line no-plusplus
	for (let indexId = 1; indexId < delimitersIds.length - 1; indexId++) {
		const delimiterId = delimitersIds[indexId];

		// shift delimiter to the left and to the right and find better result
		for (let offset = -1; offset <= 1; offset += 2) {
			const testDelimiterId = delimiterId + offset;

			if (
				// previous and next ids haven't to equal this one
				testDelimiterId > delimitersIds[indexId - 1] &&
				testDelimiterId < delimitersIds[indexId + 1]
			) {
				const separatedDelimitersIds: TDelimitersIds = delimitersIds.map(id =>
					delimiterId === id ? testDelimiterId : id,
				);
				const separatedDelimiters: TDelimiters = separatedDelimitersIds.map(
					id => delimiters[id],
				);
				const columnsVariant: TColumns = calcColumnsFromDelimiters({
					columns,
					orderList,
					delimiters,
					delimitersIds: separatedDelimitersIds,
					totalWidth,
				});
				const diffFromOriginal: number = calcDiff({
					firstList: columnsVariant,
					secondList: columns,
					orderList,
				});

				if (diffFromOriginal < minDiff) {
					return {
						delimiters: separatedDelimiters,
						diff: diffFromOriginal,
						columns: columnsVariant,
					};
				}
			}
		}
	}

	return null;
};

export const findBestVariant: TMethodFindBestVariant = ({
	columns,
	orderList,
	delimiters,
	delimitersIds,
	totalWidth = 1,
}) => {
	let bestColumnsVariant: TColumns = calcColumnsFromDelimiters({
		columns,
		orderList,
		delimiters,
		delimitersIds,
		totalWidth,
	});
	let minDiff: number = calcDiff({
		firstList: bestColumnsVariant,
		secondList: columns,
		orderList,
	});
	let variant: TColumnsVariant = null;

	do {
		variant = getNextVariant({
			columns,
			orderList,
			delimiters,
			delimitersIds,
			minDiff,
			totalWidth,
		});

		if (variant && variant.diff < minDiff) {
			bestColumnsVariant = variant.columns;
			minDiff = variant.diff;
		}
	} while (variant);

	return bestColumnsVariant;
};

export const calcColumns: TMethodCalcColumns = ({
	columns,
	orderList,
	delimiters,
	totalWidth = 1,
}) => {
	const scaledColumns = scaleColumns({ columns, totalWidth });
	const delimitersCount = delimiters.length;
	const columnsCount = orderList.length;
	const maxDelimiterId = delimitersCount - 1;
	const maxColumnIndex = columnsCount - 1;
	const delimitersIds = [0];

	let leftDelimiterId = 0; // always starts with the first delimiter
	let rightDelimiterId = 1; // min column width

	orderList.forEach((columnIds, columnIndex) => {
		const isLastColumn = columnIndex === maxColumnIndex;
		const maxPossibleDelimiterId = maxDelimiterId - columnsCount + columnIndex;

		// eslint-disable-next-line no-shadow
		const getDiff = rightDelimiterId => {
			const redimilitersWidth =
				delimiters[rightDelimiterId] - delimiters[leftDelimiterId];
			return Math.abs(
				1 -
					(redimilitersWidth * totalWidth) /
						calcWidth(scaledColumns[columnIds.trueId]),
			);
		};

		if (isLastColumn) {
			// always ends on the last delimiter
			rightDelimiterId = maxDelimiterId;
		}

		while (
			rightDelimiterId < maxPossibleDelimiterId &&
			getDiff(rightDelimiterId) >= getDiff(rightDelimiterId + 1)
		) {
			// eslint-disable-next-line no-plusplus
			rightDelimiterId++;
		}

		delimitersIds.push(rightDelimiterId);

		leftDelimiterId = rightDelimiterId;
		// eslint-disable-next-line no-plusplus
		rightDelimiterId++;
	});

	return findBestVariant({
		columns: scaledColumns,
		orderList,
		delimiters,
		delimitersIds,
		totalWidth,
	});
};

export const getScaledSize: TMethodGetScaledSize = ({ srcSize, dragRect, destRect }) => {
	if (dragRect && dragRect.width && destRect && destRect.width) {
		if (srcSize) {
			const sumSrcDeviceWidth = srcSize.width;
			const scaleRatio = dragRect.width / sumSrcDeviceWidth / destRect.width;

			return {
				width: scaleRatio * srcSize.width,
				margin: {
					left: scaleRatio * srcSize.margin.left,
					right: scaleRatio * srcSize.margin.right,
				},
			};
		}
		return {
			width: dragRect.width / destRect.width,
			margin: { left: 0, right: 0 },
		};
	}

	return srcSize;
};

export default {};
