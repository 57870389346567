// @flow

import React from 'react';
import { Text } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	padding: number,
	// unit: number,
|}>;

const PoperTitle = (props: TProps) => {
	const {
		// unit,
		padding,
	} = props;

	const px = padding;
	// const titleUnit = (px / unit).toFixed(1);

	return (
		<>
			{/* <Text variant="headlinesm" color="spec.lightblue20">
				{`${titleUnit} unit`}
			</Text> */}
			<Text variant="captionlg" color="spec.lightblue10">
				{`${px} px`}
			</Text>
		</>
	);
};

export default PoperTitle;
