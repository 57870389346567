// @flow
import React from 'react';
import { Box, Checkers } from '@graphite/uneon';
import emptyFunction from 'empty/function';
import chroma from 'chroma-js';

type TProps = $ReadOnly<{|
	alpha: number,
	color: string,
	onChange?: number => void,
	onPreview?: number => void,
	setActive: boolean => void,
|}>;

const containerStyle = {
	position: 'relative',
	userSelect: 'none',
	height: '18px',
	cursor: 'pointer',
};

const handleWrapperStyle = {
	position: 'relative',
	width: '0',
	height: '0',
};

const boundStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
};

const handleStyle = {
	position: 'absolute',
	left: '-7px',
	top: '-7px',
	width: '14px',
	height: '14px',
	cursor: 'pointer',
	borderWidth: '3px',
	borderStyle: 'solid',
	borderColor: 'spec.lightblue10',
	borderRadius: 'rounded.all',
	boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
};

// возможно будет раскоментировано, если решим что кружок надо все-таи заливать цветом
// а не держать прозрачным
// const previewStyle = {
// 	position: 'absolute',
// 	left: '-4px',
// 	top: '-4px',
// 	width: '8px',
// 	height: '8px',
// 	cursor: 'pointer',
// 	borderRadius: 'rounded.all',
// };

const roundedStyle = {
	...boundStyle,
	borderRadius: 'rounded.all',
};

const checkersStyle = {
	height: '18px',
	borderRadius: 'rounded.all',
	boxShadow: '0 0 0  1px #222',
};

const getLeftRightStyle = color => {
	const [r, g, b] = chroma(color).rgb();
	const rgb = `${r},${g},${b}`;
	return {
		background: [
			'linear-gradient(90deg, rgba(',
			rgb,
			', 0) 0%, rgba(',
			rgb,
			', 1) 100%)',
		].join(''),
	};
};

function SliderColorAlpha({
	alpha,
	color,
	onChange = emptyFunction,
	onPreview = emptyFunction,
	setActive,
}: TProps) {
	const [width, setWidth] = React.useState(0);
	const [ownAlpha, setOwnAlpha] = React.useState(alpha);

	React.useEffect(() => setOwnAlpha(alpha), [alpha]);

	const x = ownAlpha * width;

	const containerRef = React.useRef();

	const pickColor = React.useCallback(
		clientX => {
			if (!containerRef.current) {
				return null;
			}
			const rect = containerRef.current.getBoundingClientRect();
			// eslint-disable-next-line no-shadow
			const x = Math.max(0, Math.min(width, clientX - rect.x));
			const newAlpha = x / width;
			setOwnAlpha(newAlpha);
			return newAlpha;
		},
		[width, setOwnAlpha],
	);

	const onMouseMove = React.useCallback(
		({ clientX }: MouseEvent) => {
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const alpha = pickColor(clientX);
				if (alpha !== null) {
					onPreview(alpha);
				}
			});
		},
		[pickColor, onPreview],
	);

	const onMouseUp = React.useCallback(
		({ clientX }: MouseEvent) => {
			document.removeEventListener('mouseup', onMouseUp);
			document.removeEventListener('mousemove', onMouseMove);
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const alpha = pickColor(clientX);
				if (alpha !== null) {
					onChange(alpha);
				}
			});
			setActive(false);
		},
		[pickColor, onChange, onMouseMove, setActive],
	);

	const onMouseDown = React.useCallback(
		({ clientX }: MouseEvent) => {
			document.addEventListener('mouseup', onMouseUp);
			document.addEventListener('mousemove', onMouseMove);
			window.requestAnimationFrame(() => {
				// eslint-disable-next-line no-shadow
				const alpha = pickColor(clientX);
				if (alpha !== null) {
					onPreview(alpha);
				}
			});
			setActive(true);
		},
		[pickColor, onPreview, onMouseMove, onMouseUp, setActive],
	);

	React.useEffect(() => {
		window.requestAnimationFrame(() => {
			if (containerRef.current) {
				setWidth(containerRef.current.getBoundingClientRect().width);
			}
		});
	}, []);

	const positionStyle = React.useMemo(
		() => ({
			position: 'absolute',
			left: `${x}px`,
			top: '50%',
		}),
		[x],
	);

	// возможно будет раскоментировано, если решим что кружок надо все-таи заливать цветом
	// а не держать прозрачным
	// const handleColorStyle = useMemo(
	// 	() => ({
	// 		backgroundColor: color,
	// 	}),
	// 	[color],
	// );

	const leftRightStyle = React.useMemo(() => getLeftRightStyle(color), [color]);

	return (
		<Box sx={containerStyle} ref={containerRef}>
			<Checkers color="#222" size={4.5} sx={checkersStyle} />
			<Box sx={roundedStyle} style={leftRightStyle} />
			<Box style={positionStyle}>
				<Box sx={handleWrapperStyle} onMouseDown={onMouseDown}>
					<Box sx={handleStyle} />
					{/* возможно будет раскоментировано, если решим что кружок надо все-таи заливать цветом
					а не держать прозрачным */}
					{/* <Box sx={previewStyle} style={handleColorStyle} /> */}
				</Box>
			</Box>
			<Box style={boundStyle} onMouseDown={onMouseDown} />
		</Box>
	);
}

SliderColorAlpha.defaultProps = {
	onChange: emptyFunction,
	onPreview: emptyFunction,
};

export default React.memo<TProps>(SliderColorAlpha);
