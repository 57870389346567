// @flow

import React from 'react';
import _ from 'lodash/fp';
import getDisplayName from '@graphite/get-display-name';
import type { TId, TSizesPublished, TWidget, TGridBreakpointName } from '@graphite/types';

import type { TColumnsGroup } from 'libs/types/calc-columns';

import { columnReorder, simpleReorder } from './lib';
import dndContext from './dndContext';

type TMinimalProps = $ReadOnly<{
	id: TId,
	originId: ?TId,
	currentDevice: TGridBreakpointName,
	data: TWidget,
	colSizeMap?: TColumnsGroup,
	colAmount?: number,
	sizes?: TSizesPublished,
	babies: $ReadOnlyArray<TId>,
	children?: React$Node,
	controls?: string,
	rowId?: string,
}>;

const DragReorderWrapper = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<T> => {
	const DragReorderHOC = (props, ref) => {
		const { currentDevice, rowId } = props;
		const [state] = React.useContext(dndContext);
		const { dragId, widgets, dragPlace, dropPlace, srcContainerId } = state;

		const placeId = dragPlace?.composeId || null;
		const draggedWidget = dragId ? widgets[dragId] : null;
		const placeWidget = placeId ? widgets[placeId] : null;
		const isDrag = placeId && draggedWidget;
		const isBlockContainer =
			props.data.kind === 'block' &&
			(srcContainerId === props.id || placeWidget?.containerId === props.id);
		const isColumnDrag =
			isDrag && isBlockContainer && draggedWidget?.widgetType === 'column';

		let controls = props?.controls;
		if (draggedWidget?.widgetType === 'widget' && rowId) {
			const overRowId = dragPlace?.rowId || draggedWidget?.rowId;
			const isPlaceColumn = overRowId === rowId && !dropPlace;
			controls = isPlaceColumn ? 'flip' : 'none';
		}

		const colSizeMap = React.useMemo(
			() => (isColumnDrag ? columnReorder(state, props) : props.colSizeMap),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[props, dragId, placeId, dragPlace?.position, isColumnDrag],
		);

		const sizes = React.useMemo(() => {
			let { sizes } = props.data;

			if (!colSizeMap || props.colSizeMap === colSizeMap) return sizes;

			colSizeMap.forEach(({ orderList, beforeId, nextId, ...columns }, i) => {
				// если строку не трогали, то ничего и не меняем
				if (
					!(
						props.colSizeMap &&
						orderList.length !== props.colSizeMap[i].orderList.length
					)
				)
					return;

				orderList.forEach(({ trueId }) => {
					if (draggedWidget?.widgetId === trueId && !columns[trueId].width) {
						return;
					}

					sizes = _.set(
						`${trueId}.${currentDevice}`,
						{
							width: columns[trueId].width,
							margin: {
								left: columns[trueId].marginLeft,
								right: columns[trueId].marginRight,
							},
						},
						sizes,
					);
				});
			});

			return sizes;
		}, [props.data, props.colSizeMap, colSizeMap, draggedWidget, currentDevice]);

		const babies = React.useMemo(
			() => (isDrag ? simpleReorder(state, props.babies) : props.babies),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[props.babies, dragId, placeId, dragPlace?.position, isDrag],
		);

		const data = React.useMemo(
			() => (sizes !== props.sizes ? { ...props.data, sizes } : props.data),
			[props.data, props.sizes, sizes],
		);

		return React.useMemo(
			() => (
				<Component
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					ref={ref}
					key={props.id}
					babies={babies}
					colSizeMap={colSizeMap || undefined}
					controls={controls}
					data={data}
				>
					{props.children}
				</Component>
			),
			// eslint-disable-next-line react-hooks/exhaustive-deps
			[props, controls, colSizeMap, babies.join('-')],
		);
	};

	DragReorderHOC.displayName = `DragReorder(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(DragReorderHOC));
};

export default DragReorderWrapper;
