// @flow
import React from 'react';
import _ from 'lodash/fp';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { ButtonDeprecated, PopupWhite, Box } from '@graphite/uneon';
import { AbsoluteContext } from '@graphite/abs-grid';
import { getCurrentSiteId, getCurrentUserId, getPresets } from '@graphite/selectors';

import GridContext from 'Widget/libs/dnd/dndContext';

import WidgetPanel from './WidgetPanel';

type TProps = $ReadOnly<{||}>;

const buttonSx = {
	boxShadow: 'md',
};

const excludeList = ['project', 'site', 'page', 'block', 'col', 'icon'];

const PopupWhiteWrapped = styled(PopupWhite)`
	${({ isHidden }) =>
		isHidden
			? css`
					transition: left 0.1s ease-out 0.15s, opacity 0.15s ease-out 0s !important;
					opacity: 0 !important;
					left: -100% !important;
			  `
			: ''}
`;

function WidgetAdd() {
	const siteId = useSelector(getCurrentSiteId);
	const userId = useSelector(getCurrentUserId);

	const widgetPresets = getPresets({
		// ToDo: по хорошему siteId и userId надо ловить из пропсов
		// и они должны быть обязательными
		userId: userId || '',
		scope: 'site',
		scopeId: siteId || '',
	});

	const availableWidgetPresets = _.filter(
		({ kind }) => !excludeList.includes(kind),
		widgetPresets,
	);

	const [isPanelShown, setIsPanelShown] = React.useState(false);

	const togglePanel = React.useCallback(() => setIsPanelShown(!isPanelShown), [
		isPanelShown,
	]);

	const hidePanel = React.useCallback(() => setIsPanelShown(false), []);

	const panelRef = React.useRef();

	const { dragId: absDragId } = React.useContext(AbsoluteContext);
	const [{ dragId }] = React.useContext(GridContext);

	return (
		<>
			<Box>
				<ButtonDeprecated
					colors="success"
					icons="plus"
					size="sm"
					onClick={togglePanel}
					title="Add Content"
					shape="rounded"
					sx={buttonSx}
				/>
				<Box ref={panelRef} />
			</Box>
			<PopupWhiteWrapped
				anchorEl={panelRef}
				isOpen={isPanelShown}
				onClose={hidePanel}
				offsetTop={12}
				mutex="topleft"
				isFixed
				isHidden={absDragId || dragId}
			>
				{isPanelShown && <WidgetPanel widgetPresets={availableWidgetPresets} />}
			</PopupWhiteWrapped>
		</>
	);
}

export default React.memo<TProps>(WidgetAdd);
