// @flow
import React from 'react';
import useHotkeys from '@graphite/use-hotkeys';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Box } from '@graphite/uneon';
import type { TSx } from '@graphite/types';
import getDisplayName from '@graphite/get-display-name';
import { transitions, zIndices } from '@graphite/constants';
import type {
	TStackJustifyContent,
	TStackAlignItems,
} from 'Widget/constants/types/stack-props';
import { startEdit } from 'Editor/ducks/editor';
import FrameContext from 'Editor/Frame/Context';

// ToDo вынести в либу и из других мест тоже
import type { TMinimalProps, TSymbioteProps } from './types';

type TPropsMixed = $ReadOnly<{
	...$Exact<TMinimalProps>,
	...$Exact<TSymbioteProps>,
	direction?: string,
	justifyContent?: TStackJustifyContent,
	alignItems?: TStackAlignItems,
	widgetOrderIndex: number,
}>;

type TCoords = $ReadOnly<{| x: number, y: number |}>;

const MOVE_DELTA = 2;

const EditHandler = styled(Box)`
	transition-duration: ${transitions.widgetControls.showDuration};
	transition-timing-function: ${transitions.widgetControls.showTiming};
	transition-delay: ${transitions.widgetControls.showDelay};
	transition-property: opacity, border, background-color;

	${({ isEdit, kind, isResizeControls }) => {
		return !(isEdit || (kind === 'stack' && isResizeControls))
			? css`
					bottom: 0;
					display: flex;
					flex-direction: column;
					left: 0;
					pointer-events: none;
					position: absolute;
					right: 0;
					top: 0;
			  `
			: ``;
	}}

	${({ kind, isResizeControls }) => {
		// Это нужно для того, что бы визивиг нормально отображал курсор)
		return isResizeControls && kind !== 'stack'
			? css`
					pointer-events: auto !important;
			  `
			: ``;
	}}
`;

const withWidgetEdit = <TProps: TPropsMixed>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const WithWidgetEdit = (props: TProps, ref) => {
		const {
			data,
			dispatch,
			id,
			instanceId,
			dragHandler,
			widgetMode,
			widgetChain,
			editId = null,
			editChain,
			clickAvailable,
			dragAvailable,
			hovered,
			widgetOrderIndex,
			position,
		} = props;

		const [coordinates, setCoordinates] = React.useState<TCoords>({ x: 0, y: 0 });
		const editControlsRef: {| current: ?React$ElementRef<'div'> |} = React.useRef();

		const { document: documentFrame, window: windowFrame } = React.useContext(
			FrameContext,
		);

		const isEdit = widgetMode === 'widget-edit';
		const isResizeControls = widgetMode === 'widget-resize';
		const isAbsolute = position === 'absolute';

		const handleDown = React.useCallback(
			(event: MouseEvent) => {
				if (event.button === 0) {
					setCoordinates({ x: event.pageX, y: event.pageY });
				}
			},
			[setCoordinates],
		);

		const showEditWidget = React.useCallback(
			(event: MouseEvent) => {
				event.preventDefault();
				/* Если уже в редактировании, то лишний раз не кидаем диспатч */
				if (isEdit) return;

				const itWasResize =
					isResizeControls &&
					coordinates.x &&
					coordinates.y &&
					(Math.abs(coordinates.x - event.pageX) > MOVE_DELTA ||
						Math.abs(coordinates.y - event.pageY) > MOVE_DELTA);

				/* Если на самом деле был ресайз */
				if (event.isTrusted && itWasResize) {
					return;
				}

				dispatch(startEdit(editId || id, editChain || widgetChain, instanceId));
			},
			[
				editId,
				editChain,
				id,
				widgetChain,
				isEdit,
				isResizeControls,
				coordinates.x,
				coordinates.y,
				dispatch,
				instanceId,
			],
		);

		const sx: TSx = React.useMemo(() => {
			const sx = {
				zIndex: zIndices.editFactor + widgetOrderIndex,
				pointerEvents:
					hovered && clickAvailable && !isEdit && !isResizeControls
						? 'auto !important'
						: 'none !important',
			};

			return sx;
		}, [hovered, clickAvailable, isEdit, isResizeControls, widgetOrderIndex]);

		const hotkeyEnterEditHandler = React.useCallback(
			e => {
				e.preventDefault();
				if (isEdit) return;
				if (isResizeControls)
					dispatch(
						startEdit(editId || id, editChain || widgetChain, instanceId),
					);
			},
			[
				dispatch,
				editId,
				id,
				editChain,
				widgetChain,
				instanceId,
				isEdit,
				isResizeControls,
			],
		);

		useHotkeys('Enter', hotkeyEnterEditHandler, documentFrame, windowFrame);

		return (
			<>
				{!isAbsolute && (
					<EditHandler
						ref={editControlsRef}
						data-kind="with-widget-edit"
						onClick={showEditWidget}
						onMouseDown={handleDown}
						sx={sx}
						kind={data.kind}
						isResizeControls={isResizeControls}
						isEdit={isEdit}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...dragHandler}
						draggable={
							dragAvailable && dragHandler ? dragHandler.draggable : false
						}
					/>
				)}
				<Component
					/* eslint-disable-next-line react/jsx-props-no-spreading */
					{...props}
					ref={ref}
				/>
			</>
		);
	};

	WithWidgetEdit.displayName = `withWidgetEdit(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(WithWidgetEdit));
};

export default withWidgetEdit;
