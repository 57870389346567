/* eslint-disable flowtype/require-valid-file-annotation */
/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import users from '../Editor/ducks/users';
import specs from '../Editor/ducks/specs';
import widgets from '../Editor/ducks/widgets';
import editor from '../Editor/ducks/editor';
import constructorHistory from '../Editor/ducks/history';
import history from './history';

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers = {}) {
	return combineReducers({
		router: connectRouter(history),
		users,
		specs,
		widgets,
		editor,
		constructorHistory,
		...injectedReducers,
	});
}
