// @flow
import React, { memo, useCallback } from 'react';
import { ListItem } from '@graphite/uneon';
import { Color as LabelColor } from '@graphite/labels';
import type {
	TColorShades,
	TPaletteColorRemoveFn,
	TPaletteColorEditFn,
	TColorReference,
} from '@graphite/types';
import ListRowColors from '../RowColors';

type TProps = $ReadOnly<{|
	id: string,
	active?: ?TColorReference,
	name: string,
	color: string,
	shades: TColorShades,
	dotAt?: string,
	onRemove?: TPaletteColorRemoveFn,
	onClick?: TPaletteColorEditFn,
|}>;

const rowStyle = {
	marginTop: '-3px',
	marginBottom: '9px',
};

const listItemSx = {
	cursor: 'pointer',

	':hover': {
		backgroundColor: '#fff',
	},
};

const colorButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

function ListItemColors({
	id,
	name,
	active,
	color,
	shades,
	dotAt,
	onRemove,
	onClick,
}: TProps) {
	const boundRemove = useCallback(
		(e, itemName, buttonName) => {
			if (buttonName === 'remove' && onRemove) {
				onRemove(id);
				return;
			}
			if (onClick && !active) {
				onClick(e, id, null);
			}
		},
		[active, id, onClick, onRemove],
	);
	const boundClick = useCallback(
		(e, shadeId = null) => {
			if (onClick) {
				onClick(e, id, shadeId);
			}
		},
		[id, onClick],
	);
	const shadeId =
		active && typeof active === 'object' ? active.shadeId || active.entryId : null;

	return (
		<>
			<ListItem buttonGroup={colorButtons} onClick={boundRemove} sx={listItemSx}>
				<LabelColor
					title={name}
					color={color}
					onClick={boundClick}
					isActive={shadeId === id}
				/>
			</ListItem>
			{shades.length > 0 && (
				<ListRowColors
					shades={shades}
					dotAt={dotAt}
					onClick={boundClick}
					active={shadeId}
					sx={rowStyle}
				/>
			)}
		</>
	);
}

ListItemColors.defaultProps = {
	active: null,
	dotAt: null,
	onRemove: null,
	onClick: null,
};

export default memo<TProps>(ListItemColors);
