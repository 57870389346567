// @flow
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line import/extensions
import translation from 'Editor/i18n.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: translation,
		lng: 'en',

		keySeparator: '.',

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
