// @flow
import React from 'react';
import { Box } from '@graphite/uneon';
import set from 'lodash/fp/set';
import type { TColorReference, TShadow, TColorTabs, TSpecsColor } from '@graphite/types';
import { defaultShadow } from '@graphite/constants';
import { Params as ListParams } from '@graphite/lists';

import LibColors from '../Color';

const spacerSx = {
	height: '30px',
};

type TProps = $ReadOnly<{|
	value?: TShadow,
	onChange?: ?(TShadow) => void,
	colorspec: TSpecsColor,
|}>;

const colorTabs: TColorTabs = ['custom', 'theme', 'palette'];

const paramList = [
	{
		title: 'Name',
		key: 'name',
		kind: 'string',
		info: {
			maxLength: 16,
		},
	},
	{
		title: 'Type',
		key: 'kind',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'drop', label: 'Drop' },
					{ name: 'inner', label: 'Inner' },
				],
			},
		},
	},
	{
		title: 'X',
		key: 'x',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
	{
		title: 'Y',
		key: 'y',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
	{
		title: 'Blur',
		key: 'blur',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
	{
		title: 'Spread',
		key: 'spread',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
];

const Shadow = ({ value = defaultShadow, onChange = null, colorspec }: TProps) => {
	const paramSource = React.useMemo(
		() => ({
			name: value.name,
			kind: value.kind,
			x: `${value.x}`,
			y: `${value.y}`,
			blur: `${value.blur}`,
			spread: `${value.spread}`,
		}),
		[value],
	);

	const changeParam = React.useCallback(
		(key, changed) => {
			if (!onChange) {
				return;
			}
			onChange(set(key, changed, value));
		},
		[onChange, value],
	);

	const changeColor = React.useCallback(
		(color: TColorReference) => {
			if (onChange) {
				onChange(set(`color`, color, value));
			}
		},
		[onChange, value],
	);

	// цвет понадобится в будущем для правильного интерфейса, где колорпикер не встроен в окно
	// const effectiveColor = React.useMemo(() => getColor(colorspec, value.color), [
	// 	colorspec,
	// 	value,
	// ]);

	// const effectiveColorName = React.useMemo(() => getColorName(colorspec, value.color), [
	// 	colorspec,
	// 	value,
	// ]);

	return (
		<>
			<ListParams
				listName="shadow"
				paramSource={paramSource}
				paramList={paramList}
				unit={1}
				onChange={changeParam}
			/>
			{/* цвет понадобится в будущем для правильного интерфейса, где колорпикер не встроен в окно */}
			{/* <LabelColor title={effectiveColorName} color={effectiveColor} /> */}
			<Box sx={spacerSx} />
			<LibColors
				tabs={colorTabs}
				value={value.color}
				onChange={changeColor}
				colorspec={colorspec}
			/>
		</>
	);
};

export default React.memo<TProps>(Shadow);
