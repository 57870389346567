// @flow
import React from 'react';
import { type List } from 'immutable';
import styled from '@emotion/styled';
import {
	EditorBlock,
	type ContentBlock,
	type SelectionState,
	type ContentState,
	type CompositeDecorator,
} from 'draft-js';

const AlignWrapper = styled.div`
	text-align: ${({ align }) => align};
`;

type TProps = $ReadOnly<{
	block: ContentBlock,
	blockProps?: Object,
	blockStyleFn: (block: ContentBlock) => string,
	contentState: ContentState,
	customStyleFn: (style: Object, block: ContentBlock) => ?Object,
	customStyleMap: Object,
	decorator: ?CompositeDecorator,
	direction: 'LTR' | 'RTL' | 'NEUTRAL',
	forceSelection: boolean,
	offsetKey: string,
	selection: SelectionState,
	startIndent?: boolean,
	tree: List<any>,
}>;

const CustomBlock = (props: TProps) => {
	const { block } = props;
	const align = block.getData().get('text-align');

	if (!align) {
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <EditorBlock {...props} />;
	}

	return (
		<AlignWrapper align={align}>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<EditorBlock {...props} />
		</AlignWrapper>
	);
};

const blockRendererFn = () => ({
	component: React.memo<TProps>(CustomBlock),
});

export default blockRendererFn;
