// @flow

import React, { memo, useMemo } from 'react';
import { Flex, Box, Text } from '@graphite/uneon';
import getRadius from '@graphite/get-radius';
import type { TEffectRadius } from '@graphite/types';

type TProps = $ReadOnly<{|
	radius: TEffectRadius,
	isActive?: boolean,
|}>;

const flexSx = {
	alignItems: 'center',
	padding: '3px 0',
};

const boxSx = {
	backgroundColor: 'bg.accent',
	width: '18px',
	height: '18px',
	marginTop: '-2px',
	marginRight: '12px',
};

function LabelRadius({ radius, isActive = false }: TProps) {
	const boxStyle = useMemo(
		() => ({
			borderRadius: getRadius(radius, 0.5),
		}),
		[radius],
	);
	return (
		<Flex sx={flexSx}>
			<Box sx={boxSx} style={boxStyle} />
			<Text variant="bodysm" color={isActive ? 'text.accent' : 'text.primary'}>
				{radius.name}
			</Text>
		</Flex>
	);
}

export default memo<TProps>(LabelRadius);
