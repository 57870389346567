// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex } from '@graphite/uneon';
import emptyObject from 'empty/object';
import logger from 'libs/logger';
import reSelect from 'libs/re-select';
import { getDesignSx } from '@graphite/selectors';
import Widget from 'Widget';
import type {
	TSx,
	TId,
	TDesign,
	TDesigns,
	TSpecsGrid,
	TSpecsColor,
	TSpecsEffect,
	TSpecsWidget,
	TWidget,
} from '@graphite/types';
import getDisplayName from '@graphite/get-display-name';

import type { TMinimalPropsWrapperProvider } from '../constants/types';

const baseSx = {
	position: 'relative',
	justifyContent: 'center',
	width: '100%',
};

type TGetBlockSxParams = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
	colorspec: TSpecsColor,
	effectspec: TSpecsEffect,
	widgetspec: TSpecsWidget,
|}>;

// Эта функция возвращает объект, передаваемый в свойство `sx`
type TGetBlockSxFn = TGetBlockSxParams => TSx;

const getBlockSx: TGetBlockSxFn = reSelect<
	TGetBlockSxParams,
	?TId,
	TDesigns,
	TSpecsGrid,
	TSpecsColor,
	TSpecsEffect,
	TSpecsWidget,
	TSx,
>(
	({ data }) => data.designId,
	({ data }) => data.designs || emptyObject,
	({ gridspec }) => gridspec,
	({ colorspec }) => colorspec,
	({ effectspec }) => effectspec,
	({ widgetspec }) => widgetspec,
	(designId, designs, gridspec, colorspec, effectspec, widgetspec) => {
		try {
			if (!designId) {
				return baseSx;
			}

			const custom: ?TDesign = designs[designId] || null;
			const design: ?TDesign =
				custom || widgetspec.block.find(d => d._id === designId);
			if (!design) {
				return baseSx;
			}

			return _.assign(
				baseSx,
				getDesignSx({
					design,
					gridspec,
					colorspec,
					effectspec,
					widgetspec,
				}),
			);
		} catch (e) {
			logger.error(e);
			return emptyObject;
		}
	},
)(({ data, gridspec, colorspec, effectspec, widgetspec }) =>
	[
		'block-wrapper@getBlockSx',
		data.designId || 'null',
		gridspec._id,
		colorspec._id,
		effectspec._id,
		widgetspec._id,
	].join('-'),
);

const withWrapper = <T: TMinimalPropsWrapperProvider>(
	Component: React$ComponentType<T>,
) => {
	const WithWrapper = (props: T, ref) => {
		const {
			data,
			dragContainer,
			dragFlip,
			instanceId,
			babies,
			positions,
			gridspec,
			widgetspec,
			colorspec,
			effectspec,
			onClick = null,
			widgetChain,
			blockSizes,
		} = props;

		const { _id } = data;
		const parentChain = React.useMemo(() => [_id, ...(widgetChain || [])], [
			widgetChain,
			_id,
		]);

		const boxSx =
			widgetspec && gridspec && colorspec && effectspec
				? getBlockSx({ data, widgetspec, gridspec, colorspec, effectspec })
				: emptyObject;

		const sx = React.useMemo(() => _.merge(blockSizes, boxSx), [blockSizes, boxSx]);

		const dynamicStyle = React.useMemo(() => {
			if (!data.style) {
				return null;
			}
			return {
				paddingTop: data.style.paddingTop,
				paddingBottom: data.style.paddingBottom,
			};
		}, [data.style]);

		const otherProps = _.assign(dragContainer, dragFlip);

		// deviding abs widgets on 2 groups.
		// 1 - rendering before a colls; 2 - after a colls
		const topGroup = React.useMemo(() => {
			let topIndicator = 0;
			return (
				babies.filter(i => {
					if (!topIndicator && positions?.[i]) {
						return 1;
					}
					topIndicator++;
					return 0;
				}) || []
			);
		}, [babies, positions]);

		const listAbsWidgets = React.useMemo(
			() =>
				babies.map(id => ({
					id,
					data: (
						<Widget
							key={id}
							id={id}
							containerId={data._id}
							instanceId={data.modified ? data._id : instanceId}
							rowId="no-row"
							widgetChain={parentChain}
							widgetspec={widgetspec}
							colorspec={colorspec}
							gridspec={gridspec}
							effectspec={effectspec}
						/>
					),
				})),
			[
				babies,
				colorspec,
				data._id,
				data.modified,
				effectspec,
				gridspec,
				instanceId,
				parentChain,
				widgetspec,
			],
		);

		return (
			<Flex
				sx={sx}
				ref={ref}
				onMouseDown={onClick}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...otherProps}
				data-kind="block-wrapper"
				style={dynamicStyle}
			>
				{topGroup.map(
					id =>
						positions[id] &&
						!positions[id].includes('container') &&
						listAbsWidgets[_.findIndex({ id }, listAbsWidgets)]?.data,
				)}

				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />

				{babies.map(
					id =>
						!topGroup.includes(id) &&
						positions[id] &&
						!positions[id].includes('container') &&
						listAbsWidgets[_.findIndex({ id }, listAbsWidgets)]?.data,
				)}
			</Flex>
		);
	};

	WithWrapper.displayName = `withWrapper(${getDisplayName(Component)})`;

	return React.memo<T>(React.forwardRef(WithWrapper));
};

export default withWrapper;
