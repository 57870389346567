// @flow
import _ from 'lodash/fp';
import {
	takeEvery,
	put,
	fork,
	select,
	call,
	all,
	take,
	cancelled,
} from 'redux-saga/effects';
import { eventChannel, type EventChannel } from 'redux-saga';
import { handleActions } from 'redux-actions';
import type { Saga } from 'redux-saga';
import type {
	TId,
	TAction,
	TQueryConstraints2,
	TQueryConstraint,
	TSpecs,
	TSpec,
} from '@graphite/types';

import { commitSpecsToBd, collection } from 'libs/firebase';
import logger from 'libs/logger';
import { getSpecByIds } from '@graphite/selectors';

import { historyNewAction } from './history';
import { APPLY, apply } from './editor';

const LOAD_AND_WATCH_SPECS = 'SPECS/LOAD_AND_WATCH_SPECS';
const SAVE_SPECS = 'SPECS/SAVE_SPECS';
const UPDATE_SPECS = 'SPECS/UPDATE_SPECS';

const initialState: TSpecs = {};

export const loadAndWatchSpecs = (userId: TId, projectId: TId, siteId: TId): TAction => ({
	type: LOAD_AND_WATCH_SPECS,
	payload: {
		userId,
		projectId,
		siteId,
	},
});

export const saveSpecs = (specs: TSpecs): TAction => ({
	type: SAVE_SPECS,
	payload: {
		specs,
	},
});

export const updateSpecs = (specs: TSpecs): TAction => ({
	type: UPDATE_SPECS,
	payload: { specs },
});

/**
	Коллаборация
 */
const getSpecsEventsChannel = (
	userId: TId,
	projectId: TId,
	siteId: TId,
): EventChannel<{ specs: TSpecs }> => {
	const minDate = new Date().toISOString();
	return eventChannel<{ specs: TSpecs }>(
		(emit: ({ specs: TSpecs }) => void): (() => void) => {
			const unsubscriptors: $ReadOnlyArray<() => void> = [
				{ scope: ['==', 'system'], scopeId: ['==', null] },
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'market'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'user'],
					scopeId: ['==', userId],
					removedAt: ['>', minDate],
				},
				{
					scope: ['==', 'project'],
					scopeId: ['==', projectId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'project'],
					scopeId: ['==', projectId],
					removedAt: ['>', minDate],
				},
				{
					scope: ['==', 'site'],
					scopeId: ['==', siteId],
					removedAt: ['==', null],
				},
				{
					scope: ['==', 'site'],
					scopeId: ['==', siteId],
					removedAt: ['>', minDate],
				},
			].map((constraints: TQueryConstraints2): (() => void) => {
				let specs = collection('specs').where('userId', '==', userId);
				_.forEach(([k, v]: [string, TQueryConstraint]) => {
					specs = specs.where(k, v[0], v[1]);
				}, _.entries(constraints));
				return specs.onSnapshot((snapshot: any) => {
					const specs = {};
					snapshot.docChanges().forEach((change: any) => {
						const spec = change.doc.data();
						specs[spec._id] = spec;
					});
					if (_.size(specs)) {
						emit({ specs });
					}
				});
			});
			// Return an unregister function
			return (): void => unsubscriptors.forEach((u: () => void): void => u());
		},
	);
};

export function* loadAndWatchSpecsSaga(): Saga<void> {
	let specsEventsChannel = null;
	yield takeEvery(LOAD_AND_WATCH_SPECS, function*({
		payload: { userId, projectId, siteId },
	}: {
		payload: { userId: TId, projectId: TId, siteId: TId },
	}): any {
		if (specsEventsChannel) {
			specsEventsChannel.close();
			specsEventsChannel = null;
		}

		if (!userId) return;

		specsEventsChannel = getSpecsEventsChannel(userId, projectId, siteId);

		try {
			while (!0) {
				const { specs } = yield take(specsEventsChannel);
				const specsAll: TSpecs = yield select(getSpecByIds, {
					ids: Object.keys(specs),
				});

				const updated: TSpecs = _.omitBy(
					(spec: TSpec): boolean =>
						(specsAll[spec._id] && !spec.updatedAt) ||
						Date.parse(specsAll[spec._id]?.updatedAt || '') >=
							Date.parse(spec.updatedAt || ''),
					specs,
				);

				// if change current page or site
				// and loading specs
				if (!_.find({ scopeId: siteId }, specsAll)) {
					yield put(
						apply({
							specs: updated,
						}),
					);
				} else if (_.size(updated)) {
					yield put(
						historyNewAction({
							specs: updated,
						}),
					);
				}
			}
		} catch (e) {
			logger.error(e);
		} finally {
			if (yield cancelled() && specsEventsChannel) specsEventsChannel.close();
		}
	});
}

export function* saveSpecsSaga(): Saga<void> {
	yield takeEvery(SAVE_SPECS, function*({
		payload: { specs },
	}: {
		payload: {
			specs: TSpecs,
		},
	}): Saga<void> {
		try {
			// if change specs need update modificate date
			// for right working collaboration and etc.
			const update: TSpecs = _.reduce(
				(update: TSpecs, spec: TSpec): TSpecs => {
					update[spec._id] = {
						...spec,
						updatedAt: new Date().toISOString(),
					};
					return update;
				},
				{},
				specs,
			);
			yield all([
				call(commitSpecsToBd, update),
				// FixMe: переделать на call
				// чтобы можно было ловить ошибки внизу
				put(historyNewAction({ specs: update })),
			]);
		} catch (e) {
			// FixMe: тут по хорошему нужно обработать ошибку
			// если мы сюда попали, значит
			// или упало сохранение на сервер, или упало сохранение в стейт
			// оба варианта плохи.
			// Поэтому в идеале нужно с сервера подтянуть актуальный стейт
			// и полностью его обновить на клиенте.
			logger.error(e);
		}
	});
}

export function* updateSpecsSaga(): Saga<void> {
	yield takeEvery(UPDATE_SPECS, function*({
		payload: { specs },
	}: {
		payload: { specs: TSpecs },
	}): Saga<void> {
		yield put(saveSpecs(specs));
	});
}

export function* saga(): Saga<void> {
	yield fork(updateSpecsSaga);
	yield fork(saveSpecsSaga);
	yield fork(loadAndWatchSpecsSaga);
}

export default handleActions<$ReadOnly<TSpecs>, TAction>(
	{
		[APPLY](
			state: $ReadOnly<TSpecs>,
			{ payload: { specs } }: { +payload: { +specs: TSpecs } },
		): $ReadOnly<TSpecs> {
			return _.pickBy(_.identity, _.assign(state, specs));
		},
	},
	initialState,
);
