// @flow
import { find } from 'lodash/fp';
import type { ElementRef } from 'react';

type TPlaces = $ReadOnly<{
	ref: ElementRef<*>,
}>;

type TMethodFindPlace = <T>({
	node: HTMLElement | Element,
	places: TPlaces,
	callback?: (place: T) => boolean,
}) => T | null;

export const findPlace: TMethodFindPlace = ({ node, places, callback }) =>
	find(
		place => place.ref.current === node && (callback ? callback(place) : true),
		places,
	) || null;

export default {};
