// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Toolbar, ToolbarBtn, Popper } from '@graphite/uneon';
import type { TGridBreakpointName } from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';
import type { TWidgetButton } from '../constants/types';

type TProps = $ReadOnly<{|
	device: TGridBreakpointName,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	data: TWidgetButton,
	save: (string, string | Object) => void,
|}>;

const ICONS = {
	left: 'align-left',
	center: 'align-center-y',
	right: 'align-right',
	justify: 'align-center-y',
};

const Align = ({ device, openedPanel, setOpenedPanel, save, data }: TProps) => {
	const alignBtnEl = React.useRef(null);

	const handleTextAlign = React.useCallback(
		e => {
			e.preventDefault();
			setOpenedPanel(openedPanel => (openedPanel === 'align' ? null : 'align'));
		},
		[setOpenedPanel],
	);

	const saveAlign = React.useCallback(
		value => save('box', _.set(`${device}.align`, value, data.box)),
		[device, save, data.box],
	);

	const setLeft = React.useCallback(() => saveAlign('left'), [saveAlign]);
	const setCenter = React.useCallback(() => saveAlign('center'), [saveAlign]);
	const setRight = React.useCallback(() => saveAlign('right'), [saveAlign]);
	const setJustify = React.useCallback(() => saveAlign('justify'), [saveAlign]);

	const isActive = openedPanel === 'align';

	const { align = 'left' } = closestDeviceWithKey(data.box, {
		currentDevice: device,
		key: `box-${data._id}`,
	});

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleTextAlign}
				iconName={ICONS[align]}
				ref={alignBtnEl}
				isActive={isActive}
			/>

			<Popper
				isOpen={isActive}
				anchorEl={alignBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<ToolbarBtn
						onMouseDown={setLeft}
						iconName={ICONS.left}
						isActive={align === 'left'}
					/>
					<ToolbarBtn
						onMouseDown={setCenter}
						iconName={ICONS.center}
						isActive={align === 'center'}
					/>
					<ToolbarBtn
						onMouseDown={setRight}
						iconName={ICONS.right}
						isActive={align === 'right'}
					/>
					<ToolbarBtn
						onMouseDown={setJustify}
						iconName={ICONS.justify}
						isActive={align === 'justify'}
					/>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(Align);
