// @flow
import type { TUnit } from '@graphite/types';

const getUnit = (unit: number, value: number, valueUnit: ?TUnit): string => {
	try {
		if (!valueUnit) {
			return `${value}px`;
		}
		if (valueUnit === 'auto') {
			return 'auto';
		}
		if (valueUnit === 'unit') {
			return `${value * unit}px`;
		}
		return `${value}${valueUnit}`;
	} catch (e) {
		return '0';
	}
};

export default getUnit;
