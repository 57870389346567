// @flow
import React from 'react';
import { Editor } from 'draft-js';
import emptyFunction from 'empty/function';
import { Helmet } from 'react-helmet';
import createLinkUrl from '@graphite/fonts-lib/lib/libs/create-link-url';

import type { TConnectProps } from './constants/types';

import fromRaw from './libs/from-raw';
import { customStyleMap, getCustomStyleFn } from './libs/style-map';
import { getBlockRenderMap } from './libs/blockRenderMap';
import blockRendererFn from './libs/blockRendererFn';
import Wrap from './Wrap';
import getFonts from './libs/getFonts';

const Text = (props: TConnectProps, ref) => {
	const {
		data,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
		direction,
		position,
	} = props;
	const { raw } = data;

	const [editorState, setEditorState] = React.useState(() => fromRaw(raw));

	React.useEffect(() => setEditorState(fromRaw(raw)), [raw, setEditorState]);

	const blockRenderMap = React.useMemo(
		() =>
			widgetspec &&
			colorspec &&
			gridspec &&
			effectspec &&
			getBlockRenderMap({
				designs: data.designs,
				widgetspec,
				colorspec,
				gridspec,
				effectspec,
			}),
		[colorspec, data.designs, gridspec, widgetspec, effectspec],
	);

	const fonts = React.useMemo(
		() =>
			widgetspec &&
			getFonts({
				id: data._id,
				raw: data.raw,
				designs: data.designs || {},
				widgetspec,
			}),
		[widgetspec, data._id, data.raw, data.designs],
	);

	const fontsUrl = React.useMemo(() => {
		return fonts && createLinkUrl(fonts);
	}, [fonts]);

	if (!widgetspec || !colorspec || !gridspec || !effectspec) return null;

	return (
		<>
			{fontsUrl && (
				<Helmet>
					<link href={fontsUrl} rel="stylesheet" />
				</Helmet>
			)}
			<Wrap
				ref={ref}
				data={data}
				widgetspec={widgetspec}
				effectspec={effectspec}
				colorspec={colorspec}
				gridspec={gridspec}
				direction={direction}
				position={position}
			>
				<Editor
					readOnly
					editorState={editorState}
					customStyleFn={getCustomStyleFn(colorspec)}
					customStyleMap={customStyleMap}
					blockRenderMap={blockRenderMap}
					blockRendererFn={blockRendererFn}
					onChange={emptyFunction}
				/>
			</Wrap>
		</>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Text));
