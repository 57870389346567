// @flow
import React from 'react';
import styled from '@emotion/styled';

import { Box } from './with-absolute/Absolute';
import checkMove from './libs/check-move';

import type { TDropRect, TPageOffset } from './constants/types';

type TProps = $ReadOnly<{
	offset: TPageOffset,
	position: $ReadOnly<{| x: number, y: number |}>,
	rect: TDropRect,
}>;

const Wrapper: React$ComponentType<{}> = styled.div`
	${Box} {
		position: static;
	}

	position: absolute;
	display: ${({ position }) => (checkMove(position) ? 'block' : 'none')};
`;

const Duplicate = (props: TProps) => {
	const { position, rect, offset } = props;

	// FixMe: React Memo
	const style = {
		transform: `translate(${position.x}px, ${position.y}px)`,
		left: `${rect.left - offset.x}px`,
		top: `${rect.top - offset.y}px`,
	};

	// eslint-disable-next-line react/jsx-props-no-spreading
	return <Wrapper {...props} style={style} />;
};

export default Duplicate;
