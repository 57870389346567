// @flow
import React from 'react';

import type { TData } from './types';

const defaultValue: TData = {
	targetIds: null,
	regSymbiote: () => {},
	unregSymbiote: () => {},
	currentEdit: false,
	mutationKey: null,
	symbioteRoot: {
		current: null,
	},
};

const SymbioteContext = React.createContext<TData>(defaultValue);

export const ContextActiveLevel = React.createContext<?string>(null);
export const ContextSetActiveLevel = React.createContext<(?string) => void>(() => {});

export default SymbioteContext;
