// @flow
import React from 'react';
import { Box, Section, PopupWhite } from '@graphite/uneon';
import { Background as LabelBackground } from '@graphite/labels';
import type {
	TId,
	TSpecsColor,
	TBackgroundValue,
	TBackgroundValues,
	TLibConfig,
	TLibraryValue,
	TSpecsGrid,
	TWidgets,
	TImageLibraryListUploads,
} from '@graphite/types';
import Library from '@graphite/library';

type TProps = $ReadOnly<{|
	t: string => string,
	label: string,
	isSeparated?: boolean,
	source: TBackgroundValues,
	defaultItem: TBackgroundValue,
	config?: ?TLibConfig,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	onChange?: ?(TBackgroundValues) => void,
	genId: string => string,
	images: ?TWidgets,
	insertImage: FileList => void,
	resetImage: () => void,
	removeImage: (imageId: TId) => void,
	uploads: TImageLibraryListUploads,
|}>;

const anchorBoxOuterStyle = {
	position: 'relative',
	margin: '0 -24px 0 -24px',
	height: 0,
};

const anchorBoxInnerStyle = {
	position: 'absolute',
	width: 0,
	height: 0,
	right: 0,
	top: 0,
};

const libBackgroundAll = {
	color: { tabs: ['theme', 'palette', 'custom'] },
	gradient: {},
	bgimage: {},
};

const colorInsertButtons = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};
const colorRemoveButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

const primaryColor = {
	kind: 'color',
	value: 'text.primary',
};

function SectionBackground({
	t,
	label,
	isSeparated = false,
	source,
	config = null,
	defaultItem,
	colorspec,
	gridspec,
	onChange = null,
	genId,
	images,
	insertImage,
	resetImage,
	removeImage,
	uploads,
}: TProps) {
	const [editedColorIdx, setEditedColorIdx] = React.useState<?number>(null);

	const uneditColor = React.useCallback(() => setEditedColorIdx(null), [
		setEditedColorIdx,
	]);

	const clickSection = React.useCallback(
		(e, buttonName) => {
			if (buttonName === 'insert') {
				if (onChange && Array.isArray(source)) {
					onChange([defaultItem, ...source]);
				}
			}
		},
		[defaultItem, onChange, source],
	);

	const clickItem = React.useCallback(
		(e, itemName, buttonName) => {
			const idx = +itemName;
			if (Number.isNaN(idx)) {
				return;
			}
			if (buttonName === 'remove') {
				if (editedColorIdx === itemName) {
					setEditedColorIdx(null);
				}
				if (onChange) {
					onChange([...source.slice(0, idx), ...source.slice(idx + 1)]);
				}
				return;
			}
			if (source[idx]) {
				setEditedColorIdx(idx);
			}
		},
		[source, editedColorIdx, onChange],
	);

	const changeColor = React.useCallback(
		(value: TLibraryValue) => {
			if (
				value.kind !== 'bgimage' &&
				value.kind !== 'color' &&
				value.kind !== 'gradient'
			) {
				return;
			}
			const background: TBackgroundValue = value;

			if (!onChange) {
				return;
			}
			if (editedColorIdx !== null) {
				const idx = +editedColorIdx;
				if (Number.isNaN(idx)) {
					return;
				}
				onChange([...source.slice(0, idx), background, ...source.slice(idx + 1)]);
			}
		},
		[editedColorIdx, source, onChange],
	);

	const anchorRef = React.useRef();

	const sourceMapped = React.useMemo(
		() => source.map((value, i) => ({ value, key: `${i}` })),
		[source],
	);

	const currentSource = React.useMemo(
		() => source.find((value, i) => i === editedColorIdx),
		[source, editedColorIdx],
	);

	return (
		<>
			<Section
				size="xsm"
				isSeparated={isSeparated}
				color={sourceMapped.length ? 'text.primary' : 'text.secondary'}
				label={label}
				buttonGroup={colorInsertButtons}
				onClick={clickSection}
			>
				<Box sx={anchorBoxOuterStyle}>
					<Box ref={anchorRef} sx={anchorBoxInnerStyle} />
				</Box>
				{sourceMapped.map(({ value, key }) => (
					<LabelBackground
						key={key}
						name={key}
						color={value}
						colorspec={colorspec}
						onClick={clickItem}
						isActive={editedColorIdx === +key || false}
						buttonGroup={colorRemoveButtons}
					/>
				))}
			</Section>
			{editedColorIdx !== null && (
				<PopupWhite
					key={
						currentSource
							? `${editedColorIdx || 0}-${currentSource.kind}`
							: 'null'
					}
					isOpen={!!currentSource}
					anchorEl={anchorRef}
					offsetLeft={12}
					offsetTop={-100}
					onClose={uneditColor}
					mutex="color"
					isFixed
				>
					<Library
						t={t}
						config={config || libBackgroundAll}
						value={currentSource || primaryColor}
						onChange={changeColor}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
						images={images}
						insertImage={insertImage}
						removeImage={removeImage}
						resetImage={resetImage}
						uploads={uploads}
					/>
				</PopupWhite>
			)}
		</>
	);
}

export default React.memo<TProps>(SectionBackground);
