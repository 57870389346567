// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import refineOrder from '@graphite/refine-order';
import { getScaledSize } from '@graphite/calc-columns';

import {
	removeColumnFromRow,
	dropToRow,
	reorder,
	refineDropPosition,
	defaultSize,
} from 'libs/calc-rows';
import compose from 'libs/compose';
import refineSizes from 'libs/refine-sizes';
import changeOrder from 'libs/change-order';
import changeSizes from 'libs/change-sizes';
import smartAdjustCol from 'libs/smart-adjust-col';
import cast from 'libs/types/widgets';
import { packToBaskets } from 'libs/utils/array';
import {
	getTrueWidgetIds,
	getWidgetSwappedIds,
	closestDeviceWithKey,
	getPresets,
	getOrder,
} from '@graphite/selectors';
import { defaultDevice } from '@graphite/constants';
import type {
	TId,
	TEntityChildren,
	TWidget,
	TWidgetComposed,
	TOrderDevice,
	TOrder,
	TPosition,
	TWidgetDiff,
	TWidgetEnterResult,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TSize,
	TSizes,
	TWidgetMethodAddWidget,
	TWidgetMethodRemoveWidget,
	TWidgetMethodReorderWidgets,
	TGridBreakpointName,
	TWidgetEnterParams,
	TPositioned,
	TPositionValue,
	TRowInfo,
	TWidgets,
} from '@graphite/types';
import refinePositions from 'libs/refine-positions';
import changePositions from 'libs/change-positions';
import { removeWidgetHook as removeWidgetHookStack } from 'Widget/libs/stack-hooks';

// Called when children ids change, or a child was removed
export const applyChildren = (
	widget: TWidget,
	changed: TEntityChildren,
	currentDevice?: TGridBreakpointName,
): TWidgetDiff => {
	let newOrder: TOrder = refineOrder(
		changeOrder(widget.order, changed),
		widget.children,
		currentDevice,
	);
	let positions: TPositioned = refinePositions(
		widget._id,
		changePositions(widget.positions, changed),
		widget.children,
	);

	let newSizes: TSizes = refineSizes(
		changeSizes(widget.sizes, changed),
		_.pickBy(key => !positions?.[key], { ...widget.children }), // exclude all abs widgets
	);

	const removedId: ?TId = (_.findKey((v: ?TId): boolean => v === null, {
		...changed,
	}): ?TId);

	if (removedId) {
		if (!currentDevice) {
			newOrder = (_.mapValues(
				(deviceOrder: TOrderDevice): TOrderDevice =>
					(_.pickBy((v: number, k: TId): boolean => k !== removedId, {
						...deviceOrder,
					}): TOrderDevice),
				newOrder,
			): TOrder);
		}

		const render2data = getWidgetSwappedIds({
			...widget,
			currentDevice: currentDevice || null,
		});
		const ids = getTrueWidgetIds({
			...widget,
			currentDevice: currentDevice || null,
		}).map(({ trueId }) => trueId);

		const baskets: $ReadOnlyArray<$ReadOnlyArray<TId>> = packToBaskets(
			ids,
			1,
			id =>
				newSizes[render2data[`${id}`]].desktop.width +
				newSizes[render2data[`${id}`]].desktop.margin.left +
				newSizes[render2data[`${id}`]].desktop.margin.right,
		);

		const basket: ?$ReadOnlyArray<TId> = baskets.filter(
			(basket: $ReadOnlyArray<TId>): boolean => _.includes(removedId, basket),
		)[0];

		if (basket) {
			const row = (_.pickBy(
				(v, k: TId) => _.includes(k, basket) && k !== render2data[removedId],
				{ ...newSizes },
			): TSizes);
			newSizes = (_.pickBy((v: TSize, k: TId): boolean => k !== removedId, {
				...newSizes,
				...smartAdjustCol(row, null),
			}): TSizes);
		}
		positions = (_.pickBy((v: TPositionValue, k: TId): boolean => k !== removedId, {
			...positions,
		}): TPositioned);
	}
	const result = {
		sizes: newSizes,
		order: newOrder,
	};

	return _.size(positions) ? { ...result, positions } : result;
};

export const applyPosition = (
	widget: TWidget,
	position: {
		kind: 'grid' | 'absolute',
		row?: TRowInfo,
		prevId?: TId,
		nextId?: TId,
	},
	newId: TId,
	currentDevice?: TGridBreakpointName,
): TWidgetDiff => {
	const { row, prevId, nextId } =
		position.kind === 'grid' ? position : { row: null, prevId: null, nextId: null };

	const { order } = getOrder({
		widget,
		position,
		newId,
		currentDevice,
	});

	// We need generate new sizes for only grid children
	const children: ?TEntityChildren = widget.children
		? _.pickBy(child => !widget?.positions?.[child], widget.children)
		: widget.children;
	let sizes: TSizes = refineSizes(widget.sizes, children);

	if (row === 'new') {
		sizes = { ...sizes, ...smartAdjustCol({}, newId) };
	} else {
		const render2data = getWidgetSwappedIds({
			...widget,
			currentDevice: currentDevice || null,
		});
		const ids = getTrueWidgetIds({
			...widget,
			currentDevice: currentDevice || null,
		}).map(({ trueId }) => trueId);

		const baskets: $ReadOnlyArray<$ReadOnlyArray<TId>> = packToBaskets(ids, 1, id => {
			if (sizes[render2data[`${id}`]] && newId !== id) {
				const device = closestDeviceWithKey(sizes[render2data[`${id}`]], {
					currentDevice: 'desktop',
					key: `sizes-${render2data[`${id}`]}`,
				});

				return device.width + device.margin.left + device.margin.right;
			}
			return 0;
		});

		let compareId: ?TId = row === 'before' ? prevId : nextId;

		if (row === null) {
			compareId = nextId === newId ? prevId : nextId;
		}

		const basket: ?$ReadOnlyArray<TId> = baskets.filter(
			(basket: $ReadOnlyArray<TId>): boolean => _.includes(compareId, basket),
		)[0];

		if (basket) {
			const thisRow: TSizes = (_.pickBy(
				(v: TSize, k: TId): boolean => _.includes(k, basket) && k !== newId,
				{ ...sizes },
			): TSizes);
			sizes = { ...sizes, ...smartAdjustCol(thisRow, newId) };
		} else if (!widget?.positions?.[newId]) {
			sizes = { ...sizes, ...smartAdjustCol({}, newId) };
		}
	}

	return { sizes, order };
};

// Вынимаем из блока
export const leave = async (): Promise<null> => null;

// Кладём в блок
export const enter = async ({
	widgets: widgetsWithoutPresets,
	srcId,
	destContainerId,
	destInstanceId,
	destOriginId,
	position,
	operations,
	currentDevice,
}: TWidgetEnterParams): Promise<?TWidgetEnterResult> => {
	const composed = compose(widgetsWithoutPresets, widgetsWithoutPresets[srcId]);
	if (!composed.userId || !composed.scopeId) return;

	const widgetPresets = getPresets({
		userId: composed.userId,
		scope: composed.scope,
		scopeId: composed.scopeId,
	});
	const widgets: TWidgets = { ...widgetsWithoutPresets, ...widgetPresets };

	const protoCol: ?TWidget = _.find(({ kind }) => kind === 'col', widgetPresets);
	if (!protoCol) return;

	const protoColId = protoCol._id;

	// Если будет вставляться кол, absolute или absolute-container, то ничего не делаем.
	if (
		composed.kind === 'col' ||
		['absolute', 'absolute-container'].includes(position.kind)
	) {
		return null;
	}
	// Если будет вставляться всё что угодно, кроме кола, то нужно обвернуть в кол.

	const protoId = protoColId;

	// Сюда будет записан _id нового виджета
	const feedbackEmpty: TWidgetOpFeedbackEmpty = {};

	const updated = await operations.placeWidget({
		widgets,
		protoId,
		destId: destContainerId,
		destInstanceId,
		destOriginId,
		position,
		feedback: feedbackEmpty,
		currentDevice,
		widget: emptyObject,
	});

	const feedback: ?TWidgetOpFeedbackFull = cast.TWidgetOpFeedbackFull(feedbackEmpty);

	if (!feedback) {
		return null;
	}

	return {
		updated,
		destContainerId: feedback.targetId,
		position: {
			kind: 'grid',
			destRect: position.destRect || null,
			dragRect: position.dragRect || null,
			breakpoints: position.breakpoints || null,
			prevId: null,
			nextId: null,
		},
	};
};

const combineDeviceList = ({
	order,
	sizes,
	currentDevice,
}: $ReadOnly<{|
	order: ?TOrder,
	sizes: ?TSizes,
	currentDevice: ?TGridBreakpointName,
|}>): $ReadOnlyArray<TGridBreakpointName> => {
	const sizesDevices: $ReadOnlyArray<TGridBreakpointName> = _.reduce(
		(devices, widgetSizes: TSize) =>
			devices.concat(_.keys<TGridBreakpointName>(widgetSizes)),
		[],
		sizes || (emptyObject: TSizes),
	);

	const orderDevices = _.keys<TGridBreakpointName>(order || emptyObject);
	const devices = _.uniq([].concat(sizesDevices, orderDevices));
	return devices.length ? devices : [currentDevice || defaultDevice];
};

const mobileSize = { width: 1, margin: { left: 0, right: 0 } };

export const addWidgetHook: TWidgetMethodAddWidget = (
	widgets,
	srcContainerId,
	destContainerId,
	position,
	srcId,
	currentDevice,
) => {
	const { breakpoints, destRect, dragRect } = position.kind === 'grid' ? position : {};
	let placeExtra: TWidgetDiff = emptyObject;
	let srcSizes: TSize = emptyObject;
	let srcOrders: TOrder = emptyObject;

	if (position.kind && position.kind.includes('absolute')) {
		// для абс виджетов необходимо добавить новый order
		const mutatedWidget = _.merge(widgets[destContainerId], {
			children: { [srcId]: srcId },
		});

		const newOrderList = getOrder({
			widget: mutatedWidget,
			position,
			newId: srcId,
			currentDevice,
		});
		return newOrderList;
	}

	if (srcContainerId) {
		const srcContainer: TWidget = widgets[srcContainerId];
		const srcContainerComposed: TWidgetComposed = compose(widgets, srcContainer);

		srcSizes = srcContainerComposed.sizes?.[srcId] || {
			[`${defaultDevice}`]: defaultSize,
		};
		srcOrders = srcContainerComposed.order || emptyObject;
	}

	const destContainer: TWidget = widgets[destContainerId];
	const refinedPosition: TPosition = refineDropPosition({
		widget: destContainer,
		position,
		currentDevice,
	});

	const sizes = srcContainerId
		? { ...destContainer.sizes, [srcContainerId]: srcSizes }
		: { ...destContainer.sizes };

	const devices = combineDeviceList({
		order: { ...destContainer.order, ...srcOrders },
		sizes,
		currentDevice,
	});

	let orderDevices = _.keys(destContainer.order);
	orderDevices = orderDevices.length ? orderDevices : [currentDevice];

	devices.forEach(device => {
		const colAmount = breakpoints?.[device]?.columns || 1;
		const srcScaledSizes = getScaledSize({
			srcSize: closestDeviceWithKey(srcSizes, {
				currentDevice: device,
				key: `sizes-${srcId}`,
			}),
			dragRect,
			destRect,
		});

		placeExtra = _.merge(
			placeExtra,
			dropToRow({
				widget: destContainer,
				srcSizes: srcScaledSizes,
				currentDevice: device,
				position: refinedPosition,
				colAmount,
				srcId,
			}),
		);

		// TODO: fixme UPRO-477 (rewrite breakpoints)
		if (!orderDevices.includes(device)) {
			delete placeExtra.order[device];
		}
	});

	// ToDo Лолирую стоя, удалить это нахуй, когда сделаем мобильный драг, return placeExtra;
	return _.set(
		'sizes',
		_.mapValues(
			wsz => ({ ...wsz, tablet_p: mobileSize, mobile_p: mobileSize }),
			placeExtra.sizes,
		),
		placeExtra,
	);
};

export const removeWidgetHook: TWidgetMethodRemoveWidget = (
	widget,
	position,
	srcId,
	currentDevice,
) => {
	const { breakpoints } = position.kind === 'grid' ? position : {};
	const removeBase: TWidgetDiff = removeWidgetHookStack(
		widget,
		position,
		srcId,
		currentDevice,
	);
	let removeExtra: TWidgetDiff = {};

	// don't skip this hook for absolute widgets
	// it removes empty column sizes
	// if (position.kind && position.kind.includes('absolute')) return removeExtra;

	const devices = combineDeviceList({
		order: widget.order,
		sizes: widget.sizes,
		currentDevice,
	});

	let orderDevices = _.keys(widget.order);
	orderDevices = orderDevices.length ? orderDevices : [currentDevice];

	devices.forEach(device => {
		const colAmount = breakpoints?.[device]?.columns || 1;
		removeExtra = _.merge(
			removeExtra,
			removeColumnFromRow({
				currentDevice: device,
				widget,
				srcId,
				colAmount,
			}),
		);

		// TODO: fixme UPRO-477 (rewrite breakpoints)
		if (!orderDevices.includes(device)) {
			delete removeExtra.order[device];
		}
	});

	const removeData = {
		...removeExtra,
		order: removeBase.order,
	};

	// ToDo убрать, когда сделаем мобильный драг, return removeData;
	return _.set(
		'sizes',
		_.mapValues(
			wsz => ({ ...wsz, tablet_p: mobileSize, mobile_p: mobileSize }),
			removeData.sizes,
		),
		removeData,
	);
};

export const reorderWidgetsHook: TWidgetMethodReorderWidgets = (
	widget,
	position,
	srcId,
	currentDevice,
) => {
	const { breakpoints } = position.kind === 'grid' ? position : {};
	const refinedPosition: TPosition = refineDropPosition({
		widget,
		position,
		currentDevice,
	});
	let reorderExtra: TWidgetDiff = {};

	const devices = combineDeviceList({
		order: widget.order,
		sizes: widget.sizes,
		currentDevice,
	});

	let orderDevices = _.keys(widget.order);
	orderDevices = orderDevices.length ? orderDevices : [currentDevice];

	devices.forEach(device => {
		const colAmount = breakpoints?.[device]?.columns || 1;

		reorderExtra = _.merge(
			reorderExtra,
			reorder({
				currentDevice: device,
				position: refinedPosition,
				widget,
				srcId,
				colAmount,
			}),
		);

		// TODO: fixme UPRO-477 (rewrite breakpoints)
		if (!orderDevices.includes(device)) {
			delete reorderExtra.order[device];
		}
	});

	// ToDo убрать, когда сделаем мобильный драг, return reorderExtra;
	return _.set(
		'sizes',
		_.mapValues(
			wsz => ({ ...wsz, tablet_p: mobileSize, mobile_p: mobileSize }),
			_.omit(Object.keys(widget.positions || {}), reorderExtra.sizes), // exclude all abs widgets
		),
		reorderExtra,
	);
};
