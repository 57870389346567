// @flow
import type { TEffectBorder } from '@graphite/types';

const getBorder = (border: TEffectBorder, factor: number = 1): ?string => {
	try {
		const sizePx = `${border.size * factor}px`;
		if (!border.sides) {
			return sizePx;
		}

		const { n, e, s, w } = border.sides;
		// Order: top-left, top-right, bottom-right, bottom-left
		return [
			n !== null && n !== undefined ? `${n * factor}px` : sizePx,
			e !== null && e !== undefined ? `${e * factor}px` : sizePx,
			s !== null && s !== undefined ? `${s * factor}px` : sizePx,
			w !== null && w !== undefined ? `${w * factor}px` : sizePx,
		].join(' ');
	} catch (e) {
		return null;
	}
};

export default getBorder;
