// @flow

import React from 'react';

import getDisplayName from '@graphite/get-display-name';
import GridHighlight from 'GridHighlight';

import type { TGridHiglightHOCTMinamalProps } from '../constants/types';

const withGridHighlight = <TProps: TGridHiglightHOCTMinamalProps>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const WrappedComponent = (props: TProps) => {
		if (!props.gridspec) return null;

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
				{props.gridHighlight && (
					<GridHighlight
						currentDevice={props.currentDevice}
						gridspec={props.gridspec}
						colAmount={props.colAmount}
						data={props.data}
					/>
				)}
			</>
		);
	};

	WrappedComponent.displayName = `withGridHighlight(${getDisplayName(Component)})`;
	return React.memo<TProps>(WrappedComponent);
};

export default withGridHighlight;
