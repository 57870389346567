// @flow
import { matchPath } from 'react-router-dom';
import type {
	TId,
	TWidgets,
	TWidget,
	TStateEditor,
	TPage,
	TSite,
	TProject,
} from '@graphite/types';

import reSelect from './libs/re-select';

type TGetCurrent = ($ReadOnly<{ router: { location: { pathname: string } } }>) => ?string;

export const getCurrentProjectId: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { projectId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId',
			}) || {};

		return projectId;
	},
)(() => `router@getCurrentProjectId`);

export const getCurrentProject = reSelect<TStateEditor, ?TId, TWidgets, ?TProject>(
	getCurrentProjectId,
	({ widgets }) => widgets,
	(projectId, widgets) => {
		if (!projectId) return null;

		const widget: TWidget = widgets[projectId];
		if (widget?.kind !== 'project') return null;

		return { ...widget, kind: 'project' };
	},
)(() => `router@getCurrentProject`);

export const getCurrentPageId: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { pageId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId/site/:siteId/page/:pageId',
			}) || {};

		return pageId;
	},
)(() => `router@getCurrentPageId`);

export const getCurrentPage = reSelect<TStateEditor, ?TId, TWidgets, ?TPage>(
	getCurrentPageId,
	({ widgets }) => widgets,
	(pageId, widgets) => {
		if (!pageId) return null;

		const widget: TWidget = widgets[pageId];
		if (widget?.kind !== 'page') return null;

		return { ...widget, kind: 'page' };
	},
)(() => `router@getCurrentPage`);

export const getCurrentSiteId: TGetCurrent = reSelect(
	({ router }) => router.location.pathname,
	pathname => {
		const { params: { siteId } = {} } =
			matchPath(pathname, {
				path: '/project/:projectId/site/:siteId',
			}) || {};

		return siteId;
	},
)(() => `router@getCurrentSiteId`);

type TgetCurrentSite = TStateEditor => ?TSite;
export const getCurrentSite: TgetCurrentSite = reSelect<
	TStateEditor,
	?TId,
	TWidgets,
	?TSite,
>(
	getCurrentSiteId,
	({ widgets }) => widgets,
	(siteId, widgets) => {
		if (!siteId) return null;

		const widget: TWidget = widgets[siteId];
		if (widget?.kind !== 'site') return null;
		if (
			typeof widget?.widgetspecId !== 'string' ||
			typeof widget?.gridspecId !== 'string' ||
			typeof widget?.effectspecId !== 'string' ||
			typeof widget?.colorspecId !== 'string'
		)
			return null;

		return {
			...widget,
			kind: 'site',
			widgetspecId: widget?.widgetspecId,
			gridspecId: widget?.gridspecId,
			effectspecId: widget?.effectspecId,
			colorspecId: widget?.colorspecId,
		};
	},
)(() => `router@getCurrentSite`);

export default {};
