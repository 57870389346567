// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Flex, Text } from '@graphite/uneon';
import _ from 'lodash/fp';

const STEPS = [1, 2, 3];

const StepBox = styled.div`
	border: 2px solid rgba(0, 0, 0, 0);
	background-color: ${({ color, theme }) =>
		_.get(color, theme.colors) || theme.colors.text.oncolor};
	border-radius: 50%;
	height: 30px;
	width: 30px;
`;

const StepText = styled(Text)`
	text-align: center;
	margin: 4px 0px;
`;
const StepLine = styled.div`
	width: 69px;
	height: 1px;
	margin: 15px 18px 0 18px;
	background-color: ${({ theme }) => theme.colors.bg.secondary};
`;

type TProps = $ReadOnly<{|
	step: number,
|}>;

function StepsPanel({ step }: TProps) {
	return (
		<Flex>
			{STEPS.map(i => (
				<Flex key={i}>
					<StepBox color={i === step ? 'bg.accent' : 'bg.primaryalt'}>
						<StepText
							variant="headlinesm"
							color={i === step ? 'white' : 'text.primaryalt'}
						>
							{i}
						</StepText>
					</StepBox>
					{i !== 3 && <StepLine />}
				</Flex>
			))}
		</Flex>
	);
}

export default React.memo<TProps>(StepsPanel);
