// @flow
import get from 'lodash/fp/get';
import reSelect from 'libs/re-select';
import type { TSpecsGrid, TGridBreakpointName, TUnit } from '@graphite/types';

export const getActiveWidth = reSelect<
	{
		currentDevice: TGridBreakpointName,
		gridspec: TSpecsGrid,
	},
	TGridBreakpointName,
	TSpecsGrid,
	number | string,
>(
	({ currentDevice }) => currentDevice,
	({ gridspec }) => gridspec,
	(device, gridspec) => {
		if (device === 'phone_p') {
			return '320px';
		}
		const valuePath = `breakpoints.${device}.container`;
		const value: number = get(valuePath, gridspec);
		const valueUnit: TUnit = get(`${valuePath}Unit`, gridspec) || 'px';
		if (valueUnit === 'unit') {
			return `${value * gridspec.unit}px`;
		}
		return `${value}${valueUnit}`;
	},
)(({ currentDevice }) => `site@getActiveWidth-${currentDevice}`);

export const getActiveGutter = reSelect<
	{
		currentDevice: TGridBreakpointName,
		gridspec: TSpecsGrid,
	},
	TGridBreakpointName,
	TSpecsGrid,
	string,
>(
	({ currentDevice }) => currentDevice,
	({ gridspec }) => gridspec,
	(device, gridspec) => {
		const gutterUnit = get(`breakpoints.${device}.gutterUnit`, gridspec) || 'px';
		if (gutterUnit === 'unit') {
			const { unit } = gridspec;
			return `${(get(`breakpoints.${device}.gutter`, gridspec) * unit) / 2}px`;
		}
		return `${get(`breakpoints.${device}.gutter`, gridspec) / 2}${gutterUnit}`;
	},
)(({ currentDevice }) => `getGutters-${currentDevice}`);

export const getActiveColAmount = reSelect<
	{|
		currentDevice: TGridBreakpointName,
		gridspec: TSpecsGrid,
	|},
	TGridBreakpointName,
	TSpecsGrid,
	number,
>(
	({ currentDevice }) => currentDevice,
	({ gridspec }) => gridspec,
	(device, gridspec) => get(`breakpoints.${device}.columns`, gridspec),
)(({ currentDevice }) => `getGutters-${currentDevice}`);

export const getActiveColWidthEdit = reSelect<
	{
		currentDevice: TGridBreakpointName,
		gridspec: TSpecsGrid,
	},
	number | string,
	string,
	string,
>(
	({ currentDevice, gridspec }) => getActiveWidth({ currentDevice, gridspec }),
	({ currentDevice, gridspec }) => getActiveGutter({ currentDevice, gridspec }),
	(width, padding) => {
		if (typeof width !== 'number') {
			return width;
		}
		if (padding.includes('px')) {
			return `${width + parseInt(padding, 10) * 2}px`;
		}
		return `${width}px`;
	},
)(({ currentDevice }) => `site@getActiveColWidth-${currentDevice}`);

export const getActivePaddingEdit = reSelect<
	{
		currentDevice: TGridBreakpointName,
		gridspec: TSpecsGrid,
	},
	TGridBreakpointName,
	TSpecsGrid,
	string,
>(
	({ currentDevice }) => currentDevice,
	({ gridspec }) => gridspec,
	(device, gridspec) => {
		const paddingUnit = get(`breakpoints.${device}.paddingUnit`, gridspec) || 'px';
		if (paddingUnit === 'unit') {
			const { unit } = gridspec;
			return `${(get(`breakpoints.${device}.padding`, gridspec) * unit) / 2}px`;
		}
		return `${get(`breakpoints.${device}.padding`, gridspec) / 2}${paddingUnit}`;
	},
)(({ currentDevice }) => `site@getActivePaddingEdit-${currentDevice}`);
