// @flow
import React from 'react';
import Draggable from 'react-draggable';
import { Box, ButtonColor } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import type { TId, TSpecsColor, TGradientStop } from '@graphite/types';

type TBounds = $ReadOnly<{|
	left: number,
	top: number,
	right: number,
	bottom: number,
|}>;

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	stop: TGradientStop,
	isActive?: boolean,
	width: number,
	onClick?: ?(TId) => void,
	onRemove?: ?(TId) => void,
	onChange?: ?(TGradientStop) => void,
|}>;

const stopOuterSx = {
	position: 'absolute',
};

const stopInnerSx = {
	marginBottom: '18px',
	position: 'relative',
};

const stopLegSx = {
	position: 'absolute',
	width: '1px',
	height: '18px',
	bottom: '-18px',
	left: '50%',
	opacity: 0.1,
	backgroundColor: '#000000',
};

function GradientStop({
	colorspec,
	stop,
	width,
	isActive = false,
	onClick = null,
	onRemove = null,
	onChange = null,
}: TProps) {
	const bounds: TBounds = React.useMemo(
		() => ({ left: 0, right: width, top: onRemove ? -50 : 0, bottom: 0 }),
		[width, onRemove],
	);

	const [ownValue, setOwnValue] = React.useState<number>(stop.at);
	const [opacity, setOpacity] = React.useState<number>(1);

	React.useEffect(() => setOwnValue(stop.at), [stop.at]);

	const stepPixels = width / 100;

	const onDrag = React.useCallback(
		(e, { x, y }) => {
			if (onRemove) {
				setOpacity(((50 + y) / 50) * 0.8 + 0.2);
			}
			const newValue = Math.round(x / stepPixels);
			setOwnValue(newValue);
		},
		[stepPixels, onRemove],
	);

	const onStop = React.useCallback(
		(e, { x, y }) => {
			if (y < -30) {
				if (onRemove) {
					setOpacity(0);
					// Сразу удалять - плохо, потому что ДРАГ ещё не кончил
					setImmediate(() => onRemove(stop.id));
				}
				return;
			}
			const newValue = Math.round(Math.min(100, Math.max(0, x / stepPixels)));
			if (Math.abs(stop.at - newValue) < 1) {
				if (onClick) {
					onClick(stop.id);
				}
				return;
			}
			setOwnValue(newValue);
			if (onChange) {
				onChange({ ...stop, at: newValue });
			}
		},
		[onChange, onClick, onRemove, stepPixels, stop],
	);

	const percent = ownValue * 0.01;
	const x = Math.floor(width * percent);

	const grid = React.useMemo(() => [stepPixels, 1], [stepPixels]);
	const position = React.useMemo(() => ({ x, y: 0 }), [x]);

	const color = React.useMemo(() => getColor(colorspec, stop.color), [
		colorspec,
		stop.color,
	]);

	const stopOuterStyle = React.useMemo(
		() => ({
			opacity,
		}),
		[opacity],
	);

	// const preventClick = useCallback(
	// 	e => {
	// 		e.stopPropagation();
	// 	},
	// 	[],
	// );

	return (
		<Draggable
			// axis="x"
			bounds={bounds}
			position={position}
			grid={grid}
			onDrag={onDrag}
			onStop={onStop}
		>
			<Box sx={stopOuterSx} style={stopOuterStyle}>
				<Box sx={stopInnerSx}>
					<Box sx={stopLegSx} />
					<ButtonColor
						name={stop.id}
						color={color}
						cursor="grab"
						isActive={isActive}
						shape="sm"
					/>
				</Box>
			</Box>
		</Draggable>
	);
}

export default React.memo<TProps>(GradientStop);
