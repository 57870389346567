// @flow
import React from 'react';
import { Box, Text, ButtonDeprecated } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import type { TColorTheme, TSpecsColor } from '@graphite/types';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	name: ?string,
	theme: ?TColorTheme,
	isActive: boolean,
	onClick?: ?(?string) => void,
	t: string => string,
|}>;

const themeBoxStyle = {
	flexShrink: 0,
	width: '74px',
	height: '84px',
	textAlign: 'center',
	cursor: 'pointer',
	marginRight: '20px',
	':nth-of-type(3n)': {
		marginRight: 0,
	},
};

const previewSx = {
	marginTop: '7px',
	marginBottom: '8px',
	position: 'relative',
};
const previewActiveSx = {
	...previewSx,
	boxShadow: 'md',
};

const titleTextSx = {
	div: {
		textOverflow: 'ellipsis',
	},
};

const previewTextStyle = {
	padding: '10px',
	position: 'absolute',
	lineHeight: '100%',
	top: 0,
	left: 0,
	bottom: 0,
	right: 0,
	zIndex: 2,
};

const previewRightSx = {
	position: 'absolute',
	right: 0,
	top: 0,
	bottom: 0,
	left: '50%',
	borderRadius: 'md.right',
	zIndex: 1,
};

function ListItemThemes({
	colorspec,
	name = null,
	theme = null,
	isActive,
	onClick,
	t,
}: TProps) {
	const clickBound = React.useCallback(() => {
		if (onClick) {
			onClick(name);
		}
	}, [name, onClick]);

	const bgPrimary = theme && theme.bg.primary;
	const bgPrimaryalt = theme && theme.bg.primaryalt;
	const textPrimary = theme && theme.text.primary;

	const dynamicPreviewStyle = React.useMemo(
		() =>
			(bgPrimary && {
				backgroundColor: getColor(colorspec, bgPrimary),
			}) ||
			null,
		[colorspec, bgPrimary],
	);
	const dynamicPreviewRightStyle = React.useMemo(
		() =>
			(bgPrimaryalt && {
				backgroundColor: getColor(colorspec, bgPrimaryalt),
			}) ||
			null,
		[colorspec, bgPrimaryalt],
	);
	const dynamicPreviewTextStyle = React.useMemo(
		() =>
			(textPrimary && {
				color: getColor(colorspec, textPrimary),
			}) ||
			null,
		[colorspec, textPrimary],
	);

	return (
		<Box sx={themeBoxStyle} onClick={clickBound}>
			<ButtonDeprecated
				as="div"
				colors={isActive ? 'accentghost' : 'primaryghost'}
				style={dynamicPreviewStyle}
				sx={isActive ? previewActiveSx : previewSx}
			>
				<Box style={dynamicPreviewRightStyle} sx={previewRightSx} />
				<Text
					variant="headlinesm"
					style={dynamicPreviewTextStyle}
					sx={previewTextStyle}
				>
					A
				</Text>
			</ButtonDeprecated>
			<ButtonDeprecated
				as="div"
				size="sm"
				variant="flat"
				label={(theme && theme.name) || t('Default')}
				colors={isActive ? 'accentflat' : 'secondaryflat'}
				sx={titleTextSx}
			/>
		</Box>
	);
}

ListItemThemes.defaultProps = {
	onClick: null,
};

export default React.memo<TProps>(ListItemThemes);
