// @flow
import type { TSpecsColor, TColorReference } from '@graphite/types';

const themeNames = {
	'text.primary': 'Text / Primary',
	'text.primaryalt': 'Text / Primary Alt',
	'text.secondary': 'Text / Secondary',
	'text.tertiary': 'Text / Tertiary',
	'text.accent': 'Text / Accent',
	'text.accentalt': 'Text / Accent Alt',
	'text.success': 'Text / Success',
	'text.error': 'Text / Error',
	'text.warning': 'Text / Warning',
	'text.oncolor': 'Text / On Color',
	'text.accentplus': 'Text / Accent Plus',
	'text.accentaltplus': 'Text / Accentalt Plus',
	'text.successplus': 'Text / Success Plus',
	'text.errorplus': 'Text / Error Plus',
	'text.warningplus': 'Text / Warning Plus',
	'text.accentminus': 'Text / Accent Minus',
	'text.accentaltminus': 'Text / Accentalt Minus',
	'text.successminus': 'Text / Success Minus',
	'text.errorminus': 'Text / Error Minus',
	'text.warningminus': 'Text / Warning Minus',
	'bg.primary': 'Background / Primary',
	'bg.primaryalt': 'Background / Primary Alt',
	'bg.secondary': 'Background / Secondary',
	'bg.tertiary': 'Background / Tertiary',
	'bg.accent': 'Background / Accent',
	'bg.accentalt': 'Background / Accent Alt',
	'bg.success': 'Background / Success',
	'bg.error': 'Background / Error',
	'bg.warning': 'Background / Warning',
	'bg.primaryplus': 'Background / Primary Plus',
	'bg.primaryaltplus': 'Background / Primaryalt Plus',
	'bg.secondaryplus': 'Background / Secondary Plus',
	'bg.tertiaryplus': 'Background / Tertiary Plus',
	'bg.accentplus': 'Background / Accent Plus',
	'bg.accentaltplus': 'Background / Accentalt Plus',
	'bg.successplus': 'Background / Success Plus',
	'bg.errorplus': 'Background / Error Plus',
	'bg.warningplus': 'Background / Warning Plus',
	'bg.primaryminus': 'Background / Primary Minus',
	'bg.primaryaltminus': 'Background / Primaryalt Minus',
	'bg.secondaryminus': 'Background / Secondary Minus',
	'bg.tertiaryminus': 'Background / Tertiary Minus',
	'bg.accentminus': 'Background / Accent Minus',
	'bg.accentaltminus': 'Background / Accentalt Minus',
	'bg.successminus': 'Background / Success Minus',
	'bg.errorminus': 'Background / Error Minus',
	'bg.warningminus': 'Background / Warning Minus',
};

// Закомментирован вывод имени цвета, потому что он здесь не нужен,
// Но нужен в настройках дизайн-система, где будет подставляться в название переменной
// const getHsvName = rgb => {
// 	const chromaColor = chroma(rgb);
// 	const [h, s, v] = chromaColor.hsv();
// 	if (v < 0.1) {
// 		return 'Black';
// 	}
// 	if (s < 0.05) {
// 		return (v < 0.5 && 'Dark Grey') || (v > 0.95 && 'White') || 'Grey';
// 	}
// 	const prefix = (v < 0.5 && 'Dark ') || (s < 0.5 && 'Pale ') || '';
// 	return `${prefix}${colorNames[Math.floor(h)] || 'Red'}`;
// };

export default (spec: TSpecsColor, ref: ?TColorReference): string => {
	try {
		if (!ref) {
			return 'None';
		}
		if (typeof ref === 'string') {
			return themeNames[ref] || 'Unknown';
		}
		if (!ref.entryId) {
			return ref.snapshot.toUpperCase();
		}
		const entry = spec.palette.find(e => e.id === ref.entryId);
		if (!entry) {
			return ref.snapshot.toUpperCase();
		}
		if (!ref.shadeId) {
			return entry.name;
		}
		const shadeIdx = entry.shades.findIndex(s => s.id === ref.shadeId);
		if (shadeIdx < 0) {
			return ref.snapshot.toUpperCase();
		}
		return `${entry.name} / ${shadeIdx}0`;
	} catch (e) {
		return 'None';
	}
};
