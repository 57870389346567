// @flow

import _ from 'lodash/fp';

import type { TWidgetBoxBreakpoint } from '@graphite/types';
import { minHeight, minWidth } from '@graphite/constants';

import type { TParams, TWidthParams, THeightParams, TNewBoxParams } from './types';

const getWidth = ({ dir, computed = {}, x, width }: TWidthParams): number => {
	const { refRect, widthUnit } = computed;

	let ratio = 1;
	if (widthUnit === '%' && refRect) {
		ratio = width / refRect.width;
	}

	let out = width || 0;
	if (dir && dir.includes('left')) {
		out = Math.max(minWidth * ratio, width + x * ratio);
	}
	if (dir && dir.includes('right')) {
		out = Math.max(minWidth * ratio, width - x * ratio);
	}

	return Math.ceil(out * 100) / 100;
};

const getHeight = ({ dir, computed = {}, y, height }: THeightParams): number => {
	const { refRect, heightUnit } = computed;

	let ratio = 1;
	if (heightUnit === '%' && refRect) {
		ratio = height / refRect.height;
	}

	let out = height || 0;
	if (dir && dir.includes('top')) {
		out = Math.max(minHeight * ratio, height + y * ratio);
	}
	if (dir && dir.includes('bottom')) {
		out = Math.max(minHeight * ratio, height - y * ratio);
	}

	return Math.ceil(out * 100) / 100;
};

const getOffset = ({
	dir,
	initialBox,
	x,
	y,
	isHeightEnabled,
	isWidthEnabled,
}: TParams): TWidgetBoxBreakpoint => {
	if (dir === null) {
		return { ...initialBox };
	}

	let offset = _.assign(
		{
			..._.assign(
				isHeightEnabled ? { height: minHeight } : {},
				isWidthEnabled ? { width: minWidth } : {},
			),
		},
		initialBox.offset,
	);

	if (dir.includes('top')) {
		const finalY: number = Math.max(minHeight - offset.height, y);
		if (offset.top === 0 || offset.top) {
			offset = { ...offset, top: offset.top - finalY };
		}
		if (offset.height) {
			offset = { ...offset, height: offset.height + finalY };
		}
	}

	if (dir.includes('bottom')) {
		const finalY: number = Math.max(minHeight - offset.height, -y);
		if (offset.bottom === 0 || offset.bottom) {
			offset = { ...offset, bottom: offset.bottom - finalY };
		}
		if (offset.height) {
			offset = { ...offset, height: offset.height + finalY };
		}
	}

	if (dir.includes('left')) {
		const finalX: number = Math.max(minWidth - offset.width, x);
		if (offset.left === 0 || offset.left) {
			offset = { ...offset, left: offset.left - finalX };
		}
		if (offset.width) {
			offset = { ...offset, width: offset.width + finalX };
		}
	}

	if (dir.includes('right')) {
		const finalX: number = Math.max(minWidth - offset.width, -x);
		if (offset.right === 0 || offset.right) {
			offset = { ...offset, right: offset.right - finalX };
		}
		if (offset.width) {
			offset = { ...offset, width: offset.width + finalX };
		}
	}

	return { ...initialBox, offset };
};

const getMargin = ({
	dir,
	initialBox,
	maxRange,
	x,
	y,
}: TParams): TWidgetBoxBreakpoint => {
	if (dir === null) {
		return initialBox;
	}

	let margin = _.assign(
		{
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
		},
		initialBox.margin,
	);

	// Написал развёрнуто, что бы явно указать какие стороны мы используем тут
	// т.к в типах указаны углы, а не только 4 стороны
	// плюс для каждой стороны есть свои правила

	if (dir === 'top') {
		if (margin.top === 0 || margin.top) {
			let delta = margin.top - y;

			if (delta < 0) {
				delta = 0;
			}
			margin = { ...margin, top: delta };
		}
	}

	if (dir === 'bottom') {
		if (margin.bottom === 0 || margin.bottom) {
			let delta = margin.bottom - y;

			if (delta < 0) {
				delta = 0;
			}

			margin = { ...margin, bottom: delta };
		}
	}

	if (dir === 'left') {
		if (margin.left === 0 || margin.left) {
			let delta = margin.left - x;

			if (maxRange && delta > maxRange) {
				delta = maxRange;
			}

			if (maxRange && delta < -maxRange) {
				delta = -maxRange;
			}

			margin = { ...margin, left: delta };
		}
	}

	if (dir === 'right') {
		if (margin.right === 0 || margin.right) {
			let delta = margin.right + x;

			if (maxRange && delta > maxRange) {
				delta = maxRange;
			}

			if (maxRange && delta < -maxRange) {
				delta = -maxRange;
			}

			margin = { ...margin, right: delta };
		}
	}

	return { ...initialBox, margin };
};

const getSizes = ({
	initialBox,
	computed,
	x,
	y,
	dir,
	isHeightEnabled,
	isWidthEnabled,
}: TParams): TWidgetBoxBreakpoint => {
	const initialHeight = initialBox.height || computed?.refRect?.height || minHeight;
	const initialWidth = initialBox.width || computed?.refRect?.width || minWidth;

	const sizes = {};

	if (isHeightEnabled)
		sizes.height = getHeight({ dir, computed, y, height: initialHeight });

	if (isWidthEnabled) sizes.width = getWidth({ dir, computed, x, width: initialWidth });

	return _.assign(initialBox, sizes);
};

export default ({ propName, data }: TNewBoxParams): TWidgetBoxBreakpoint => {
	const {
		initialBox,
		computed,
		x,
		y,
		dir,
		maxRange,
		isHeightEnabled,
		isWidthEnabled,
	} = data;
	if (propName === 'margin') {
		return getMargin({ initialBox, x, y, dir, maxRange });
	}

	if (propName === 'offset') {
		return getOffset({ initialBox, x, y, dir, isHeightEnabled, isWidthEnabled });
	}

	if (propName === 'sizes') {
		return getSizes({
			initialBox,
			computed,
			x,
			y,
			dir,
			isHeightEnabled,
			isWidthEnabled,
		});
	}

	return { ...data.initialBox };
};
