// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex } from '@graphite/uneon';

import Widget from 'Widget';

import type { TConnectProps } from './constants/types';

const boxSx = {
	flexDirection: 'column',
	position: 'relative',
	overflow: 'hidden',
};

const Page = (props: TConnectProps, ref: React$Ref<*>) => {
	const {
		children,
		data,
		instanceId,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
	} = props;

	return (
		<Flex sx={boxSx} ref={ref} data-kind="page">
			{children ||
				_.map(
					id => (
						<Widget
							id={id}
							containerId={data._id}
							instanceId={data.modified ? data._id : instanceId}
							key={id}
							widgetChain={widgetChain}
							widgetspec={widgetspec}
							colorspec={colorspec}
							gridspec={gridspec}
							effectspec={effectspec}
						/>
					),
					props.babies,
				)}
		</Flex>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Page));
