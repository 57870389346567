// @flow
import reverse from 'lodash/fp/reverse';
import keyBy from 'lodash/fp/keyBy';
import mapValues from 'lodash/fp/mapValues';
import type {
	TWidgetKind,
	TSizeRule,
	TSizeRules,
	TOrder,
	TPositioned,
	TGridBreakpointName,
} from '@graphite/types';

export const fallbackWidget = 'stack';

export const gridBreakpointsNames: $ReadOnlyArray<TGridBreakpointName> = [
	'desktop_hd',
	'desktop',
	'tablet_l',
	'tablet_p',
	'phone_l',
	'phone_p',
];

export const defaultDevice: TGridBreakpointName = 'desktop';
// FIXME: можно будет отковырять после UPRO-399
// export const defaultDevice = gridBreakpointsNames[gridBreakpointsNames.length - 1];

export const deviceGt = (a: TGridBreakpointName, b: TGridBreakpointName): boolean =>
	gridBreakpointsNames.indexOf(a) > gridBreakpointsNames.indexOf(b);

export const widgetKinds: $ReadOnlyArray<TWidgetKind> = [
	'block',
	'stack',
	'col',
	'button',
	'text',
	'image',
	'page',
	'site',
	'spacer',
];

export const mobileDelimiter = 'tablet_p';

const scaleDelimiterIndex: number = gridBreakpointsNames.indexOf(mobileDelimiter);

export const sizeRules: TSizeRules = (mapValues(
	(v: TGridBreakpointName): TSizeRule => ({
		isScaling: scaleDelimiterIndex < gridBreakpointsNames.indexOf(v),
	}),
	(keyBy(x => x, gridBreakpointsNames): {
		[key: TGridBreakpointName]: TGridBreakpointName,
	}),
): TSizeRules);

export const gridBreakpointsNamesReverse: $ReadOnlyArray<TGridBreakpointName> = (reverse(
	gridBreakpointsNames,
): $ReadOnlyArray<TGridBreakpointName>);

/* многие могут использовать ссылку на этот объект, поэтому юзать ТОЛЬКО иммутабельные копии */
export const emptyOrder: TOrder = {
	[`${gridBreakpointsNamesReverse[0]}`]: {},
};

export const emptyPositions: TPositioned = {};

export const minHeight = 20;
export const minWidth = 20;

export default {};
