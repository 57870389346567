// @flow
import { connect } from 'react-redux';
import type { TWidgetOwnProps } from '@graphite/types';

import type { TConnectProps, TWrapperProviderProps } from './constants/types';
import mapStateToProps from './libs/mapStateToProps';
import withThemeProvider from './libs/with-theme-provider';
import withWrapper from './libs/with-wrapper';
import withContainer from './libs/with-container';
import Block from './Block';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(mapStateToProps)(
	withThemeProvider(withWrapper<TWrapperProviderProps>(withContainer(Block))),
);
