// @flow

import type { TWidgetMethodReorderWidgets } from '@graphite/types';
import { getOrder } from '@graphite/selectors';
import repopulate from 'libs/repopulate';

export {
	addWidgetHook,
	removeWidgetHook,
	reorderWidgetsHook,
} from 'Widget/libs/stack-hooks';

export const applyChildren = repopulate;
export const applyPosition: TWidgetMethodReorderWidgets = (
	widget,
	position,
	newId,
	currentDevice,
) =>
	getOrder({
		widget,
		position,
		newId,
		currentDevice,
	});
export const enter = () => null;
export const findDrop = () => null;

// Вынимаем из стека
export const leave = () => null;
