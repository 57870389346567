// @flow

import React from 'react';
import getDisplayName from '@graphite/get-display-name';

/**
 * Прокидывает реф компонента виджета
 */
const withRef = <TProps>(
	Component: React$ComponentType<TProps>,
): React$ComponentType<TProps> => {
	const Hoc = (props: TProps) => {
		const ref = React.useRef();
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} ref={ref} />;
	};
	Hoc.displayName = `withRef(${getDisplayName(Component)})`;
	return React.memo<TProps>(Hoc);
};

export default withRef;
