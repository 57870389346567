// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Flex, Box, Text, Logo } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	logOut: () => void,
	insertProject: () => void,
|}>;

const topBarSx = {
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingTop: '15px',
	paddingBottom: '14px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
};

const logoSx = {
	width: '120px',
};

const menuBarSx = {
	flexDirection: ['column', 'row'],
	justifyContent: 'space-between',
	alignItems: ['start', 'center'],
	paddingTop: '35px',
	paddingBottom: '57px',
	h2: {
		marginRight: '30px',
	},
};

const Header = ({ logOut, insertProject }: TProps) => {
	const { t } = useTranslation();

	return (
		<Box variant="container.fluid">
			<Box variant="container.negative">
				<Flex sx={topBarSx} variant="container.fluid">
					<Link to="/">
						<Logo sx={logoSx} />
					</Link>
					<Button variant="primaryaltflat.flat.sm" onClick={logOut}>
						{t('dashboard.header.logout')}
					</Button>
				</Flex>
			</Box>

			<Flex sx={menuBarSx}>
				<Text as="h2" variant="title1" color="text.primaryalt">
					{t('dashboard.header.projects')}
				</Text>
				<Button variant="accentflat.flat.sm" onClick={insertProject}>
					{t('dashboard.header.new project')}
				</Button>
			</Flex>
		</Box>
	);
};
export default React.memo<TProps>(Header);
