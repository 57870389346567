// @flow
import React from 'react';

import type { TUseResizeWatch } from '../types';

const MUTATION_OBSERVER_OPTIONS = {
	attributes: true,
	childList: true,
	subtree: true,
};

const useResizeWatch: TUseResizeWatch = frameContext => {
	const [mutationKey, setMutationKey] = React.useState(null);
	const isPending = React.useRef(false);

	const handleMutation = React.useCallback(() => {
		if (isPending.current) return;
		isPending.current = true;

		requestAnimationFrame(() => {
			isPending.current = false;
			setMutationKey(Math.random());
		});
	}, [setMutationKey]);

	React.useEffect(() => {
		const { window, document } = frameContext;
		if (!document || !window) return;

		const { body } = document;
		if (!body) return;

		window.addEventListener('resize', handleMutation);

		const observer = new MutationObserver(handleMutation);
		observer.observe(body, MUTATION_OBSERVER_OPTIONS);

		return () => {
			if (observer) observer.disconnect();
			window.removeEventListener('resize', handleMutation);
		};
	}, [frameContext, handleMutation]);

	return [mutationKey];
};

export default useResizeWatch;
