// @flow
import React from 'react';
import styled from '@emotion/styled';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import type { TSx } from '@graphite/types';
import getDisplayName from '@graphite/get-display-name';
import { transitions } from '@graphite/constants';
import type {
	TStackJustifyContent,
	TStackAlignItems,
} from 'Widget/constants/types/stack-props';
import Controls from './Controls';
import Resize from './Resize';
import type { TMinimalProps, TSymbioteProps, TRequiredControls } from './types';

const Concealer = styled.div`
	display: ${({ isActive }) => (isActive ? 'block' : 'none')};
`;

type TPropsMixed = $ReadOnly<{
	...$Exact<TMinimalProps>,
	...$Exact<TSymbioteProps>,
	direction?: string,
	justifyContent?: TStackJustifyContent,
	alignItems?: TStackAlignItems,
}>;

const boxSx: TSx = {
	transitionDuration: transitions.widgetControls.showDuration,
	transitionTimingFunction: transitions.widgetControls.showTiming,
	transitionDelay: transitions.widgetControls.showDelay,
	transitionProperty: 'opacity, border, background-color',
	// пока не работают, потому что надо консилер переделать на опасити

	height: '100%',
	width: '100%',
	pointerEvents: 'none',
};

const withWidgetControls = (requiredControls: TRequiredControls) => <TProps: TPropsMixed>(
	Component: React$ComponentType<$Diff<TProps, TSymbioteProps>>,
): React$ComponentType<TProps> => {
	const WithWidgetControls = (props: TProps, ref) => {
		const {
			data,
			dispatch,
			originId,
			instanceId,
			containerId,
			position,
			currentRef,
			currentDevice,
			widgetMode,
			hoverAvailable,
			gridspec,
			direction,
			justifyContent,
			alignItems,
		} = props;

		const { symbioteRef, hovered, ...ownProps } = props;

		const isAbsolute = position === 'absolute' || position === 'absolute-container';
		const isEdit = widgetMode === 'widget-edit';
		const isResizeControls = widgetMode === 'widget-resize';

		const isControls = (hovered && hoverAvailable) || isResizeControls || isEdit;

		return (
			<Box data-kind="with-widget-controls" sx={boxSx}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...ownProps} data={data} ref={ref} />
				<Concealer isActive={isControls}>
					<Controls
						data={data}
						title={_.capitalize(data.kind || '')}
						position={position}
						originId={originId}
						instanceId={instanceId}
						containerId={containerId}
						currentDevice={currentDevice}
						widgetMode={widgetMode}
						currentRef={currentRef}
						dispatch={dispatch}
					/>
				</Concealer>
				{isResizeControls && (
					<Resize
						isShown={isResizeControls}
						currentDevice={currentDevice}
						gridspec={gridspec}
						requiredControls={requiredControls}
						direction={direction}
						justifyContent={justifyContent}
						alignItems={alignItems}
						isAbsolute={isAbsolute}
					/>
				)}
			</Box>
		);
	};

	WithWidgetControls.displayName = `withWidgetControls(${getDisplayName(Component)})`;
	return React.memo(React.forwardRef(WithWidgetControls));
};

export default withWidgetControls;
