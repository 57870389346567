// @flow
import React from 'react';
import styled from '@emotion/styled';
import { Box as RebassBox } from '@graphite/uneon';
import { getAbsSx } from '@graphite/selectors';
import type { TSx, TSpecsGrid, TWidget } from '@graphite/types';

import AbsContext from '../Context';

type TProps = $ReadOnly<{|
	data: TWidget,
	gridspec: TSpecsGrid,
	children: React$Node,
|}>;

export const Box: React$ComponentType<{}> = styled(RebassBox)`
	position: absolute;
	visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
`;

const Absolute = (props: TProps) => {
	const { data, gridspec, children } = props;

	const { dragId } = React.useContext(AbsContext);

	const dynamicStyle: ?TSx = React.useMemo(() => {
		if (!data.style) {
			return null;
		}
		return {
			height: data.style.absHeight,
			width: data.style.absWidth,
			top: data.style.absTop,
			right: data.style.absRight,
			bottom: data.style.absBottom,
			left: data.style.absLeft,
		};
	}, [data.style]);

	return (
		<Box
			sx={getAbsSx({ data, gridspec })}
			style={dynamicStyle}
			isHidden={dragId === data._id}
		>
			{children}
		</Box>
	);
};

export default Absolute;
