// @flow

import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';
import {
	getWidgetsByKind,
	getImageLibraryListUploads,
	getCurrentSiteId,
} from '@graphite/selectors';
import getMapStateToPropsEdit from 'Widget/libs/primitive/getMapStateToPropsEdit';

const mapDispatchToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const siteId = getCurrentSiteId(state);

	return {
		...getMapStateToPropsEdit(state, ownProps),
		images: getWidgetsByKind(state, {
			kind: 'file',
			scopeId: siteId,
			removedAt: null,
		}),
		uploads: getImageLibraryListUploads(state),
	};
};

export default mapDispatchToPropsEdit;
