// @flow

import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import type { TId, TWidgets } from '@graphite/types';

import type { TDropData, TDropPlaces, TDropRect } from '../constants/types';

type TGetOnlyIdParams = $ReadOnly<{|
	isDefaultDevice: boolean,
	widgets: TWidgets,
	dropId: {| current: ?TId |},
	dropData: {| current: ?TDropData |},
	dropRects: {| current: { [string]: TDropRect } |},
	dropPlaces: {| current: TDropPlaces |},
|}>;

type TGetOnlyIdFn = TGetOnlyIdParams => ?TId;

// Если нельзя перетаскивать в другой контейнер, то эта функция вернёт ДРОП-ИД текущего
// (Она таким образом полностью заменяет findDropId на девайсах кроме дефолтного)
// Если перетаскивать в другой контейнер можно, то вернет `null`
const getOnlyId: TGetOnlyIdFn = ({
	isDefaultDevice,
	widgets,
	dropId,
	dropData,
	dropRects,
	dropPlaces,
}: TGetOnlyIdParams) => {
	if (!isDefaultDevice && dropId.current) {
		return dropId.current;
	}
	if (isDefaultDevice || dropId.current || !dropData.current) {
		return null;
	}
	const { containerId, id } = dropData.current;
	/* Взять виджет-контейнер и у него в чилдрен найти указание на реальный ИД
	 * данного виджета, а уже по этому иду понять какая у него позиция,
	 * и уже тогда привести его к виду драг-ИД
	 */
	const container = widgets[containerId];
	const { positions = emptyObject, children = emptyObject } = container;
	const refId = _.findKey(v => v === id, { ...children });
	if (!refId) {
		return null;
	}

	let finalId = null;
	if (container.kind === 'col') {
		finalId = `${containerId}-col`;
	}
	if (positions[id] === 'absolute-container') {
		finalId = `${containerId}-container`;
	}
	if (container.kind === 'block') {
		finalId = `${containerId}-block`;
	}
	if (container.kind === 'stack') {
		finalId = `${containerId}-stack`;
	}
	const place = finalId && dropPlaces.current && dropPlaces.current[finalId];
	if (place) {
		const rect = place.ref.current && place.ref.current.getBoundingClientRect();
		if (rect) {
			// eslint-disable-next-line no-param-reassign
			dropRects.current[place.dropId] = rect;
		}
	}
	return finalId;
};

export default getOnlyId;
