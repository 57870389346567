// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonDeprecated, Icon, Toolbar, Box, Flex, Text } from '@graphite/uneon';

import useDefaultDevice from 'Editor/libs/use-default-device';

import WidgetAdd from '../WidgetAdd';
import type { TPropsLeftSide } from '../constants/types';

export const leftSideSx = {
	pointerEvents: 'auto',
	position: 'absolute',
	top: '12px',
	left: '12px',
};

const buttonSx = {
	boxShadow: 'sm',
};

const handlerSx = {
	cursor: 'grab',
	marginLeft: '12px',
	userSelect: 'none',
};

const handlerNodragSx = {
	cursor: 'default',
	height: '30px',
	userSelect: 'none',
};

const textSx = {
	marginRight: '6px',
	marginLeft: '6px',
};

const ControlsLeftSide = (props: TPropsLeftSide): React$Node => {
	const { controls, dragHandler, currentDevice } = props;
	const isDefaultDevice = currentDevice === useDefaultDevice();
	const { t } = useTranslation();

	const instance = controls === 'instance';

	return (
		<Flex sx={leftSideSx}>
			{(instance && (
				<ButtonDeprecated
					colors="error"
					icons="pen"
					size="sm"
					title="Edit Component Master"
					shape="rounded"
					sx={buttonSx}
				/>
			)) ||
				(isDefaultDevice && <WidgetAdd />)}
			<div>
				{(isDefaultDevice && (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Box {...dragHandler}>
						<Toolbar variant="sm" sx={handlerSx}>
							<Icon
								size="sm"
								name="dots-horizontal-thin-6"
								colors="secondary"
							/>
							<Text
								sx={textSx}
								variant="captionlg"
								color={instance ? 'spec.violet50' : 'text.primary'}
							>
								{t('Block')}
							</Text>
						</Toolbar>
					</Box>
				)) || (
					<Toolbar variant="sm" sx={handlerNodragSx}>
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<Box {...dragHandler} />
						<Text
							sx={textSx}
							variant="captionlg"
							color={instance ? 'spec.violet50' : 'text.primary'}
						>
							{t('Block')}
						</Text>
					</Toolbar>
				)}
			</div>
		</Flex>
	);
};

export default React.memo<TPropsLeftSide>(ControlsLeftSide);
