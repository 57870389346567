// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';
import withWrapper from './libs/with-wrapper';

import getMapStateToProps from '../../libs/primitive/getMapStateToProps';
import Code from './Code';
import type { TConnectProps } from './constants/types';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(getMapStateToProps)(
	withAbsolute()(withWrapper(Code)),
);
