// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Flex, Text, Box, Button } from '@graphite/uneon';

const DomainCopyPart = styled(Box)`
	flex-direction: raw;
	padding: 10px 18px;
	background-color: ${({ theme }) => theme.colors.bg.primaryalt};
	border-radius: 6px;
	margin-top: ${({ isConnected }) => (isConnected ? 12 : 0)}px;
	margin-bottom: ${({ isConnected }) => (isConnected ? 30 : 107)}px;
`;
const RecText = styled(Text)`
	${({ recLength, recKey, subDomain }) =>
		recLength === 1
			? 'flex-basis: 100%'
			: `flex-basis: ${(recKey === 2 ? 0 : recKey) +
					(subDomain && recKey !== 2 ? 3 : 1)}0%`}
`;

const DomainCopyRecord = styled(Button)`
	position: absolute;
	right: 0px;
	bottom: ${({ isConnected }) => (isConnected ? 4 : 80)}px;
`;

type TProps = $ReadOnly<{|
	isConnected?: boolean,
	subDomain: ?string,
	recordsOptions: ?$ReadOnlyArray<$ReadOnlyArray<string>>,
	t: string => void,
	copyPart: ?string,
|}>;

function Records({
	isConnected = false,
	subDomain = '',
	recordsOptions,
	t,
	copyPart = '',
}: TProps) {
	const copyRecords = React.useCallback(() => {
		const input = document.createElement('input');
		input.setAttribute('value', copyPart || '');
		document.body?.appendChild(input);
		input.select();
		document.execCommand('copy');
		document.body?.removeChild(input);
	}, [copyPart]);

	if (!recordsOptions) return null;

	return (
		<>
			<DomainCopyPart isConnected={isConnected}>
				{recordsOptions.map(record => (
					<Flex key={record.join('')}>
						{record.map((item, key) => (
							<RecText
								variant="codesm"
								color="text.primary"
								key={item}
								recLength={record.length}
								recKey={key}
								subDomain={subDomain}
							>
								{item}
							</RecText>
						))}
					</Flex>
				))}
			</DomainCopyPart>

			<DomainCopyRecord
				isConnected={isConnected}
				variant="accentflat.flat.sm"
				onClick={copyRecords}
			>
				{t('Copy')}
			</DomainCopyRecord>
		</>
	);
}

export default React.memo<TProps>(Records);
