// @flow
import type { TWidgetMethodApplyPosition } from '@graphite/types';

export default async (name: string): Promise<TWidgetMethodApplyPosition> => {
	const {
		applyPosition,
	}: {
		applyPosition: TWidgetMethodApplyPosition,
		// eslint-disable-next-line prefer-template
	} = await import('Widget/Widgets/' + name + '/libs');
	return applyPosition;
};
