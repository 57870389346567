// @flow
import pickBy from 'lodash/fp/pickBy';
import type { TId, TStateEditor, TUsers, TUser, TWidgets } from '@graphite/types';

import reSelect from './libs/re-select';
import { getWidgets } from './widgets';

type TGetUsers = TStateEditor => TUsers;
export const getUsers: TGetUsers = reSelect(getWidgets, (widgets: TWidgets): TUsers =>
	pickBy(({ kind }) => kind === 'user', widgets),
)(() => 'specs@getUsers');

type TGetCurrentUserId = TStateEditor => ?TId;
export const getCurrentUserId: TGetCurrentUserId = reSelect(
	({ users }) => users.currentUser,
	currentUser => currentUser,
)(() => 'users@getCurrentUserId');

type TGetCurrentUser = TStateEditor => ?TUser;
export const getCurrentUser: TGetCurrentUser = reSelect(
	getCurrentUserId,
	getUsers,
	(userId, users) => (userId ? users[userId] : null),
)(() => 'users@getCurrentUser');

type TGetAuthError = TStateEditor => $PropertyType<
	$PropertyType<TStateEditor, 'users'>,
	'authError',
>;
export const getAuthError: TGetAuthError = reSelect(
	({ users }) => users.authError,
	authError => authError,
)(() => 'users@getAuthError');

type TGetIsAuth = TStateEditor => boolean;
export const getIsAuth: TGetIsAuth = reSelect(
	({ users }) => users.isAuth,
	isAuth => isAuth,
)(() => 'users@getIsAuth');
export default {};
