// @flow

import React from 'react';
import _ from 'lodash/fp';

import { Section } from '@graphite/uneon';
import { emptySelection } from '@graphite/constants';
import type { TDesignProps, TParams } from '@graphite/types';
import useBreakpoint from '@graphite/use-breakpoint';
import { Params as ListParams } from '@graphite/lists';

const paramList: TParams = [
	{
		title: 'Border',
		key: 'border',
		kind: 'select',
		info: {
			label: 'None',
			list: {
				items: [],
			},
		},
	},
];

function Style({
	design,
	device,
	gridspec: { unit },
	effectspec,
	onChange = null,
}: TDesignProps) {
	const { paramSource, changeParam } = useBreakpoint(onChange, design, device);

	const paramListFinal: TParams = React.useMemo(
		() =>
			_.set(
				[0, 'info', 'list', 'items'],
				[
					emptySelection,
					...effectspec.borders.map(border => ({
						name: border.id,
						label: border.name,
					})),
				],
				paramList,
			),
		[effectspec.borders],
	);

	return (
		<>
			<Section label="Style">
				<ListParams
					listName={`${device}-c`}
					paramSource={paramSource}
					paramList={paramListFinal}
					unit={unit}
					onChange={changeParam}
				/>
			</Section>
		</>
	);
}

export default React.memo<TDesignProps>(Style);
