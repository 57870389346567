// @flow
import React from 'react';
import { Flex } from '@graphite/uneon';
import type { TId, TSpecsColor } from '@graphite/types';

import ListItemThemes from './ListItemThemes';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	activeTheme: ?TId,
	hasNull?: ?boolean,
	onClick?: ?(?string) => void,
	t: string => string,
|}>;

const themeFlexStyle = {
	flexWrap: 'wrap',
};

function ListThemes({ colorspec, activeTheme, hasNull, onClick, t }: TProps) {
	const { themes } = colorspec;

	return (
		<Flex sx={themeFlexStyle}>
			{hasNull && (
				<ListItemThemes
					key="_null"
					name={null}
					theme={null}
					colorspec={colorspec}
					isActive={activeTheme === null}
					onClick={onClick}
					t={t}
				/>
			)}
			{themes.map(theme => {
				return (
					<ListItemThemes
						key={theme.id}
						name={theme.id}
						theme={theme}
						colorspec={colorspec}
						isActive={activeTheme === theme.id}
						onClick={onClick}
						t={t}
					/>
				);
			})}
		</Flex>
	);
}

ListThemes.defaultProps = {
	hasNull: false,
	onClick: null,
};

export default React.memo<TProps>(ListThemes);
