// @flow
import type { TSx } from '@graphite/types';
import { zIndices } from '@graphite/constants';

export const wrapperSx: TSx = {
	position: 'relative',
	backgroundColor: 'bg.primaryminus',
	overflow: 'scroll',
	top: '54px',
	height: 'calc(100vh - 54px)',

	'::-webkit-scrollbar': {
		width: '8px',
		height: '8px',
	},
	'::-webkit-scrollbar-button': {
		width: '0px',
		height: '0px',
	},
	'::-webkit-scrollbar-thumb': {
		background: '#FFF2',
		border: '0px none #FFF3',
		borderRadius: '8px',
	},
	'::-webkit-scrollbar-thumb:hover': {
		background: '#FFF4',
	},
	'::-webkit-scrollbar-track': {
		background: 'transparent',
		border: '0px none transparent',
		borderRadius: '8px',
	},
	'::-webkit-scrollbar-track:hover': {
		background: '#FFF1',
	},
	'::-webkit-scrollbar-corner': {
		background: 'transparent',
	},
};

export const overlayOpenSx: TSx = {
	position: 'fixed',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	zIndex: zIndices.pageOverlay,
};

export const overlayClosedSx: TSx = {
	display: 'none',
};

export const grabStyle: TSx = {
	cursor: 'grab',
};

export const grabbingStyle: TSx = {
	cursor: 'grabbing',
};

export const resizeStyle: TSx = {
	cursor: 'ew-resize',
};

export const resizeSideSx: TSx = {
	backgroundColor: 'transparent',
	borderRadius: 'rounded.all',
	top: '0',
	height: '100%',
	width: '18px',
	position: 'absolute',
	cursor: 'ew-resize',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'background-color 0.15s ease-out',
	':hover': {
		backgroundColor: 'bg.primaryalt',
		':after': {
			opacity: '1',
		},
	},
	':after': {
		content: '""',
		backgroundColor: 'text.tertiary',
		opacity: '.5',
		borderRadius: 'rounded.all',
		width: '3px',
		margin: '18px 0',
		height: '50%',
		maxHeight: '30px',
		position: 'sticky',
		top: 'calc(50% - 15px)',
		bottom: 'calc(50% - 15px)',
		transition: 'opacity 0.15s ease-out',
	},
};

export const resizeLeftSx: TSx = {
	...resizeSideSx,
	left: '-27px',
};

export const resizeLeftActiveSx: TSx = {
	...resizeLeftSx,
	backgroundColor: 'bg.primaryalt',
};

export const resizeRightSx: TSx = {
	...resizeSideSx,
	right: '-27px',
};

export const resizeRightActiveSx: TSx = {
	...resizeRightSx,
	backgroundColor: 'bg.primaryalt',
};

export default {};
