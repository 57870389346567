// @flow
import React from 'react';
import type { TWidgetOwnProps } from '@graphite/types';
import { getTrueIds, getCurrentUserId, getWidget } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import { useTranslation } from 'react-i18next';
import faker from 'faker';

import { removeWidget, placeWidget } from 'Editor/ducks/widgets';

import Project from './Project';

const ProjectEdit = ({ id, instanceId, containerId }: TWidgetOwnProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const project = useSelector(state => getWidget(state, { id }));
	const projectName = project?.name;
	const userId = useSelector(getCurrentUserId);
	const originId = userId;

	const babies = project
		? getTrueIds({
				...project,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];

	const onRemove = React.useCallback(() => {
		if (!originId) return;

		dispatch(removeWidget(id, containerId, instanceId, originId));
	}, [containerId, dispatch, id, instanceId, originId]);

	const onInsert = React.useCallback(() => {
		dispatch(
			placeWidget(
				'site',
				id,
				null,
				id,
				{
					kind: 'grid',
					prevId: null,
					nextId: null,
					destRect: null,
					dragRect: null,
					breakpoints: null,
				},
				{ name: faker.address.streetName() },
			),
		);
	}, [dispatch, id]);

	if (!projectName) return null;

	return (
		<Project
			id={id}
			projectName={projectName}
			babies={babies}
			t={t}
			onRemove={onRemove}
			onInsert={onInsert}
		/>
	);
};

export default React.memo<TWidgetOwnProps>(ProjectEdit);
