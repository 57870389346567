// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Text, Box } from '@graphite/uneon';
import Records from '../Records';

const DomainFinishPart = styled(Box)`
	flex-direction: raw;
	padding: 10px 18px;
	background-color: ${({ theme }) => theme.colors.bg.warningplus};
	border-radius: 6px;
	margin-top: 18px;
	margin-bottom: 77px;
`;

const DomainText = styled(Text)`
	${({ isAdd }) => (isAdd ? 'margin: 24px 0' : 'margin-top: 24px')};
	& > div {
		display: inline;
	}
`;

const BoxRecords = styled(Box)`
	position: relative;
`;

type TProps = $ReadOnly<{|
	subDomain: ?string,
	step: number,
	t: string => void,
	domain: ?string,
	copyPart: ?string,
	recordsOptions: ?$ReadOnlyArray<$ReadOnlyArray<string>>,
|}>;

function Steps({
	subDomain = '',
	step,
	t,
	domain = '',
	copyPart = '',
	recordsOptions,
}: TProps) {
	const timeFihish = React.useMemo(() => {
		if (step !== 3 && timeFihish) return timeFihish;
		const day = new Date();
		const hours = day.getHours();
		const minutes = day.getMinutes();
		return `${hours >= 10 ? hours : `0${hours}`}:${
			minutes >= 10 ? minutes : `0${minutes}`
		}`;
	}, [step]);

	return (
		<>
			{step === 1 && (
				<DomainText variant="bodymd" color="text.primary">
					{t(
						`Enter the domain you want to connect.
				You must be the owner of this domain.`,
					)}
				</DomainText>
			)}
			{step === 2 && (
				<BoxRecords>
					<DomainText variant="bodymd" color="text.primary">
						{t(
							subDomain
								? 'Create an CNAME record for '
								: 'Create A records for ',
						)}
						<Text variant="headlinemd" color="text.primary">
							{subDomain || domain}
						</Text>
						{subDomain && t(' subdomain of ')}
						{subDomain && (
							<Text variant="headlinemd" color="text.primary">
								{domain?.replace(`${subDomain}.`, '')}
							</Text>
						)}
						{t(' at your domain registrar dashboard.')}
					</DomainText>
					<DomainText isAdd variant="bodymd" color="text.primary">
						{t('Records should point to ')}
						<Text variant="headlinemd" color="text.primary">
							{copyPart}
						</Text>
					</DomainText>

					<Records
						copyPart={copyPart}
						recordsOptions={recordsOptions}
						subDomain={subDomain}
						t={t}
					/>
				</BoxRecords>
			)}
			{step === 3 && (
				<>
					<DomainText variant="bodymd" color="text.primary">
						{t(
							`After completing the previous steps,
				 click the “Connect” button.`,
						)}
					</DomainText>
					<DomainFinishPart>
						<Text variant="bodymd" color="text.primary">
							{t('The domain will start working no later than ')}
							{timeFihish}
							{t(' tomorrow.')}
						</Text>
					</DomainFinishPart>
				</>
			)}
		</>
	);
}

export default React.memo<TProps>(Steps);
