// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@graphite/uneon';
import type { TDesignProps, TDesignImageLibProps } from '@graphite/types';
import _ from 'lodash/fp';
import { Theme as SectionTheme } from '@graphite/sections';

// import ListItemParams from 'Editor/Lists/Params/ListItemParams';

import Style from './Style';
import Color from './Color';

const titleStyle = {
	marginBottom: '18px',
	cursor: 'default',
};

type TDesignBlockProps = $ReadOnly<{|
	...$Exact<TDesignProps>,
	...$Exact<TDesignImageLibProps>,
|}>;

function DesignBlock({
	design,
	gridspec,
	colorspec,
	effectspec,
	widgetspec,
	onChange = null,
	device,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignBlockProps) {
	const { t } = useTranslation();

	const onChangeTheme = React.useCallback(
		id => {
			if (onChange) {
				onChange(_.set('theme', id, design));
			}
		},
		[design, onChange],
	);

	// Имя дизайна блока закоментировано до создания интерфейса, который позволит эти имена менять
	// const nameParamProps = useNameParamProps(design, onChange, t);

	return (
		<>
			<Text variant="title4" color="text.primaryalt" sx={titleStyle}>
				{t('Design')}
			</Text>
			{/* Имя дизайна блока закоментировано до создания интерфейса, который позволит имена менять */}
			{/* <ListItemParams {...nameParamProps} /> */}
			<SectionTheme
				label={t('Theme')}
				active={design.theme}
				colorspec={colorspec}
				onChange={onChangeTheme}
				t={t}
			/>
			<Style
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
			/>
			<Color
				design={design}
				device={device}
				gridspec={gridspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				colorspec={colorspec}
				onChange={onChange}
				t={t}
				genId={genId}
				insertImage={insertImage}
				removeImage={removeImage}
				resetImage={resetImage}
				images={images}
				uploads={uploads}
			/>
		</>
	);
}

export default React.memo<TDesignBlockProps>(DesignBlock);
