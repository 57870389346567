// @flow
type TCheckMove = ($ReadOnly<{| x: number, +y: number |}>) => boolean;

const DELAY = 2;

const checkMove: TCheckMove = ({ x, y }) =>
	// Math.abs(x) + Math.abs(y) > DELAY
	// eslint-disable-next-line no-bitwise
	(x ^ (x >> 31)) - (x >> 31) + (y ^ (y >> 31)) - (y >> 31) > DELAY;

export default checkMove;
