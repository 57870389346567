// @flow
import type { TStateEditor, TWidgetOwnProps } from '@graphite/types';
import { getCurrentPageId } from '@graphite/selectors';

import mapStateToProps from './mapStateToProps';

const mapStateToPropsEdit = (state: TStateEditor, ownProps: TWidgetOwnProps) => {
	const currentPage = getCurrentPageId(state);
	const { editor } = state;
	return {
		...mapStateToProps(state, ownProps),
		// FixMe: нужно только для драга, не должно быть здесь
		originId: currentPage,
		currentDevice: editor.currentDevice,
	};
};

export default mapStateToPropsEdit;
