// @flow
import type { TEffectRadius } from '@graphite/types';

const getRadius = (radius: TEffectRadius, factor: number = 1): ?string => {
	try {
		const sizePx = `${radius.size * factor}px`;
		if (!radius.sides) {
			return sizePx;
		}

		const { nw, ne, se, sw } = radius.sides;
		// Order: top-left, top-right, bottom-right, bottom-left
		return [
			nw !== null && nw !== undefined ? `${nw * factor}px` : sizePx,
			ne !== null && ne !== undefined ? `${ne * factor}px` : sizePx,
			se !== null && se !== undefined ? `${se * factor}px` : sizePx,
			sw !== null && sw !== undefined ? `${sw * factor}px` : sizePx,
		].join(' ');
	} catch (e) {
		return null;
	}
};

export default getRadius;
