// @flow
import React from 'react';
import { useSelector } from 'react-redux';

import Widget from 'Widget';
import { anyKind } from 'Widget/libs';
import ComponentContext from 'ComponentContext';

import type {
	TWidget,
	TSpecsGrid,
	TSpecsWidget,
	TSpecsColor,
	TSpecsEffect,
} from '@graphite/types';
import {
	getCurrentPageId,
	getCurrentSite,
	getCurrentSiteGridSpec,
	getCurrentSiteWidgetSpec,
	getCurrentSiteColorSpec,
	getCurrentSiteEffectSpec,
} from '@graphite/selectors';

export type TProps = $ReadOnly<{||}>;

const Published = () => {
	const pageId = useSelector(getCurrentPageId);
	const site: ?TWidget = useSelector(getCurrentSite);

	const gridspec: ?TSpecsGrid = useSelector(getCurrentSiteGridSpec);
	const widgetspec: ?TSpecsWidget = useSelector(getCurrentSiteWidgetSpec);
	const colorspec: ?TSpecsColor = useSelector(getCurrentSiteColorSpec);
	const effectspec: ?TSpecsEffect = useSelector(getCurrentSiteEffectSpec);

	if (!pageId || !site || !widgetspec || !colorspec || !gridspec || !effectspec)
		return 'Page not found';

	return (
		<ComponentContext.Provider value={anyKind('get-view-component')}>
			<Widget
				id={pageId}
				containerId={null}
				instanceId={null}
				widgetspec={widgetspec}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
			/>
		</ComponentContext.Provider>
	);
};

export default React.memo<TProps>(Published);
