// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Button, Flex, Box, Text } from '@graphite/uneon';
import type { TId } from '@graphite/types';

import Widget from 'Widget';

import EmptySite from './EmptySite';

type TProps = $ReadOnly<{|
	id: TId,
	projectName: string,
	babies: $ReadOnlyArray<TId>,
	t: string => string,
	onRemove: () => void,
	onInsert: () => void,
|}>;

const headerSx = {
	justifyContent: 'space-between',
	alignItems: 'flex-end',
	marginBottom: '30px',
	paddingBottom: '16px',
	borderBottomWidth: '2px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'text.primaryalt',
};

const headersButtonSx = {
	marginLeft: '18px',
};

const sitesSx = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
};

const Project = ({ id, projectName, babies, t, onRemove, onInsert }: TProps) => {
	const onRemoveHandler = React.useCallback(
		e => {
			e.stopPropagation();
			onRemove();
		},
		[onRemove],
	);

	const onInsertHandler = React.useCallback(
		(e: Event) => {
			e.stopPropagation();
			onInsert();
		},
		[onInsert],
	);

	return (
		<>
			<Flex sx={headerSx}>
				<Text as="h4" variant="title4" color="text.primaryalt">
					{projectName}
				</Text>
				<Flex>
					<Button
						variant="primaryaltflat.flat.sm"
						onClick={onRemoveHandler}
						sx={headersButtonSx}
					>
						{t('dashboard.project.remove')}
					</Button>
				</Flex>
			</Flex>

			<Box variant="grid" sx={sitesSx}>
				{babies.map(babyId => (
					<Widget key={babyId} containerId={id} instanceId={null} id={babyId} />
				))}
				<EmptySite
					insertSiteHandler={onInsertHandler}
					isEmptySite={_.isEmpty(babies)}
				/>
			</Box>
		</>
	);
};

export default React.memo<TProps>(Project);
