// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Box } from '@graphite/uneon';
import type { TSx, TPositionValue, TSpecsGrid } from '@graphite/types';
import { getBoxSx } from '@graphite/selectors';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import type {
	TDragPropsFlip,
	TDragPropsContainer,
} from 'Widget/libs/dnd/constants/types';
import type { TWidgetCode } from '../constants/types';

type TMinimalProps = $ReadOnly<{
	dragContainer?: TDragPropsContainer,
	dragFlip?: TDragPropsFlip,
	data: TWidgetCode,
	position: TPositionValue,
	direction: 'vertical' | 'horizontal',
	gridspec: TSpecsGrid,
}>;

const withWrapper = <T: TMinimalProps>(Component: React$ComponentType<T>) => {
	const WidgetWrapper = (props: T, ref) => {
		const { dragContainer, dragFlip, data, position, direction, gridspec } = props;

		const boxSx = React.useMemo(() => {
			const sx = getBoxSx({ data, position, direction, gridspec });

			return {
				...sx,
				flexDirection: 'column',
			};
		}, [data, position, direction, gridspec]);

		const dynamicStyle: ?TSx = useDynamicStyle(data.style);

		const otherProps = _.assign(dragContainer, dragFlip);

		return (
			<Box
				ref={ref}
				style={dynamicStyle}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...otherProps}
				sx={boxSx}
			>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
			</Box>
		);
	};

	return React.memo<T>(React.forwardRef(WidgetWrapper));
};

export default withWrapper;
