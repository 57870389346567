// @flow

import _ from 'lodash/fp';
import refineOrder from '@graphite/refine-order';
import type {
	TId,
	TOrder,
	TOrderDevice,
	TPositioned,
	TPositionValue,
	TWidget,
	TWidgetDiff,
	TGridBreakpointName,
	TEntityChildren,
} from '@graphite/types';

import changeOrder from 'libs/change-order';
import refinePositions from 'libs/refine-positions';
import changePositions from 'libs/change-positions';

export default (
	widget: $ReadOnly<TWidget>,
	changed: $ReadOnly<TEntityChildren>,
	currentDevice?: TGridBreakpointName,
): TWidgetDiff => {
	// FIXME: Я хз как правильно: так, или вариант ниже
	// let order: TOrder = changeOrder(
	// 	refineOrder(widget.order, widget.children, currentDevice), changed
	// );
	let order: TOrder = refineOrder(
		changeOrder(widget.order, changed),
		widget.children,
		currentDevice,
	);
	// пока что оба херово работают

	let positions: TPositioned = refinePositions(
		widget._id,
		changePositions(widget.positions, changed),
		widget.children,
	);

	const removedId: ?TId = currentDevice
		? null
		: (_.findKey((v: ?TId): boolean => v === null, { ...changed }): ?TId);

	if (removedId) {
		order = (_.mapValues(
			(deviceOrder: TOrderDevice): TOrderDevice =>
				(_.pickBy((v: number, k: TId): boolean => k !== removedId, {
					...deviceOrder,
				}): TOrderDevice),
			order,
		): TOrder);

		positions = (_.pickBy((v: TPositionValue, k: TId): boolean => k !== removedId, {
			...positions,
		}): TPositioned);
	}

	return ({ order, positions }: TWidgetDiff);
};
