// @flow
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { defaultDevice, gridBreakpointsNames } from '@graphite/constants';
import type { TSizes, TWidget, TGridBreakpointName } from '@graphite/types';
import {
	calcColumns,
	flatDelimiters,
	getDelimiters,
	groupColumnsToRow,
} from '@graphite/calc-columns';
import type {
	TColumnsGroup,
	TColumnsGroupRow,
	TColumnsOrderList,
} from '@graphite/calc-columns';

import reSelect from './libs/re-select';

const mapValues = _.mapValues.convert({ cap: false });

/**
 * returns sorted list
 * from source device to the start of array,
 * then from the source device to the end of array
 *
 * for example: 4, [1, 2, 3, 4, 5, 6] → [4, 3, 2, 1, 5, 6]
 *
 * Пиздёж...
 * Вернёт массив, от current и по порядку от малого к большому.
 * т.к у нас mobile last должен быть, то нужно было сделать
 * от current и по порядку от большого к малому.
 * current = 2, [current, 6, 5, 4, 3, 1]
 */
export const getSortedClosestDeviceList: (
	name: TGridBreakpointName,
) => $ReadOnlyArray<TGridBreakpointName> = reSelect<
	TGridBreakpointName,
	TGridBreakpointName,
	$ReadOnlyArray<TGridBreakpointName>,
>(
	name => name,
	name => {
		let isFound = false;
		return gridBreakpointsNames.reduce((deviceList, current) => {
			if (current === name) {
				isFound = true;
				return [current, ...deviceList];
			}

			if (isFound) return [...deviceList];
			return [current, ...deviceList];
		}, []);
	},
)(name => `grid@sortedClosestDeviceList-${name}`);

const clearFromNull = reSelect<
	{},
	{
		key: string,
	},
	{},
	{},
>(
	(list): {} => list,
	list => _.omitBy(_.isNil, list),
)((list, { key }) => `grid@clearFromNull-${key}`);

type TList<T> = ?$ReadOnly<{ +[TGridBreakpointName]: T }>;
type TBreakpoint = ?TGridBreakpointName;
type TMin = $ReadOnly<*>;
type TClosestDeviceKey = $ReadOnly<{| currentDevice: ?TBreakpoint, key: string |}>;

// Ищем ближайший девайс
export const closestDeviceWithKey: <T>(TList<T>, TClosestDeviceKey) => T = reSelect(
	(list: TList<TMin>) => list,
	(list: TList<TMin>, { currentDevice }: TClosestDeviceKey) => currentDevice,
	(list: TList<TMin>, { key }: TClosestDeviceKey) => key,
	(list: TList<TMin>, currentDevice, key): TMin => {
		if (!list || typeof list !== 'object') {
			return emptyObject;
		}

		const device: TGridBreakpointName = currentDevice || defaultDevice;

		const sortedDeviceList = getSortedClosestDeviceList(device);

		const closest: ?TGridBreakpointName = sortedDeviceList.find(
			// eslint-disable-next-line no-shadow
			device => !!list[device],
		);

		// получаем ближайшие доступные данные по девайсам
		// если они конечно же есть
		const params = closest ? clearFromNull(list[closest], { key }) : emptyObject;

		// временный объект, что бы собрать все данные из соседних брейкпоинтов
		// типа наследование, но на самом деле это не совсем так
		// иногда в box currentDevice отсутствует свойство, которое есть у девайса выше
		// в таком случае нужно добавить это свойство к box currentDevice
		let mergedParams = {
			...params,
		};

		// Вот тут мы дополним недостающими данными box currentDevice
		_.forEachRight(item => {
			mergedParams = {
				...list[item],
				...mergedParams,
			};
		}, sortedDeviceList);

		return mergedParams;
	},
)((__, { key }) => `grid@closestDeviceWithKey${key}`);

export const preferDevice = reSelect<
	$ReadOnlyArray<TGridBreakpointName>,
	?TGridBreakpointName,
	$ReadOnlyArray<TGridBreakpointName>,
	TGridBreakpointName,
	TGridBreakpointName,
>(
	(
		availableBreakpoints: $ReadOnlyArray<TGridBreakpointName>,
	): $ReadOnlyArray<TGridBreakpointName> => availableBreakpoints,
	(availableBreakpoints, currentDevice: ?TGridBreakpointName): TGridBreakpointName =>
		currentDevice || defaultDevice,
	(
		availableDevices: $ReadOnlyArray<TGridBreakpointName>,
		currentDevice: TGridBreakpointName,
	): TGridBreakpointName => {
		if (availableDevices.includes(currentDevice)) {
			return currentDevice;
		}
		return availableDevices.length
			? availableDevices[availableDevices.length - 1]
			: currentDevice;
	},
)(
	(availableDevices, currentDevice) =>
		`grid@preferDevice-${availableDevices.join(',')}-${currentDevice || '?'}`,
);

export const getColSizeMap = reSelect<
	$ReadOnly<{|
		data: TWidget,
		currentDevice: TGridBreakpointName,
		colAmount: number,
		orderList: TColumnsOrderList,
	|}>,
	{} | TSizes,
	TGridBreakpointName,
	number,
	TColumnsOrderList,
	TColumnsGroup,
>(
	({ data: { sizes } }) => sizes || emptyObject,
	({ currentDevice }) => currentDevice,
	({ colAmount }) => colAmount,
	({ orderList }) => orderList,
	(columns, currentDevice, colAmount, orderList) => {
		const deviceColumns = mapValues((column, id) => {
			const sizes = closestDeviceWithKey(column, {
				currentDevice,
				key: `size-${id}`,
			});
			return {
				width: sizes.width,
				marginLeft: sizes.margin.left,
				marginRight: sizes.margin.right,
			};
		}, columns);

		return groupColumnsToRow({
			columns: deviceColumns,
			orderList,
		}).map((columnsInRow: TColumnsGroupRow) => {
			// eslint-disable-next-line no-shadow
			const { orderList, nextId, beforeId, ...columns } = columnsInRow;

			const delimiters = flatDelimiters(getDelimiters(orderList.length, colAmount));

			return _.assign(
				{
					orderList,
					nextId,
					beforeId,
				},
				calcColumns({ columns, orderList, delimiters }),
			);
		});
	},
)(
	({ data: { _id }, currentDevice, colAmount }) =>
		`grid@getColSizeMap-${currentDevice}-${_id}-${colAmount}`,
);
