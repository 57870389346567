// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn, PopupWhite } from '@graphite/uneon';
import type {
	TSpecsColor,
	TDesign,
	TGridBreakpointName,
	TLibColorValue,
	TLibraryValue,
	TSpecsGrid,
} from '@graphite/types';
import { closestDeviceWithKey } from '@graphite/selectors';
import { defaultBlackRef } from '@graphite/constants';
import getTrueColor from '@graphite/get-color';
import Library from '@graphite/library';

import { getColor, toggleColor } from '../libs/draft-utils';

const libConfig = { color: { tabs: ['theme', 'palette', 'custom'] } };

const defaultLibColor = {
	kind: 'color',
	value: defaultBlackRef,
};

type TProps = $ReadOnly<{|
	device: TGridBreakpointName,
	design: TDesign,
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	genId: string => string,
|}>;

const ColorBtn = styled.div`
	background: ${({ currentColor }) => currentColor || 'black'};
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	width: 18px;
	height: 18px;
`;

const Colors = ({
	device,
	design,
	editorState,
	setEditorState,
	colorspec,
	gridspec,
	setOpenedPanel,
	openedPanel,
	genId,
}: TProps) => {
	const { t } = useTranslation();
	const colorBtnEl = React.useRef(null);
	const handleColor = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setOpenedPanel(openedPanel => (openedPanel === 'color' ? null : 'color'));
		},
		[setOpenedPanel],
	);
	const changeColor = React.useMemo(() => toggleColor(editorState), [editorState]);
	const currentColor: TLibColorValue = React.useMemo(() => {
		const inlineColor = getColor(editorState);
		if (!inlineColor && design.target === 'text') {
			const breakpoint = closestDeviceWithKey(design.breakpoints, {
				currentDevice: device,
				key: `breakpoint-${design._id}`,
			});
			const designColor = breakpoint.normal?.text?.color;
			if (designColor) {
				return {
					kind: 'color',
					value: designColor,
				};
			}
		}
		return inlineColor || defaultLibColor;
	}, [design, editorState, device]);
	const handleChangeColor = React.useCallback(
		(value: TLibraryValue) => {
			const editorStateNew = changeColor(value);
			setEditorState(editorStateNew);
		},
		[setEditorState, changeColor],
	);
	return (
		<>
			<ToolbarBtn onMouseDown={handleColor} ref={colorBtnEl}>
				<ColorBtn currentColor={getTrueColor(colorspec, currentColor.value)} />
			</ToolbarBtn>

			<PopupWhite
				isOpen={openedPanel === 'color'}
				onClose={handleColor}
				anchorEl={colorBtnEl}
				offsetTop={10}
				isFixed
				mutex="color"
			>
				{openedPanel === 'color' && (
					<Library
						t={t}
						config={libConfig}
						value={currentColor}
						onChange={handleChangeColor}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(Colors);
