// @flow
import React from 'react';
import type { TWidgetOwnProps } from '@graphite/types';
import { getTrueIds, getCurrentUserId, getWidget } from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';
import { useHistory } from 'react-router-dom';

import { removeWidget } from 'Editor/ducks/widgets';

import Site from './Site';

const SiteEdit = ({ id, instanceId, containerId }: TWidgetOwnProps) => {
	const dispatch = useDispatch();
	const history = useHistory();

	const projectId = containerId;
	const siteId = id;
	const site = useSelector(state => getWidget(state, { id }));
	const siteName = site?.name;
	const userId = useSelector(getCurrentUserId);
	const originId = userId;

	const babies = site
		? getTrueIds({
				...site,
				currentDevice: 'desktop', // Мы в дашбоарде, здесь нет девайсов
		  })
		: [];
	const pageId = babies[0];

	const onRemove = React.useCallback(() => {
		if (!siteId || !originId) return;

		dispatch(removeWidget(siteId, projectId, instanceId, originId));
	}, [dispatch, instanceId, originId, projectId, siteId]);

	const onSelect = React.useCallback(() => {
		if (!containerId || !siteId) return;

		history.push(`/project/${containerId}/site/${siteId}/page/${pageId}`);
	}, [containerId, history, pageId, siteId]);

	if (!projectId || !siteId || !siteName) return null;

	return <Site siteName={siteName} onRemove={onRemove} onSelect={onSelect} />;
};

export default React.memo<TWidgetOwnProps>(SiteEdit);
