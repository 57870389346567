// @flow
import React from 'react';
import _ from 'lodash/fp';
import type {
	TId,
	TSpecsColor,
	TColorReference,
	TGradient,
	TGradientStop,
	TColorTabs,
} from '@graphite/types';
import { defaultGradient } from '@graphite/constants';
import { Params as ListParams } from '@graphite/lists';

import LibColors from '../Color';
import GradientStopSlider from './GradientStopSlider';

type TProps = $ReadOnly<{|
	value?: TGradient,
	onChange?: ?(TGradient) => void,
	colorspec: TSpecsColor,
	genId: string => string,
|}>;

const colorTabs: TColorTabs = ['theme', 'palette', 'custom'];

const paramListCommon = [
	{
		title: 'Type',
		key: 'kind',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'linear', label: 'Linear' },
					{ name: 'radial', label: 'Radial' },
				],
			},
		},
	},
	// ---------- Это пока спрятано
	// {
	// 	title: 'Repeating',
	// 	key: 'isRepeating',
	// 	kind: 'switch',
	// 	info: {},
	// },
];

const paramListLinear = [
	...paramListCommon,
	{
		title: 'Angle',
		key: 'angle',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'unitDegree',
		},
	},
];

const paramListRadial = [
	...paramListCommon,
	{
		title: 'Shape',
		key: 'shape',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'circle', label: 'Circle' },
					{ name: 'ellipse', label: 'Ellipse' },
				],
			},
		},
	},
	{
		title: 'Size',
		key: 'size',
		kind: 'select',
		info: {
			list: {
				items: [
					{ name: 'closest-side', label: 'Closest Side' },
					{ name: 'farthest-side', label: 'Farthest Side' },
					{ name: 'closest-corner', label: 'Closest Corner' },
					{ name: 'farthest-corner', label: 'Farthest Corner' },
				],
			},
		},
	},
	{
		title: 'X',
		key: 'x',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'unitPercent',
		},
	},
	{
		title: 'Y',
		key: 'y',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: 'unitPercent',
		},
	},
];

function Gradient({
	colorspec,
	value = defaultGradient,
	onChange = null,
	genId,
}: TProps) {
	const stopsSorted: $ReadOnlyArray<TGradientStop> = React.useMemo(
		() => _.values(value.stops).sort((a, b) => a.at - b.at),
		[value.stops],
	);

	const [editedId, setEditedId] = React.useState<TId>(stopsSorted[0].id);

	const paramList = value.kind === 'linear' ? paramListLinear : paramListRadial;

	const paramSource = React.useMemo(() => {
		const common = {
			kind: value.kind,
			unitDegree: '°',
			unitPercent: '%',
		};
		if (value.kind === 'linear') {
			return {
				...common,
				angle: `${value.angle}`,
			};
		}
		return {
			...common,
			shape: value.shape,
			size: value.size,
			x: `${value.x}`,
			y: `${value.y}`,
		};
	}, [value]);

	const changeParam = React.useCallback(
		(key, changed) => {
			if (!onChange) {
				return;
			}
			if (key === 'kind') {
				if (changed === 'linear') {
					onChange({
						kind: 'linear',
						stops: value.stops,
						angle: 0,
						isRepeating: false,
					});
				} else if (changed === 'radial') {
					onChange({
						kind: 'radial',
						stops: value.stops,
						isRepeating: false,
						shape: 'circle',
						size: 'closest-side',
						x: 50,
						y: 50,
					});
				}
				return;
			}
			onChange(_.set(key, changed, value));
		},
		[onChange, value],
	);

	const clickStop = React.useCallback(id => {
		if (id) {
			setEditedId(id);
		}
	}, []);

	const changeStops = React.useCallback(
		stops => {
			if (onChange) {
				onChange(_.set('stops', stops, value));
			}
		},
		[onChange, value],
	);

	const changeStop = React.useCallback(
		(color: TColorReference) => {
			if (onChange) {
				onChange(_.set(`stops.${editedId}.color`, color, value));
			}
		},
		[onChange, editedId, value],
	);

	return (
		<>
			<ListParams
				listName="gradient"
				paramSource={paramSource}
				paramList={paramList}
				unit={1}
				onChange={changeParam}
			/>
			<GradientStopSlider
				stops={stopsSorted}
				colorspec={colorspec}
				active={editedId}
				onClick={clickStop}
				onChange={changeStops}
				genId={genId}
			/>
			<LibColors
				tabs={colorTabs}
				value={value.stops[editedId].color}
				onChange={changeStop}
				colorspec={colorspec}
			/>
		</>
	);
}

export default React.memo<TProps>(Gradient);
