// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { ToolbarBtn } from '@graphite/uneon';

import {
	getBlockStyles,
	applyBlockStyle,
	type TBlockStyle,
} from '../../libs/draft-utils';

type TPropsBlockStylesBtn = $ReadOnly<{|
	iconName: string,
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	value: TBlockStyle,
|}>;

const BlockStylesBtn = (props: TPropsBlockStylesBtn) => {
	const { iconName, editorState, setEditorState, value } = props;

	const changeInlineStyle = React.useMemo(() => {
		const setBlockStyle = applyBlockStyle(editorState);

		return e => {
			e.preventDefault();

			const editorStateNew = setBlockStyle(value);
			setEditorState(editorStateNew);
		};
	}, [editorState, setEditorState, value]);

	return (
		<ToolbarBtn
			onMouseDown={changeInlineStyle}
			iconName={iconName}
			isActive={getBlockStyles(editorState)(value)}
		/>
	);
};

export default React.memo<TPropsBlockStylesBtn>(BlockStylesBtn);
