// @flow

import React from 'react';
import { Box } from '@graphite/uneon';
import getDisplayName from '@graphite/get-display-name';

import { transitions } from '@graphite/constants';
import type { TMinimalPropsWithControls, TPropControls } from '../constants/types';

import Controls from '../Controls';

const hiddenSx = {
	opacity: 0,
	pointerEvents: 'none',
	transitionDuration: transitions.blockControls.hideDuration,
	transitionTimingFunction: transitions.blockControls.hideTiming,
	transitionDelay: transitions.blockControls.hideDelay,
	transitionProperty: 'opacity',
};
const visibleSx = {
	opacity: 1,
	pointerEvents: 'auto',
	transitionDuration: transitions.blockControls.showDuration,
	transitionTimingFunction: transitions.blockControls.showTiming,
	transitionDelay: transitions.blockControls.showDelay,
	transitionProperty: 'opacity',
};

const withControls = <
	TProps: TMinimalPropsWithControls,
	WrapedComponent: React$ComponentType<$Diff<TProps, { controls: TPropControls }>>,
>(
	Component: WrapedComponent,
): React$AbstractComponent<TProps, mixed> => {
	const WithControls = (props: TProps) => {
		const { controls, ...otherProps } = props;
		const [openedPanel, setOpenedPanel] = React.useState<?string>(null);
		const isActive = (openedPanel || otherProps.hovered) && controls !== 'none';

		React.useEffect(() => {
			if (!isActive && openedPanel !== null) {
				setOpenedPanel(null);
			}
		}, [isActive, openedPanel]);

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...otherProps} />
				<Box sx={isActive ? visibleSx : hiddenSx} data-kind="block-controls">
					<Controls
						dragHandler={props.dragHandler}
						data={props.data}
						setData={props.setData}
						currentDevice={props.currentDevice}
						gridspec={props.gridspec}
						colorspec={props.colorspec}
						effectspec={props.effectspec}
						widgetspec={props.widgetspec}
						controls={controls}
						containerId={props.containerId}
						instanceId={props.instanceId}
						originId={props.originId}
						hovered={props.hovered}
						dispatch={props.dispatch}
						openedPanel={openedPanel}
						setOpenedPanel={setOpenedPanel}
						insertImage={props.insertImage}
						removeImage={props.removeImage}
						resetImage={props.resetImage}
						images={props.images}
						uploads={props.uploads}
					/>
				</Box>
			</>
		);
	};

	WithControls.displayName = `withControls(${getDisplayName(Component)})`;

	return React.memo<TProps>(WithControls);
};

export default withControls;
