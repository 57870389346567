// @flow

import React from 'react';
import { Box } from '@graphite/uneon';
import { zIndices } from '@graphite/constants';

import useDefaultDevice from 'Editor/libs/use-default-device';

import type { TPropsControls } from '../constants/types';
import ControlsRightSide from './RightSide';
import ControlsLeftSide from './LeftSide';
import ControlsBottomSide from './BottomSide';

const wrapperSx = {
	pointerEvents: 'none',
	position: 'absolute',
	top: '0',
	right: '0',
	bottom: '0',
	left: '0',
	zIndex: zIndices.blockControls,
	':before': {
		content: '""',
		position: 'absolute',
		top: '0',
		right: '0',
		bottom: '0',
		left: '0',
		outlineWidth: '2px',
		outlineStyle: 'solid',
		outlineColor: 'spec.blue10',
		opacity: '0.5',
	},
};

const BlockControls = (props: TPropsControls) => {
	const {
		gridspec,
		colorspec,
		effectspec,
		widgetspec,
		data,
		setData,
		currentDevice,
		controls,
		dragHandler,
		hovered,
		containerId,
		instanceId,
		originId,
		dispatch,
		openedPanel,
		setOpenedPanel,
		insertImage,
		removeImage,
		resetImage,
		images,
		uploads,
	} = props;

	const isDefaultDevice = currentDevice === useDefaultDevice();

	return (
		<Box sx={wrapperSx}>
			<ControlsLeftSide
				currentDevice={currentDevice}
				controls={controls}
				dragHandler={dragHandler}
			/>
			<ControlsRightSide
				gridspec={gridspec}
				colorspec={colorspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				currentDevice={currentDevice}
				containerId={containerId}
				instanceId={instanceId}
				originId={originId}
				data={data}
				setData={setData}
				hovered={hovered}
				dispatch={dispatch}
				openedPanel={openedPanel}
				setOpenedPanel={setOpenedPanel}
				insertImage={insertImage}
				removeImage={removeImage}
				resetImage={resetImage}
				images={images}
				uploads={uploads}
			/>
			{isDefaultDevice && (
				<ControlsBottomSide data={data} originId={originId} dispatch={dispatch} />
			)}
		</Box>
	);
};

export default React.memo<TPropsControls>(BlockControls);
