// @flow
import React from 'react';
import { Box, Input, Switch, ListItem, ButtonDeprecated } from '@graphite/uneon';
import { defaultLink } from '@graphite/constants';
import type { TLinkReference } from '@graphite/types';

type TProps = $ReadOnly<{|
	value?: TLinkReference,
	onChange?: ?(TLinkReference) => void,
|}>;

const switchBoxSx = {
	justifyContent: 'start',
};

const buttonBoxSx = {
	padding: '18px 0',
	textAlign: 'right',
};

const buttonStyle = {
	display: 'inline',
};

const LibLink = ({ value = defaultLink, onChange = null }: TProps) => {
	const [ownValue, setOwnValue] = React.useState<TLinkReference>(value || defaultLink);

	React.useEffect(
		() => {
			if (value.href !== ownValue.href || value.isNewTab !== ownValue.isNewTab) {
				setOwnValue(value);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[value],
	);

	const handleText = React.useCallback(
		e => {
			const { target } = e;
			if (!(target instanceof window.HTMLInputElement)) {
				return;
			}
			const href = target.value || '';
			setOwnValue(prev => ({ ...prev, href }));
		},
		[setOwnValue],
	);

	const handleNewTab = React.useCallback(() => {
		const isNewTab = !ownValue.isNewTab;
		setOwnValue(prev => ({ ...prev, isNewTab }));
	}, [setOwnValue, ownValue.isNewTab]);

	const handleSubmit = React.useCallback(
		e => {
			e.preventDefault();
			if (onChange) {
				onChange(ownValue);
			}
		},
		[ownValue, onChange],
	);

	const refInput: { current: React$ElementRef<'input'> | null } = React.useRef(null);
	React.useEffect(() => {
		if (refInput.current) {
			/* $FlowFixMe(>=0.102.0) preventScroll эксперементальная опция
			 * */
			refInput.current.focus({ preventScroll: true });
		}
	}, []);

	return (
		<form onSubmit={handleSubmit}>
			<ListItem size="xlg">
				<Input
					width="100%"
					placeholder="Paste URL"
					value={ownValue.href}
					onChange={handleText}
					ref={refInput}
				/>
			</ListItem>
			<ListItem size="xlg" sx={switchBoxSx}>
				<Switch
					checked={ownValue.isNewTab}
					onClick={handleNewTab}
					label="Open in New Window"
				/>
			</ListItem>
			<Box sx={buttonBoxSx}>
				<ButtonDeprecated
					colors="accent"
					label="Apply"
					onClick={handleSubmit}
					style={buttonStyle}
					type="submit"
				/>
			</Box>
		</form>
	);
};

export default React.memo<TProps>(LibLink);
