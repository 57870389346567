// @flow
import { ButtonDeprecated } from '@graphite/uneon';
import styled from '@emotion/styled';

const ButtonAuth: React$ComponentType<{}> = styled(ButtonDeprecated)`
	width: 100%;
	margin-bottom: 18px;
`;

export default ButtonAuth;
