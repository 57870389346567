// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Input, Text, Box } from '@graphite/uneon';

const DomainInp = styled(Input)`
	width: 100%;
	${({ error, theme }) => (error ? `border-color: ${theme.colors.bg.error}` : '')};
`;
const DomainInpText = styled(Text)`
	margin-top: 9px;
`;

const BoxInput = styled(Box)`
	margin: ${({ error }) => (!error ? '18px 0 77px 0' : '18px 0 50px 0')};
	${({ hidden }) => (hidden ? 'display: none' : '')}
`;

type TProps = $ReadOnly<{|
	onClick: () => void,
	error: number,
	t: string => void,
	domainRef: {| current: ?React$ElementRef<any> |},
	step: number,
|}>;

function DomainInput({ onClick, error, t, domainRef, step }: TProps) {
	const onKeyDown = React.useCallback(
		e => (e.key === 'Enter' || e.keyCode === 13) && onClick(),
		[onClick],
	);
	return (
		<BoxInput error={!!error} hidden={step !== 1}>
			<DomainInp
				placeholder="example.com or sub.example.com"
				ref={domainRef}
				error={!!error}
				onKeyDown={onKeyDown}
			/>
			{!!error && (
				<DomainInpText variant="captionlg" color="text.error">
					{t(error === 1 ? 'Invalid domain name' : 'Please enter domain')}
				</DomainInpText>
			)}
		</BoxInput>
	);
}

export default React.memo<TProps>(DomainInput);
