// @flow
import React, { memo, useMemo } from 'react';
import emptyFunction from 'empty/function';
import mapValues from 'lodash/fp/mapValues';
import getColor from '@graphite/get-color';
import { Section, Separator, Expand } from '@graphite/uneon';
import type { TSpecsColor, TColorTheme } from '@graphite/types';

import ListCircleColors from '../CircleColors';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	theme: TColorTheme,
	themeAt: number,
	active?: ?string,
	onClick?: string => void,
|}>;

const separatorSx = {
	marginLeft: '-24px',
	marginRight: '-24px',
};

const themeSlots = {
	text: {
		main: ['primary', 'primaryalt', 'secondary', 'tertiary', 'oncolor'],
		accent: ['accent', 'accentalt'],
		accentplus: ['accentplus', 'accentaltplus'],
		accentminus: ['accentminus', 'accentaltminus'],
		state: ['success', 'error', 'warning'],
		stateplus: ['successplus', 'errorplus', 'warningplus'],
		stateminus: ['successminus', 'errorminus', 'warningminus'],
	},
	bg: {
		main: ['primary', 'primaryalt', 'secondary'],
		accent: ['accent', 'accentalt'],
		accentplus: ['accentplus', 'accentaltplus'],
		accentminus: ['accentminus', 'accentaltminus'],
		state: ['success', 'error', 'warning'],
		stateplus: ['successplus', 'errorplus', 'warningplus'],
		stateminus: ['successminus', 'errorminus', 'warningminus'],
	},
};

function ListThemeColors({ colorspec, theme, themeAt, active, onClick }: TProps) {
	const textSlotLists = useMemo(
		() =>
			mapValues(
				v =>
					v.map(key => ({
						id: `themes.${themeAt}.text.${key}`,
						color: getColor(colorspec, theme.text[key]),
					})),
				themeSlots.text,
			),
		[theme.text, colorspec, themeAt],
	);

	const bgSlotLists = useMemo(
		() =>
			mapValues(
				v =>
					v.map(key => ({
						id: `themes.${themeAt}.bg.${key}`,
						color: getColor(colorspec, theme.bg[key]),
					})),
				themeSlots.bg,
			),
		[theme.bg, colorspec, themeAt],
	);

	return (
		<>
			<Section label="Text">
				<Expand variant="bodysm" title="Main">
					<ListCircleColors
						active={active}
						items={textSlotLists.main}
						onClick={onClick}
					/>
				</Expand>
				<Expand
					variant="bodysm"
					title="Accent"
					additional={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<>
							<ListCircleColors
								active={active}
								items={textSlotLists.accentplus}
								onClick={onClick}
							/>
							<ListCircleColors
								active={active}
								items={textSlotLists.accentminus}
								onClick={onClick}
							/>
						</>
					}
				>
					<ListCircleColors
						active={active}
						items={textSlotLists.accent}
						onClick={onClick}
					/>
				</Expand>
				<Expand
					variant="bodysm"
					title="State"
					additional={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<>
							<ListCircleColors
								active={active}
								items={textSlotLists.stateplus}
								onClick={onClick}
							/>
							<ListCircleColors
								active={active}
								items={textSlotLists.stateminus}
								onClick={onClick}
							/>
						</>
					}
				>
					<ListCircleColors
						active={active}
						items={textSlotLists.state}
						onClick={onClick}
					/>
				</Expand>
			</Section>
			<Separator sx={separatorSx} />
			<Section label="Background">
				<Expand variant="bodysm" title="Main">
					<ListCircleColors
						active={active}
						items={bgSlotLists.main}
						onClick={onClick}
					/>
				</Expand>
				<Expand
					variant="bodysm"
					title="Accent"
					additional={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<>
							<ListCircleColors
								active={active}
								items={bgSlotLists.accentplus}
								onClick={onClick}
							/>
							<ListCircleColors
								active={active}
								items={bgSlotLists.accentminus}
								onClick={onClick}
							/>
						</>
					}
				>
					<ListCircleColors
						active={active}
						items={bgSlotLists.accent}
						onClick={onClick}
					/>
				</Expand>
				<Expand
					variant="bodysm"
					title="State"
					additional={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<>
							<ListCircleColors
								active={active}
								items={bgSlotLists.stateplus}
								onClick={onClick}
							/>
							<ListCircleColors
								active={active}
								items={bgSlotLists.stateminus}
								onClick={onClick}
							/>
						</>
					}
				>
					<ListCircleColors
						active={active}
						items={bgSlotLists.state}
						onClick={onClick}
					/>
				</Expand>
			</Section>
		</>
	);
}

ListThemeColors.defaultProps = {
	active: null,
	onClick: emptyFunction,
};

export default memo<TProps>(ListThemeColors);
