// @flow
import React from 'react';

type TUseScrollEffectParams = $ReadOnly<{|
	el: mixed,
|}>;
type TUseScrollEffectHook = TUseScrollEffectParams => void;

const useScrollEffect: TUseScrollEffectHook = ({ el }: TUseScrollEffectParams) => {
	React.useEffect(() => {
		if (el && el instanceof HTMLElement) {
			el.scrollTo(el.scrollLeft, Math.floor(window.innerHeight * 0.8));
		}
	}, [el]);

	React.useEffect(() => {
		if (el && el instanceof HTMLElement) {
			el.scrollTo(Math.floor(window.innerWidth * 0.8), el.scrollTop);
		}
	}, [el]);
};

export default useScrollEffect;
