// @flow
import type { TLibName, TLibraryValue } from '@graphite/types';
import {
	defaultColorRef,
	defaultGradient,
	defaultShadow,
	defaultImage,
	defaultBgImage,
	defaultIcon,
	defaultLink,
} from './defaults';

export const libNames: $ReadOnlyArray<TLibName> = [
	'color',
	'bgimage',
	'image',
	'gradient',
	'shadow',
	'icon',
	'link',
];

export const libLabels: $ReadOnly<{ [TLibName]: string }> = {
	color: 'Color',
	gradient: 'Gradient',
	shadow: 'Shadow',
	image: 'Image',
	bgimage: 'Image',
	icon: 'Icon',
	link: 'Link',
};

export const defaultValues: $ReadOnly<{ [TLibName]: TLibraryValue }> = {
	color: { kind: 'color', value: defaultColorRef },
	gradient: { kind: 'gradient', value: defaultGradient },
	shadow: { kind: 'shadow', value: defaultShadow },
	image: { kind: 'image', value: defaultImage },
	bgimage: { kind: 'bgimage', value: defaultBgImage },
	icon: { kind: 'icon', value: defaultIcon },
	link: { kind: 'link', value: defaultLink },
};

export default {};
