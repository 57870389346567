// @flow
import React from 'react';
import _ from 'lodash/fp';

import type { TUseFindTargetIds } from '../types';

const forIn = _.forIn.convert({ cap: false });

const useFindTargetIds: TUseFindTargetIds = (refs, setTargetIds) => {
	const findTargetIds = React.useCallback(
		(target, overWidget = {}) => {
			const ids = [];
			let isSameParent = false;
			// looking for target in currentRefs
			if (refs) {
				forIn((ref, id) => {
					if (!ref.currentRefs) return;
					ref.currentRefs.forEach(({ current }) => {
						if (current && current.contains(target)) {
							ids.push(id);
							isSameParent =
								isSameParent ||
								overWidget.containerId === ref.containerId;
						}
					});
				}, refs);
			}

			// Go out if block control is overed but outside of block element (+)
			if (overWidget.kind === 'block') {
				const isOverInBlock = overWidget.currentRefs.some(
					ref => ref.current && ref.current.contains(target),
				);
				if (!isOverInBlock) return;
			}

			// Go out if some widget is overed and we try to over other widget in the another container
			if (overWidget.containerId && !isSameParent) {
				return;
			}

			setTargetIds(targetIds => {
				const targetIdsNew = _.isEqual(targetIds, ids) ? targetIds : ids;
				return targetIdsNew;
			});
		},
		[refs, setTargetIds],
	);

	return findTargetIds;
};

export default useFindTargetIds;
