// @flow
import React from 'react';
import _ from 'lodash/fp';

type TState = {|
	x: number,
	y: number,
|};

type TProps = $ReadOnly<{|
	children: React$Node,
|}>;

export const CursorContext = React.createContext<() => TState>(() => ({
	x: 0,
	y: 0,
}));

function CursorProvider({ children }: TProps) {
	const position = React.useRef<TState>({ x: 0, y: 0 });

	const onMouseUpdate = React.useMemo(
		() =>
			_.throttle(10, (e: MouseEvent) => {
				position.current = {
					x: e.pageX,
					y: e.pageY,
				};
			}),
		[],
	);

	React.useEffect(() => {
		document.addEventListener('mousemove', onMouseUpdate, false);
		document.addEventListener('mouseenter', onMouseUpdate, false);
		return () => {
			document.removeEventListener('mousemove', onMouseUpdate);
			document.removeEventListener('mouseenter', onMouseUpdate);
		};
	});

	const getPosition = React.useCallback(() => position.current, []);

	return (
		<CursorContext.Provider value={getPosition}>{children}</CursorContext.Provider>
	);
}

export default React.memo<TProps>(CursorProvider);
