// @flow
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withAbsolute, withAbsoluteDrag } from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';

import withSymbiote from 'Editor/libs/with-symbiote';
import withControls from 'Editor/libs/with-controls';
import checkActiveWidget from 'Editor/libs/check-active-widget';
import withDrag from 'Widget/libs/dnd/drag';
import withWidgetControls, { withWidgetResizer } from 'Widget/libs/with-widget-controls';
import withWidgetEdit from 'Widget/libs/with-widget-edit';
import withRef from 'Widget/libs/with-ref';

import withEditable from './libs/with-editable';
import withEditorState from './libs/with-editor-state';
import Controls from './Controls';
import type {
	TConnectPropsEdit,
	TConnectPropsDrag,
	TMinimalEditableProps,
} from './constants/types';

import mapDispatchToPropsEdit from './libs/mapDispatchToPropsEdit';
import mapStateToPropsEdit from './libs/mapStateToPropsEdit';

const WidgetComponentEdit = compose(
	// берём данные из стейта
	connect<TConnectPropsEdit, TWidgetOwnProps, _, _, _, _>(
		mapStateToPropsEdit,
		mapDispatchToPropsEdit,
	),
	// добавляет реф, который прокидывается через все ХОКи прямо вглубь
	Component => withRef<TConnectPropsEdit>(Component),
	withDrag,
	withWidgetResizer,
	withSymbiote(
		compose(
			withWidgetEdit,
			withWidgetControls({ margin: true, height: false, width: true }),
		),
	),
	// ...
	withSymbiote(
		// ...
		withAbsoluteDrag,
		{
			level: 'abs-drag-place',
		},
	),
	withAbsolute<TConnectPropsDrag>(),
	withEditorState,
	withSymbiote(withControls(checkActiveWidget, Controls)),
)(withEditable<TMinimalEditableProps>());

export default WidgetComponentEdit;
