// @flow
import React from 'react';
import { anyKind } from 'Widget/libs';
import type { TGetComponent } from '@graphite/types';

const ComponentContext = React.createContext<TGetComponent>(
	anyKind('get-view-component'),
);

export default ComponentContext;
