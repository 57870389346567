// @flow

import React from 'react';
import { Box } from '@graphite/uneon';
import type { TSx } from '@graphite/types';

import Context, { type TContext } from './Context';

type TProps = $ReadOnly<{|
	children: React$Node,
|}>;

const wrapperSx = {
	pointerEvents: 'none',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
};

const frameSx = {
	background: '#fff',
	position: 'relative',
	pointerEvents: 'auto',
};

function FrameProvider({ children }: TProps) {
	const elBox = React.useRef(null);
	const isSet = React.useRef(false);
	const [context, setContext] = React.useState({
		window: null,
		document: null,
	});

	const setData = React.useCallback((window, document) => {
		if (isSet.current) return;

		isSet.current = true;

		setContext({ window, document });
	}, []);

	const value: TContext = React.useMemo<TContext>(
		() => ({
			...context,
			setData,
			elBox,
		}),
		[context, elBox, setData],
	);

	const [wh, setWh] = React.useState<[number, number]>([
		window.innerWidth,
		window.innerHeight,
	]);

	const frameStyle: TSx = React.useMemo<TSx>(
		() => ({
			margin: [`${Math.floor(wh[1] * 0.8)}px`, `auto`].join(' '),
		}),
		[wh],
	);

	const wrapperStyle: TSx = React.useMemo<TSx>(
		() => ({
			width: `${Math.floor(wh[0] + wh[0] * 0.8 * 2)}px`,
		}),
		[wh],
	);

	const handleResize = React.useCallback(
		() => setWh([window.innerWidth, window.innerHeight]),
		[],
	);

	React.useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [handleResize]);

	return (
		<Context.Provider value={value}>
			<Box style={wrapperStyle} sx={wrapperSx}>
				<Box ref={elBox} style={frameStyle} sx={frameSx}>
					{children}
				</Box>
			</Box>
		</Context.Provider>
	);
}

export default React.memo<TProps>(FrameProvider);
