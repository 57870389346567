// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageLib from '@graphite/image-lib';
import type {
	Dispatch,
	TAction,
	TWidgetComposed,
	TId,
	TWidgetMode,
	TDesignImageLibProps,
	TWidgetDiff,
} from '@graphite/types';

type TMinimalProps = $ReadOnly<{
	dispatch: Dispatch<TAction>,
	instanceId: ?TId,
	originId: TId,
	data: TWidgetComposed,
	widgetMode: TWidgetMode,
	editWidget: (
		targetId: TId,
		instanceId: ?TId,
		originId: TId,
		diff: TWidgetDiff,
	) => void,
	resetEdit: () => void,
	...$Exact<TDesignImageLibProps>,
}>;

const withImageLib = <T: TMinimalProps>(
	Component: React$ComponentType<T>,
): React$ComponentType<$ReadOnly<{ ...$Exact<T> }>> => {
	const WidgetWrapper = (props: T) => {
		const {
			instanceId,
			originId,
			data: { _id },
			widgetMode,
			insertImage,
			removeImage,
			resetImage,
			editWidget,
			resetEdit,
			images,
			uploads,
		} = props;

		const { t } = useTranslation();

		const handleClose = React.useCallback(
			e => {
				e.preventDefault();
				resetEdit();
				// reset state for image lib
				resetImage();
			},
			[resetImage, resetEdit],
		);

		const handleChange = React.useCallback(
			src => {
				editWidget(_id, instanceId, originId, { src });
			},
			[editWidget, _id, instanceId, originId],
		);

		const isShow = widgetMode === 'widget-edit';

		return (
			<>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
				{isShow && (
					<ImageLib
						t={t}
						uploads={uploads}
						images={images}
						isShow={isShow}
						onClose={handleClose}
						onChange={handleChange}
						onUpload={insertImage}
						onRemove={removeImage}
					/>
				)}
			</>
		);
	};

	return React.memo(WidgetWrapper);
};

export default withImageLib;
