// @flow
import React from 'react';
import emptyFunction from 'empty/function';
import { Button, Icon, Flex, Box, Text, Image } from '@graphite/uneon';

import previewImage from './img/site-preview.inline.svg';

type TProps = $ReadOnly<{|
	siteName: string,
	onRemove: () => void,
	onSelect: () => void,
|}>;

const siteSx = {
	marginBottom: '30px',
};

// overlay with action buttons

const overlayWrapperSx = {
	position: 'relative',
	cursor: 'pointer',
};

const overlaySx = {
	opacity: '0',
	position: 'absolute',
	top: 0,
	right: 0,
	bottom: 0,
	left: 0,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	transition: '0.15s ease-out',
	transitionProperty: 'opacity, background-color, border-color',

	'&:hover': {
		opacity: '1',
		backgroundColor: 'rgba(0,0,0,0.04)',
		borderColor: 'bg.tertiary',
	},
};

const overlayButtonsSx = {
	justifyContent: 'space-between',
	padding: '15px',
};

// card content

const sitePreviewSx = {
	display: 'block',
	marginBottom: '15px',
	width: '100%',
	height: 'auto',
	borderWidth: '1px',
	borderStyle: 'solid',
	borderColor: 'bg.secondary',
};
const siteNameSx = {
	color: 'text.primaryalt',
	a: {
		textDecoration: 'none',
		color: 'text.primary',
	},
	marginBottom: '6px',
};

const Site = ({ siteName, onRemove, onSelect }: TProps) => {
	const onRemoveHandler = React.useCallback(
		e => {
			e.stopPropagation();
			onRemove();
		},
		[onRemove],
	);

	const onSelectHandler = React.useCallback(
		e => {
			e.stopPropagation();
			onSelect();
		},
		[onSelect],
	);

	return (
		<Box variant="grid.column" mb="27px" sx={siteSx}>
			<Box sx={overlayWrapperSx}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a onClick={onSelectHandler}>
					<Image src={previewImage} alt={siteName} sx={sitePreviewSx} />
					<Box sx={overlaySx}>
						<Flex sx={overlayButtonsSx}>
							<Button variant="accent.iconrounded" onClick={emptyFunction}>
								<Icon name="pen" colors="accent" />
							</Button>
							<Button variant="error.iconrounded" onClick={onRemoveHandler}>
								<Icon name="trash" colors="accent" />
							</Button>
						</Flex>
					</Box>
				</a>
			</Box>

			<Text variant="bodysm" sx={siteNameSx}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a onClick={onSelectHandler}>{siteName}</a>
			</Text>
		</Box>
	);
};

export default React.memo<TProps>(Site);
