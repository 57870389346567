// @flow
import React, { memo, useCallback, useRef, useMemo } from 'react';
import emptyFunction from 'empty/function';
import {
	ListItem,
	Section,
	PopupWhite,
	ButtonGroup,
	Flex,
	Box,
	Text,
} from '@graphite/uneon';
import type { TId, TSpecs, TSpecsEffect } from '@graphite/types';
import { Params as ListParams } from '@graphite/lists';
import { genId } from 'libs/firebase';
import logger from 'libs/logger';
import ListRadii from './ListRadii';
import InputRadius from './InputRadius';

type TProps = $ReadOnly<{|
	effectspec: TSpecsEffect,
	updateSpecs?: TSpecs => void,
|}>;

const insertButton = {
	buttons: [
		{
			name: 'insert',
			icons: [
				{
					name: 'plus',
					iconSize: 18,
				},
			],
			colors: 'primary',
		},
	],
};

const detailButton = [
	{
		name: 'detail',
		icons: [
			{
				name: 'square-corners',
				iconSize: 18,
			},
		],
	},
];

const paramList = [
	{
		title: 'Name',
		key: 'name',
		kind: 'string',
		info: {
			maxLength: 16,
		},
	},
	{
		title: 'Radius',
		key: 'size',
		kind: 'unit',
		info: {
			showUnits: true,
			unitKey: null,
		},
	},
];

const relContainerSx = {
	position: 'relative',
};

const absButtonSx = {
	position: 'absolute',
	bottom: '3px',
	right: '-9px',
};

const detailActive = ['detail'];

function Radii({ effectspec, updateSpecs = emptyFunction }: TProps) {
	const [editedRadiusId, setEditedRadius] = React.useState<?TId>(null);
	const editedRadius = React.useMemo(() => {
		return effectspec.radii.find(p => p.id === editedRadiusId);
	}, [effectspec, editedRadiusId]);

	const insertRadius = useCallback(() => {
		try {
			updateSpecs({
				[effectspec._id]: {
					...effectspec,
					radii: [
						{
							id: genId('specs'),
							name: 'Radius',
							size: 4,
							sides: null,
						},
						...effectspec.radii,
					],
				},
			});
		} catch (e) {
			logger.error(e);
		}
	}, [effectspec, updateSpecs]);

	const removeRadius = useCallback(
		id => {
			try {
				const removeAt = effectspec.radii.findIndex(p => p.id === id);
				if (removeAt < 0) {
					return;
				}
				if (editedRadius && editedRadius.id === id) {
					setEditedRadius(null);
				}
				updateSpecs({
					[effectspec._id]: {
						...effectspec,
						radii: [
							...effectspec.radii.slice(0, removeAt),
							...effectspec.radii.slice(removeAt + 1),
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[effectspec, updateSpecs, editedRadius],
	);

	const clickRadius = useCallback(
		id => {
			try {
				const target = effectspec.radii.find(p => p.id === id);
				if (!target) {
					return;
				}

				setEditedRadius(id);
			} catch (e) {
				logger.error(e);
			}
		},
		[effectspec.radii],
	);

	const uneditRadius = useCallback(() => setEditedRadius(null), []);

	const changeRadius = useCallback(
		value => {
			try {
				if (!editedRadius) {
					return;
				}

				const editAt = effectspec.radii.findIndex(p => p.id === value.id);
				if (editAt < 0) {
					return;
				}

				updateSpecs({
					[effectspec._id]: {
						...effectspec,
						radii: [
							...effectspec.radii.slice(0, editAt),
							value,
							...effectspec.radii.slice(editAt + 1),
						],
					},
				});
			} catch (e) {
				logger.error(e);
			}
		},
		[editedRadius, effectspec, updateSpecs],
	);

	const changeParam = useCallback(
		(key, value) => {
			if (editedRadius && Object.keys(editedRadius).includes(key)) {
				const newRadius = {
					...editedRadius,
					[key]: value,
				};
				changeRadius(newRadius);
			}
		},
		[changeRadius, editedRadius],
	);

	const changeSide = useCallback(
		(side, value) => {
			if (!editedRadius) {
				return;
			}

			const newRadius = {
				...editedRadius,
				sides: {
					...editedRadius.sides,
					[`${side}`]: value,
				},
			};
			changeRadius(newRadius);
		},
		[changeRadius, editedRadius],
	);

	const changeDetail = useCallback(
		(e, names) => {
			if (!editedRadius || !names || typeof names === 'string') {
				return;
			}
			const newRadius = {
				...editedRadius,
				sides: names[0] === 'detail' ? {} : null,
			};
			changeRadius(newRadius);
		},
		[changeRadius, editedRadius],
	);

	const paramSource = useMemo(
		() =>
			(editedRadius && {
				name: editedRadius.name,
				size: `${editedRadius.size}`,
			}) ||
			null,
		[editedRadius],
	);

	const anchorRef = useRef();

	return (
		<>
			<Flex>
				<Box flexGrow={1}>
					<Section
						label="Radii"
						buttonGroup={insertButton}
						onClick={insertRadius}
					>
						<ListRadii
							active={editedRadius ? editedRadius.id : null}
							radii={effectspec.radii}
							onRemove={removeRadius}
							onClick={clickRadius}
						/>
					</Section>
				</Box>
				<Box ref={anchorRef} />
			</Flex>
			{editedRadius && paramSource && (
				<PopupWhite
					isOpen={!!editedRadius}
					anchorEl={anchorRef}
					offsetLeft={36}
					offsetTop={-20}
					onClose={uneditRadius}
					mutex="color"
					isFixed
				>
					<Text variant="title4" color="text.primaryalt" marginBottom={21}>
						Radius
					</Text>
					<Box sx={relContainerSx}>
						<ListParams
							listName={editedRadius.id}
							paramSource={paramSource}
							paramList={paramList}
							unit={1}
							onChange={changeParam}
						/>
						<ButtonGroup
							sx={absButtonSx}
							behavior="checkbox"
							buttons={detailButton}
							colors="tertiaryflat"
							activeColors="accentflat"
							variant="flat"
							active={editedRadius.sides ? detailActive : null}
							onClick={changeDetail}
						/>
					</Box>
					{editedRadius.sides && (
						<ListItem>
							<InputRadius
								radius={editedRadius}
								side="nw"
								onChange={changeSide}
							/>
							<InputRadius
								radius={editedRadius}
								side="ne"
								onChange={changeSide}
							/>
							<InputRadius
								radius={editedRadius}
								side="se"
								onChange={changeSide}
							/>
							<InputRadius
								radius={editedRadius}
								side="sw"
								onChange={changeSide}
							/>
						</ListItem>
					)}
				</PopupWhite>
			)}
		</>
	);
}

Radii.defaultProps = {
	updateSpecs: emptyFunction,
};

export default memo<TProps>(Radii);
