// @flow
import 'firebase/remote-config';
import firebase from '../firebase';

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings = {
	minimumFetchIntervalMillis: 3600000,
};

type TConfig = $ReadOnly<{|
	sentryEnabled: boolean,
	sentryDNS: string,
|}>;

remoteConfig.defaultConfig = ({
	sentryEnabled: false,
	sentryDNS: '',
}: TConfig);

type TGetConfig = () => Promise<TConfig>;
export const getConfig: TGetConfig = async () => {
	await remoteConfig.fetchAndActivate();
	return {
		sentryEnabled: remoteConfig.getBoolean('sentryEnabled'),
		sentryDNS: remoteConfig.getString('sentryDNS'),
	};
};

remoteConfig.fetchAndActivate();

export default remoteConfig;
