// @flow
import React from 'react';
import emptyFunction from 'empty/function';
import { ButtonColor as CircleColor } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	id: string,
	color: string,
	isActive?: boolean,
	onClick?: string => void,
|}>;

function ListItemColors({
	id,
	color,
	isActive = false,
	onClick = emptyFunction,
}: TProps) {
	const boundClick = React.useCallback(() => onClick(id), [id, onClick]);
	return <CircleColor color={color} onClick={boundClick} isActive={isActive} />;
}

ListItemColors.defaultProps = {
	isActive: false,
	onClick: emptyFunction,
};

export default React.memo<TProps>(ListItemColors);
