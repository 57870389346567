// @flow
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import { Global, css } from '@emotion/core';
import { useInjectReducer } from 'libs/inject-reducer';

import { themes } from '@graphite/uneon';

import { useInjectSaga } from 'libs/inject-saga';

import { useDispatch, useSelector } from '@graphite/use-redux';
import type { TId } from '@graphite/types';
import {
	getCurrentUser,
	getAuthError,
	getIsAuth,
	getCurrentUserId,
	getCurrentPageId,
	getCurrentSiteId,
} from '@graphite/selectors';
import ComponentContext from 'ComponentContext';
import Widget from 'Widget';
import { anyKind } from 'Widget/libs';
import Login from '../Login';
import SignUp from '../SignUp';
import Loader from '../Loader';
import Site from './Site';

import { saga as sagaEditor } from './ducks/editor';
import {
	saga as sagaUsers,
	signInWithGoogle,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
} from './ducks/users';
import { saga as sagaSpecs } from './ducks/specs';
import imageLibraryReducer, { saga as sagaImageLibrary } from './ducks/imageLibrary';
import {
	saga as sagaWidgets,
	syncScopedWidgets,
	unsyncScopedWidgets,
} from './ducks/widgets';
import { saga as sagaHistory } from './ducks/history';

import PtRootUiMediumEot from './fonts/pt_root_ui_medium.eot';
import PtRootUiMediumWoff2 from './fonts/pt_root_ui_medium.woff2';
import PtRootUiMediumWoff from './fonts/pt_root_ui_medium.woff';
import PtRootUiMediumTtf from './fonts/pt_root_ui_medium.ttf';

import PtRootUiBoldEot from './fonts/pt_root_ui_bold.eot';
import PtRootUiBoldWoff2 from './fonts/pt_root_ui_bold.woff2';
import PtRootUiBoldWoff from './fonts/pt_root_ui_bold.woff';
import PtRootUiBoldTtf from './fonts/pt_root_ui_bold.ttf';

type TProps = $ReadOnly<{||}>;

const globalStyle = css`
	@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@800;900&display=swap');
	@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap');

	@font-face {
		font-family: 'PTRootUIWeb';
		src: url('${PtRootUiMediumEot}');
		src: url('${PtRootUiMediumEot}?#iefix') format('embedded-opentype'),
			url('${PtRootUiMediumWoff2}') format('woff2'),
			url('${PtRootUiMediumWoff}') format('woff'),
			url('${PtRootUiMediumTtf}') format('truetype');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'PTRootUIWeb';
		src: url('${PtRootUiBoldEot}');
		src: url('${PtRootUiBoldEot}?#iefix') format('embedded-opentype'),
			url('${PtRootUiBoldWoff2}') format('woff2'),
			url('${PtRootUiBoldWoff}') format('woff'),
			url('${PtRootUiBoldTtf}') format('truetype');
		font-weight: 700;
		font-style: normal;
	}
`;

const Editor = () => {
	useInjectSaga({ key: 'editor', saga: sagaEditor });
	useInjectSaga({ key: 'users', saga: sagaUsers });
	useInjectSaga({ key: 'specs', saga: sagaSpecs });
	useInjectSaga({ key: 'widgets', saga: sagaWidgets });
	useInjectSaga({ key: 'constructorHistory', saga: sagaHistory });
	useInjectSaga({ key: 'imageLibrary', saga: sagaImageLibrary });

	useInjectReducer({ key: 'imageLibrary', reducer: imageLibraryReducer });

	const dispatch = useDispatch();
	const pageId: ?TId = useSelector(getCurrentPageId);
	const siteId: ?TId = useSelector(getCurrentSiteId);

	const signInWithGoogleHandler = React.useCallback(
		e => {
			e.preventDefault();
			dispatch(signInWithGoogle());
		},
		[dispatch],
	);
	const signInWithEmailAndPasswordHandler = React.useCallback(
		({ email, password }) => {
			dispatch(signInWithEmailAndPassword(email, password));
		},
		[dispatch],
	);
	const createUserWithEmailAndPasswordHandler = React.useCallback(
		({ email, password, name }) => {
			dispatch(createUserWithEmailAndPassword(email, password, name));
		},
		[dispatch],
	);

	const authError = useSelector(getAuthError);
	const isAuth = useSelector(getIsAuth);
	const userId = useSelector(getCurrentUserId);
	const user = useSelector(getCurrentUser);
	const isFetching = useSelector(state => state.users.isFetching);

	React.useEffect(() => {
		if (userId) {
			dispatch(syncScopedWidgets('system', null));
			dispatch(syncScopedWidgets('user', userId));
		}

		if (siteId) {
			dispatch(syncScopedWidgets('site', siteId));
		}

		if (pageId) {
			dispatch(syncScopedWidgets('page', pageId));
		}

		return () => {
			if (userId) {
				dispatch(unsyncScopedWidgets('system', null));
				dispatch(unsyncScopedWidgets('user', userId));
			}
			if (pageId) {
				dispatch(unsyncScopedWidgets('page', pageId));
			}
			if (siteId) {
				dispatch(unsyncScopedWidgets('site', siteId));
			}
		};
	}, [userId, pageId, dispatch, siteId]);

	if (isFetching || (isAuth && !user))
		return (
			<ThemeProvider theme={themes.light}>
				<Loader />
			</ThemeProvider>
		);

	return (
		<ThemeProvider theme={themes.light}>
			<Global styles={globalStyle} />
			<ComponentContext.Provider value={anyKind('get-edit-component')}>
				<Switch>
					<Route exact path="/login">
						{!user ? (
							<Login
								signInWithGoogle={signInWithGoogleHandler}
								signInWithEmailAndPassword={
									signInWithEmailAndPasswordHandler
								}
								error={authError}
								isAuth={isAuth}
							/>
						) : (
							<Redirect to="/" />
						)}
					</Route>
					<Route exact path="/signup">
						{!user ? (
							<SignUp
								signInWithGoogle={signInWithGoogleHandler}
								createUserWithEmailAndPassword={
									createUserWithEmailAndPasswordHandler
								}
								error={authError}
								isAuth={isAuth}
							/>
						) : (
							<Redirect to="/" />
						)}
					</Route>
					<Route path="/project/:projectId/site/:siteId">
						{user ? <Site user={user} /> : <Redirect to="/login" />}
					</Route>
					<Route path="/">
						{user ? (
							<Widget containerId={null} instanceId={null} id={user._id} />
						) : (
							<Redirect to="/login" />
						)}
					</Route>
				</Switch>
			</ComponentContext.Provider>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Editor);
