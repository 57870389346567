// @flow

import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';

import logger from 'libs/logger';
import { editWidget } from 'Editor/ducks/widgets';

import type {
	TProps,
	TContext,
	TResizeDataStart,
	TUseResize,
	TResizeDataUpdate,
	ResizeData,
} from '../types';
import contextDefaults from './context-defaults';

const resizeStart = (state: TContext, resizeData: TResizeDataStart) => {
	return {
		...resizeData,
		isResize: true,
	};
};

const resizeStop = (state: TContext, resizeData: TResizeDataUpdate) => {
	const { data, instanceId, originId, dispatch } = state;
	const { newData } = resizeData;
	dispatch(editWidget(data._id, instanceId, originId, { box: newData.box }));
	return {
		dir: null,
		x: 0,
		y: 0,
		data,
		initialData: data,
		resizeInfo: emptyObject,
		isResize: false,
		propName: null,
	};
};

const resizeUpdate = (state: TContext, resizeData: TResizeDataUpdate) => {
	const { data, dir } = state;
	const { newData, resizeInfo = null } = resizeData;

	if (
		![
			'top',
			'left',
			'right',
			'bottom',
			'top-left',
			'top-right',
			'bottom-left',
			'bottom-right',
		].includes(dir)
	) {
		logger.error("resizeUpdate direction doesn't exist", dir);
		return emptyObject;
	}

	if (!data._id) {
		logger.error("resizeUpdate id doesn't exist", data._id);
		return emptyObject;
	}

	return _.merge({ data }, { data: newData, resizeInfo });
};

const resizeReducer = (state: TContext, action) => {
	switch (action.type) {
		case 'start': {
			return {
				...state,
				...resizeStart(state, action.payload),
			};
		}
		case 'stop': {
			return {
				...state,
				...resizeStop(state, action.payload),
			};
		}
		case 'update': {
			return {
				...state,
				...resizeUpdate(state, action.payload),
			};
		}
		case 'reset': {
			return {
				...state,
				data: action.payload.data,
				initialData: action.payload.data,
			};
		}
		default:
			logger.error('resizeUpdate action not valid', action);
			return state;
	}
};

export const useResizeContext: TUseResize = (props: TProps) => {
	const resize = React.useReducer(resizeReducer, props, props => {
		const { data, currentDevice, instanceId, originId, dispatch } = props;
		return {
			initialData: data,
			data,
			currentDevice,
			instanceId,
			originId,
			dir: null,
			x: 0,
			y: 0,
			resizeInfo: emptyObject,
			maxRange: null,
			isResize: false,
			dispatch,
			propName: null,
		};
	});

	const [, dispatch] = resize;

	const countRender = React.useRef(0);
	React.useEffect(() => {
		if (countRender.current > 0)
			dispatch({
				type: 'reset',
				payload: {
					data: props.data,
				},
			});
		++countRender.current;
	}, [countRender, dispatch, props.data]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	return React.useMemo(() => resize, [resize[0]]);
};
const ResizeContext = React.createContext<ResizeData>([contextDefaults, () => {}]);

export default ResizeContext;
