// @flow

import React from 'react';
import _ from 'lodash/fp';

type ModifierKeyMap = $ReadOnly<{
	control: 'ctrlKey',
	shift: 'shiftKey',
	alt: 'altKey',
	meta: 'metaKey',
	[string]: string,
}>;

const KEY_SEQUENCE_TIMEOUT: number = 1000;
const ESCAPE_HATCH_KEY: string = '*';
const DISABLE_KEY_DOWN_NODE = ['TEXTAREA', 'INPUT', 'DIV'];

const getActiveModifierKeys = (event: KeyboardEvent): $ReadOnlyArray<?string> => {
	let modifiers = [];

	if (event.ctrlKey) {
		modifiers = [...modifiers, 'ctrlKey'];
	}

	if (event.shiftKey) {
		modifiers = [...modifiers, 'shiftKey'];
	}

	if (event.altKey) {
		modifiers = [...modifiers, 'altKey'];
	}

	if (event.metaKey) {
		modifiers = [...modifiers, 'metaKey'];
	}

	return modifiers;
};

const getHotkeysArray = (hotkeys: string): $ReadOnlyArray<string> => {
	const hotKeys = hotkeys.toLowerCase();

	if (hotKeys.length === 1) {
		return [hotKeys];
	}

	if (hotKeys.includes('+')) {
		return hotKeys.replace(/\s+/g, '').split('+');
	}

	return [...(hotKeys.match(/[^\s"']+|"([^"]*)"|'([^']*)'/g) || [])].map(key =>
		key.replace(/("|').*?("|')/, ' '),
	);
};

const isSameSet = (
	arr1: $ReadOnlyArray<string>,
	arr2: $ReadOnlyArray<?string>,
): boolean =>
	arr1.length === arr2.length && arr1.every((item: string) => arr2.includes(item));

const modifierKeyMap: ModifierKeyMap = {
	control: 'ctrlKey',
	shift: 'shiftKey',
	alt: 'altKey',
	meta: 'metaKey',
};

const mapModifierKeys = (keys: $ReadOnlyArray<string>) =>
	keys.map(k => modifierKeyMap[k]);

const modifierKeyPressed = event =>
	event.altKey || event.ctrlKey || event.shiftKey || event.metaKey;

const takeUntilLast = (arr: $ReadOnlyArray<string>) => arr.slice(0, -1);

const useHotkeys = (
	hotkeys: string | $ReadOnlyArray<string>,
	callback: (event: KeyboardEvent) => void,
	documentFrame: ?Object,
	windowFrame: ?Object,
	filter?: $ReadOnlyArray<string> = DISABLE_KEY_DOWN_NODE, // Ноды на которых нельзя кейдаун
) => {
	const hotkeysArray = React.useMemo(
		() =>
			Array.isArray(hotkeys)
				? hotkeys.map(getHotkeysArray)
				: [getHotkeysArray(hotkeys)],
		[hotkeys],
	);

	React.useEffect(() => {
		const keySequences: {
			[number]: $ReadOnlyArray<string>,
		} = {};
		const sequenceTimers: {
			[number]: ?TimeoutID,
		} = {};

		const clearSequenceTimer = (index: number) => {
			clearTimeout(sequenceTimers[index]);
		};

		const resetKeySequence = (index: number) => {
			clearSequenceTimer(index);
			keySequences[index] = [];
		};

		const handleKeySequence = (event, keys, index) => {
			clearSequenceTimer(index);

			keySequences[index] = keySequences[index] || [];
			sequenceTimers[index] = window.setTimeout(() => {
				resetKeySequence(index);
			}, KEY_SEQUENCE_TIMEOUT);

			const keySequence = keySequences[index];
			const code = event.code.replace('Digit', '').replace('Key', '');
			keySequences[index] = [...keySequences[index], code.toLowerCase()];

			if (_.isEqual(keySequence, keys)) {
				resetKeySequence(index);
				callback(event);
			}
		};

		const handleModifierCombo = (event, keys) => {
			const actionKey = _.last(keys);
			const modKeys = mapModifierKeys(takeUntilLast(keys));
			const activeModKeys = getActiveModifierKeys(event);
			const allModKeysPressed = isSameSet(modKeys, activeModKeys);
			const code = event.code.replace('Digit', '').replace('Key', '');

			if (allModKeysPressed && code.toLowerCase() === actionKey) {
				callback(event);
			}
		};

		const onKeydown = (event: KeyboardEvent) => {
			const { target } = event;

			// Тип ноды в iframe не равен типу ноды в основном window
			const IFrameNode = windowFrame ? windowFrame.Node : Node;
			if (
				(target instanceof Node || target instanceof IFrameNode) &&
				filter.includes(target.nodeName)
			)
				return;

			const code = event.code.replace('Digit', '').replace('Key', '');
			if (!code && !modifierKeyPressed(event)) {
				return;
			}

			hotkeysArray.forEach((keysArray: $ReadOnlyArray<string>, i) => {
				if (keysArray.length === 1 && keysArray[0] === ESCAPE_HATCH_KEY) {
					callback(event);
					return;
				}

				if (modifierKeyPressed(event)) {
					handleModifierCombo(event, keysArray);
					return;
				}

				if (keysArray.length > 1 && !modifierKeyPressed(event)) {
					handleKeySequence(event, keysArray, i);
					return;
				}

				if (code.toLowerCase() === keysArray[0]) {
					callback(event);
				}
			});
		};

		document.addEventListener('keydown', onKeydown);
		if (documentFrame) documentFrame.addEventListener('keydown', onKeydown);

		return () => {
			document.removeEventListener('keydown', onKeydown);
			if (documentFrame) documentFrame.removeEventListener('keydown', onKeydown);
		};
	}, [hotkeysArray, callback, documentFrame, windowFrame, filter]);
};

export default useHotkeys;
