// @flow
import React from 'react';

import type { TConnectProps } from './constants/types';
import Wrap from './Wrap';
import Element from './Element';

const Image = (props: TConnectProps, ref) => {
	const { colorspec, effectspec, widgetspec, gridspec, data } = props;

	if (!colorspec || !effectspec || !widgetspec || !gridspec) return null;

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Wrap {...props} ref={ref}>
			<Element
				colorspec={colorspec}
				effectspec={effectspec}
				widgetspec={widgetspec}
				gridspec={gridspec}
				data={data}
			/>
		</Wrap>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Image));
