// @flow
import React from 'react';
import { Box, InputInplace } from '@graphite/uneon';
import type { TCurveParam } from '@graphite/types';

type TProps = $ReadOnly<{|
	value: string,
	param: TCurveParam,
	onChange?: ?($ReadOnlyArray<number>) => void,
|}>;

const paramRightStyle = {
	flexGrow: 1,
	width: '50%',
	position: 'relative',
};

const onValidate = value => {
	const numbers = value.split(',').map(s => +s.trim());
	if (numbers.length !== 4 || numbers.some(n => Number.isNaN(n) || n < 0 || n > 1)) {
		return false;
	}
	return true;
};

function ListItemCurveParams({ value, param, onChange }: TProps) {
	const changeParam = React.useMemo(
		() =>
			// eslint-disable-next-line no-shadow
			value => {
				if (onChange) {
					onChange(value.split(',').map(s => +s.trim()));
				}
			},
		[onChange],
	);
	return (
		<Box sx={paramRightStyle}>
			<InputInplace
				value={value}
				onEnter={changeParam}
				onBlur={changeParam}
				onValidate={onValidate}
				max={param.info.maxLength || 0}
				placeholder="0, 0, 1, 1"
			/>
		</Box>
	);
}

ListItemCurveParams.defaultProps = {
	onChange: null,
};

export default React.memo<TProps>(ListItemCurveParams);
