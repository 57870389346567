// @flow
import { createBrowserHistory } from 'history';

// eslint-disable-next-line import/no-mutable-exports
let history;
try {
	history = createBrowserHistory();
} catch (e) {
	history = {};
}
export default history;
