// @flow
import React from 'react';
import _ from 'lodash/fp';
import { CacheProvider, Global } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';

import type { TOptions } from './types';
import Symbiote from './Symbiote';
// import useVisible from './use-visible';
import { ContextActiveLevel } from './Provider';

const INITIAL_OPTIONS: TOptions = {
	padding: true,
	margin: true,
	symbioteRoot: null,
};

const memoizedCreateCacheWithContainer = weakMemoize(container =>
	createCache({ container }),
);

// чтобы сохранить displayName
const Empty: React$ComponentType<{}> = () => null;

const withSymbiote = <T3>(
	decoratorsBone: (
		React$ComponentType<{}>,
	) => React$ComponentType<$ReadOnly<{ ...T3 }>>,
	options: $Shape<TOptions> = INITIAL_OPTIONS,
): ((React$ComponentType<T3>) => React$ComponentType<$ReadOnly<{ ...T3 }>>) => {
	const opts: TOptions = _.merge(INITIAL_OPTIONS, options);
	const Bone = decoratorsBone(Empty);

	return Component => {
		const SymbioteWrapped = (props, ref) => {
			const activeLevel = React.useContext(ContextActiveLevel);
			const [className] = React.useState(
				() =>
					`symbiote-level-${Math.random()
						.toString(36)
						.substring(7)}`,
			);

			const currentRef = React.useRef<?HTMLElement>(null);
			React.useImperativeHandle(ref, () => currentRef.current);

			const isVisible = true; // useVisible(currentRef);
			// const isEqueleLevel = !activeLevel || activeLevel === opts.level;

			const globalStyles = React.useMemo(() => {
				return {
					[`.${className}`]: {
						display:
							!activeLevel || activeLevel === opts.level ? 'block' : 'none',
					},
				};
			}, [className, activeLevel]);

			if (!document.head) return null;

			return (
				<>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					<Component {...props} ref={(currentRef: { current: any })} />
					<CacheProvider
						value={memoizedCreateCacheWithContainer(document.head)}
					>
						<>
							<Global styles={globalStyles} />
							{isVisible && (
								<Symbiote
									ownProps={props}
									opts={opts}
									currentRef={currentRef}
									className={className}
								>
									<Bone
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...props}
										currentRef={currentRef}
									/>
								</Symbiote>
							)}
						</>
					</CacheProvider>
				</>
			);
		};

		return React.memo(React.forwardRef(SymbioteWrapped));
	};
};

export default withSymbiote;
