// @flow

import type { TId, Dispatch, TAction } from '@graphite/types';

import { insertImages, removeImage, reset } from 'Editor/ducks/imageLibrary';

const mapDispatchToPropsEdit = (dispatch: Dispatch<TAction>) => {
	return {
		dispatch,
		insertImage: (files: FileList) => {
			dispatch(insertImages(files));
		},
		removeImage: (imageId: TId) => {
			dispatch(removeImage(imageId));
		},
		resetImage: () => {
			dispatch(reset());
		},
	};
};

export default mapDispatchToPropsEdit;
