// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '@graphite/uneon';

// import logger from 'libs/logger';
// правильный импорт для тени, закомментировано до восстановления ее работы без багов
// import { defaultCommonEffect, defaultShadow } from '@graphite/constants';
import { defaultCommonEffect } from '@graphite/constants';
import useBreakpoint from '@graphite/use-breakpoint';

import type {
	TDesignCommonEffect,
	TBackgroundValues,
	TColorValue,
	TDesignColorProps,
	// TShadowValue,
	// TShadowValues,
} from '@graphite/types';

import {
	Color as SectionColor,
	Background as SectionBackground,
} from '@graphite/sections';

const defaultBorderItem: TColorValue = {
	kind: 'color',
	value: 'bg.accent',
};

const defaultBgItem: TColorValue = {
	kind: 'color',
	value: 'bg.primary',
};

// const defaultBgShadowItem: TShadowValue = {
// 	kind: 'shadow',
// 	value: defaultShadow,
// };

function Color({
	design,
	device,
	colorspec,
	gridspec,
	onChange = null,
	genId,
	insertImage,
	removeImage,
	resetImage,
	images,
	uploads,
}: TDesignColorProps) {
	const { t } = useTranslation();
	const { breakpoint, changeBreakpoint } = useBreakpoint(onChange, design, device);

	// const [stateName, setStateName] = React.useState('normal');
	const [stateName] = React.useState('normal');
	const stateData: TDesignCommonEffect =
		breakpoint[stateName || 'normal'] || defaultCommonEffect;

	const bgSource: TBackgroundValues = React.useMemo(
		() => stateData.container.background || [],
		[stateData.container.background],
	);

	// закомментировано до восстановления тени без багов
	// const bgShadowSource: TShadowValues = React.useMemo(
	// 	() => stateData.container.shadow || [],
	// 	[stateData.container.shadow],
	// );

	// закомментировано до восстановления оверлея без багов
	// const overlaySource: TBackgroundValues = React.useMemo(
	// 	() => stateData.container.overlay || [],
	// 	[stateData.container.overlay],
	// );

	const borderSource: ?TColorValue = React.useMemo(
		() =>
			stateData.container.border
				? { kind: 'color', value: stateData.container.border }
				: null,
		[stateData.container.border],
	);

	const bgChange = React.useCallback(
		(background: TBackgroundValues) => {
			if (!stateName) {
				return;
			}
			changeBreakpoint({
				...breakpoint,
				[stateName]: {
					...stateData,
					container: {
						...stateData.container,
						background,
					},
				},
			});
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	// закомментировано до восстановления тени без багов
	// const bgShadowChange = React.useCallback(
	// 	(shadow: TShadowValues) => {
	// 		try {
	// 			if (!stateName) {
	// 				return;
	// 			}
	// 			changeBreakpoint({
	// 				...breakpoint,
	// 				[stateName]: {
	// 					...stateData,
	// 					container: {
	// 						...stateData.container,
	// 						shadow,
	// 					},
	// 				},
	// 			});
	// 		} catch (e) {
	// 			logger.error(e);
	// 		}
	// 	},
	// 	[breakpoint, changeBreakpoint, stateData, stateName],
	// );

	// закомментировано до восстановления оверлея без багов
	// const overlayChange = React.useCallback(
	// 	(overlay: TBackgroundValues) => {
	// 		if (!stateName) {
	// 			return;
	// 		}
	// 		changeBreakpoint({
	// 			...breakpoint,
	// 			[stateName]: {
	// 				...stateData,
	// 				container: {
	// 					...stateData.container,
	// 					overlay,
	// 				},
	// 			},
	// 		});
	// 	},
	// 	[breakpoint, changeBreakpoint, stateData, stateName],
	// );

	const borderChange = React.useCallback(
		(color: ?TColorValue) => {
			if (!stateName) {
				return;
			}
			changeBreakpoint({
				...breakpoint,
				[stateName]: {
					...stateData,
					container: {
						...stateData.container,
						border: color ? color.value : null,
					},
				},
			});
		},
		[breakpoint, changeBreakpoint, stateData, stateName],
	);

	return (
		<>
			{/* <SelectState state={stateName || 'normal'} onChange={setStateName} /> */}
			<Section label={t('Сolor')}>
				<SectionBackground
					t={t}
					label={t('Background')}
					source={bgSource}
					defaultItem={defaultBgItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={bgChange}
					isSeparated
					genId={genId}
					images={images}
					insertImage={insertImage}
					resetImage={resetImage}
					removeImage={removeImage}
					uploads={uploads}
				/>
				<SectionColor
					t={t}
					label={t('Border Color')}
					source={borderSource}
					defaultItem={defaultBorderItem}
					colorspec={colorspec}
					gridspec={gridspec}
					onChange={borderChange}
					genId={genId}
				/>
				{/* <SectionShadow
					label={t('Shadow')}
					source={bgShadowSource}
					defaultItem={defaultBgShadowItem}
					colorspec={colorspec}
					onChange={bgShadowChange}
				/> */}
			</Section>
			{/* <Section label={t('Overlay')}>
				<SectionBackground
					label={t('Background')}
					source={overlaySource}
					defaultItem={defaultBgItem}
					colorspec={colorspec}
					onChange={overlayChange}
				/>
			</Section> */}
		</>
	);
}

export default React.memo<TDesignColorProps>(Color);
