// @flow
import firebase from '../firebase';

export const analytics = (() => {
	if (process.env.NODE_ENV === 'production' && process.env.ENV_MODE !== 'server') {
		require('firebase/analytics');
		return firebase.analytics();
	}

	return { logEvent: () => {} };
})();

export const logEvent = analytics.logEvent.bind(analytics);

export default analytics;
