// @flow

import React from 'react';

import getDisplayName from '@graphite/get-display-name';
import ResizeCols, { useResize } from 'Widget/libs/resize-cols';

import type {
	TResizeColHOC,
	TResizeColHOCMinimalResizeColProps,
} from '../constants/types';

const withResizeCol: TResizeColHOC = Component => {
	const WrappedComponent = (
		props: TResizeColHOCMinimalResizeColProps,
		ref: React$ElementRef<*>,
	) => {
		const resize = useResize(props, ref);
		const [state] = resize;

		const colSizeMap = state.isResize ? state.colSizeMap : props.colSizeMap;

		return (
			<ResizeCols.Provider value={resize}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} ref={ref} colSizeMap={colSizeMap} />
			</ResizeCols.Provider>
		);
	};

	WrappedComponent.displayName = `withResizeCol(${getDisplayName(Component)})`;

	return React.memo<TResizeColHOCMinimalResizeColProps>(
		React.forwardRef(WrappedComponent),
	);
};

export default withResizeCol;
