// @flow
import type { TWidgetMethodApplyChildren } from '@graphite/types';

export default async (name: string): Promise<TWidgetMethodApplyChildren> => {
	const {
		applyChildren,
	}: {
		applyChildren: TWidgetMethodApplyChildren,
		// eslint-disable-next-line prefer-template
	} = await import('Widget/Widgets/' + name + '/libs');
	return applyChildren;
};
