// @flow

export const zIndices = {
	gridHightlight: 40,
	dropPlace: 50,
	dropPlaceActive: 60,
	widgetControls: 60,

	// z-index для колонки
	// columnControlsHover - что бы можно было навести на хендлер колонки
	columnControls: 10580, // z-index symbiote
	columnControlsHover: 10599, // z-index symbiote

	// z-index для блока
	// ресайз паддинга блока ниже всех виджетов
	blockPadding: 50, // z-index symbiote
	// ресайз паддинга блока при высоте меньше 10px выше всех виджетов
	blockPaddingLow: 10000, // z-index symbiote
	blockControls: 10590, // z-index symbiote

	pageOverlay: 10500,

	// Тут пока что получается, что zIndex'ов хватит только на 9900 виджетов на холсте)))
	// Максимальная высота контрола абсолютного драга
	absControlsFactor: 100, // z-index symbiote
	// Максимальная высота контрола, отвечающего за вход в редактирование
	editFactor: 100, // z-index symbiote

	// все попапчики
	popup: 10700,
	// оверлей селектов, которые открываются в попапах, должен быть выше popup
	popupSelectOverlay: 10701,
	// всё, что открывается в попапчиках
	popupInner: 10702,
	// редактор кода, желательно выше конролов должен быть
	codeEditor: 10710,
	// DragUploader drop place
	dragUploader: 11000,

	// для контролов когда они активны + синий бордер
	widgetActiveControls: 10400, // z-index symbiote
	// для контролов ресайза
	resizeHandler: 10401, // z-index symbiote
	resizeCircle: 10402, // z-index symbiote
};

export const transitions = {
	gridHightlight: {
		showDuration: '0.1s',
		showTiming: 'ease-in',
		showDelay: '0s',
	},
	blockPadding: {
		showDuration: '0.15s',
		showTiming: 'ease-in',
		showDelay: '0s',
		hideDuration: '0s',
		hideTiming: 'ease-in',
		hideDelay: '0s',
	},
	dropPlace: {
		showDuration: '0.1s',
		showTiming: 'ease-in',
		showDelay: '0s',
	},
	widgetControls: {
		// добавлены в виджет, но еще не работают, надо переделать принцип появления
		// работают только для панели настроек виджета
		showDuration: '0.1s',
		showTiming: 'ease-in',
		showDelay: '0s',
		hideDuration: '0s',
		hideTiming: 'ease-in',
		hideDelay: '0s',
	},
	columnControls: {
		showDuration: '0.1s',
		showTiming: 'ease-in',
		showDelay: '0.05s',
		hideDuration: '0s',
		hideTiming: 'ease-in',
		hideDelay: '0s',
	},
	blockControls: {
		showDuration: '0.25s',
		showTiming: 'ease-in',
		showDelay: '0s',
		hideDuration: '0s',
		hideTiming: 'ease-in',
		hideDelay: '0s',
	},
};

export default {};
