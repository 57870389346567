// @flow

import React from 'react';
import emptyArray from 'empty/array';

import Widget from 'Widget';

import type { TConnectProps } from './constants/types';

type TProps = $ReadOnly<{|
	...$Exact<TConnectProps>,
|}>;

const Block = (props: TProps) => {
	const {
		data,
		children,
		colSizeMap,
		positions,
		widgetChain,
		widgetspec,
		colorspec,
		gridspec,
		effectspec,
	} = props;
	const containerId = data._id;
	const instanceId = data.modified ? containerId : props.instanceId;
	const parentChain = React.useMemo(
		() => [containerId, ...(widgetChain || emptyArray)],
		[widgetChain, containerId],
	);

	// Fixme: в positions лежат указания не по trueId (и это правильно), тут починить проверку
	const cols = React.useMemo(() => {
		return colSizeMap.reduce((sizeMap, { orderList }, rowIndex) => {
			const rowId = `${containerId}-${rowIndex}`;

			orderList.forEach(
				({ trueId }) =>
					!positions[trueId] &&
					sizeMap.push(
						<Widget
							containerId={containerId}
							instanceId={instanceId}
							widgetChain={parentChain}
							rowId={rowId}
							key={`${trueId}-${rowId}`}
							id={trueId}
							widgetspec={widgetspec}
							colorspec={colorspec}
							gridspec={gridspec}
							effectspec={effectspec}
						/>,
					),
			);

			return sizeMap;
		}, []);
	}, [
		colSizeMap,
		colorspec,
		containerId,
		effectspec,
		gridspec,
		instanceId,
		parentChain,
		positions,
		widgetspec,
	]);

	if (children) return children;
	return cols;
};

export default React.memo<TProps>(Block);
