/* eslint-disable flowtype/require-valid-file-annotation */
import React from 'react';
import { ReactReduxContext } from 'react-redux';
import hoistNonReactStatics from 'hoist-non-react-statics';

import getInjectors from './reducer-injectors';

/**
 * Dynamically injects a reducer
 *
 * @param {string} key A key of the reducer
 * @param {function} reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) => WrappedComponent => {
	class ReducerInjector extends React.Component {
		static WrappedComponent = WrappedComponent;

		// eslint-disable-next-line react/static-property-placement
		static contextType = ReactReduxContext;

		// eslint-disable-next-line react/static-property-placement
		static displayName = `withReducer(${WrappedComponent.displayName ||
			WrappedComponent.name ||
			'Component'})`;

		constructor(props, context) {
			super(props, context);

			getInjectors(context.store).injectReducer(key, reducer);
		}

		render() {
			// eslint-disable-next-line react/jsx-props-no-spreading
			return <WrappedComponent {...this.props} />;
		}
	}

	return hoistNonReactStatics(ReducerInjector, WrappedComponent);
};

const useInjectReducer = ({ key, reducer }) => {
	const context = React.useContext(ReactReduxContext);
	React.useEffect(() => {
		getInjectors(context.store).injectReducer(key, reducer);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export { useInjectReducer };
