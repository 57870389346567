// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';
import type {
	TId,
	TWidgetComposed,
	TGridBreakpointName,
	Dispatch,
	TAction,
	TWidgetMode,
} from '@graphite/types';

import { ResizeContext, useResizeContext } from './libs';

type TMinimalProps = $ReadOnly<{
	data: TWidgetComposed,
	currentDevice: TGridBreakpointName,
	dispatch: Dispatch<TAction>,
	originId: TId,
	instanceId: ?TId,
	id: TId,
	widgetMode: TWidgetMode,
}>;

type TWithWidgetResizer = (
	React$ComponentType<TMinimalProps>,
) => React$ComponentType<$ReadOnly<{ ...$Exact<TMinimalProps> }>>;

const withWidgetResizer: TWithWidgetResizer = Component => {
	function WithWidgetResizer(props, ref) {
		const resize = useResizeContext(props);
		const [{ data }] = resize;

		return (
			<ResizeContext.Provider value={resize}>
				<Component
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...props}
					data={data}
					ref={ref}
				/>
			</ResizeContext.Provider>
		);
	}

	WithWidgetResizer.displayName = `withResizer(${getDisplayName(Component)})`;

	return React.memo(React.forwardRef(WithWidgetResizer));
};

export default withWidgetResizer;
