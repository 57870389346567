// @flow
import React from 'react';
import _ from 'lodash/fp';
import type { Dispatch } from 'redux';
import type { RawDraftContentState } from 'draft-js/lib/RawDraftContentState';
import { type EditorState as TEditorState, convertToRaw } from 'draft-js';
import type { TId, TAction } from '@graphite/types';

import { editWidget } from 'Editor/ducks/widgets';
import getDisplayName from '@graphite/get-display-name';
import fromRaw from './from-raw';

type TMinimal = $ReadOnly<{
	dispatch: Dispatch<TAction>,
	instanceId: ?TId,
	originId: TId,
	data: {
		_id: TId,
		raw: RawDraftContentState,
	},
}>;

const DELAY_SAVE = 1e3;

const withEditorState = <
	T: TMinimal,
	T2: $ReadOnly<{
		...T,
		editorState: TEditorState,
		setEditorState: ((TEditorState => TEditorState) | TEditorState) => void,
	}>,
>(
	Component: React$ComponentType<T>,
): React$ComponentType<T2> => {
	const WithEditorState = (props: T, ref) => {
		const {
			dispatch,
			instanceId,
			originId,
			data: { _id, raw },
		} = props;

		const [editorState, setEditorState] = React.useState(() => fromRaw(raw));

		const prevRaw = React.useRef(null);
		React.useEffect(() => {
			prevRaw.current = raw;
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		// Реакция на изменение RAW извне
		React.useEffect(() => {
			if (prevRaw.current !== raw) {
				prevRaw.current = raw;
				setEditorState(() => fromRaw(raw));
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [raw]);

		const save = React.useMemo(
			() =>
				_.debounce(DELAY_SAVE, editorState => {
					const contentState = editorState.getCurrentContent();
					const raw = convertToRaw(contentState);

					if (!_.isEqual(raw, prevRaw.current)) {
						prevRaw.current = raw;
						dispatch(editWidget(_id, instanceId, originId, { raw }));
					}
				}),
			[_id, instanceId, originId, dispatch],
		);

		const onChange = React.useCallback(
			editorState => {
				// для случ. когда через колаборацию меняется стайт
				// в with-editible.js:85 useEffect срабатывает раньше того что здесь
				// из-за зависимости blockRenderMap(которая нужна!)
				if (prevRaw.current !== raw) {
					prevRaw.current = raw;
					return setEditorState(() => fromRaw(raw));
				}
				if (typeof editorState === 'function') {
					setEditorState(oldEditorState => {
						const newEditorState = editorState(oldEditorState);
						save(newEditorState);
						return newEditorState;
					});
				} else {
					save(editorState);
					setEditorState(editorState);
				}

				setEditorState(oldEditorState => {
					let newEditorState;
					if (typeof editorState === 'function') {
						newEditorState = editorState(oldEditorState);
					} else {
						newEditorState = editorState;
					}

					save(newEditorState);
					return newEditorState;
				});
			},
			[save, setEditorState, raw],
		);

		return (
			<Component
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}
				ref={ref}
				editorState={editorState}
				setEditorState={onChange}
			/>
		);
	};

	WithEditorState.displayName = `withEditorState(${getDisplayName(Component)})`;

	return React.memo<T2>(React.forwardRef(WithEditorState));
};

export default withEditorState;
