// @flow
import _ from 'lodash/fp';

import type { TRefsItem, THandleCurrentWidgetCase } from '../types';

const handleCurrentWidgetCase: THandleCurrentWidgetCase = ({
	event,
	refs,
	currentWidget,
	setCurrentEdit,
	overTargets,
}) => {
	const { clientX, clientY } = event;
	const { id, instanceId } = currentWidget;
	if (!overTargets) {
		overTargets = document.elementsFromPoint(clientX, clientY) || [];
	}
	if (!refs) {
		return;
	}
	// проверить в overtargtes что есть хотя бы один ref currentWidget-а
	const found: ?TRefsItem = _.find({ widgetId: id, instanceId })(refs);
	if (!found || found.kind === 'stack') {
		return;
	}
	const { symbioteRefs, currentRefs } = found;
	const isOverlap = overTargets.some(
		/* Берется нулевой элемент, потому что на данном этапе(!) у примитивов только один симбиот */
		target =>
			(currentRefs[0].current && currentRefs[0].current.contains(target)) ||
			(symbioteRefs[0].current && symbioteRefs[0].current.contains(target)),
	);

	setCurrentEdit(isOverlap);
};

export default handleCurrentWidgetCase;
