// @flow
import { Input } from '@graphite/uneon';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

const InputAuth: React$ComponentType<{}> = styled(Input)`
	width: 100%;
	margin-bottom: 15px;

	${({ isError, theme }) =>
		isError
			? css`
					border-color: ${theme.colors.bg.error};
					margin-bottom: 9px;
			  `
			: null}
`;

export default InputAuth;
