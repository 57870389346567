// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';

import type { TOptsWithDropPlace, TMinimalPropsWithDropPlace } from './constants/types';
import Context from './Context';
import Border from './Border';

const withAbsolutePlace = <T: TMinimalPropsWithDropPlace>(
	opts: TOptsWithDropPlace,
	Component: React$ComponentType<T>,
) => {
	const WithAbsolutePlace = (props: T) => {
		const { data, symbioteRef, instanceId, originId } = props;
		const { _id, kind } = data;
		const combineId = `${_id}-${opts.type}`;

		const { dragId, dropId, regDropPlace, unregDropPlace } = React.useContext(
			Context,
		);

		React.useEffect(() => {
			regDropPlace({
				id: _id,
				widgetKind: kind,
				dropId: combineId,
				ref: symbioteRef,
				type: opts.type,
				kind: opts.kind,
				instanceId,
				originId,
			});
			return () => unregDropPlace({ id: combineId });
		}, [
			_id,
			kind,
			combineId,
			instanceId,
			originId,
			regDropPlace,
			symbioteRef,
			unregDropPlace,
		]);

		return (
			<>
				{!!dragId && dropId === combineId && <Border borderName={opts.name} />}
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
			</>
		);
	};

	WithAbsolutePlace.displayName = `withAbsolutePlace(${getDisplayName(Component)})`;
	return React.memo<T>(WithAbsolutePlace);
};

export default withAbsolutePlace;
