// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Route, Switch, Redirect } from 'react-router-dom';
import CursorProvider from 'CursorProvider';
import emptyObject from 'empty/object';
import { ThemeProvider } from 'emotion-theming';
import { themes } from '@graphite/uneon';
import styled from '@emotion/styled';
import type { TId } from '@graphite/types';
import logger from 'libs/logger';

import {
	getCurrentProjectId,
	getCurrentSiteId,
	getCurrentSite,
	getCurrentPageId,
} from '@graphite/selectors';
import { useDispatch, useSelector } from '@graphite/use-redux';

import './libs/i18n';
import TopBar from './TopBar';
import Page from './Page';
import Published from '../Published';
import Loader from '../Loader';
import { syncScopedWidgets, unsyncScopedWidgets } from './ducks/widgets';
import { editorEmpty } from './ducks/editor';
import { historyEmpty } from './ducks/history';
import { loadAndWatchSpecs } from './ducks/specs';
import Error from '../Error';

const PublishedBox = styled.div`
	margin-top: 54px;
`;

type TProps = $ReadOnly<{|
	user: $ReadOnly<{
		_id: TId,
	}>,
|}>;

const Site = ({ user: { _id: userId } }: TProps) => {
	const dispatch = useDispatch();
	// for analytic
	React.useEffect(() => {
		logger.info('editorVisit');
	}, []);

	const projectId: ?TId = useSelector(getCurrentProjectId);
	const pageId: ?TId = useSelector(getCurrentPageId);
	const siteId: ?TId = useSelector(getCurrentSiteId);
	const site = useSelector(getCurrentSite);

	React.useEffect(() => {
		dispatch(editorEmpty());
		dispatch(historyEmpty());
		if (siteId) {
			dispatch(syncScopedWidgets('site', siteId));
		}
		if (pageId) {
			dispatch(syncScopedWidgets('page', pageId));
		}
		// TODO: Store as widget
		dispatch(loadAndWatchSpecs(userId, projectId || '', siteId || ''));
		return () => {
			if (siteId) {
				dispatch(unsyncScopedWidgets('site', siteId));
			}
			if (pageId) {
				dispatch(unsyncScopedWidgets('page', pageId));
			}
		};
	}, [siteId, pageId, dispatch, userId, projectId]);

	if (!site) return <Loader />;

	return (
		<ThemeProvider theme={themes.dark}>
			<CursorProvider>
				<TopBar />
				<Switch>
					<Route
						exact
						path="/project/:projectId/site/:siteId/page/:pageId"
						component={Page}
					/>
					<Route path="/project/:projectId/site/:siteId/page/:pageId/preview">
						<PublishedBox>
							<Published />
						</PublishedBox>
					</Route>
					{/* eslint-disable-next-line max-len */}
					{/* Если человек промахнулся, то переадресовываем его на случайную страницу */}
					{_.size(site.children) ? (
						<Redirect
							to={`/project/:projectId/site/${site._id}/page/${_.sample(
								site.children || emptyObject,
							)}`}
						/>
					) : (
						<Route path="" component={Error} />
					)}
				</Switch>
			</CursorProvider>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(Site);
