// @flow

import React from 'react';
import styled from '@emotion/styled';
import { Text, Box, Icon, Button, LoaderIcon, Link } from '@graphite/uneon';
import Records from '../Records';

const ButtonDisconnectDomain = styled(Button)`
	position: absolute;
	right: 24px;
	bottom: 94px;
	padding: 9px;
`;

const BoxConnectedDomain = styled(Box)`
	position: relative;
	padding: 0 24px 18px 24px;
	margin: 0 -24px 18px -24px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.bg.primaryalt};
	& > div:last-child {
		margin-top: 6px;
	}
`;
const BoxRecords = styled(Box)`
	position: relative;
`;

const TextConectedDomain = styled(Text)`
	display: inline;
`;

const serviceSx = {
	marginTop: '18px',
};

const linkSx = {
	textDecoration: 'none',
};

const textSx = {
	marginBottom: '12px',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
};

type TProps = $ReadOnly<{|
	domain: ?string,
	onDisconnect: () => void,
	subDomain: ?string,
	copyPart?: string,
	serviceUrl?: string,
	isLoading: boolean,
	recordsOptions: ?$ReadOnlyArray<$ReadOnlyArray<string>>,
	t: string => void,
|}>;

function Connected({
	domain = '',
	onDisconnect,
	copyPart = '',
	serviceUrl = '',
	subDomain = '',
	isLoading = false,
	recordsOptions,
	t,
}: TProps) {
	return (
		<>
			<BoxConnectedDomain>
				<Text variant="bodysm" color="text.secondary">
					{t('Connected')}
				</Text>
				<Text variant="bodymd" color="text.accent">
					<Link href={`http://${domain || ''}`} target="_blank">
						{domain}
					</Link>
				</Text>
				<Text variant="bodysm" color="text.secondary" sx={serviceSx}>
					Service URL
				</Text>
				<Link
					href={`http://${serviceUrl}`}
					rel="noopener noreferrer"
					target="_blank"
					sx={linkSx}
				>
					<Text variant="bodysm" color="text.accent" sx={textSx}>
						{serviceUrl}
					</Text>
				</Link>
				<ButtonDisconnectDomain
					variant="secondary.rounded.md"
					onClick={onDisconnect}
				>
					{isLoading && <LoaderIcon color="text.primary" />}
					{!isLoading && (
						<Icon name="delete" colors="primaryflat" size="xxsm" />
					)}
				</ButtonDisconnectDomain>
			</BoxConnectedDomain>

			<Text variant="bodysm" color="text.secondary">
				{t(subDomain ? 'Keep an CNAME record for ' : 'Keep A records for ')}
				<TextConectedDomain variant="headlinesm" color="text.secondary">
					{subDomain || domain}
				</TextConectedDomain>
				{t(' at your domain registrar dashboard.')}
			</Text>
			<BoxRecords>
				<Records
					isConnected
					copyPart={copyPart}
					recordsOptions={recordsOptions}
					subDomain={subDomain}
					t={t}
				/>
			</BoxRecords>
		</>
	);
}

export default React.memo<TProps>(Connected);
