// @flow

import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useInjectSaga } from 'libs/inject-saga';
import { useInjectReducer } from 'libs/inject-reducer';

import { themes, PopupWhite, Box, Text, Button, LoaderIcon } from '@graphite/uneon';
import { ThemeProvider } from 'emotion-theming';
import { useSelector, useDispatch } from '@graphite/use-redux';
import { getCurrentSite } from '@graphite/selectors';
import domainsReducer, {
	connect,
	saga as sagaDomain,
	disconnect,
} from '../../../ducks/domains';
import { publish } from '../../../ducks/publish';
import StepsPanel from './StepsPanel';
import Steps from './Steps';
import DomainInput from './DomainInput';
import Connected from './Connected';
import ConnectingError from './ConnectingError';

type TProps = $ReadOnly<{|
	positionRef: {| current: ?React$ElementRef<any> |},
	isOpen: boolean,
	openPublishPopup: () => void,
	onClose: () => void,
|}>;

const titleSx = { marginBottom: '18px' };

const DomainBox = styled(Box)`
	display: flex;
	margin-bottom: 18px;
	flex-direction: column;
	width: 300px;
`;

const DomainPublishText = styled(Text)`
	margin-top: 18px;
	margin-bottom: 59px;
`;

const DomainNext = styled(Button)`
	position: absolute;
	right: 24px;
	bottom: 30px;
`;

const DomainBack = styled(Button)`
	position: absolute;
	left: 24px;
	bottom: 30px;
	${({ isHidden }) => (isHidden ? 'display:none' : '')};
`;
const DomainPublish = styled(Button)`
	position: absolute;
	left: 24px;
	bottom: 30px;
`;

const NETLIFY_IP = '104.198.14.52';

const replaceExpSubDomain = /\.?([\w-]+\.[\w-]+)$/;
const regExpValidDomain = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;

function Domains({ positionRef, isOpen = false, onClose, openPublishPopup }: TProps) {
	useInjectSaga({ key: 'domains', saga: sagaDomain });
	useInjectReducer({ key: 'domains', reducer: domainsReducer });

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const site = useSelector(getCurrentSite);
	const isConected: boolean = !!site?.domain;
	const isPublish: boolean = !!site?.url;
	const [isLoading, setLoading] = React.useState<boolean>(false);
	const netlifyPublishUrl = site?.url && site.url.replace(/http(s)?:\/\//, '');
	const findNetlifyDomen = site?.domain?.replace(replaceExpSubDomain, '');
	const [step, setStep] = React.useState<number>(1);
	const [error, setError] = React.useState<number>(0);
	const [errorConnect, setErrorConnect] = React.useState<number>(0);
	const domainRef = React.useRef(null);
	const timerRef = React.useRef(null);
	React.useEffect(() => {
		setLoading(false);
		if (!isConected) {
			setStep(1);
		}
	}, [isConected]);

	const [subDomain, setSubDomain] = React.useState<?string>(findNetlifyDomen || null);
	const [domain, setDomain] = React.useState<?string>(site?.domain);

	const recordsOptions = React.useMemo(
		() => ({
			A: [
				['A', '@', '→', NETLIFY_IP],
				['A', 'www', '→', NETLIFY_IP],
			],
			CNAME: [['CNAME', subDomain || '', '→'], [netlifyPublishUrl || '']],
		}),
		[netlifyPublishUrl, subDomain],
	);

	const onDisconnect = React.useCallback(() => {
		if (domain) {
			dispatch(disconnect(domain));
			setLoading(true);
			setErrorConnect(0);
			if (timerRef.current) clearTimeout(timerRef.current);
		}
	}, [dispatch, domain]);

	const nextStep = React.useCallback(() => {
		setError(0);
		setErrorConnect(0);
		if (step === 3 && domain) {
			setLoading(true);
			timerRef.current = setTimeout(() => {
				if (!isConected) setErrorConnect(1);
			}, 10000);
			dispatch(connect(domain));
			return;
		}
		if (step > 1) return setStep(step + 1);

		const domainStart = domainRef.current?.value;
		const validDomain = domainStart?.match(regExpValidDomain);
		if (validDomain) {
			const findSubDomain = domainStart?.replace(replaceExpSubDomain, '');
			setSubDomain(findSubDomain);
			setDomain(domainStart || null);
			setStep(step + 1);
		} else {
			setError(domainStart ? 1 : 2);
		}
	}, [dispatch, domain, isConected, step]);

	const onPublish = React.useCallback(() => {
		dispatch(publish());
		openPublishPopup();
		onClose();
	}, [dispatch, openPublishPopup, onClose]);

	const onBack = React.useCallback(() => {
		if (!isLoading) setStep(step - 1);
	}, [isLoading, step]);

	const onFirstStep = React.useCallback(() => {
		setErrorConnect(0);
		setLoading(false);
		setStep(1);
	}, []);

	return (
		<ThemeProvider theme={themes.light}>
			<PopupWhite
				isOpen={isOpen}
				anchorEl={positionRef}
				onClose={onClose}
				offsetTop={0}
				offsetLeft={0}
				mutex="topleft"
				width="auto"
			>
				<Box>
					<Text variant="title3" color="text.primaryalt" sx={titleSx}>
						{t('Domain')}
					</Text>
					{!isPublish && (
						<DomainBox>
							<DomainPublishText variant="bodymd" color="text.primary">
								{t('Publish your site before connecting domain.')}
							</DomainPublishText>
							<DomainPublish
								variant="accent.rounded.md"
								onClick={onPublish}
							>
								{t('Publish')}
							</DomainPublish>
						</DomainBox>
					)}
					{isConected && (
						<DomainBox>
							<Connected
								copyPart={subDomain ? netlifyPublishUrl : NETLIFY_IP}
								serviceUrl={netlifyPublishUrl}
								onDisconnect={onDisconnect}
								t={t}
								domain={domain}
								subDomain={subDomain}
								isLoading={isLoading}
								recordsOptions={
									subDomain ? recordsOptions.CNAME : recordsOptions.A
								}
							/>
						</DomainBox>
					)}
					{!errorConnect && isPublish && !isConected && (
						<DomainBox>
							<StepsPanel step={step} />
							<Steps
								copyPart={subDomain ? netlifyPublishUrl : NETLIFY_IP}
								domain={domain}
								recordsOptions={
									subDomain ? recordsOptions.CNAME : recordsOptions.A
								}
								step={step}
								subDomain={subDomain}
								t={t}
							/>
							<DomainInput
								error={error}
								onClick={nextStep}
								t={t}
								domainRef={domainRef}
								step={step}
							/>
							<DomainBack
								isHidden={step === 1}
								variant="secondary.rounded.md"
								onClick={onBack}
								disabled={isLoading}
							>
								{t('Back')}
							</DomainBack>
							{!isLoading && (
								<DomainNext
									variant="success.rounded.md"
									onClick={nextStep}
								>
									{step === 3 ? t('Connect') : t('Next')}
								</DomainNext>
							)}
							{isLoading && (
								<DomainNext variant="success.rounded.md">
									<LoaderIcon />
								</DomainNext>
							)}
						</DomainBox>
					)}
					{!!(isLoading && errorConnect) && (
						<DomainBox>
							<ConnectingError t={t} onBack={onFirstStep} />
						</DomainBox>
					)}
				</Box>
			</PopupWhite>
		</ThemeProvider>
	);
}

export default React.memo<TProps>(Domains);
