// @flow
import React from 'react';
import _ from 'lodash/fp';

import handleCurrentWidgetCase from './handle-current-widget-case';
import type { TUseMoveWatch } from '../types';

const useMoveWatch: TUseMoveWatch = ({
	frameContext,
	symbioteRootRef,
	refs,
	targetIdsCache,
	findTargetIds,
	setCurrentEdit,
	currentWidget,
}) => {
	const rafID = React.useRef(null);

	React.useEffect(() => {
		const { document: contentDocument } = frameContext;
		const symbioteRoot = symbioteRootRef.current;
		if (!window || !document || !contentDocument || !symbioteRoot) return;

		let lastOverTargets = null;

		const handleMoveModal = (event: MouseEvent) => {
			if (!(event.target instanceof window.Node)) {
				return;
			}
			const eventTarget: Node = event.target;
			const { clientX, clientY }: { clientX: number, clientY: number } = event;
			let overWidget = null;

			if (!refs) {
				return;
			}

			if (rafID.current) cancelAnimationFrame(rafID.current);
			rafID.current = requestAnimationFrame(() => {
				let isColumnControls = false;

				for (const id in refs) {
					const symbioteRefs = refs[id].symbioteRefs || [];
					const isContainsTarget = symbioteRefs.some(
						ref => ref.current && ref.current.contains(eventTarget),
					);

					if (isContainsTarget) {
						isColumnControls = refs[id].kind === 'col';
						overWidget = refs[id];

						// break if symbiote target was found
						break;
					}
				}

				// don't find widgets nodes if column controls is overed
				if (isColumnControls && overWidget) {
					findTargetIds(overWidget.currentRefs[0].current, overWidget);
					return;
				}

				// looking for targets under cursor
				// it needs for finding target in #app when some symbiote is overed
				const { left: baseX, top: baseY } = symbioteRoot.getBoundingClientRect();
				const overTargets =
					contentDocument.elementsFromPoint(clientX - baseX, clientY - baseY) ||
					[];

				if (currentWidget) {
					handleCurrentWidgetCase({
						event: { clientX: clientX - baseX, clientY: clientY - baseY },
						refs,
						currentWidget,
						setCurrentEdit,
						overTargets,
					});
				}

				// small optimization
				if (_.isEqual(lastOverTargets, overTargets)) return;
				lastOverTargets = overTargets;

				const appNode = overTargets.find(node => contentDocument.contains(node));
				findTargetIds(appNode, overWidget !== null ? overWidget : undefined);
			});
		};

		symbioteRoot.addEventListener('mousemove', handleMoveModal);

		return () => {
			symbioteRoot.removeEventListener('mousemove', handleMoveModal);
		};
	}, [
		refs,
		findTargetIds,
		currentWidget,
		frameContext,
		symbioteRootRef,
		targetIdsCache,
		setCurrentEdit,
	]);
};

export default useMoveWatch;
