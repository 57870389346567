// @flow
import Transport from 'winston-transport';

import type { TLogEntry, TCallback } from '../types';

type TOpts = $ReadOnly<{|
	level: string,
|}>;

class FbTransport extends Transport {
	constructor(opts: TOpts) {
		super(opts);
		if (window.fbq) this.logEvent = window.fbq;
	}

	log(logEntry: TLogEntry, callback: TCallback) {
		const { message, level, meta = {} } = logEntry;
		if (level === 'info' && this.logEvent) this.logEvent('track', message, meta);
		return callback(null, true);
	}
}

export default FbTransport;
