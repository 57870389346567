// @flow

import chroma from 'chroma-js';

const TRANSPARENT = [0, 0, 0, 0];
const DEFAULT_BG = [255, 255, 255, 0];

type TColor = $ReadOnlyArray<number>;

export const mixColors = ([r1, g1, b1, a1]: TColor, [r2, g2, b2, a2]: TColor): TColor => {
	const alpha = a2 + a1 * (1 - a2);

	if (alpha === 0) return TRANSPARENT;

	return [
		(r2 * a2 + r1 * a1 * (1 - a2)) / alpha,
		(g2 * a2 + g1 * a1 * (1 - a2)) / alpha,
		(b2 * a2 + b1 * a1 * (1 - a2)) / alpha,
		alpha,
	];
};

export const getBgColor = (target: Element, alpha: number = 0) => {
	let node = target;
	let color = TRANSPARENT;

	while (node) {
		const nodeStyles: CSSStyleDeclaration = getComputedStyle(node);
		const nodeColor = nodeStyles.getPropertyValue('background-color');
		if (nodeColor) {
			const nodeColorRGBA: TColor = chroma(nodeColor).rgba();
			color = mixColors(nodeColorRGBA, color);
		}
		node = node.parentElement;
	}

	const [r, g, b, a] = mixColors(DEFAULT_BG, color);
	color = [r, g, b, Math.min(alpha, a)];

	return `rgba(${color.join(',')})`;
};

export default {};
