// @flow
import mapKeys from 'lodash/fp/mapKeys';
import type { TId, TPositioned, TPositionValue, TEntityChildren } from '@graphite/types';

export default (
	positions: TPositioned = {},
	changed: $ReadOnly<TEntityChildren> = {},
): TPositioned =>
	(mapKeys.convert({ cap: false })(
		(v: ?TPositionValue, k: TId): TId => changed[k] || k,
		positions,
	): TPositioned);
