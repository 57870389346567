// @flow
import React from 'react';
import emptyFunction from 'empty/function';
import chroma from 'chroma-js';
import { InputInplace, Flex } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	hue: number,
	saturation: number,
	value: number,
	alpha: number,
	onChange?: (number, number, number, number) => void,
|}>;

const flexWideStyle = {
	alignItems: 'center',
	justifyContent: 'space-between',
};

const hsvFromString = str => str.split(',').map((c, i) => +c * (i > 0 ? 0.01 : 1));

const formatInfo = {
	hex: {
		fromHsv: (h, s, v) =>
			chroma(h, s, v, 'hsv')
				.hex()
				.slice(1)
				.toUpperCase(),
		toHsv: str => chroma(`#${str}`).hsv(),
		onValidate: str => {
			if (/\b[0-9A-F]{3}\b/gi.test(str) || /\b[0-9A-F]{6}\b/gi.test(str)) {
				const asInt = parseInt(str, 16);
				return asInt >= 0 && asInt <= 0xffffff;
			}

			return false;
		},
		onFilter: value => value.replace(/#/g, ''),
		prefix: '#',
		prefixGap: 9,
		suffix: '',
	},
	hsv: {
		fromHsv: (h, s, v) => `${h},${Math.floor(s * 100)},${Math.floor(v * 100)}`,
		toHsv: hsvFromString,
		onValidate: str => {
			const [h, s, v] = hsvFromString(str);
			return (
				(!Number.isNaN(h) && h >= 0 && h <= 360) ||
				(!Number.isNaN(s) && s >= 0 && s <= 100) ||
				(!Number.isNaN(v) && v >= 0 && v <= 100)
			);
		},
		prefix: '',
		prefixGap: 0,
		suffix: '',
	},
};

const validatePercent = str => {
	const num = +str;
	return !Number.isNaN(num) && num >= 0 && num <= 100;
};

function InputColor({ hue, saturation, value, alpha, onChange = emptyFunction }: TProps) {
	const info = formatInfo.hex;

	const colorText = info.fromHsv(hue, saturation, value);

	const changeTextBound = React.useCallback(
		str => {
			const [h, s, v] = info.toHsv(str);
			onChange(h, s, v, alpha);
		},
		[onChange, info, alpha],
	);

	const changeAlphaBound = React.useCallback(
		str => {
			const a = +str * 0.01;
			onChange(hue, saturation, value, a);
		},
		[onChange, hue, saturation, value],
	);

	return (
		<Flex sx={flexWideStyle}>
			<Flex>
				<InputInplace
					value={colorText}
					prefix={info.prefix}
					suffix={info.suffix}
					prefixGap={info.prefixGap}
					onValidate={info.onValidate}
					onFilter={info.onFilter}
					onEnter={changeTextBound}
					onBlur={changeTextBound}
				/>
			</Flex>
			<InputInplace
				value={`${Math.floor(alpha * 100)}`}
				max={3}
				suffix="%"
				onValidate={validatePercent}
				onEnter={changeAlphaBound}
				onBlur={changeAlphaBound}
			/>
		</Flex>
	);
}

InputColor.defaultProps = {
	onChange: emptyFunction,
};

export default React.memo<TProps>(InputColor);
