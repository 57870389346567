// @flow
import React from 'react';
import { ListItem } from '@graphite/uneon';
import type { TSx, TStyle, TColorShades } from '@graphite/types';

import RowItemColors from './ListRowItemColors';

type TProps = $ReadOnly<{|
	shades: TColorShades,
	active?: ?string,
	dotAt?: string,
	onClick?: (MouseEvent, ?string) => void,
	sx?: TSx,
	style?: TStyle,
|}>;

function ListRowColors({ shades, dotAt, active, sx, style, onClick }: TProps) {
	return (
		<ListItem sx={sx} style={style}>
			{shades.map(({ color, id }) => (
				<RowItemColors
					key={id}
					id={id}
					color={color}
					isDotted={dotAt === id}
					isActive={active === id}
					onClick={onClick}
				/>
			))}
		</ListItem>
	);
}

ListRowColors.defaultProps = {
	dotAt: null,
	active: null,
	sx: null,
	style: null,
	onClick: null,
};

export default React.memo<TProps>(ListRowColors);
