// @flow
import React, { memo, useCallback } from 'react';
import { ListItem } from '@graphite/uneon';
import type { TEffectRadii } from '@graphite/types';

import LabelRadius from './LabelRadius';

type TProps = $ReadOnly<{|
	radii: TEffectRadii,
	active?: ?string,
	onClick?: ?(?string) => void,
	onRemove?: ?(?string) => void,
|}>;

const listItemSx = {
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'bg.primaryalt',
	cursor: 'pointer',
	':last-child': {
		border: 'none',
	},
	':hover': {
		backgroundColor: '#fff',
	},
};

const radiusButtons = {
	buttons: [
		{
			name: 'remove',
			icons: [
				{
					name: 'minus',
					iconSize: 18,
				},
			],
			colors: 'secondary',
		},
	],
};

function ListRadii({ radii, active = null, onClick = null, onRemove = null }: TProps) {
	const boundClick = useCallback(
		(e, item, button) => {
			if (typeof item === 'string' && button === 'remove') {
				if (onRemove) {
					onRemove(item);
				}
				return;
			}
			if (typeof item === 'string') {
				if (onClick) {
					onClick(item);
				}
			}
		},
		[onClick, onRemove],
	);
	return (
		<>
			{radii.map(radius => (
				<ListItem
					key={radius.id}
					name={radius.id}
					onClick={boundClick}
					buttonGroup={radiusButtons}
					sx={listItemSx}
				>
					<LabelRadius radius={radius} isActive={active === radius.id} />
				</ListItem>
			))}
		</>
	);
}

export default memo<TProps>(ListRadii);
