// @flow

import type { TColorPalette, TColorThemes } from '@graphite/types';

type TPreset = $ReadOnly<{|
	palette: TColorPalette,
	themes: TColorThemes,
|}>;

export const colorspecBootstrap: TPreset = {
	palette: [
		{
			id: 'gray500',
			name: 'Gray',
			color: '#adb5bd',
			shades: [
				{ color: '#212529', id: 'gray900' },
				{ color: '#343a40', id: 'gray800' },
				{ color: '#495057', id: 'gray700' },
				{ color: '#6c757d', id: 'gray600' },
				{ color: '#adb5bd', id: 'gray500' },
				{ color: '#ced4da', id: 'gray400' },
				{ color: '#dee2e6', id: 'gray300' },
				{ color: '#e9ecef', id: 'gray200' },
				{ color: '#f8f9fa', id: 'gray100' },
			],
		},
		{
			id: 'blue',
			name: 'Blue',
			color: '#007bff',
			shades: [],
		},
		{
			id: 'green',
			name: 'Green',
			color: '#28a745',
			shades: [],
		},
		{
			id: 'red',
			name: 'Red',
			color: '#dc3545',
			shades: [],
		},
		{
			id: 'yellow',
			name: 'Yellow',
			color: '#ffc107',
			shades: [],
		},
		{
			id: 'cyan',
			name: 'Cyan',
			color: '#17a2b8',
			shades: [],
		},
		{
			id: 'white',
			name: 'White',
			color: '#fff',
			shades: [],
		},
		{
			id: 'black',
			name: 'Black',
			color: '#000',
			shades: [],
		},
	],

	themes: [
		{
			id: 'light',
			name: 'Light',
			text: {
				primary: { entryId: 'gray500', shadeId: 'gray900', snapshot: '#212529' },
				primaryalt: {
					entryId: 'gray500',
					shadeId: 'gray800',
					snapshot: '#343a40',
				},
				secondary: {
					entryId: 'gray500',
					shadeId: 'gray600',
					snapshot: '#6c757d',
				},
				tertiary: { entryId: 'gray500', shadeId: 'gray600', snapshot: '#6c757d' },
				accent: { entryId: 'blue', shadeId: null, snapshot: '#007bff' },
				accentalt: { entryId: 'cyan', shadeId: null, snapshot: '#17a2b8' },
				success: { entryId: 'green', shadeId: null, snapshot: '#28a745' },
				warning: { entryId: 'yellow', shadeId: null, snapshot: '#ffc107' },
				error: { entryId: 'red', shadeId: null, snapshot: '#dc3545' },
				oncolor: { entryId: 'white', shadeId: null, snapshot: '#fff' },
				accentplus: { entryId: null, shadeId: null, snapshot: '#0056b3' },
				accentaltplus: { entryId: null, shadeId: null, snapshot: '#138496' },
				successplus: { entryId: null, shadeId: null, snapshot: '#218838' },
				warningplus: { entryId: null, shadeId: null, snapshot: '#e0a800' },
				errorplus: { entryId: null, shadeId: null, snapshot: '#c82333' },
				accentminus: { entryId: null, shadeId: null, snapshot: '#0062cc' },
				accentaltminus: { entryId: null, shadeId: null, snapshot: '#117a8b' },
				successminus: { entryId: null, shadeId: null, snapshot: '#1e7e34' },
				warningminus: { entryId: null, shadeId: null, snapshot: '#d39e00' },
				errorminus: { entryId: null, shadeId: null, snapshot: '#bd2130' },
			},
			bg: {
				primary: { entryId: 'white', shadeId: null, snapshot: '#fff' },
				primaryalt: {
					entryId: 'gray500',
					shadeId: 'gray200',
					snapshot: '#e9ecef',
				},
				secondary: {
					entryId: 'gray500',
					shadeId: 'gray600',
					snapshot: '#6c757d',
				},
				tertiary: { entryId: 'gray500', shadeId: 'gray800', snapshot: '#343a40' },
				accent: { entryId: 'blue', shadeId: null, snapshot: '#007bff' },
				accentalt: { entryId: 'cyan', shadeId: null, snapshot: '#17a2b8' },
				success: { entryId: 'green', shadeId: null, snapshot: '#28a745' },
				warning: { entryId: 'yellow', shadeId: null, snapshot: '#ffc107' },
				error: { entryId: 'red', shadeId: null, snapshot: '#dc3545' },
				primaryplus: {
					entryId: 'gray500',
					shadeId: 'gray100',
					snapshot: '#f8f9fa',
				},
				primaryaltplus: { entryId: null, shadeId: null, snapshot: '#e2e6ea' },
				secondaryplus: { entryId: null, shadeId: null, snapshot: '#5a6268' },
				tertiaryplus: { entryId: null, shadeId: null, snapshot: '#23272b' },
				accentplus: { entryId: null, shadeId: null, snapshot: '#0069d9' },
				accentaltplus: { entryId: null, shadeId: null, snapshot: '#138496' },
				successplus: { entryId: null, shadeId: null, snapshot: '#218838' },
				warningplus: { entryId: null, shadeId: null, snapshot: '#e0a800' },
				errorplus: { entryId: null, shadeId: null, snapshot: '#c82333' },
				primaryminus: {
					entryId: 'gray500',
					shadeId: 'gray200',
					snapshot: '#e9ecef',
				},
				primaryaltminus: { entryId: null, shadeId: null, snapshot: '#dae0e5' },
				secondaryminus: { entryId: null, shadeId: null, snapshot: '#545b62' },
				tertiaryminus: { entryId: null, shadeId: null, snapshot: '#1d2124' },
				accentminus: { entryId: null, shadeId: null, snapshot: '#0062cc' },
				accentaltminus: { entryId: null, shadeId: null, snapshot: '#117a8b' },
				successminus: { entryId: null, shadeId: null, snapshot: '#1e7e34' },
				warningminus: { entryId: null, shadeId: null, snapshot: '#d39e00' },
				errorminus: { entryId: null, shadeId: null, snapshot: '#bd2130' },
			},
		},
		{
			id: 'dark',
			name: 'Dark',
			text: {
				primary: { entryId: 'gray500', shadeId: 'gray100', snapshot: '#f8f9fa' },
				primaryalt: { entryId: 'white', shadeId: null, snapshot: '#fff' },
				secondary: {
					entryId: 'gray500',
					shadeId: 'gray600',
					snapshot: '#6c757d',
				},
				tertiary: { entryId: 'gray500', shadeId: 'gray600', snapshot: '#6c757d' },
				accent: { entryId: 'blue', shadeId: null, snapshot: '#007bff' },
				accentalt: { entryId: 'cyan', shadeId: null, snapshot: '#17a2b8' },
				success: { entryId: 'green', shadeId: null, snapshot: '#28a745' },
				warning: { entryId: 'yellow', shadeId: null, snapshot: '#ffc107' },
				error: { entryId: 'red', shadeId: null, snapshot: '#dc3545' },
				oncolor: { entryId: 'white', shadeId: null, snapshot: '#fff' },
				accentplus: { entryId: null, shadeId: null, snapshot: '#0056b3' },
				accentaltplus: { entryId: null, shadeId: null, snapshot: '#138496' },
				successplus: { entryId: null, shadeId: null, snapshot: '#218838' },
				warningplus: { entryId: null, shadeId: null, snapshot: '#e0a800' },
				errorplus: { entryId: null, shadeId: null, snapshot: '#c82333' },
				accentminus: { entryId: null, shadeId: null, snapshot: '#0062cc' },
				accentaltminus: { entryId: null, shadeId: null, snapshot: '#117a8b' },
				successminus: { entryId: null, shadeId: null, snapshot: '#1e7e34' },
				warningminus: { entryId: null, shadeId: null, snapshot: '#d39e00' },
				errorminus: { entryId: null, shadeId: null, snapshot: '#bd2130' },
			},
			bg: {
				primary: { entryId: 'gray500', shadeId: 'gray800', snapshot: '#343a40' },
				primaryalt: { entryId: 'black', shadeId: null, snapshot: '#000' },
				secondary: {
					entryId: 'gray500',
					shadeId: 'gray600',
					snapshot: '#6c757d',
				},
				tertiary: { entryId: 'gray500', shadeId: 'gray900', snapshot: '#212529' },
				accent: { entryId: 'blue', shadeId: null, snapshot: '#007bff' },
				accentalt: { entryId: 'cyan', shadeId: null, snapshot: '#17a2b8' },
				success: { entryId: 'green', shadeId: null, snapshot: '#28a745' },
				warning: { entryId: 'yellow', shadeId: null, snapshot: '#ffc107' },
				error: { entryId: 'red', shadeId: null, snapshot: '#dc3545' },

				primaryplus: { entryId: null, shadeId: null, snapshot: '#23272b' },
				primaryaltplus: { entryId: null, shadeId: null, snapshot: '#e2e6ea' },
				secondaryplus: { entryId: null, shadeId: null, snapshot: '#5a6268' },
				tertiaryplus: { entryId: null, shadeId: null, snapshot: '#23272b' },
				accentplus: { entryId: null, shadeId: null, snapshot: '#0069d9' },
				accentaltplus: { entryId: null, shadeId: null, snapshot: '#138496' },
				successplus: { entryId: null, shadeId: null, snapshot: '#218838' },
				warningplus: { entryId: null, shadeId: null, snapshot: '#e0a800' },
				errorplus: { entryId: null, shadeId: null, snapshot: '#c82333' },
				primaryminus: { entryId: null, shadeId: null, snapshot: '#545b62' },
				primaryaltminus: { entryId: null, shadeId: null, snapshot: '#dae0e5' },
				secondaryminus: { entryId: null, shadeId: null, snapshot: '#545b62' },
				tertiaryminus: { entryId: null, shadeId: null, snapshot: '#1d2124' },
				accentminus: { entryId: null, shadeId: null, snapshot: '#0062cc' },
				accentaltminus: { entryId: null, shadeId: null, snapshot: '#117a8b' },
				successminus: { entryId: null, shadeId: null, snapshot: '#1e7e34' },
				warningminus: { entryId: null, shadeId: null, snapshot: '#d39e00' },
				errorminus: { entryId: null, shadeId: null, snapshot: '#bd2130' },
			},
		},
	],
};

export default colorspecBootstrap;
