// @flow
import React from 'react';
import { Box } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	id: string,
	color: string,
	isDotted?: boolean,
	isActive?: boolean,
	onClick?: (MouseEvent, ?string) => void,
|}>;

const itemStyle = {
	border: '1px solid rgba(0, 0, 0, 0.05)',
	height: '24px',
	flexGrow: 1,
	position: 'relative',
	cursor: 'pointer',
	':first-of-type': {
		borderRadius: 'rounded.left',
	},
	':last-of-type': {
		borderRadius: 'rounded.right',
	},
};

const itemActiveStyle = {
	position: 'absolute',
	zIndex: 1,
	top: '-2px',
	left: '-2px',
	right: '-2px',
	bottom: '-2px',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'bg.accent',
	borderRadius: 'sm.all',
};

const dotStyle = {
	borderRadius: 'rounded.all',
	width: '6px',
	height: '6px',
	backgroundColor: 'rgba(255, 255, 255, 0.5)',
	top: 'calc(50% - 3px)',
	left: 'calc(50% - 3px)',
	position: 'absolute',
};

function RowItemColors({ id, color, isDotted, isActive, onClick }: TProps) {
	const boundEdit = React.useCallback(
		e => {
			if (onClick) {
				onClick(e, id);
			}
		},
		[onClick, id],
	);
	const colorStyle = React.useMemo(() => ({ backgroundColor: color }), [color]);

	return (
		<Box key={id} style={colorStyle} sx={itemStyle} onClick={boundEdit}>
			{isDotted && <Box sx={dotStyle} />}
			{isActive && <Box sx={itemActiveStyle} />}
		</Box>
	);
}

RowItemColors.defaultProps = {
	isDotted: false,
	isActive: false,
	onClick: null,
};

export default React.memo<TProps>(RowItemColors);
