// @flow
import React from 'react';

import type { TSetTargetIds, TData, TProps } from './types';
import useFindTargetIds from './libs/use-find-target-ids';
import useResizeWatch from './libs/use-resize-watch';
import useOverWatch from './libs/use-over-watch';
import useOutWatch from './libs/use-out-watch';
import useMoveWatch from './libs/use-move-watch';
import useRegSymbiote from './libs/use-reg-symbiote';
import SymbioteContext, { ContextActiveLevel, ContextSetActiveLevel } from './Context';

import FrameContext from '../../../Frame/Context';

const rootStyle = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	left: '0',
	top: '0',
	pointerEvents: 'none',
};

const SymbioteProvider = ({ children, currentWidget }: TProps) => {
	const frameContext = React.useContext(FrameContext);
	const [activeLevel, setActiveLevel] = React.useState(null);

	const symbioteRootRef = React.useRef(null);

	const [currentEdit, setCurrentEdit] = React.useState(false);
	React.useEffect(() => {
		if (!currentWidget) {
			setCurrentEdit(false);
		}
	}, [currentWidget]);

	const targetIdsCache = React.useRef(null);
	const [targetIds, setTargetIdsOriginal] = React.useState<?$ReadOnlyArray<string>>(
		null,
	);
	const setTargetIds = React.useCallback<TSetTargetIds>(func => {
		setTargetIdsOriginal(ids => {
			const newIds = func(ids);
			targetIdsCache.current = newIds;
			return newIds;
		});
	}, []);

	// Регистрирует симбиоты на текущй странице
	const { refs, regSymbiote, unregSymbiote } = useRegSymbiote();

	//
	const findTargetIds = useFindTargetIds(refs, setTargetIds);

	// следим за изменением окна и модификациями атрибутов в ДОМе
	const [mutationKey] = useResizeWatch(frameContext);

	// следим за тем, как мышка надвигается на элемент
	useOverWatch({
		frameContext,
		currentWidget,
		refs,
		setCurrentEdit,
		setTargetIds,
		findTargetIds,
	});

	// следим за тем, как мышка уходит с элемента
	useOutWatch({ setTargetIds, symbioteRootRef });

	//
	useMoveWatch({
		frameContext,
		refs,
		targetIdsCache,
		findTargetIds,
		setCurrentEdit,
		currentWidget,
		symbioteRootRef,
	});

	const providedData: TData = React.useMemo(
		() => ({
			targetIds,
			regSymbiote,
			unregSymbiote,
			currentEdit,
			mutationKey,
			symbioteRoot: symbioteRootRef,
		}),
		[targetIds, regSymbiote, unregSymbiote, currentEdit, mutationKey],
	);

	return (
		<SymbioteContext.Provider value={providedData}>
			<ContextActiveLevel.Provider value={activeLevel}>
				<ContextSetActiveLevel.Provider value={setActiveLevel}>
					{children}
					<div style={rootStyle} ref={symbioteRootRef} />
				</ContextSetActiveLevel.Provider>
			</ContextActiveLevel.Provider>
		</SymbioteContext.Provider>
	);
};

export default React.memo<TProps>(SymbioteProvider);
