// @flow

import _ from 'lodash/fp';

import { getRects } from './rect';
import type {
	TDNDMethodInRect,
	TDNDMethodSwap,
	TDNDMethodSetPosition,
	TDNDMethodSetDropPlace,
	TDNDMethodSetDragPlace,
	TDNDMethodSetStackPlace,
} from '../constants/types';

const FLIP_ZONE_RATIO = 0.5;

const inRect: TDNDMethodInRect = ({ x, y }, rect) =>
	x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;

const setStackPlace: TDNDMethodSetStackPlace = (state, overId = null) => {
	const { stackPlace, widgets } = state;
	const overWidget = overId ? widgets[overId] : null;

	if (!overWidget) return stackPlace ? _.set('stackPlace', null, state) : state;
	if (_.isEqual(overWidget, stackPlace)) return state;

	return _.set('stackPlace', overWidget, state);
};

const setDropPlace: TDNDMethodSetDropPlace = (state, overId = null) => {
	const { dropPlaces, dropPlace } = state;
	const overDrop = (overId && dropPlaces[overId]) || null;

	if (!overDrop) return dropPlace ? _.set('dropPlace', null, state) : state;
	if (_.isEqual(overDrop, dropPlace)) return state;

	const resetStackState = setStackPlace(state);
	return _.set('dropPlace', overDrop, resetStackState);
};

const setDragPlace: TDNDMethodSetDragPlace = (state, overId = null, position = null) => {
	const { dragPlace, widgets } = state;
	const overWidget = overId ? widgets[overId] : null;
	const overPlace = { ...overWidget, position };

	if (!position && !overWidget) {
		return _.merge(state, { dragPlace: null, isFliping: false });
	}

	if (_.isEqual(overPlace, dragPlace)) return state;
	const resetStackState = setStackPlace(state);
	return _.set('dragPlace', overPlace, resetStackState);
};

const swap: TDNDMethodSwap = ({
	state,
	dragRect,
	overRect,
	canStack,
	cursor,
	direction,
}) => {
	const isVertical = direction === 'vertical';
	const size = isVertical ? 'height' : 'width';
	const firstSide = isVertical ? 'top' : 'left';
	const secondSide = isVertical ? 'bottom' : 'right';
	const cursorPosition = isVertical ? cursor.y : cursor.x;

	const { overId, lastOverId } = state;
	const minSize: number = _.min([overRect[size], dragRect[size]]);
	const flipZone = overRect[size] * FLIP_ZONE_RATIO;
	const crossZone = canStack && flipZone < minSize ? flipZone : minSize;

	let position = null;

	const isCrossFirst = cursorPosition < overRect[firstSide] + crossZone;
	const isCrossSecond = cursorPosition > overRect[secondSide] - crossZone;

	const isCrossFlip =
		overRect[secondSide] <= dragRect[firstSide] ? isCrossFirst : isCrossSecond;
	const isStackOver = overId && canStack && !isCrossFlip && inRect(cursor, overRect);

	if (overRect[secondSide] <= dragRect[firstSide]) {
		position = isCrossFirst ? 'before' : 'after';
	} else {
		position = isCrossSecond ? 'after' : 'before';
	}

	if (overId || lastOverId) {
		const stackState = setStackPlace(
			state,
			isStackOver && overId ? overId : undefined,
		);
		return setDragPlace(stackState, overId || lastOverId || undefined, position);
	}

	return state;
};

export const setPosition: TDNDMethodSetPosition = (state, payload, isDefaultDevice) => {
	const { x = null, y = null } = payload || {};

	if (x === null || y === null) {
		return state;
	}

	if (state.isFliping) {
		return setStackPlace(state);
	}

	const {
		dragId,
		overId,
		lastOverId,
		dropPlaces,
		dropPlace,
		stackPlace,
		widgets,
		srcContainerId,
	} = state;

	const resetState = _.merge(state, {
		dragPlace: null,
		dropPlace: null,
		stackPlace: null,
		overId: null,
		overNode: null,
		lastOverId: null,
	});
	const dragWidget = dragId ? widgets[dragId] : null;
	const overWidget = widgets[overId || ''] || widgets[lastOverId || ''] || null;
	const drop = overId ? dropPlaces[overId] : null;

	if (!isDefaultDevice && overWidget && overWidget.containerId !== srcContainerId) {
		return resetState;
	}

	if (overId && drop) return setDropPlace(state, overId);
	if (dropPlace && !drop) return setDropPlace(state);

	const canStack = !!(
		dragWidget &&
		overWidget &&
		dragWidget.widgetId !== overWidget.widgetId &&
		dragWidget.widgetType === 'widget' &&
		overWidget.widgetType === 'widget' &&
		!overWidget.isInstance
	);

	if (!canStack && stackPlace) return setStackPlace(state);
	if (overId === dragId) return resetState;

	if (!dragWidget || !overWidget || dragWidget.widgetId === overWidget.widgetId) {
		return state;
	}

	const composeId = `${overWidget.containerId}-${dragWidget.widgetId}-${overWidget.rowId}`;
	const clonedWidget = widgets[composeId] || null;
	const { direction } = overWidget;

	const dragRect = getRects(clonedWidget || dragWidget)?.outer;
	const overRect = getRects(overWidget)?.outer;

	if (!dragRect || !overRect) return state;

	return swap({
		state,
		dragRect,
		overRect,
		canStack,
		cursor: { x, y },
		direction,
	});
};

export default {};
