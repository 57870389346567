// @flow

import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import type { TId } from '@graphite/types';
import { ButtonDeprecated, Input, ListItem, Text } from '@graphite/uneon';
import { defaultColor, config } from '@graphite/constants';

import logger from 'libs/logger';
import { genId } from 'libs/firebase';
import { useDispatch } from '@graphite/use-redux';
import { getCurrentUser, getCurrentSiteId } from '@graphite/selectors';
import { updateSpecs } from 'Editor/ducks/specs';
import { editWidget } from 'Editor/ducks/widgets';
import { Color as LabelColor } from '@graphite/labels';

import type { TColors } from './types';
import fillColorSpec from './libs/fill-color-spec';

type TProps = $ReadOnly<{|
	onExit: () => void,
|}>;

const { coloraiUrl } = config;

const applyButtonSx = {
	margin: '10px 0 10px auto',
};

const initialColors: TColors = {
	primary: defaultColor,
	secondary: defaultColor,
	normal: defaultColor,
	error: defaultColor,
	warning: defaultColor,
	success: defaultColor,
};

const byte = x => x.toString(16).padStart(2, '0');

const getColors = async query => {
	const url = `${coloraiUrl}?name=${encodeURIComponent(query)}`;
	const response = await fetch(url, { mode: 'cors' });
	const json = await response.json();
	return _.mapValues(([r, g, b]) => `#${byte(r)}${byte(g)}${byte(b)}`, json);
};

function NewPalette({ onExit }: TProps) {
	const dispatch = useDispatch();
	const user = useSelector(getCurrentUser);
	const { t } = useTranslation();
	const siteId: ?TId = useSelector(getCurrentSiteId);
	const [isBusy, setIsBusy] = React.useState<boolean>(false);
	const [colors, setColors] = React.useState<TColors>(initialColors);
	const [query, setQuery] = React.useState<string>('');

	React.useEffect(() => {
		if (query.length < 3) {
			return;
		}
		let isMounted = true;
		setIsBusy(true);
		(async () => {
			try {
				const colors = await getColors(query);
				if (!isMounted) {
					return;
				}
				setIsBusy(false);
				setColors(colors);
			} catch (e) {
				logger.error(e);
				if (!isMounted) {
					return;
				}
				setIsBusy(false);
			}
		})();
		return () => {
			isMounted = false;
		};
	}, [query]);

	const applyPalette = React.useCallback(() => {
		try {
			if (!user || !siteId) {
				return;
			}
			const newId = genId('specs');
			const newSpec = fillColorSpec({
				colors,
				_id: newId,
				userId: user._id,
				siteId,
				name: query,
			});
			logger.info('editColorSpec');
			dispatch(updateSpecs({ [newId]: newSpec }));
			// не ебу какой тут нужен instanceId и originId
			dispatch(editWidget(siteId, null, siteId, { colorspecId: newId }));
			onExit();
		} catch (e) {
			logger.error(e);
		}
	}, [colors, dispatch, onExit, query, siteId, user]);

	const updateInputDebounced = React.useMemo(
		() =>
			_.debounce(1500, text => {
				setQuery(text);
			}),
		[],
	);
	const updateInput = React.useCallback(
		e => {
			const { target } = e;
			if (!(target instanceof window.HTMLInputElement)) {
				return;
			}
			updateInputDebounced(target.value || '');
		},
		[updateInputDebounced],
	);

	return (
		<>
			<ListItem size="lg">
				<Text variant="headlinemd" color="text.primary">
					{t('Enter your type of business or any keyword')}
				</Text>
			</ListItem>
			<ListItem size="xxxlg">
				<Input
					width="100%"
					placeholder={t('e.g., gifts')}
					onChange={updateInput}
					disabled={isBusy}
				/>
			</ListItem>
			<ListItem size="lg">
				<Text variant="bodysm" color="text.secondary">
					{t('Enter your type of business or any keyword')}
				</Text>
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.primary} title={t('Primary')} />
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.secondary} title={t('Secondary')} />
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.normal} title={t('Normal')} />
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.error} title={t('Error')} />
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.warning} title={t('Warning')} />
			</ListItem>
			<ListItem size="lg">
				<LabelColor color={colors.success} title={t('Success')} />
			</ListItem>
			<ButtonDeprecated
				size="sm"
				colors="accent"
				variant="normal"
				label={t('Apply Palette')}
				onClick={applyPalette}
				sx={applyButtonSx}
				isDisabled={isBusy}
			/>
		</>
	);
}

export default React.memo<TProps>(NewPalette);
