// @flow
import React from 'react';
import type { Dispatch } from 'redux';
import { Flex } from '@graphite/uneon';
import { zIndices } from '@graphite/constants';

import ControlsPopup from 'Editor/ControlsPopup';
import getDisplayName from '@graphite/get-display-name';
import type {
	TWidget,
	TPositionValue,
	TGridBreakpointName,
	TId,
	TAction,
	TOffsetDevice,
	TSpecsColor,
	TSpecsGrid,
	TSpecsEffect,
	TSpecsWidget,
	TWidgetMode,
} from '@graphite/types';

const flexSx = {
	position: 'relative',
	justifyContent: 'center',
	width: '100%',
	zIndex: zIndices.widgetActiveControls,
};

type TCheckActive = ($ReadOnly<{ widgetMode: TWidgetMode }>) => boolean;

type TMinimalProps = $ReadOnly<{
	data: TWidget,
	position: TPositionValue,
	originId: ?TId,
	instanceId: ?TId,
	containerId: ?TId,
	dispatch: Dispatch<TAction>,
	currentRef: {| current: ?HTMLDivElement |},
	widgetMode: TWidgetMode,
	currentDevice: TGridBreakpointName,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	effectspec: TSpecsEffect,
	widgetspec: TSpecsWidget,
	repositionWidget: (
		targetId: TId,
		originId: TId,
		containerId: ?TId,
		position: TPositionValue,
		offset: TOffsetDevice,
	) => void,
}>;

const withControls = <T1: TMinimalProps>(
	checkActive: TCheckActive,
	CustomControls?: React$ComponentType<$ReadOnly<{| ...$Exact<T1> |}>>,
) => (
	Component: React$ComponentType<$ReadOnly<{| ...$Exact<T1> |}>>,
): React$ComponentType<$Exact<T1>> => {
	const WithControls = (props: $Exact<T1>) => {
		const {
			data,
			position,
			originId,
			instanceId,
			containerId,
			dispatch,
			currentRef,
			currentDevice,
			repositionWidget,
		} = props;

		return (
			<Flex sx={flexSx}>
				<ControlsPopup
					isActive={checkActive(props)}
					data={data}
					position={position}
					originId={originId}
					instanceId={instanceId}
					containerId={containerId}
					dispatch={dispatch}
					currentRef={currentRef}
					currentDevice={currentDevice}
					repositionWidget={repositionWidget}
				>
					{/* eslint-disable-next-line react/jsx-props-no-spreading */}
					{CustomControls ? <CustomControls {...props} /> : null}
				</ControlsPopup>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Component {...props} />
			</Flex>
		);
	};

	WithControls.displayName = `withControls(${getDisplayName(Component)})`;

	return React.memo(WithControls);
};

export default withControls;
