// @flow
import React from 'react';
import _ from 'lodash/fp';

import type { TId, TWidgetKind } from '@graphite/types';
import { SymbioteContext } from './Provider';
import Portal from './SymbiotePortal';
import type { TOptions } from './types';

type TProps = $ReadOnly<{|
	ownProps: mixed,
	opts: TOptions,
	currentRef: {| current: ?HTMLElement |},
	children: React$Element<React$ElementType>,
	className: ?string,
|}>;

const Symbiote = (props: TProps) => {
	const { ownProps, currentRef, children, className } = props;
	const context = React.useContext(SymbioteContext);
	const {
		targetIds,
		mutationKey,
		regSymbiote,
		unregSymbiote,
		currentEdit,
		symbioteRoot,
	} = context;

	const opts: TOptions = React.useMemo(() => {
		if (!props.opts.symbioteRoot) {
			return {
				...props.opts,
				symbioteRoot,
			};
		}
		return props.opts;
	}, [props.opts, symbioteRoot]);

	const symbioteRef = React.useRef<?HTMLElement>(null);

	const kind: TWidgetKind = _.get('data.kind', ownProps);
	const widgetId: TId = _.get('id', ownProps);
	const instanceId: TId = _.get('instanceId', ownProps);
	const containerId: TId = _.get('containerId', ownProps);
	const rowId: string = _.get('rowId', ownProps);
	const composeId = `${containerId}-${widgetId}-${rowId}`;

	React.useEffect(() => {
		regSymbiote({
			composeId,
			kind,
			containerId,
			regRefs: { symbioteRef, currentRef },
			widgetId,
			instanceId,
		});
		return () => unregSymbiote({ composeId, symbioteRef, currentRef });
	}, [
		composeId,
		kind,
		containerId,
		regSymbiote,
		unregSymbiote,
		symbioteRef,
		currentRef,
		widgetId,
		instanceId,
	]);

	const isHovered = !!(targetIds && targetIds.includes(composeId) && !currentEdit);

	return (
		<Portal
			currentRef={currentRef}
			symbioteRef={symbioteRef}
			ownProps={ownProps}
			opts={opts}
			renderKey={mutationKey}
			isHovered={isHovered}
			className={className}
		>
			{React.cloneElement(children, { hovered: isHovered, symbioteRef })}
		</Portal>
	);
};

export default React.memo<TProps>(Symbiote);
