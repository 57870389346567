// @flow
import _ from 'lodash/fp';
import type {
	TWidget,
	TWidgetOpFeedbackEmpty,
	TWidgetOpFeedbackFull,
	TWidgetComposed,
} from '@graphite/types';

const cast = {
	TWidgetOpFeedbackFull(feedback: TWidgetOpFeedbackEmpty): ?TWidgetOpFeedbackFull {
		if (feedback.targetId) {
			return { targetId: feedback.targetId };
		}
		return null;
	},
	TWidgetInstance(widget: $ReadOnly<TWidget>): ?$ReadOnly<TWidget> {
		if (widget.modified) {
			return widget;
		}
		return null;
	},
	TWidgetComposed(widget: $ReadOnly<TWidget>): ?$ReadOnly<TWidgetComposed> {
		if (widget.kind && widget.name) {
			return _.assign(widget, { kind: widget.kind });
		}
		return null;
	},
	TWidgetComposedInstance(widget: $ReadOnly<TWidget>): ?$ReadOnly<TWidgetComposed> {
		const composed: ?TWidgetComposed = cast.TWidgetComposed(widget);
		if (composed && composed.modified) {
			return composed;
		}
		return null;
	},
};

export default cast;
