// @flow
import React from 'react';
import _ from 'lodash/fp';
import { Flex } from '@graphite/uneon';
import type { TSx, TDesign } from '@graphite/types';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import emptyObject from 'empty/object';

import Widget from 'Widget';
import type { TConnectProps } from './constants/types';

const baseSx = {
	position: 'relative',
	flexDirection: 'column',
	alignItems: 'stretch',
	justifyContent: 'flex-start',
	flexWrap: 'nowrap',
};

const Col = (props: TConnectProps, ref) => {
	const {
		data,
		babies,
		dragContainer,
		dragFlip,
		rowId,
		widgetChain,
		gridspec,
		widgetspec,
		colorspec,
		effectspec,
		style,
		onClick = null,
		colSizes,
	} = props;

	const { _id } = data;

	const parentChain = React.useMemo(() => [_id, ...(widgetChain || [])], [
		widgetChain,
		_id,
	]);
	const instanceId = data.modified ? data._id : props.instanceId;

	const childs = React.useMemo(() => {
		return babies.map(id => (
			<Widget
				key={id}
				id={id}
				rowId={rowId}
				containerId={data._id}
				instanceId={instanceId}
				widgetChain={parentChain}
				widgetspec={widgetspec}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
			/>
		));
	}, [
		babies,
		colorspec,
		data._id,
		effectspec,
		gridspec,
		instanceId,
		parentChain,
		rowId,
		widgetspec,
	]);

	const boxSx = gridspec
		? getBoxSx({ data, position: 'grid', direction: 'horizontal', gridspec })
		: emptyObject;

	const designSx = React.useMemo(() => {
		if (!data.designId || !widgetspec || !gridspec || !colorspec || !effectspec) {
			return null;
		}

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.col.find(d => d._id === data.designId);
		if (!design) {
			return null;
		}

		return getDesignSx({
			design,
			gridspec,
			colorspec,
			effectspec,
			widgetspec,
		});
	}, [data.designId, data.designs, widgetspec, gridspec, colorspec, effectspec]);

	const outerSx: TSx = React.useMemo(() => _.mergeAll([baseSx, colSizes]), [colSizes]);

	const innerSx: TSx = React.useMemo(() => _.mergeAll([baseSx, boxSx, designSx]), [
		boxSx,
		designSx,
	]);

	const otherProps = _.assign(dragContainer, dragFlip);

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<Flex sx={outerSx} style={style} {...otherProps}>
			<Flex ref={ref} onMouseDown={onClick} sx={innerSx}>
				{childs}
			</Flex>
		</Flex>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Col));
