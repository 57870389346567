// @flow
import React from 'react';
import type { TPopupOnClick, TSelectParam } from '@graphite/types';
import { SelectSimple } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	value: string,
	param: TSelectParam,
	onChange?: ?(?string) => void,
	onClick?: ?TPopupOnClick,
|}>;

const paramRightStyle = {
	flexGrow: 1,
	width: '50%',
	position: 'relative',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
};

function ListItemSelectParams({ value, param, onChange, onClick }: TProps) {
	const changeParam = React.useCallback(
		// eslint-disable-next-line no-shadow
		(e, value, buttons) => {
			if (typeof value !== 'string' && value !== null) {
				return;
			}
			if (buttons) {
				if (onClick) {
					// eslint-disable-next-line consistent-return
					return onClick(e, value, buttons);
				}
				return;
			}
			if (onChange) {
				onChange(value);
			}
		},
		[onChange, onClick],
	);
	const list = React.useMemo(
		() => ({
			items: param.info.list.items,
			active: value,
		}),
		[param, value],
	);

	return (
		<SelectSimple
			size="sm"
			label={param.info.label}
			list={list}
			sx={paramRightStyle}
			onClick={changeParam}
		/>
	);
}

ListItemSelectParams.defaultProps = {
	onChange: null,
	onClick: null,
};

export default React.memo<TProps>(ListItemSelectParams);
