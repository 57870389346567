// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { ToolbarBtn, PopupWhite } from '@graphite/uneon';
import getColor from '@graphite/get-color';
import type { TSpecsColor, TLibColorValue, TSpecsGrid } from '@graphite/types';
import Library from '@graphite/library';

type TProps = $ReadOnly<{|
	colorspec: TSpecsColor,
	value?: TLibColorValue,
	onChange: () => void,
	gridspec: TSpecsGrid,
	genId: string => string,
|}>;

const libColorPalette = { color: { tabs: ['theme', 'palette', 'custom'] } };

const ColorBtn = styled.div`
	background: ${({ currentColor }) => currentColor || 'black'};
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	width: 18px;
	height: 18px;
`;

const ToolbarColorButton = ({ colorspec, gridspec, value, onChange, genId }: TProps) => {
	const { t } = useTranslation();
	const colorBtnEl = React.useRef(null);

	const [isActive, setActive] = React.useState(false);
	const toogleActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setActive(isActive => !isActive);
		},
		[setActive],
	);
	const disableActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setActive(false);
		},
		[setActive],
	);

	return (
		<>
			<ToolbarBtn onMouseDown={toogleActive} ref={colorBtnEl}>
				<ColorBtn currentColor={getColor(colorspec, value?.value)} />
			</ToolbarBtn>

			<PopupWhite
				isOpen={isActive}
				onClose={disableActive}
				anchorEl={colorBtnEl}
				offsetTop={10}
				isFixed
				mutex="color"
			>
				{isActive && (
					<Library
						t={t}
						config={libColorPalette}
						value={value}
						onChange={onChange}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(ToolbarColorButton);
