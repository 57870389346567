// @flow

import emptyObject from 'empty/object';
import { DefaultDraftInlineStyle } from 'draft-js';
import createStyles from 'draft-js-custom-styles';
import type { TSpecsColor } from '@graphite/types';
import getTrueColor from '@graphite/get-color';

const PREFIX = 'CUSTOM_';

export const customStyleMap = {
	...DefaultDraftInlineStyle,
};

export const { styles } = createStyles(
	[
		'font-size',
		'font-weight',
		'font-style',
		'color',
		'text-decoration',
		'text-transform',
		'vertical-align',
	],
	PREFIX,
	customStyleMap,
);

const oldColorStyleFn = styles.color.styleFn;
styles.color.styleFn = (style, colorspec) => {
	const { color } = oldColorStyleFn(style);

	try {
		const colorParsed = JSON.parse(color);
		return { color: getTrueColor(colorspec, colorParsed.value) };
	} catch (e) {
		return emptyObject;
	}
};

const fnList = Object.keys(styles).map(style => styles[style].styleFn);

export const customStyleFns = (fnList: any, colorspec: TSpecsColor) => (
	prefixedStyle: any,
) => {
	return fnList.reduce((css, fn) => {
		return { ...css, ...fn(prefixedStyle, colorspec) };
	}, emptyObject);
};

export const getCustomStyleFn = (colorspec: TSpecsColor) =>
	customStyleFns(fnList, colorspec);

export default customStyleMap;
