// @flow
import React, { useMemo, useEffect } from 'react';
import { EditorState, Editor } from 'draft-js';
import { useGoogleFonts } from '@graphite/fonts-lib';
import getFonts from './getFonts';

import Wrap from '../Wrap';
import type { TMinimalEditableProps } from '../constants/types';
import { selectAll } from './draft-utils';
import { customStyleMap, getCustomStyleFn } from './style-map';
import { getBlockRenderMap } from './blockRenderMap';
import blockRendererFn from './blockRendererFn';
import { createWithContent } from './from-raw';

const withEditable = <T: TMinimalEditableProps>(): React$ComponentType<T> => {
	const WithEditable = (props: T, ref) => {
		const {
			data,
			widgetspec,
			colorspec,
			gridspec,
			effectspec,
			dragContainer,
			dragFlip,
			currentDevice,
			direction,
			position,
			editorState,
			setEditorState,
		} = props;

		const isEdit = props.widgetMode === 'widget-edit';

		const editorRef = React.useRef(null);

		const focus = React.useCallback(() => {
			if (editorRef.current) editorRef.current.focus();
		}, [editorRef]);

		/**
			issues 37
		 */
		const [prevWidgetMode, setPrevWidgetMode] = React.useState(props.widgetMode);
		React.useEffect(() => {
			if (props.widgetMode !== prevWidgetMode) {
				setPrevWidgetMode(props.widgetMode);

				const changeSelection = selectAll(editorState);

				switch (props.widgetMode) {
					case 'widget-edit':
						setEditorState(changeSelection());
						// FixMe: временное решение для виджета текст
						// при входе в редактирвоание виджета теряется фокус
						if (editorRef.current) {
							editorRef.current.focus();
						}
						break;
					case 'widget-resize':
						setEditorState(changeSelection(true));
						break;
				}
			}
		}, [
			editorState,
			setEditorState,
			props.widgetMode,
			prevWidgetMode,
			setPrevWidgetMode,
			editorRef,
		]);

		const blockRenderMap = useMemo(
			() =>
				widgetspec &&
				colorspec &&
				gridspec &&
				effectspec &&
				getBlockRenderMap({
					device: currentDevice,
					designs: data.designs,
					widgetspec,
					colorspec,
					gridspec,
					effectspec,
					unit: gridspec.unit,
				}),
			[colorspec, currentDevice, data.designs, gridspec, widgetspec, effectspec],
		);

		useEffect(() => {
			setEditorState(editorState => {
				const contentState = editorState.getCurrentContent();
				const newEditorStateInstance = createWithContent(contentState);

				return EditorState.set(newEditorStateInstance, {
					selection: editorState.getSelection(),
					undoStack: editorState.getUndoStack(),
					redoStack: editorState.getRedoStack(),
					lastChangeType: editorState.getLastChangeType(),
				});
			});
		}, [blockRenderMap, setEditorState]);

		const fonts = React.useMemo(
			() =>
				widgetspec &&
				getFonts({
					id: data._id,
					raw: data.raw,
					designs: data.designs || {},
					widgetspec,
				}),
			[widgetspec, data._id, data.raw, data.designs],
		);

		useGoogleFonts(fonts, {
			isFrame: true,
		});

		if (!widgetspec || !colorspec || !gridspec || !effectspec) return null;

		return (
			<Wrap
				data={data}
				widgetspec={widgetspec}
				colorspec={colorspec}
				gridspec={gridspec}
				effectspec={effectspec}
				direction={direction}
				position={position}
				onClick={focus}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...dragContainer}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...dragFlip}
				ref={ref}
			>
				<Editor
					readOnly={!isEdit}
					customStyleFn={getCustomStyleFn(colorspec)}
					customStyleMap={customStyleMap}
					editorState={editorState}
					blockRenderMap={blockRenderMap}
					blockRendererFn={blockRendererFn}
					onChange={setEditorState}
					ref={editorRef}
				/>
			</Wrap>
		);
	};

	WithEditable.displayName = 'withTextEditable(Text)';

	return React.memo(React.forwardRef(WithEditable));
};

export default withEditable;
