// @flow

import mapKeys from 'lodash/fp/mapKeys';
import mapValues from 'lodash/fp/mapValues';
import type { TId, TOrder, TOrderDevice, TEntityChildren } from '@graphite/types';

export default (order: TOrder = {}, changed: $ReadOnly<TEntityChildren> = {}): TOrder =>
	(mapValues(
		(deviceOrder: TOrderDevice): TOrderDevice =>
			(mapKeys.convert({ cap: false })(
				(v: ?TId, k: TId): TId => changed[k] || k,
				deviceOrder,
			): TOrderDevice),
		order,
	): TOrder);
