// @flow
import React from 'react';
import {
	type ContentState as TContentState,
	type ContentBlock as TContentBlock,
} from 'draft-js';

function findLinkEntities(
	contentBlock: TContentBlock,
	callback: (start: number, end: number) => void,
	contentState: TContentState,
) {
	contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity();
		return (
			typeof entityKey === 'string' &&
			contentState.getEntity(entityKey).getType() === 'LINK'
		);
	}, callback);
}

type TProps = $ReadOnly<{|
	contentState: TContentState,
	entityKey: string,
	children: React$Node,
|}>;

const Link = ({ contentState, entityKey, children }: TProps) => {
	const { url, target } = contentState.getEntity(entityKey).getData();
	return (
		<a href={url} target={target}>
			{children}
		</a>
	);
};

export default () => ({
	strategy: findLinkEntities,
	component: React.memo<TProps>(Link),
});
