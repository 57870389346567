// @flow

import React, { type Node, useCallback, memo, useRef, useMemo } from 'react';
import { Flex, Box } from '@graphite/uneon';

import { zIndices, transitions } from '@graphite/constants';
import type { TPropsDragSide } from '../constants/types';

const areaSx = {
	position: 'absolute',
	width: '100%',
	opacity: '0',

	zIndex: zIndices.blockPadding,
	cursor: 'ns-resize',
	pointerEvents: 'auto',
	touchAction: 'none',

	transitionDuration: transitions.blockPadding.hideDuration,
	transitionTimingFunction: transitions.blockPadding.hideTiming,
	transitionDelay: transitions.blockPadding.hideDelay,
	transitionProperty: 'opacity',
	':before': {
		content: '""',
		width: '100%',
		height: '100%',
		minHeight: '10px',
	},
	':hover': {
		opacity: '1',
		transitionDuration: transitions.blockPadding.showDuration,
		transitionTimingFunction: transitions.blockPadding.showTiming,
		transitionDelay: transitions.blockPadding.showDelay,
	},
	':active:before': {
		backgroundColor: 'spec.blue10',
		opacity: '0.2',
	},
	':active:after': {
		content: '""',
		cursor: 'ns-resize',
		position: 'fixed',
		zIndex: 2,
		top: '0',
		left: '0',
		bottom: '0',
		right: '0',
	},
};

const areaTopSx = {
	...areaSx,
	top: '0',
};

const areaBottomSx = {
	...areaSx,
	bottom: '0',
	alignItems: 'flex-end',
};

const handlerSx = {
	position: 'absolute',
	height: '3px',
	width: '24px',
	top: '50%',
	left: '50%',
	marginLeft: '-12px',
	marginTop: '-1px',
	borderRadius: 'rounded.all',
	backgroundColor: 'spec.blue10',
	boxShadow: 'inset 0 0 0 1px #fff',
	':hover': {
		backgroundColor: 'spec.blue30',
	},
};

const allowance = 10; // Когда паддинг маленьй, его другие контролы перекрывают наглухо

function DragSide(props: TPropsDragSide): Node {
	const { dir, resizeUpdate, resizeStart, resizeStop, height } = props;

	const swipeY = useRef(0);
	const style = useMemo(
		() => ({
			height: `${height}px`,
			zIndex:
				height <= allowance ? zIndices.blockPaddingLow : zIndices.blockPadding,
		}),
		[height],
	);

	const onResize = useCallback(y => resizeUpdate(dir, -(swipeY.current - y)), [
		resizeUpdate,
		dir,
	]);

	const onMouseUpdate = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			onResize(e.pageY);
		},
		[onResize],
	);

	const onTouchUpdate = useCallback(
		(e: TouchEvent) => {
			if (e.touches.length === 1 && e.changedTouches.length === 1) {
				const touch = e.touches ? e.touches[0] : {};
				onResize(touch.pageY);
			}
		},
		[onResize],
	);

	const onMouseStop = useCallback(
		(e: MouseEvent) => {
			e.stopPropagation();

			document.removeEventListener('mousemove', onMouseUpdate);
			document.removeEventListener('mouseup', onMouseStop);

			resizeStop();
		},
		[resizeStop, onMouseUpdate],
	);

	const onTouchStop = useCallback(
		(e: TouchEvent) => {
			e.stopPropagation();

			document.removeEventListener('touchmove', onTouchUpdate, {
				passive: true,
			});
			document.removeEventListener('touchend', onTouchStop);

			resizeStop();
		},
		[resizeStop, onTouchUpdate],
	);

	const onMouseStart = useCallback(
		(e: MouseEvent) => {
			e.preventDefault();
			e.stopPropagation();

			swipeY.current = e.pageY;

			document.addEventListener('mousemove', onMouseUpdate);
			document.addEventListener('mouseup', onMouseStop);

			resizeStart(dir);
		},
		[dir, resizeStart, onMouseUpdate, onMouseStop],
	);

	const onTouchStart = useCallback(
		(e: TouchEvent) => {
			e.preventDefault();
			e.stopPropagation();

			if (e.touches.length === 1 && e.changedTouches.length === 1) {
				const touch = e.touches ? e.touches[0] : {};

				swipeY.current = touch.pageY;

				document.addEventListener('touchmove', onTouchUpdate, {
					passive: false,
				});
				document.addEventListener('touchend', onTouchStop);
				resizeStart(dir);
			}
		},
		[dir, resizeStart, onTouchUpdate, onTouchStop],
	);

	const dirSx = dir === 'top' ? areaTopSx : areaBottomSx;

	return (
		<Flex
			onMouseDown={onMouseStart}
			onTouchStart={onTouchStart}
			sx={dirSx}
			style={style}
		>
			<Box sx={handlerSx} />
		</Flex>
	);
}

export default memo<TPropsDragSide>(DragSide);
