// @flow
import React from 'react';
import getDisplayName from '@graphite/get-display-name';

/**
	Фильтрует пропсы, оставляет только те пропсы, которые нужны
	обычному компоненту блока. Чтобы служебные пропсы (нужные только для конструктора)
	не долетели до компонента, используемого на опубликованном сайте.
 */

const withFilter = <TPropsEdit, TProps>(
	filterProps: TPropsEdit => TProps,
	Component: React$AbstractComponent<TProps, mixed>,
): React$ComponentType<TPropsEdit> => {
	const Filter = (props, ref) => {
		const propsNew = filterProps(props);
		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...propsNew} ref={ref} />;
	};

	Filter.displayName = `withFilter(${getDisplayName(Component)})`;

	return React.memo(React.forwardRef(Filter));
};

export default withFilter;
