// @flow
import React from 'react';
import { DraggableCore } from 'react-draggable';
import styled from '@emotion/styled';
import { ThemeProvider } from 'emotion-theming';
import { themes, Flex, Box, Text, Button, Icon } from '@graphite/uneon';
import MonacoEditor, { monaco } from '@monaco-editor/react';

const Body = styled(Box)`
	transition: transform 0.3s;
	transform: translateX(${({ isShow, width }) => (isShow ? 0 : width)}px);
`;

const Resize = styled.div`
	position: absolute;
	top: 0;
	left: -10px;
	height: 100%;
	width: 20px;
	z-index: 2;
	cursor: ew-resize;

	&:before {
		content: '';
		position: fixed;
		top: 0;
		left: -100vw;
		right: 0;
		bottom: 0;
		display: none;
	}

	&:active {
		&:before {
			display: block;
		}
	}
`;

const IconBox = styled(Box)`
	position: relative;
`;

const IconButton = styled(Button)`
	position: absolute;
	right: -6px;
	top: -3px;
`;

monaco.config({
	paths: {
		// На 20 версии не работает авторесайз редактора, поэтому 19
		vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.19.0/min/vs',
	},
});

monaco.init().then(monacoInstance =>
	monacoInstance.editor.defineTheme('graphite', {
		base: 'vs-dark',
		inherit: true,
		rules: [{ background: themes.dark.colors.bg.primary }],
		colors: {
			'editor.background': themes.dark.colors.bg.primary,
		},
	}),
);

const options = {
	selectOnLineNumbers: true,
	minimap: {
		enabled: false,
	},
	automaticLayout: true,
};

type TProps = $ReadOnly<{|
	code: string,
	onClose: MouseEvent => void,
	onChange: string => void,
	className?: string,
|}>;

const CodeEditor = (props: TProps) => {
	const { code, onClose, onChange, className } = props;

	// Изначальное значение, чтобы лишний раз не перерендеривать редактор
	const valueInitial = React.useRef(code);
	const value = React.useRef(code);

	const handleEditorDidMount = React.useCallback(
		(_, editor) => {
			if (editor)
				editor.onDidChangeModelContent(() => {
					// берём текущее значение редактора
					const currentValue = editor.getValue();
					// сравниваем со старым значением
					if (currentValue !== value.current) {
						onChange(currentValue);
						value.current = currentValue;
					}
				});
		},
		[onChange],
	);

	const [width, setWidth] = React.useState(600);

	const onDragHandler = React.useCallback((e, data) => {
		setWidth(widthCurrent => widthCurrent - (data.lastX + data.deltaX));
	}, []);

	const [isShow, setShow] = React.useState(false);
	React.useEffect(() => {
		setTimeout(() => setShow(true), 0);
	}, []);

	return (
		<ThemeProvider theme={themes.dark}>
			<Body bg="bg.primary" className={className} width={width} isShow={isShow}>
				<DraggableCore onDrag={onDragHandler}>
					<Resize />
				</DraggableCore>
				<Flex p="18px 24px">
					<Box width={1 / 2}>
						<Text variant="headlinemd" color="text.primary">
							HTML
						</Text>
					</Box>
					<IconBox width={1 / 2} textAlign="right">
						<IconButton variant="primaryflat.icon.sm" onClick={onClose}>
							<Icon name="cross" />
						</IconButton>
					</IconBox>
				</Flex>
				<MonacoEditor
					value={valueInitial.current}
					editorDidMount={handleEditorDidMount}
					language="html"
					options={options}
					theme="graphite"
				/>
			</Body>
		</ThemeProvider>
	);
};

export default React.memo<TProps>(CodeEditor);
