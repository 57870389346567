// @flow

import React from 'react';
import { Toolbar, Box } from '@graphite/uneon';

import useDefaultDevice from 'Editor/libs/use-default-device';

import type { TPropsRightSide } from './constants/types';
import Actions from './Actions';
import Layout from './Layout';
import Design from './Design';

export const rightSideSx = {
	pointerEvents: 'auto',
	position: 'absolute',
	right: '12px',
	top: '12px',
	zIndex: 2,
};

const ControlsRightSide = (props: TPropsRightSide): React$Node => {
	const {
		currentDevice,
		gridspec,
		colorspec,
		effectspec,
		widgetspec,
		data,
		setData,
		containerId,
		instanceId,
		originId,
		dispatch,
		openedPanel,
		setOpenedPanel,
		insertImage,
		removeImage,
		resetImage,
		images,
		uploads,
	} = props;
	const isDefaultDevice = currentDevice === useDefaultDevice();

	return (
		<Box sx={rightSideSx}>
			<Toolbar variant="sm">
				<Design
					currentDevice={currentDevice}
					colorspec={colorspec}
					effectspec={effectspec}
					widgetspec={widgetspec}
					gridspec={gridspec}
					dispatch={dispatch}
					data={data}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					openedPanel={openedPanel}
					setOpenedPanel={setOpenedPanel}
					isDefaultDevice={isDefaultDevice}
					insertImage={insertImage}
					resetImage={resetImage}
					removeImage={removeImage}
					images={images}
					uploads={uploads}
				/>
				<Layout
					currentDevice={currentDevice}
					gridspec={gridspec}
					dispatch={dispatch}
					data={data}
					setData={setData}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					openedPanel={openedPanel}
					setOpenedPanel={setOpenedPanel}
					isDefaultDevice={isDefaultDevice}
				/>
				<Actions
					dispatch={dispatch}
					data={data}
					containerId={containerId}
					instanceId={instanceId}
					originId={originId}
					isDefaultDevice={isDefaultDevice}
				/>
			</Toolbar>
		</Box>
	);
};

export default React.memo<TPropsRightSide>(ControlsRightSide);
