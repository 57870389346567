// @flow
import React from 'react';
import { type EditorState as TEditorState } from 'draft-js';
import { Toolbar, ToolbarBtn, ToolbarDelimitr, Popper } from '@graphite/uneon';

import InlineStylesBtn from './InlineStylesBtn';
import BlockStylesBtn from './BlockStylesBtn';
import RemoveStylesBtn from './RemoveStylesBtn';

type TProps = $ReadOnly<{|
	editorState: TEditorState,
	setEditorState: TEditorState => void,
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
|}>;

const InlineStyles = (props: TProps) => {
	const { editorState, setEditorState, setOpenedPanel, openedPanel } = props;

	const inlineStylesBtnEl = React.useRef(null);
	const handleInlineStyle = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel =>
				openedPanel === 'inlineStyle' ? null : 'inlineStyle',
			);
		},
		[setOpenedPanel],
	);

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleInlineStyle}
				iconName="bold"
				ref={inlineStylesBtnEl}
				isActive={openedPanel === 'inlineStyle'}
			/>

			<Popper
				isOpen={openedPanel === 'inlineStyle'}
				anchorEl={inlineStylesBtnEl}
				offsetTop={-10}
				placement="top"
			>
				<Toolbar>
					<InlineStylesBtn
						iconName="bold"
						editorState={editorState}
						setEditorState={setEditorState}
						type="fontWeight"
						value="bold"
					/>
					<InlineStylesBtn
						iconName="italic"
						editorState={editorState}
						setEditorState={setEditorState}
						type="fontStyle"
						value="italic"
					/>
					<InlineStylesBtn
						iconName="underline"
						editorState={editorState}
						setEditorState={setEditorState}
						type="textDecoration"
						value="underline"
					/>
					<InlineStylesBtn
						iconName="strikethrough"
						editorState={editorState}
						setEditorState={setEditorState}
						type="textDecoration"
						value="line-through"
					/>

					<ToolbarDelimitr />

					<BlockStylesBtn
						iconName="list-ordered"
						editorState={editorState}
						setEditorState={setEditorState}
						value="ordered-list-item"
					/>
					<BlockStylesBtn
						iconName="list-unordered"
						editorState={editorState}
						setEditorState={setEditorState}
						value="unordered-list-item"
					/>

					<ToolbarDelimitr />

					<InlineStylesBtn
						iconName="subscript"
						editorState={editorState}
						setEditorState={setEditorState}
						type="verticalAlign"
						value="sub"
					/>
					<InlineStylesBtn
						iconName="superscript"
						editorState={editorState}
						setEditorState={setEditorState}
						type="verticalAlign"
						value="super"
					/>

					<ToolbarDelimitr />

					<RemoveStylesBtn
						editorState={editorState}
						setEditorState={setEditorState}
					/>
				</Toolbar>
			</Popper>
		</>
	);
};

export default React.memo<TProps>(InlineStyles);
