// @flow

import _ from 'lodash/fp';

export default (str: string): string => {
	const sp = '-'; // separator
	const translateChars = {
		а: 'a',
		б: 'b',
		в: 'v',
		г: 'g',
		д: 'd',
		е: 'e',
		ё: 'e',
		ж: 'zh',
		з: 'z',
		и: 'i',
		й: 'j',
		к: 'k',
		л: 'l',
		м: 'm',
		н: 'n',
		о: 'o',
		п: 'p',
		р: 'r',
		с: 's',
		т: 't',
		у: 'u',
		ф: 'f',
		х: 'h',
		ц: 'c',
		ч: 'ch',
		ш: 'sh',
		щ: 'shch',
		ъ: "'",
		ы: 'y',
		ь: '',
		э: 'e',
		ю: 'yu',
		я: 'ya',
		'\u00AB': sp, // «
		'\u00BB': sp, // »
		' ': sp,
		_: sp,
		'`': sp,
		'~': sp,
		'!': sp,
		'@': sp,
		'#': sp,
		$: sp,
		'%': sp,
		'^': sp,
		'&': sp,
		'*': sp,
		'(': sp,
		')': sp,
		'-': sp,
		'=': sp,
		'+': sp,
		'[': sp,
		']': sp,
		'\\': sp,
		'|': sp,
		'/': sp,
		'.': sp,
		',': sp,
		'{': sp,
		'}': sp,
		"'": sp,
		'"': sp,
		';': sp,
		':': sp,
		'?': sp,
		'<': sp,
		'>': sp,
		'№': sp,
	};

	return (
		_.reduce(
			(text, value) => {
				// Транслит
				if (translateChars[value]) {
					if (text[text.length - 1] === sp && translateChars[value] === sp)
						return [...text]; // trim

					return [...text, translateChars[value]];
				}

				if (value.match(/[a-z0-9]/i)) {
					return [...text, value];
				}

				return [...text];
			},
			[],
			str.toLowerCase().split(''),
		)
			.join('')
			.replace(/^-/, '')
			.replace(/-$/, '') || ''
	);
};
