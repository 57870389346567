// @flow
import { min, max } from 'lodash/fp';
import { getBgColor, getPropValue } from './lib';

const MAX_DRAG_SIZE = 800;
const MAX_DRAG_SCALE = 0.8;
const DRAGGABLE_OPACITY = 0.75;
const DRAG_DIFF_HEIGHT = 35;

let ghostImage = null;

export default (event: DragEvent, node: HTMLElement, frame: ?HTMLElement) => {
	const rectNode = node.getBoundingClientRect();
	const rect = {
		width: rectNode.width,
		height: rectNode.height,
		top: rectNode.top,
		left: rectNode.left,
	};

	if (!document.contains(node) && frame) {
		const rectFrame = frame.getBoundingClientRect();
		rect.left += rectFrame.left;
		rect.top += rectFrame.top - DRAG_DIFF_HEIGHT;
	}

	if (ghostImage) ghostImage.remove();
	ghostImage = node.ownerDocument.createElement('div');
	const ghostImageScaled = node.ownerDocument.createElement('div');

	const dragClone = node.cloneNode(true);
	const dragCss = getComputedStyle(node);
	const margin = getPropValue(dragCss, 'margin');
	const maxSize = max([rect.width, rect.height, 1]);
	const scaleRatio = min([MAX_DRAG_SIZE / maxSize, MAX_DRAG_SCALE]);

	ghostImage.style.cssText = `
		position: absolute;
		left: -9000px;
		top: -9000px;
		width: ${(rect.width + margin.left + margin.right) * scaleRatio}px;
		height: ${(rect.height + margin.top + margin.bottom) * scaleRatio}px;
		background: ${getBgColor(node, DRAGGABLE_OPACITY)};
	`;

	ghostImageScaled.style.cssText = `
		position: relative;
		display: flex;
		flex-direction: column;
		transform: scale(${scaleRatio});
		transform-origin: 0 0;
		opacity: ${DRAGGABLE_OPACITY};
	`;

	dragClone.style.cssText = `${node.style.cssText}
		opacity: 1;
		width: ${node.clientWidth}px;
		height: ${node.clientHeight}px;
		padding: ${dragCss.getPropertyValue('padding')};
		border: ${dragCss.getPropertyValue('border')};
	`;

	ghostImage.appendChild(ghostImageScaled);
	ghostImageScaled.appendChild(dragClone);

	if (!node.ownerDocument.body) return;
	node.ownerDocument.body.appendChild(ghostImage);

	const x = (event.clientX - rect.left + margin.left) * scaleRatio;
	const y = (event.clientY - rect.top + margin.top) * scaleRatio;

	if (!event.dataTransfer) return;
	event.dataTransfer.setDragImage(ghostImage, x, y);

	requestAnimationFrame(() => {
		if (ghostImage) ghostImage.remove();
		ghostImage = null;
	});
};
