// @flow
import React from 'react';
import { SelectSimple, ListItem, Text } from '@graphite/uneon';

type TProps = $ReadOnly<{|
	state: string,
	onChange: (?string) => void,
	t: string => string,
|}>;

const stateList = [
	{ name: 'normal', label: 'Normal' },
	{ name: 'hover', label: 'Hover' },
	{ name: 'active', label: 'Active' },
	{ name: 'focus', label: 'Focus' },
];

function SelectState({ state, onChange, t }: TProps) {
	const listProps = React.useMemo(
		() => ({
			items: stateList,
			active: state,
		}),
		[state],
	);

	const clickBound = React.useCallback(
		(e, name) => {
			if (onChange && typeof name === 'string') {
				onChange(name);
			}
		},
		[onChange],
	);

	return (
		<ListItem>
			<Text variant="bodysm" color="text.primary">
				{t('State')}
			</Text>
			<SelectSimple size="sm" list={listProps} onClick={clickBound} />
		</ListItem>
	);
}

export default React.memo<TProps>(SelectState);
