// @flow
import map from 'lodash/fp/map';
import set from 'lodash/fp/set';

import type { TButtonEmbedNamed } from '@graphite/types';

export const deviceTabsSm: $ReadOnlyArray<TButtonEmbedNamed> = [
	{
		name: 'phone_p',
		icons: [
			{
				name: 'mobile',
				iconSize: 18,
				rotation: 0,
				title: 'Phone Portrait',
			},
		],
	},
	{
		name: 'phone_l',
		icons: [
			{
				name: 'mobile',
				iconSize: 18,
				rotation: 90,
				title: 'Phone Landscape',
			},
		],
	},
	{
		name: 'tablet_p',
		icons: [
			{
				name: 'tablet',
				iconSize: 18,
				rotation: 0,
				title: 'Tablet Portrait',
			},
		],
	},
	{
		name: 'tablet_l',
		icons: [
			{
				name: 'tablet',
				iconSize: 18,
				rotation: 90,
				title: 'Tablet Landscape',
			},
		],
	},
	{
		name: 'desktop',
		icons: [
			{
				name: 'desktop',
				iconSize: 18,
				rotation: 0,
				title: 'Desktop',
			},
		],
	},
	{
		name: 'desktop_hd',
		icons: [
			{
				name: 'desktop',
				iconSize: 18,
				rotation: 0,
				title: 'Desktop HD',
			},
		],
	},
];

const mdIconTraits = { iconSize: 24, size: 'md' };
export const deviceTabsMd: $ReadOnlyArray<TButtonEmbedNamed> = (map(
	(tab: TButtonEmbedNamed): TButtonEmbedNamed =>
		(set(
			'icons',
			!tab.icons || typeof tab.icons === 'string'
				? tab.icons
				: map(icon => ({ ...icon, ...mdIconTraits }), tab.icons),
			tab,
		): TButtonEmbedNamed),
	deviceTabsSm,
): $ReadOnlyArray<TButtonEmbedNamed>);

export default {};
