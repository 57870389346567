// @flow
import { connect } from 'react-redux';
import { withAbsolute } from '@graphite/abs-grid';
import type { TWidgetOwnProps } from '@graphite/types';

import type { TConnectProps } from './constants/types';
import mapStateToProps from './libs/mapStateToProps';
import Stack from './Stack';

export default connect<TConnectProps, TWidgetOwnProps, _, _, _, _>(mapStateToProps)(
	withAbsolute<TConnectProps>()(Stack),
);
