// @flow
import {
	useDispatch as useDispatchOriginal,
	useSelector as useSelectorOriginal,
} from 'react-redux';
import type { TAction, Dispatch } from '@graphite/types';

export const useDispatch = () => {
	return useDispatchOriginal<Dispatch<TAction>>();
};

export const useSelector = useSelectorOriginal;
