// @flow
import firebase from '../firebase';

export const auth = firebase.auth();

export const signInWithPopup = auth.signInWithPopup.bind(auth);
export const createUserWithEmailAndPassword = auth.createUserWithEmailAndPassword.bind(
	auth,
);
export const signInWithEmailAndPassword = auth.signInWithEmailAndPassword.bind(auth);

// FixMe: поменять на язык браузера
auth.languageCode = 'en';

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

export default auth;
