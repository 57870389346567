// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToolbarBtn, PopupWhite } from '@graphite/uneon';
import Library from '@graphite/library';
import type {
	TLinkReference,
	TLibraryValue,
	TLibLinkValue,
	TSpecsColor,
	TSpecsGrid,
} from '@graphite/types';

type TProps = $ReadOnly<{|
	setOpenedPanel: (?(((?string) => ?string) | string)) => void,
	openedPanel: ?string,
	link: ?TLinkReference,
	save: (string, string | Object) => void,
	colorspec: TSpecsColor,
	gridspec: TSpecsGrid,
	genId: string => string,
|}>;

const libLinkConf = { link: {} };

const ToolbarLinkButton = ({
	openedPanel,
	setOpenedPanel,
	save,
	link,
	colorspec,
	gridspec,
	genId,
}: TProps) => {
	const { t } = useTranslation();
	const btnEl = React.useRef(null);
	const handleToogleOpen = React.useCallback(
		e => {
			e.preventDefault();

			setOpenedPanel(openedPanel => (openedPanel === 'link' ? null : 'link'));
		},
		[setOpenedPanel],
	);
	const disableActive = React.useCallback(
		(e: MouseEvent) => {
			e.preventDefault();

			setOpenedPanel(null);
		},
		[setOpenedPanel],
	);

	const handleChangeLink = React.useCallback(
		(value: TLibraryValue) => {
			if (value.kind !== 'link') {
				return;
			}
			setOpenedPanel(null);
			save('link', value.value);
		},
		[save, setOpenedPanel],
	);

	const isActive = openedPanel === 'link';

	const linkValue: TLibLinkValue = React.useMemo(
		() => ({
			kind: 'link',
			value: {
				href: (link && link.href) || '',
				isNewTab: (link && link.isNewTab) || false,
			},
		}),
		[link],
	);

	return (
		<>
			<ToolbarBtn
				onMouseDown={handleToogleOpen}
				iconName="link"
				ref={btnEl}
				isActive={isActive}
			/>
			<PopupWhite
				isOpen={isActive}
				onClose={disableActive}
				anchorEl={btnEl}
				offsetTop={10}
				isFixed
				mutex="settings"
			>
				{isActive && (
					<Library
						t={t}
						config={libLinkConf}
						onChange={handleChangeLink}
						value={linkValue}
						colorspec={colorspec}
						gridspec={gridspec}
						genId={genId}
					/>
				)}
			</PopupWhite>
		</>
	);
};

export default React.memo<TProps>(ToolbarLinkButton);
