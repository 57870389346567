// @flow
import React from 'react';

export type TContext = {|
	window: ?Object,
	document: ?Object,
	elBox: { current: ?HTMLElement },
	setData: (Object, Object) => void,
|};

const Context = React.createContext<TContext>({
	window: null,
	document: null,
	elBox: {
		current: null,
	},
	setData: () => {},
});

export default Context;
