// @flow
import React from 'react';

import Context from './Context';

type TProps = $ReadOnly<{| window: ?Object, document: ?Object |}>;

const Setter = ({ window, document }: TProps) => {
	const { setData } = React.useContext(Context);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	React.useEffect(() => setData(window, document), [setData]);

	return null;
};

export default React.memo<TProps>(Setter);
