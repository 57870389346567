// @flow
import { useState, useCallback, useMemo } from 'react';

export default function useOveride<T>(prop: T): [T, (?T) => void] {
	const [getProp, setPropSource] = useState<T>(() => prop);

	useMemo(() => {
		setPropSource(prop);
	}, [prop]);

	const setProp = useCallback(
		(propNew: ?T) => {
			if (propNew) {
				setPropSource(propNew);
			} else {
				setPropSource(prop);
			}
		},
		[prop],
	);

	return [getProp, setProp];
}
