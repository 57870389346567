// @flow
import { useContext } from 'react';
import ResizeContext from './resizeReducer';
import useActions from './useActions';

import type {
	TUseActionsResizeDevice,
	TUseActionsResizeRef,
	TResizeUseActions,
	TRequiredControls,
} from '../types';

export default ({
	refWrap,
	currentDevice,
	requiredControls,
	direction,
}: {
	refWrap: TUseActionsResizeRef,
	currentDevice: TUseActionsResizeDevice,
	requiredControls: TRequiredControls,
	direction?: string,
}): TResizeUseActions => {
	const [resizeData, dispatch] = useContext(ResizeContext);

	return {
		actions: useActions({
			refWrap,
			dispatch,
			currentDevice,
			requiredControls,
			direction,
		}),
		resizeData,
	};
};
